import * as breakpoints from "./breakpoints";
import * as fonts from "./fonts";

const colors = {
  black: "#000",
  white: "#FFF",
  background: "var(--background)",
  text: "var(--foreground)",
  border: "var(--border)",
  success: "#219653",
  error: "#EB5757",
  pageTheme: {
    White: {
      background: "#FFFFFF",
      foreground: "#3A3A1F",
      border: "#808080",
      modelBorder: "#16160e",
    },
    Brown: {
      background: "#291A16",
      foreground: "#CCA99F",
      border: "#CCA99F",
    },
    Green: {
      background: "#3A3A1F",
      foreground: "#CFCFB0",
      border: "#CFCFB0",
    },
    Blue: {
      background: "#042442",
      foreground: "#A7B6C4",
      border: "#A7B6C4",
    },
  },
};

const gutter = {
  sm: 15,
  md: 25,
};

const gridStyle = {
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gap: gutter.sm,
  [breakpoints.up("md")]: {
    gridTemplateColumns: "repeat(7, 1fr)",
    gap: gutter.md,
  },
};

const spacing = (count = 1) => {
  return count * 5;
};

const globalStyles = {
  "*, *::before, *::after": {
    boxSizing: "border-box",
    "-webkit-tap-highlight-color": "transparent",
  },
  ":root": {
    "--scrollbar-width": "0px",
    "--background": colors.pageTheme.White.background,
    "--foreground": colors.pageTheme.White.foreground,
    "--border": colors.pageTheme.White.border,
  },
  html: {
    lineHeight: 1.5,
  },
  body: {
    padding: 0,
    margin: 0,
    backgroundColor: colors.background,
    color: colors.text,
    width: "100%",
    "-webkitFontSmoothing": "antialiased",
    "-mozOsxFontSmoothing": "grayscale",
    fontFamily: fonts.body,
    fontWeight: fonts.bodyFontWeight,
    fontSize: 16,
    lineHeight: 1.5,
  },
  "h1,h2,h3,h4,h5,h6": {
    marginTop: 0,
    marginBottom: "0.4em",
    textTransform: "uppercase",
    fontFamily: fonts.heading,
    fontWeight: fonts.headingFontWeight,
  },
  h1: {
    fontSize: 45,
    lineHeight: 0.95,
    [breakpoints.up("md")]: {
      fontSize: "calc(35px + (65 - 25) * ((100vw - 320px) / (1920 - 320)))",
    },
    [breakpoints.up("lg")]: {
      fontSize: 65,
    },
  },
  h2: {
    lineHeight: 0.9,
    letterSpacing: 0.65,
    fontSize: 35,
    [breakpoints.up("md")]: {
      fontSize: "calc(35px + (65 - 35) * ((100vw - 320px) / (1920 - 320)))",
    },
    [breakpoints.up("lg")]: {
      fontSize: 65,
    },
  },
  h3: {
    fontSize: 48,
  },
  h4: {
    fontSize: 32,
  },
  h5: {
    fontSize: 20,
    marginBottom: spacing(5),
    textTransform: "none",
  },
  h6: {
    fontSize: 15,
    textTransform: "none",
  },
  p: {
    fontSize: 15,
    margin: [0, 0, 15],
  },
  blockquote: {
    borderLeft: "4px solid var(--border)",
    margin: "1.5em 10px",
    padding: "0.5em 10px",
  },
  ".srOnly": {
    border: "0 !important",
    clip: "rect(1px, 1px, 1px, 1px) !important",
    clipPath: "inset(50%) !important",
    height: "1px !important",
    margin: "-1px !important",
    overflow: "hidden !important",
    padding: "0 !important",
    position: "absolute !important",
    width: "1px !important",
    whiteSpace: "nowrap !important",
  },
  a: {
    color: "currentColor",
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "none",
    },
  },
  button: {
    fontFamily: fonts.caption,
    fontWeight: fonts.captionFontWeight,
    textTransform: "uppercase",
    fontSize: 10,
    color: "currentColor",
  },
  ".scrollbar-track.scrollbar-track-y": {
    zIndex: 2000,
  },
  "main .scrollbar-track": {
    backgroundColor: "transparent",
    width: 5,
    borderRadius: 2,
  },
  "main .scrollbar-thumb.scrollbar-thumb-y": {
    width: 3,
    borderRadius: 2,
  },
  // CHAT BUTTON STYLES
  ".chatlio-title-bar": {
    display: "none",
  },
  ".chatlio-widget-body": {
    bottom: [10, "!important"],
    [breakpoints.up("md")]: {
      bottom: [60, "!important"],
      right: [10, "!important"],
    },
  },
};

const theme = {
  global: globalStyles,
  header: {
    height: {
      large: 72,
      small: 42,
    },
  },
  section: {
    marginBottom: {
      sm: spacing(15),
      md: spacing(20),
    },
  },
  fonts,
  breakpoints,
  colors,
  zIndex: {
    header: 1002,
    drawer: 1003,
    menu: 1000,
    toolbar: 900,
  },
  spacing,
  gutter,
  gridStyle,
  span: (cols, breakpoint = "sm", padding = 0) => {
    return `calc(((100vw - ${
      theme.gutter[breakpoint] * 8
    }px) / 7) * ${cols} + (${theme.gutter[breakpoint]}px * ${
      cols - 1
    }) + ${padding}px)`;
  },
  scrollbar: {
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: colors.border,
      borderRadius: 2,
    },
  },
  styles: {
    textIndent: spacing(5),
  },
};

export default theme;
