import React, { useEffect, useRef, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import gsap from 'gsap'
import { useSelector, useDispatch } from 'react-redux'
import { isMenuOpen, openMenu, closeMenu } from '../../state/slices/layout'
import { isCartOpen } from '../../state/slices/ui/cart'

export default function MenuButton () {
  const classes = useStyles()
  const ref = useRef()
  const open = useSelector(isMenuOpen)
  const cartOpen = useSelector(isCartOpen)
  const dispatch = useDispatch()
  const toggle = useCallback(() => {
    dispatch(open || cartOpen ? closeMenu() : openMenu())
  }, [dispatch, open, cartOpen])

  useEffect(() => {
    if (ref.current) {
      gsap.to(ref.current.children, { y: open || cartOpen ? '-100%' : '0%', duration: 0.45, ease: 'expo.out' })
    }
  }, [open, cartOpen])

  return (
    <button className={classes.button} onClick={toggle}>
      <div className={classes.labelContainer} ref={ref}>
        <span className={classes.menuLabel}>Menu</span>
        <span className={classes.closeLabel}>Close</span>
      </div>
    </button>
  )
}

const useStyles = createUseStyles({
  button: {
    cursor: 'pointer',
    color: 'currentColor',
    padding: 0,
    border: 'none',
    background: 'transparent',
    borderRadius: 0,
    textTransform: 'uppercase',
    fontSize: 12,
    lineHeight: 1.2,
    fontFamily: theme.fonts.body,
    letterSpacing: 0.5,
    textDecoration: 'none',
    minHeight: 42,
    display: 'inline=block',
    alignItems: 'center',
    position: 'relative'
  },
  labelContainer: {
    overflow: 'hidden',
    position: 'relative',
    display: 'block',
    height: '1.2em'
  },
  menuLabel: {
    position: 'relative',
    display: 'block'
  },
  closeLabel: {
    display: 'block',
    right: 0
  }
})
