const yFormatter = new Intl.DateTimeFormat('en', { year: 'numeric' })
const mFormatter = new Intl.DateTimeFormat('en', { month: 'short' })
const dFromatter = new Intl.DateTimeFormat('en', { day: 'numeric' })

export const formatDate = (dateString) => {
  const d = new Date(dateString)
  const ye = yFormatter.format(d)
  const mo = mFormatter.format(d)
  const da = dFromatter.format(d)
  return `${da} ${mo} ${ye}`
}

export const toSentenceCase = (value) => {
  if (!value) return value
  const result = value.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}
