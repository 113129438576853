import { combineReducers } from 'redux'
import cart from './cart'
import listings from './listings'
import chat from './chat'
import quoteDrawer from './quoteForm'
import fireGroupCalculatorDrawer from './fireGroupCalculatorDrawer'

export default combineReducers({
  listings,
  cart,
  chat,
  quoteDrawer,
  fireGroupCalculatorDrawer
})
