// This is a deprecated rule
/* eslint-disable jsx-a11y/no-onchange */
import React, { useCallback, useState, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../../style/theme'
import cn from 'classnames'
import get from 'lodash/get'
import CaretIcon from './caret.inline.svg'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import find from 'lodash/find'

export default function FilterSelect ({ title, filter, className, emptyText = 'All', onApplyFilter, filterValue, options }) {
  const classes = useStyles()

  const [open, setOpen] = useState()

  const containerRef = useOnClickOutside(useCallback(() => {
    setOpen(false)
  }, [setOpen]))

  const onChange = useCallback((e) => {
    onApplyFilter({ key: filter, value: e.target.value })
  }, [filter, onApplyFilter])

  const onToggleDropdown = useCallback(() => {
    setOpen(value => !value)
  }, [])

  const onItemClicked = useCallback((e, value) => {
    onApplyFilter({ key: filter, value })
    setOpen(false)
  }, [filter, onApplyFilter])

  const selectedTitle = useMemo(() => get(find(options, o => o.value === filterValue), ['text'], title), [title, options, filterValue])

  return (
    <div className={cn(className, classes.item)} ref={containerRef}>
      <div className={classes.selectWrapper}>
        <select value={filterValue || ''} onChange={onChange} className={classes.select} placeholder='hello world'>
          <option value=''>{title}</option>
          {options && options.map(({ text, value }) => (
            <option key={value} value={value}>{text}</option>
          ))}
        </select>
        <CaretIcon className={classes.selectCaret} />
      </div>
      <div className={cn(classes.dropdown, { open })}>
        <button className={classes.dropdownItem} onClick={(e) => onItemClicked(e, '')}>{emptyText}</button>
        {options && options.map(({ text, value }) => (
          <button className={classes.dropdownItem} key={value} onClick={(e) => onItemClicked(e, value)}>{text}</button>
        ))}
      </div>
      <button className={cn(className, classes.button, { open })} onClick={onToggleDropdown}>
        <span>{selectedTitle}</span>
        <CaretIcon className={cn(classes.itemIcon, { open })} />
      </button>
    </div>
  )
}

const useStyles = createUseStyles({
  item: {
    position: 'relative',
    height: '100%',
    borderRight: [1, 'solid', theme.colors.border],
    [theme.breakpoints.up('md')]: {
      border: 'none',
      gridColumn: 'span 1',
      width: '100%'
    }
  },
  button: {
    position: 'absolute',
    margin: 0,
    padding: 0,
    borderRadius: 0,
    cursor: 'pointer',
    fontFamily: theme.fonts.caption,
    fontSize: 10,
    textTransform: 'uppercase',
    alignItems: 'center',
    bottom: 0,
    top: 0,
    border: 'none',
    borderRight: [1, 'solid', 'transparent'],
    borderLeft: [1, 'solid', 'transparent'],
    backgroundColor: theme.colors.background,
    display: 'none',
    transition: 'border-color 0.15s ease-in-out',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      margin: [0, 0, 0, -theme.gutter.md],
      padding: [0, theme.gutter.md],
      width: `calc(100% + ${theme.gutter.md}px)`
    },
    '&:hover, &.open': {
      borderRight: [1, 'solid', theme.colors.border],
      borderLeft: [1, 'solid', theme.colors.border]
    }
  },
  itemIcon: {
    marginLeft: theme.spacing(2)
  },
  dropdown: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    transform: 'translate(0, -100%)',
    backgroundColor: theme.colors.background,
    margin: [-1, 0, 0, -theme.gutter.sm],
    padding: theme.gutter.sm,
    border: [1, 'solid', theme.colors.border],
    transition: 'transform 0.15s ease-in-out',
    display: 'none',
    '&.open': {
      transform: 'translate(0, 0)'
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      padding: [theme.gutter.sm, theme.gutter.md],
      margin: [-1, 0, 0, -theme.gutter.md]
    }
  },
  dropdownItem: {
    width: '100%',
    backgroundColor: 'transparent',
    color: 'currentColor',
    border: 'none',
    textAlign: 'left',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  selectWrapper: {
    height: '100%',
    minWidth: 150,
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  selectCaret: {
    position: 'absolute',
    right: theme.gutter.sm,
    top: '50%',
    transform: 'translate(-50%, 0)'
  },
  select: {
    height: '100%',
    outline: 'none',
    border: 'none',
    margin: [0, theme.gutter.sm + 15, 0, theme.gutter.sm],
    padding: 0,
    background: 'transparent',
    color: 'currentColor',
    width: '100%',
    '-webkit-appearance': 'none'
  }
})
