import React, { useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get, filter } from "lodash";

// import PanelsContainer from "./PanelsContainer";
import Panel from "./Panel";
import {
  closePanel,
  isPanelOpen,
  getSelectedCategory,
  openCategoryPanel,
} from "../../state/slices/drawer";
import LinkList from "../LinkList";
import { resolveInternalLinkUrl } from "../../helpers/resolveLink";
import { primaryInput } from "detect-it";
import { isCurrentBreakpointMobile } from "../../state/slices/layout";

const CATEGORISE_BY_CATEGORY = "category";
export const CATEGORISE_BY_SUBSTRATE = "fireSubstrate";

export const useCategoryList = (
  categories,
  counts,
  url,
  categoriseBy = CATEGORISE_BY_CATEGORY
) => {
  return useMemo(() => {
    const categoryList = filter(
      categories.map((item) => ({
        to: `${url}/?${categoriseBy}=${item.slug.current}`,
        count: get(counts.byCategory, [item.slug.current, "totalCount"]),
        text: item.title,
        category: item,
      })),
      (x) => x.count > 0
    );

    // // If accessories is a category, move it to the back of the list
    // const accessoriesCategoryIndex = categoryList.findIndex(
    //   (category) => category.text === "Accessories"
    // );
    // if (accessoriesCategoryIndex >= 0) {
    //   categoryList.push(categoryList.splice(accessoriesCategoryIndex, 1)[0]);
    // }
    return categoryList;
  }, [categories, url, counts, categoriseBy]);
};

export default function ConnectedPanels({
  title = "See All",
  slug,
  categories,
  counts,
  link,
  showCloseButton,
  children,
  categoriseBy = CATEGORISE_BY_CATEGORY,
}) {
  const open = useSelector(isPanelOpen(slug));
  const category = useSelector(getSelectedCategory(slug));
  const isMobile = useSelector(isCurrentBreakpointMobile);

  const dispatch = useDispatch();
  const onClose = useCallback(() => {
    dispatch(closePanel({ slug }));
  }, [dispatch, slug]);

  const url = resolveInternalLinkUrl(link.reference);

  const categoryItems = useCategoryList(categories, counts, url, categoriseBy);

  const onSelectCategory = useCallback(
    (item) => {
      if (!isMobile) {
        if (item !== category) {
          dispatch(openCategoryPanel({ slug, category: item }));
        }
      }
    },
    [dispatch, slug, category, isMobile]
  );

  return (
    <>
      <Panel
        title={title}
        titleCount={counts.total}
        titleLink={url}
        open={open}
        showCloseButton={open && showCloseButton}
        breadcrumbLabel="Menu"
        breadcrumbOnClick={onClose}
        className="firstPanel"
        index={1}
      >
        {Boolean(categoryItems?.length) && (
          <LinkList
            items={categoryItems}
            onMouseEnter={primaryInput === "touch" ? null : onSelectCategory}
            selectedItem={category}
            size="medium"
            animateIn={open}
            delay={0.75}
          />
        )}
      </Panel>
      {children}
    </>
  );
}
