import React, { forwardRef, useCallback, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import Button from './Button'
import Section from './Section'
import composeRefs from '../helpers/composeRefs'
import useStandardInAnimation from './Animation/useStandardInAnimation'
import theme from '../style/theme'
import cn from 'classnames'
import get from 'lodash/get'
import RichText from './RichText'

const SliceHeder = forwardRef(({ title, copy, rawCopy, cta, ctaLabel, className, classNames = { }, ctaAlignment, ...rest }, ref) => {
  const classes = useStyles()
  const containerRef = useRef()
  const [inViewRef] = useStandardInAnimation(useCallback(() => { return containerRef.current.children }, []))
  const ctaText = get(cta, [0, 'text'])

  return (
    <Section grid noBottomMargin className={cn(classes.header, className)} tag='div' ref={composeRefs(inViewRef, containerRef, ref)} {...rest}>
      <h2 className={cn(classes.title, classNames.title)}>{title}</h2>
      <div className={cn(classes.copyContainer, ctaAlignment)}>
        {copy && <p className={classes.copy}>{copy}</p>}
        {rawCopy && <RichText blocks={rawCopy.text} className={classes.copy} />}
        <Button link={cta}>
          {ctaText && ctaLabel ? (<span>{ctaText} <span className={classes.buttonLabel}>{ctaLabel}</span></span>) : ctaLabel}
        </Button>
      </div>
    </Section>
  )
})

export default SliceHeder

const useStyles = createUseStyles({
  header: {
    marginBottom: theme.section.marginBottom.sm,
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.section.marginBottom.md
    }
  },
  title: {
    gridColumn: '1 / span 4',
    opacity: 0,
    [theme.breakpoints.up('md')]: {
      margin: 0,
      gridColumn: '1 / span 3'
    },
    [theme.breakpoints.up('lg')]: {
      gridColumn: '1 / span 2'
    }
  },
  copyContainer: {
    gridColumn: '1 / span 4',
    [theme.breakpoints.up('md')]: {
      maxWidth: 400,
      gridColumn: '5 / span 2'
    },
    opacity: 0,
    '&.end': {
      alignSelf: 'end'
    }
  },
  copy: {
    margin: [0, 0, theme.spacing(5)]
  },
  buttonLabel: {
    opacity: 0.5
  }
})
