import gsap from 'gsap'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import CustomEase from 'gsap/CustomEase'

gsap.registerPlugin(CustomEase)

export default function useStandardInAnimation (getElements, animationProps = {}, active = true, threshold = 0.2) {
  const [inViewRef, inView] = useInView({ threshold, triggerOnce: true })
  useEffect(() => {
    if (inView && active) {
      const elements = getElements()
      if (elements) {
        gsap.fromTo(elements, { opacity: 0 }, { opacity: 1, duration: 1.33, ease: CustomEase.create('inview', '.17, .17, 0, 1'), stagger: 0.15, ...animationProps })
      }
    }
  }, [inView, getElements, active]) /* eslint-disable-line */
  return [inViewRef, inView]
}
