import React, { useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import Logo from '../Header/logo.inline.svg'
import theme from '../../style/theme'
import gsap from 'gsap'
import CustomEase from 'gsap/CustomEase'
import { useGlobalContext } from '../SiteContext'
import map from 'lodash/map'
import MenuItem from '../Header/MenuItem'
import Link from '../Link'
import Button from '../Button'
import toArray from 'lodash/toArray'

gsap.registerPlugin(CustomEase)

export default function TopMenuPanel ({ open }) {
  const classes = useStyles()
  const containerRef = useRef()
  const listRef = useRef()
  const buttonRef = useRef()

  useEffect(() => {
    if (containerRef.current) {
      const timeline = gsap.timeline()
      const elements = [toArray(listRef.current.children), buttonRef.current]
      if (open) {
        timeline.set(elements, { opacity: 0 })
        timeline.set(containerRef.current, { opacity: 1, x: '-110%', pointerEvents: 'all' })
        timeline.to(containerRef.current, { x: 0, duration: 1, ease: CustomEase.create('top-menu', '.7,.0,.3,1') }, 0)
        timeline.to(elements, { opacity: 1, duration: 1, ease: 'sine.inOut', stagger: 0.1 }, 0.5)
      } else {
        timeline.to(containerRef.current, {
          opacity: 0,
          duration: 0.5,
          ease: 'sine.inOut',
          onComplete: () => {
            gsap.set(containerRef.current, { pointerEvents: 'none' })
          }
        })
      }
      return () => {
        if (!open) { timeline.kill() }
      }
    }
  }, [open])

  const { menu, requestPhone } = useGlobalContext()

  return (
    <nav className={classes.container} ref={containerRef}>
      <div>
        <Link className={classes.logoLink} to='/' aria-label='Home'><Logo /></Link>
        <ul className={classes.list} ref={listRef}>
          {map(menu, ({ link }, i) => (
            <MenuItem key={i} link={link} className={classes.item} isActiveLinkEnabled={false} />
          ))}
        </ul>
      </div>
      <Button ref={buttonRef} to={`tel: ${requestPhone}`} className={classes.button}>
        Call <span className={classes.buttonLabel}>{requestPhone}</span>
      </Button>
    </nav>
  )
}

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    backgroundColor: theme.colors.background,
    gridColumn: '1 / span 4',
    borderRight: [1, 'solid', theme.colors.border],
    padding: theme.gutter.sm,
    transform: 'translateX(-110%)',
    display: 'none',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      padding: [23, theme.gutter.md, theme.gutter.md],
      gridColumn: '1',
      zIndex: 4,
      marginLeft: -theme.gutter.md
    }
  },
  list: {
    listStyle: 'none',
    margin: [theme.spacing(8), 0, 0],
    padding: 0
  },
  item: {
    fontFamily: theme.fonts.body,
    fontSize: 15,
    textTransform: 'none',
    opacity: 0.5,
    transition: 'opacity 0.25s ease-in-out',
    '&:after': {
      top: '1.2em'
    },
    '&:hover, &.selected': {
      opacity: 1
    }
  },
  button: {
    alignSelf: 'start'
  },
  buttonLabel: {
    opacity: 0.5,
    whiteSpace: 'nowrap',
    marginLeft: theme.spacing(1)
  }
})
