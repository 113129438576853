
import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../../style/theme'

export default function FilterSpacer () {
  const classes = useStyles()
  return (
    <div className={classes.spacer} />
  )
}

const useStyles = createUseStyles({
  spacer: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      marginLeft: -theme.gutter.md,
      display: 'block'
    }
  }
})
