import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import ResponsiveImage from '../ResponsiveImage'
import Button from '../Button'
import get from 'lodash/get'

export default function EmptyCart ({ image, title, copy, link, linkLabel }) {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      {image && <ResponsiveImage className={classes.image} image={image} />}
      <div className={classes.content}>
        <p className={classes.title}>{title}</p>
        <p className={classes.copy}>{copy}</p>
        {link && (
          <Button link={link}>
            <span>{get(link, [0, 'text'])} <span className={classes.linkLabel}>{linkLabel}</span></span>
          </Button>
        )}
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    marginTop: theme.spacing(0),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(15)
    }
  },
  image: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(7)
    }
  },
  content: {
    width: 250,
    textAlign: 'center',
    margin: 'auto'
  },
  title: {
    fontSize: 15,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4)
    }
  },
  copy: {
    whiteSpace: 'pre-wrap',
    fontSize: 12,
    opacity: 0.5,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4)
    }
  },
  linkLabel: {
    opacity: 0.5
  }
})
