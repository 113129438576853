import React, { useCallback, useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { useDispatch } from "react-redux";
import UseInternalLinkUrl from "../../hooks/useInternalLinkUrl";
import { addItem } from "../../state/slices/ui/cart";
import theme from "../../style/theme";
import Button from "../Button";
import ProductModel from "../ProductModel";
import ResponsiveImage from "../ResponsiveImage";

export default function ProductDetailsPanel({ product }) {
  const classes = useStyles();
  const url = UseInternalLinkUrl(product);
  const [productModelHeight, setProductModelHeight] = useState();
  const contentRef = useRef();
  const containerRef = useRef();
  const dispatch = useDispatch();

  const onAddToSamples = useCallback(() => {
    dispatch(addItem({ id: product.id }));
  }, [dispatch, product]);

  useEffect(() => {
    if (containerRef.current) {
      const { height } = containerRef.current.getBoundingClientRect();
      const { height: detailsHeight } =
        contentRef.current.getBoundingClientRect();
      const style =
        contentRef.current.currentStyle ||
        window.getComputedStyle(contentRef.current);
      setProductModelHeight(
        height -
          (detailsHeight +
            parseInt(style.marginTop) +
            parseInt(style.marginBottom))
      );
    }
  }, [product]);

  if (!product) return null;
  return (
    <div className={classes.container} ref={containerRef}>
      <div className={classes.model} style={{ height: productModelHeight }}>
        {!product.flatModel && product.model && (
          <ProductModel
            height={productModelHeight}
            modelUrl={product.model.asset.url}
            thresholdAngle={product.modelAngleThreshold}
            lineOffset={product.modelLineOffsetFactor}
            zoomEnabled
          />
        )}
        {product.model && product.flatModel && (
          <ResponsiveImage
            image={product.flatModel}
            aspect={2326 / 1672}
            className={classes.flatModel}
            fadeIn
            fadeInDuration={0.5}
            showPreview={false}
          />
        )}
        {/* {product.model && <ProductModel height={productModelHeight} modelUrl='/DecorArti.gltf' />} */}
      </div>
      <div className={classes.content} ref={contentRef}>
        <div>{product?.title}</div>
        <div className={classes.summary}>{product.summary}</div>
        <div className={classes.profile}>{product.profile}</div>
        <Button className={classes.viewButton} to={url}>
          View Product
        </Button>
        <Button className={classes.samplesButton} onClick={onAddToSamples}>
          Add To Samples
        </Button>
      </div>
    </div>
  );
}

const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  model: {
    flexGrow: 1,
    flexShrink: 1,
    padding: [theme.spacing(10), 0],
  },
  flatModel: {
    top: "50%",
    transform: "translateY(-50%)",
  },
  content: {
    flexGrow: 0,
    flexShrink: 0,
    display: "grid",
    gap: theme.gutter.sm,
    gridTemplateColumns: "repeat(2, 1fr)",
    margin: [theme.gutter.sm],
    [theme.breakpoints.up("md")]: {
      gap: theme.gutter.md,
      margin: [theme.gutter.md],
    },
  },
  title: {
    gridColumn: 1,
    gridRow: 1,
    fontSize: 15,
    marginBottom: theme.spacing(4),
  },
  summary: {
    fallbacks: [{ display: "block" }],
    display: "-webkit-box",
    fontSize: 12,
    opacity: 0.5,
    gridRow: 2,
    gridColumn: "1 / span 2",
    height: "3em",
    textOverflow: "ellipsis",
    overflow: "hidden",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 2,
    // lineHeight: 1.5
  },
  profile: {
    fontSize: 10,
    opacity: 0.5,
    fontFamily: theme.fonts.caption,
    textTransform: "uppercase",
    gridColumn: 2,
    gridRow: 1,
    whiteSpace: "pre-wrap",
  },
  button: {
    fontFamily: theme.fonts.body,
    fontSize: 10,
    letterSpacing: "0.05em",
    justifySelf: "start",
    gridColumn: "1 / span 2",
  },
  viewButton: {
    composes: ["$button"],
    [theme.breakpoints.up("md")]: {
      gridColumn: "1",
    },
  },
  samplesButton: {
    composes: ["$button"],
    [theme.breakpoints.up("md")]: {
      gridColumn: "2",
      justifySelf: "end",
    },
  },
});
