import { route } from "../../slices/route";
import queryString from "query-string";
import { omitBy, forEach, find, get, keys, compact } from "lodash";
import { getPage, setPage } from "../../slices/data/page";
import {
  fetches,
  isLoadingSelector,
  isLoadedSelector,
} from "../../slices/data/lists";
import {
  actions,
  getFiltersSelector,
  SliceTypeStoreKeyMapping,
} from "../../slices/ui/listings";

const slugs = ["projects", "products", "resources"];

const listing = (store) => (next) => (action) => {
  const result = next(action);

  if (action?.meta?.type) {
    const { applyFilter, clearFilters } = actions[action.meta.type];

    if (action.type === applyFilter.toString()) {
      const filters = omitBy(
        getFiltersSelector(action.meta.type)(store.getState()),
        (x) => !x
      );
      const qs = queryString.stringify(filters);
      const relativePath = window.location.pathname + "?" + qs;
      window.history.pushState(null, "", relativePath);
    }
    if (action.type === clearFilters.toString()) {
      const relativePath = window.location.pathname;
      window.history.pushState(null, "", relativePath);
    }
  }

  const getListingSlice = (page) => {
    return find(compact(get(page, ["slicesList", "slices"], [])), (slice) =>
      keys(SliceTypeStoreKeyMapping).includes(slice._type)
    );
  };

  if (action.type === route.toString()) {
    const listingSlice = getListingSlice(getPage(store.getState()));

    if (listingSlice) {
      const type = SliceTypeStoreKeyMapping[listingSlice._type];
      const qs = queryString.parse(action.payload.prevLocation.search);
      const filters = getFiltersSelector(type)(store.getState());
      const updatedFilters = { ...filters };

      forEach(updatedFilters, (_, key) => {
        updatedFilters[key] = qs[key];
      });
      const { updateFilters } = actions[type];
      window.history.pushState(
        null,
        "",
        `${action.payload.location.pathname}${action.payload.location.search}`
      );
      store.dispatch(updateFilters(updatedFilters));
    }
    if (action?.payload?.location?.search) {
      let type = action.payload.location.pathname.replaceAll("/", "");

      if (slugs.includes(type)) {
        if (type === "resources") {
          type = "articles";
        }

        const qs = queryString.parse(action.payload.location.search);
        const filters = getFiltersSelector(type)(store.getState());
        const updatedFilters = { ...filters };
        forEach(updatedFilters, (_, key) => {
          updatedFilters[key] = qs[key];
        });

        const { updateFilters } = actions[type];
        store.dispatch(updateFilters(updatedFilters));
      }
    }
  }

  if (action.type === setPage.toString()) {
    const listingSlice = find(
      compact(get(action, ["payload", "slicesList", "slices"], [])),
      (slice) => keys(SliceTypeStoreKeyMapping).includes(slice._type)
    );

    if (listingSlice) {
      const type = SliceTypeStoreKeyMapping[listingSlice._type];
      const loadItems =
        !isLoadingSelector(type)(store.getState()) &&
        !isLoadedSelector(type)(store.getState());

      if (loadItems) {
        store.dispatch(fetches[type]());
      }
      let params;
      if (window?.location?.search) {
        params = window.location.search;
      }
      const relativePath = Boolean(params)
        ? `${window.location.pathname}${params}`
        : window.location.pathname;
      window.history.pushState(null, "", relativePath);
    }

    if (store?.getState()?.route?.location?.search) {
      const location = store?.getState()?.route?.location;

      const params = location.search;
      const relativePath = Boolean(params)
        ? `${location.pathname}${params}`
        : location.pathname;
      window.history.pushState(null, "", relativePath);
    }
  }
  return result;
};

export default listing;
