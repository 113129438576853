import Section from '../Section'
import React from 'react'
import VimeoPlayer from '../VimeoPlayer'
import { HorizontalLine } from '../Lines'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'

export default function VimeoPlayerSlice ({ slice }) {
  const classes = useStyles()

  return (
    <Section className={classes.section}>
      <VimeoPlayer className={classes.vimeoPlayer} title={slice.title} vimeoData={slice.vimeoData}/>
      <HorizontalLine position='bottom' animationOrigin='0%' extend/>
    </Section>
  )
}

const useStyles = createUseStyles({
  section: {
    position: 'relative'
  },
  vimeoPlayer: {
    paddingBottom: theme.section.marginBottom.sm,
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.section.marginBottom.md,
    }
  }
})
