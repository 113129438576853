import { createSlice, createSelector } from '@reduxjs/toolkit'
import filter from 'lodash/filter'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'

export const pageSlice = createSlice({
  name: 'page',
  initialState: { page: null },
  reducers: {
    setPage: (state, { payload }) => {
      state.page = payload
    }
  }
})

export const { setPage } = pageSlice.actions

export const getPage = (state) => state?.data?.page?.page
export const getSlices = createSelector(
  getPage,
  (page) => page?.slicesList?.slices
)

export const getPageAnchorLinks = createSelector(
  getSlices,
  (slices) => {
    if (!slices) return []
    const slicesWithAnchorLinks = filter(slices, slice => slice.anchorLink?.anchorId)
    return map(slicesWithAnchorLinks, slice => ({
      anchorId: slice.anchorLink.anchorId,
      title: slice.anchorLink.title || slice.title
    }))
  }
)

export const hasPageAnchorLinks = createSelector(
  getPageAnchorLinks,
  (links) => !isEmpty(links)
)

export default pageSlice.reducer
