import React, { useEffect, useState, useRef, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../style/theme'
import NextPreviousButtons from './NextPreviousButtons'
import Slider from './Slider'
import { Caption } from './Typography'
import cn from 'classnames'

export default function SliderWithPagination ({ children, className, paginationTitle, captions = [], paginationLocation = 'bottom', extend }) {
  const classes = useStyles()
  const sliderRef = useRef()
  const [selectedIndex, setSelectedIndex] = useState(0)

  useEffect(() => {
    const flkty = sliderRef.current.getFlickity()
    flkty.on('change', setSelectedIndex)
    return () => {
      flkty.off('change', setSelectedIndex)
    }
  }, [setSelectedIndex])

  const onNextClick = useCallback(() => {
    const flkty = sliderRef.current.getFlickity()
    flkty.select(selectedIndex + 1, false, false)
  }, [selectedIndex])
  const onBackClick = useCallback(() => {
    const flkty = sliderRef.current.getFlickity()
    flkty.select(selectedIndex - 1, false, false)
  }, [selectedIndex])

  const caption = captions[selectedIndex]

  return (
    <div className={cn(classes.container, { extend })}>
      <div className={cn(classes.pagination, paginationLocation)}>
        <div className={classes.captions}>
          <Caption className={classes.pageIndex}>{`${paginationTitle} ${selectedIndex + 1}/${children.length}`}</Caption>
          {caption && <Caption className={classes.caption}>{caption}</Caption>}
        </div>
        <NextPreviousButtons onNextClick={onNextClick} onBackClick={onBackClick} nextDisabled={selectedIndex >= children.length - 1} backDisabled={selectedIndex === 0} />
      </div>
      <Slider ref={sliderRef} className={className}>
        {children}
      </Slider>
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    '&.extend': {
      margin: [0, -theme.gutter.sm],
      padding: [0, theme.gutter.sm],
      [theme.breakpoints.up('md')]: {
        margin: [0, -theme.gutter.md],
        padding: [0, theme.gutter.md]
      }
    }
  },
  pagination: {
    '&.bottom': {
      marginTop: theme.spacing(2),
      order: 1
    },
    '&.top': {
      marginBottom: theme.spacing(2)
    }
  },
  captions: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 10,
    marginRight: '10%'
  },
  pageIndex: {
    display: 'block',
    marginRight: theme.spacing(2),
    whiteSpace: 'nowrap'
  },
  caption: {
    textAlign: 'right'
  }
})
