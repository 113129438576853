import { createSlice, createSelector, combineReducers } from "@reduxjs/toolkit";
import forEach from "lodash/forEach";
import filter from "lodash/filter";
import find from "lodash/find";
import map from "lodash/map";
import get from "lodash/get";
import isArray from "lodash/isArray";
import { toSentenceCase } from "../../../helpers/format";
import { createCachedSelector } from "re-reselect";
import { getItemsSelector, getOptionsSelector } from "../data/lists";

export const SliceTypeStoreKeyMapping = {
  article_listing: "articles",
  project_listing: "projects",
  product_listing: "products",
};

const createListingSlice = (type, defaultFilters) => {
  const slice = createSlice({
    name: type,
    initialState: {
      filters: defaultFilters,
    },
    reducers: {
      updateFilters: (state, action) => {
        action.meta = { type };
        const { payload } = action;
        state.filters = payload;
      },
      applyFilter: (state, action) => {
        action.meta = { type };
        const {
          payload: { key, value },
        } = action;
        const newFilter = { ...state.filters };
        newFilter[key] = value;
        state.filters = newFilter;
      },
      clearFilters: (state, action) => {
        action.meta = { type };
        forEach(state.filters, (_, key) => {
          state.filters[key] = null;
        });
      },
    },
  });
  return slice;
};

const createTypeSelector = (prop) => {
  return createCachedSelector(
    (type) => type,
    (type) =>
      createSelector(
        (state) => state.ui.listings,
        (listings) => get(listings[type], prop)
      )
  )((id) => id);
};

export const getFiltersSelector = createTypeSelector("filters");

export const getFilterOptionsSelector = createCachedSelector(
  (type) => type,
  (type) => getOptionsSelector(type),
  (type) => getFiltersSelector(type),
  (type, optionsSelector, filtersSelector) => {
    return createSelector(
      optionsSelector,
      filtersSelector,
      (options, filters) => {
        return map(options, (o, key) => ({
          title: o.title || toSentenceCase(key),
          emptyText: o.emptyText,
          key,
          options: o.items.map((item) => ({
            ...item,
            selected: item.value === filters[key],
          })),
        }));
      }
    );
  }
)((id) => id);

const compare = (obj, prop, value) => {
  const propValue = get(obj, prop);
  if (!propValue) return false;
  if (propValue.slug) {
    return propValue.slug.current.toLowerCase() === value.toLowerCase();
  }
  if (isArray(propValue)) {
    return !!find(propValue, (x) => {
      if (prop === "fireSubstrateFilters") {
        return x.slug.current.toLowerCase() === value.toLowerCase();
      }
      return x.toLowerCase() === value.toLowerCase();
    });
  }
  return propValue.toLowerCase() === value.toLowerCase();
};

export const getFilteredItemsSelector = createCachedSelector(
  (type) => type,
  (type) => getItemsSelector(type),
  (type) => getFiltersSelector(type),
  (type, itemsSelector, filtersSelector) => {
    return createSelector(itemsSelector, filtersSelector, (items, filters) => {
      if (!items) return [];
      let filtered = items;
      forEach(filters, (value, prop) => {
        if (value) {
          filtered = filter(filtered, (project) => {
            if (prop.startsWith("products")) {
              const products = project.products;
              const productProp = prop.split(".").slice(1);
              return !!find(products, (product) =>
                compare(product, productProp, value)
              );
            } else {
              return compare(
                project,
                prop === "fireSubstrate" ? "fireSubstrateFilters" : prop,
                value
              );
            }
          });
        }
      });
      return filtered;
    });
  }
)((id) => id);

export const articleListingSlice = createListingSlice("articles", {
  category: null,
});
export const projectListingSlice = createListingSlice("projects", {
  category: null,
  "product.application": null,
  "product.id": null,
  location: null,
});
export const productListingSlice = createListingSlice("products", {
  category: null,
  suitableFor: null,
  application: null,
  finish: null,
  fireSubstrate: null,
  groupFireRatings: null,
});

export const actions = {
  articles: articleListingSlice.actions,
  projects: projectListingSlice.actions,
  products: productListingSlice.actions,
};

export default combineReducers({
  articles: articleListingSlice.reducer,
  projects: projectListingSlice.reducer,
  products: productListingSlice.reducer,
});
