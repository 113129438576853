
import { createSlice } from '@reduxjs/toolkit'

const _resetQuoteForm = (state) => {
  state.loading = false
  state.submitted = false
  state.error = null
  state.success = false
  state.showForm = false
}

const quoteFormSlice = createSlice({
  name: 'quoteDrawer',
  initialState: {
    loading: false,
    error: null,
    open: false,
    success: false
  },
  reducers: {
    openQuoteDrawer: (state) => {
      state.open = true
    },
    closeQuoteDrawer: (state) => {
      state.open = false
      if (state.success) {
        _resetQuoteForm(state)
      }
    },
    resetForm: (state) => {
      _resetQuoteForm(state)
    },
    setSubmitQuoteFormSuccess: (state) => {
      state.success = true
      state.loading = false
      state.error = null
    },
    setSubmitQuoteFormPending: (state) => {
      state.loading = true
    },
    setSubmitQuoteFormRejected: (state, errorMessage) => {
      state.loading = false
      state.error = errorMessage
    }
  }
})

export const {
  openQuoteDrawer,
  closeQuoteDrawer,
  setSubmitQuoteFormSuccess,
  resetForm
} = quoteFormSlice.actions

export const isQuoteDrawerOpen = state => state.ui.quoteDrawer.open
export const isQuoteFormLoading = state => state.ui.quoteDrawer.loading
export const getQuoteFormError = state => state.ui.quoteDrawer.error
export const isQuoteFormSuccess = state => state.ui.quoteDrawer.success

export default quoteFormSlice.reducer
