import React from "react";
import { createUseStyles } from "react-jss";
import theme from "../../../style/theme";

export default function Toolbar() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.line} />
    </>
  );
}

const useStyles = createUseStyles({
  line: {
    height: 1,
    width: "100%",
    backgroundColor: theme.colors.border,
    zIndex: 1,
    position: "relative",
  },
  toolbar: {
    position: "relative",
    display: "flex",
    height: theme.spacing(10),
    overflow: "auto",
    gridColumn: "1 / span 4",
    [theme.breakpoints.up("md")]: {
      gridColumn: "1 / span 7",
      border: "none",
      overflow: "visible",
      display: "grid",
      gridTemplateColumns: "repeat(7, 1fr)",
      gap: theme.gutter.md,
      margin: [0, theme.gutter.md],
    },
  },
});
