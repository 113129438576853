import cn from "classnames";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import forEach from "lodash/forEach";
import map from "lodash/map";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";
import { isCurrentBreakpointMobile } from "../../../state/slices/layout";
import theme from "../../../style/theme";
import { HorizontalLine } from "../../Lines";
import RichText from "../../RichText";
import Section from "../../Section";
import Slider from "../../Slider";
import { useScrollReadyListener } from "../../useSmoothScrollbar";
import Graph from "./Graph";
import useStickyElement from "./useStickyElement";

export default function Steps({ title, steps }) {
  const classes = useStyles();
  const sliderRef = useRef();
  const stepsRef = useRef();
  const graphContainerRef = useRef();
  const containerRef = useRef();
  const [selectedStep, setSelectedStep] = useState(0);
  const isMobile = useSelector(isCurrentBreakpointMobile);

  useStickyElement(
    containerRef,
    graphContainerRef,
    theme.section.marginBottom.md * 2
  );

  useEffect(() => {
    if (sliderRef.current) {
      const flkty = sliderRef.current.getFlickity();
      if (flkty) {
        flkty.on("change", setSelectedStep);
        return () => {
          flkty.off("change", setSelectedStep);
        };
      }
    }
  }, [setSelectedStep, isMobile]);

  useScrollReadyListener(
    useCallback(() => {
      if (!isMobile && stepsRef.current) {
        const elements = stepsRef.current.children;
        const tl = gsap.timeline();
        const scrollTriggers = map(elements, (element, i) =>
          ScrollTrigger.create({
            trigger: element,
            pin: false,
            start: "top 45%",
            end: "bottom 50%",
            animation: tl,
            onEnter: () => {
              setSelectedStep(i);
            },
            onEnterBack: () => {
              setSelectedStep(i);
            },
          })
        );
        return () => {
          forEach(scrollTriggers, (st) => {
            st.kill();
          });
        };
      }
    }, [isMobile])
  );

  return (
    <div className={classes.container} ref={containerRef}>
      <Section tag="div" grid>
        <div className={classes.graphContainer} ref={graphContainerRef}>
          <h3 className={classes.title}>{title}</h3>
          <Graph
            className={classes.graph}
            steps={steps}
            selectedIndex={selectedStep}
          />
        </div>
        <div className={classes.mobileSteps}>
          <Slider ref={sliderRef}>
            {steps &&
              steps.map(({ title, _rawCopy }, i) => (
                <div
                  key={i}
                  className={cn(classes.step, { selected: i === selectedStep })}
                >
                  <h4 className={classes.stepTitle}>{title}</h4>
                  {_rawCopy && (
                    <RichText
                      className={classes.stepCopy}
                      blocks={_rawCopy.text}
                    />
                  )}
                  <HorizontalLine
                    position="bottom"
                    className={classes.stepLine}
                  />
                </div>
              ))}
          </Slider>
        </div>
        <div className={classes.desktopSteps} ref={stepsRef}>
          {steps &&
            steps.map(({ title, _rawCopy }, i) => (
              <div
                key={i}
                className={cn(classes.step, { selected: i === selectedStep })}
              >
                <h4 className={classes.stepTitle}>{title}</h4>
                {_rawCopy && (
                  <RichText
                    className={classes.stepCopy}
                    blocks={_rawCopy.text}
                  />
                )}
                <HorizontalLine
                  position="bottom"
                  className={classes.stepLine}
                />
              </div>
            ))}
        </div>
        <div className={classes.horizontalLine}>
          <HorizontalLine position="bottom" extend />
        </div>
      </Section>
    </div>
  );
}

const useStyles = createUseStyles({
  container: {
    overflow: "hidden",
  },
  graphContainer: {
    gridColumn: "1 / span 4",
    [theme.breakpoints.up("md")]: {
      gridColumn: "1 / span 3",
      alignSelf: "start",
    },
  },
  title: {
    fontSize: 35,
    [theme.breakpoints.up("md")]: {
      fontSize: "calc(35px + (65 - 35) * ((100vw - 320px) / (1920 - 320)))",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 65,
    },
  },
  graph: {
    marginBottom: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
      width: "66%",
    },
  },
  steps: {
    gridColumn: "1 / span 4",
    display: "block",
    whiteSpace: "nowrap",
    margin: [0, -theme.gutter.sm],
    padding: [0, theme.gutter.sm],
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      display: "block",
      gridColumn: "4 / span 4",
      padding: 0,
      margin: [140, 0, 150],
    },
  },
  mobileSteps: {
    composes: ["$steps"],
    display: "block",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  desktopSteps: {
    composes: ["$steps"],
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  step: {
    position: "relative",
    opacity: 0.3,
    width: "80%",
    flexShrink: 0,
    margin: [0, theme.gutter.md],
    transition: "opacity 0.5s ease-in-out",
    "&.selected": {
      opacity: 1,
    },
    [theme.breakpoints.up("md")]: {
      margin: 0,
      width: "100%",
      maxWidth: 400,
    },
  },
  stepTitle: {
    whiteSpace: "normal",
    fontSize: 20,
    marginBottom: theme.spacing(3),
    lineHeight: 1.2,
    textTransform: "none",
    fontFamily: theme.fonts.body,
  },
  stepCopy: {
    whiteSpace: "normal",
    fontSize: 15,
    marginBottom: theme.spacing(10),
    "& p:first-child": {
      textIndent: theme.styles.textIndent,
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(15),
      paddingBottom: theme.spacing(15),
    },
  },
  stepLine: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  horizontalLine: {
    position: "relative",
    gridColumn: "1 / span 4",
    [theme.breakpoints.up("md")]: {
      gridColumn: "1 / span 7",
    },
  },
});
