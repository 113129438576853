import { combineReducers } from 'redux'
import route from './route'
import layout from './layout'
import drawer from './drawer'
import preload from './preload'
import data from './data'
import ui from './ui'

export default combineReducers({
  route,
  layout,
  drawer,
  preload,
  data,
  ui
})
