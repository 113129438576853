import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../../style/theme'
import ProjectList from './ProjectList'
import SliceHeader from '../../SliceHeader'

export default function ProjectShowcase ({ slice, id }) {
  const classes = useStyles()
  const { title, copy, cta, ctaLabel, projects } = slice
  return (
    <section className={classes.section} id={id}>
      <SliceHeader title={title} copy={copy} cta={cta} ctaLabel={ctaLabel} className={classes.header} classNames={{ title: classes.title }} />
      <ProjectList projects={projects} />
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    marginBottom: theme.spacing(15),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(20)
    }
  },
  header: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(20)
    }
  },
  title: {
    marginBottom: theme.spacing(20),
    [theme.breakpoints.up('md')]: {
      margin: 0
    }
  }
})
