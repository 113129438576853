import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { createUseStyles } from "react-jss";
import theme from "../../../style/theme";
import Section from "../../Section";
import { gsap } from "gsap";
import cn from "classnames";

const LineAnimations = forwardRef(({ slice }, ref) => {
  const classes = useStyles();
  const sectionRef = useRef();

  useImperativeHandle(ref, () => ({
    createTimeline: () => {
      const timeline = gsap.timeline();
      let verticalLines = sectionRef.current.querySelectorAll(
        `.${classes.verticalLine}`
      );
      verticalLines = [
        verticalLines[3],
        verticalLines[2],
        verticalLines[4],
        verticalLines[1],
        verticalLines[5],
        verticalLines[0],
      ];
      const horizontalLines = sectionRef.current.querySelectorAll(
        `.${classes.horizontalLine}`
      );
      timeline.to(verticalLines, {
        scaleY: 1,
        duration: 2,
        ease: "power4.inOut",
        stagger: 0.15,
      });
      timeline.to(
        horizontalLines,
        { scaleX: 1, duration: 2, ease: "power4.inOut" },
        0.5
      );
      timeline.to([...verticalLines, ...horizontalLines], {
        opacity: 0,
        duration: 1.5,
        ease: "power4.inOut",
      });
      return timeline;
    },
  }));

  return (
    <Section
      grid
      className={classes.lines}
      tag="div"
      noBottomMargin
      fullWidth
      ref={sectionRef}
    >
      <div className={classes.verticalLine} />
      <div className={classes.verticalLine} />
      <div className={classes.verticalLine} />
      <div className={classes.verticalLine} />
      <div className={cn(classes.verticalLine, "desktopOnly")} />
      <div className={cn(classes.verticalLine, "desktopOnly")} />
      <div className={cn(classes.horizontalLine, "top")} />
      <div className={cn(classes.horizontalLine, "bottom")} />
    </Section>
  );
});

export default LineAnimations;

const useStyles = createUseStyles({
  lines: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "grid",
    zIndex: -1,
  },
  verticalLine: {
    justifySelf: "end",
    width: 1,
    backgroundColor: theme.colors.border,
    opacity: 0.15,
    transform: "scaleY(0)",
    "&.desktopOnly": {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "block",
      },
    },
  },
  horizontalLine: {
    position: "absolute",
    left: -theme.gutter.sm,
    right: -theme.gutter.sm,
    bottom: 0,
    backgroundColor: theme.colors.border,
    opacity: 0.15,
    height: 1,
    transform: "scaleX(0)",
    [theme.breakpoints.up("md")]: {
      left: -theme.gutter.md,
      right: -theme.gutter.md,
    },
    "&.top": {
      top: 0,
      bottom: "auto",
    },
    "&.bottom": {
      opacity: 1,
    },
  },
});
