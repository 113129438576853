import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  useMemo,
} from "react";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import {
  getFilterOptionsSelector,
  actions,
  getFiltersSelector,
} from "../../../state/slices/ui/listings";
import DropdownIcon from "./dropdown.inline.svg";
import theme from "../../../style/theme";
import gsap from "gsap";
import cn from "classnames";
import find from "lodash/find";

const SelectAccordionOption = ({ text, value, selected, onSelect }) => {
  const classes = useStyles();
  const onClick = useCallback(() => {
    if (onSelect) onSelect(selected ? null : value);
  }, [selected, onSelect, value]);

  return (
    <button className={cn(classes.option, { selected })} onClick={onClick}>
      {text}
    </button>
  );
};

const SelectAccordionItem = ({
  title,
  value,
  selected,
  options,
  onToggle,
  onSelectOption,
  open,
}) => {
  const classes = useStyles();
  const listRef = useRef();

  const handleToggle = useCallback(() => {
    onToggle(title);
  }, [title, onToggle]);

  const handleOptionSelected = useCallback(
    (optionValue) => {
      onSelectOption(value, optionValue);
    },
    [onSelectOption, value]
  );

  useEffect(() => {
    if (listRef.current) {
      gsap.to(listRef.current, {
        height: open ? "auto" : 0,
        duration: 0.25,
        ease: "expo.out",
      });
    }
  }, [listRef, open]);

  return (
    <div className={classes.accordionItem}>
      <button
        className={cn(classes.accordionButton, { open, selected })}
        onClick={handleToggle}
      >
        {title}
        <DropdownIcon className={cn(classes.dropdownIcon, { open })} />
      </button>
      <div className={classes.optionsList} ref={listRef}>
        <div className={classes.optionsListInner}>
          {options &&
            options.map((option) => (
              <SelectAccordionOption
                key={option.value}
                {...option}
                onSelect={handleOptionSelected}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

const AccordionSelectors = ({ filters, items, onSelectOption }) => {
  const [openValue, setOpenValue] = useState();
  const onToggleItem = useCallback(
    (key) => {
      setOpenValue((openKey) => (openKey === key ? null : key));
    },
    [setOpenValue]
  );

  const anySelected = useMemo(() => !!find(filters, (x) => x), [filters]);

  return (
    <div>
      {items &&
        items.map(({ title, key, options }) => (
          <SelectAccordionItem
            onToggle={onToggleItem}
            onSelectOption={onSelectOption}
            key={key}
            title={title}
            value={key}
            options={options}
            open={openValue === title}
            selected={!anySelected || filters[key]}
          />
        ))}
    </div>
  );
};

export default function Filters() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filterOptions = useSelector(getFilterOptionsSelector("products"));
  const filters = useSelector(getFiltersSelector("products"));
  const { applyFilter, clearFilters } = actions.products;

  const onSelectOption = useCallback(
    (key, value) => {
      dispatch(applyFilter({ key, value }));
    },
    [dispatch, applyFilter]
  );

  const onClear = useCallback(() => {
    dispatch(clearFilters());
  }, [dispatch, clearFilters]);

  return (
    <>
      <AccordionSelectors
        filters={filters}
        items={filterOptions}
        onSelectOption={onSelectOption}
      />
      <div className={classes.buttonContainer}>
        <button className={classes.clearButton} onClick={onClear}>
          Clear
        </button>
      </div>
    </>
  );
}

const useStyles = createUseStyles({
  filters: {
    gridColumn: "1 / span 4",
    [theme.breakpoints.up("md")]: {
      gridColumn: "1 / span 3",
    },
  },
  list: {
    gridColumn: "1 / span 4",
    [theme.breakpoints.up("md")]: {
      gridColumn: "4 / span 4",
    },
  },
  accordionItem: {
    overflow: "hidden",
    border: [1, "solid", theme.colors.border],
    marginTop: -1,
  },
  accordionButton: {
    padding: theme.spacing(2),
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    backgroundColor: "transparent",
    fontSize: 12,
    border: "none",
    opacity: 0.5,
    transition: "opacity 0.1s ease-in-out",
    "&:hover, &.open, &.selected": {
      opacity: 1,
    },
  },
  dropdownIcon: {
    transition: "transform 0.15s ease-in-out",
    "&.open": {
      transform: "rotate(180deg)",
    },
  },
  optionsList: {
    height: 0,
  },
  optionsListInner: {
    borderTop: [1, "solid", theme.colors.border],
    padding: [theme.spacing(1.5), 0],
  },
  option: {
    padding: [theme.spacing(1.5), theme.spacing(2)],
    display: "block",
    width: "100%",
    backgroundColor: "transparent",
    border: "none",
    borderRadius: 0,
    margin: 0,
    opacity: 0.5,
    transition: "opacity 0.1s ease-in-out",
    textAlign: "left",
    fontSize: 12,
    cursor: "pointer",
    "&:hover, &.selected": {
      opacity: 1,
    },
  },
  buttonContainer: {
    marginTop: -1,
    border: [1, "solid", theme.colors.border],
  },
  clearButton: {
    display: "block",
    padding: theme.spacing(4),
    fontSize: 12,
    width: "100%",
    backgroundColor: "transparent",
    borderRadius: 0,
    border: "none",
    opacity: 0.5,
    transition: "opacity 0.1s ease-in-out",
    cursor: "pointer",
    "&:hover, &.selected": {
      opacity: 1,
    },
  },
});
