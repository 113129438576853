import React, {
  useCallback,
  useRef,
  useEffect
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import gsap from 'gsap'
import CustomEase from 'gsap/CustomEase'
import {
  closeCart,
  getCartProducts,
  hideCartForm,
  isCartFormShown,
  isCartOpen,
  isCartSuccess,
  resetCart,
  showCartForm
} from '../../state/slices/ui/cart'
import theme from '../../style/theme'
import Count from '../Count'
import { useGlobalContext } from '../SiteContext'
import isEmpty from 'lodash/isEmpty'
import CartItem from './CartItem'
import Button from '../Button'
import SalesforceForm from './SalesforceForm'
import CloseIcon from '../Drawer/close.inline.svg'
import EmptyCart from './EmptyCart'

export default function Cart () {
  const classes = useStyles()
  const containerRef = useRef()
  const cartRef = useRef()
  const dispatch = useDispatch()
  const open = useSelector(isCartOpen)
  const overlayRef = useRef()
  const cartContentRef = useRef()
  const formContentRef = useRef()
  const submitButtonRef = useRef()

  const success = useSelector(isCartSuccess)
  const showForm = useSelector(isCartFormShown)

  const cartItems = useSelector(getCartProducts)
  const { cart } = useGlobalContext()
  const {
    link_title: cartLinkTitle,
    emptyCopy,
    emptyTitle,
    emptyImage,
    emptyCta,
    emptyCtaLabel,
    deliveryCopy
  } = cart

  useEffect(() => {
    if (containerRef.current) {
      gsap.set(containerRef.current, { pointerEvents: open ? 'all' : 'none' })
      gsap.to(overlayRef.current, { opacity: open ? 0.6 : 0, duration: open ? 1 : 1.5, ease: 'expo.out' })
      gsap.to(cartRef.current, { x: open ? '0%' : '100%', duration: 1, ease: CustomEase.create('drawer', '.32,0,.15,1') })
    }
  }, [open])

  useEffect(() => {
    if (cartContentRef.current) {
      gsap.to(cartContentRef.current, {
        autoAlpha: showForm ? 0 : 1,
        duration: showForm ? 0.5 : 1,
        delay: showForm ? 0 : 0.5,
        ease: 'expo.out'
      })
      gsap.to(formContentRef.current, {
        autoAlpha: showForm ? 1 : 0,
        duration: showForm ? 1 : 0.5,
        delay: showForm ? 0.5 : 0,
        ease: 'expo.out'
      })
    }
  }, [showForm])

  const onClose = useCallback(() => {
    dispatch(closeCart())
  }, [dispatch])

  const hideForm = useCallback(() => {
    dispatch(hideCartForm())
  }, [dispatch])

  const onContinueBrowsing = useCallback(() => {
    dispatch(resetCart())
    dispatch(closeCart())
  }, [dispatch])

  const onOrderSamples = useCallback(() => {
    if (showForm) {
      if (submitButtonRef.current) {
        submitButtonRef.current.click()
      }
    } else {
      dispatch(showCartForm())
    }
  }, [dispatch, showForm])

  return (
    <section ref={containerRef} className={classes.container}>
      <div className={classes.overlay} ref={overlayRef} onClick={onClose} role='button' aria-label='Close Cart' tabIndex={-1} onKeyDown={null} />
      <div className={classes.cart} ref={cartRef}>
        <div className={classes.cartContainer}>
          <header className={cn(classes.header, { empty: isEmpty(cartItems) })}>
            <div className={classes.headerTop}>
              <h2 className={classes.title}>{cartLinkTitle} {!success && <Count className={classes.count} count={cartItems.length} />}</h2>
              <button onClick={onClose} className={classes.closeButton} aria-label='Close Cart'><CloseIcon /></button>
            </div>
            {!isEmpty(cartItems) && (
              <div className={classes.headerBottom}>
                {!success && (
                  <Button
                    className={cn(classes.backToCartButton, { show: showForm })}
                    onClick={hideForm}
                    backArrow
                  >
                    Back To Cart
                  </Button>
                )}
              </div>
            )}
          </header>
          {success
            ? (
              /* TODO: Move these values into the CMS */
              <div className={classes.successMessage}>
                <p className={classes.successTitle}>We’ll get in touch shortly to confirm your order</p>
                <p className={classes.successSubtitle}>
                  Free samples of all our products are availablefor you to order, simply add to cart
                </p>
                <Button className={classes.closeCartButton} onClick={onContinueBrowsing}>
                  Continue Browsing
                </Button>
              </div>
              )
            : (
              <>
                <div className={classes.content}>
                  <div className={cn(classes.innerContent, classes.cartContent)} ref={cartContentRef}>
                    {isEmpty(cartItems) && (
                      <EmptyCart title={emptyTitle} copy={emptyCopy} link={emptyCta} linkLabel={emptyCtaLabel} image={emptyImage} />
                    )}
                    {cartItems && cartItems.map((item) => (
                      <CartItem key={item.id} item={item} />
                    ))}
                  </div>
                  <div className={cn(classes.innerContent, classes.formContent)} ref={formContentRef}>
                    <SalesforceForm ref={submitButtonRef} cartItems={cartItems} />
                  </div>
                </div>
                <footer className={classes.footer}>
                  {!isEmpty(cartItems) && (
                    <>
                      {deliveryCopy && <p className={classes.deliveryCopy}>{deliveryCopy}</p>}
                      <Button className={classes.submitButton} onClick={onOrderSamples}>
                        Order Samples
                      </Button>
                    </>
                  )}
                </footer>
              </>
              )}
        </div>
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  container: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: theme.zIndex.drawer,
    pointerEvents: 'none',
    overflow: 'hidden'
  },
  overlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    backgroundColor: theme.colors.black,
    opacity: 0
  },
  cart: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
    width: '100%',
    backgroundColor: theme.colors.background,
    padding: [theme.spacing(5), theme.gutter.sm, theme.gutter.sm],
    transform: 'translate(100%, 0)',
    [theme.breakpoints.up('md')]: {
      height: 'auto',
      bottom: 0,
      padding: theme.gutter.md,
      width: 480
    }
  },
  cartContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  header: {
    flexGrow: 0,
    flexShrink: 0,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  headerTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(5)
    }
  },
  headerBottom: {
    marginBottom: theme.spacing(9),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(9)
    }
  },
  backToCartButton: {
    visibility: 'hidden',
    '&.show': {
      visibility: 'visible'
    }
  },
  content: {
    flexGrow: 1,
    flexShrink: 1,
    overflow: 'auto',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: 1,
      backgroundColor: theme.colors.border,
      opacity: 0.3
    },
    '&.empty:after': {
      display: 'none'
    }
  },
  innerContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflowY: 'auto'
  },
  formContent: {
    opacity: 0
  },
  footer: {
    flexGrow: 0,
    flexShrink: 0
  },
  title: {
    fontSize: 20,
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
    fontFamily: theme.fonts.body
  },
  count: {
    marginLeft: theme.spacing(1)
  },
  copy: {
    whiteSpace: 'pre-wrap',
    fontSize: 10,
    textAlign: 'center',
    marginBottom: theme.spacing(3)
  },
  emptyCopy: {
    composes: ['$copy'],
    marginTop: theme.spacing(3),
    fontSize: 15
  },
  deliveryCopy: {
    composes: ['$copy'],
    opacity: 0.5
  },
  successCopy: {
    composes: ['$copy'],
    color: theme.colors.success
  },
  errorCopy: {
    composes: ['$copy'],
    color: theme.colors.error
  },
  submitButton: {
    width: '100%',
    padding: [16, 32]
  },
  closeButton: {
    padding: 0,
    margin: 0,
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: 0,
    cursor: 'pointer'
  },
  successMessage: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    width: '100%',
    maxWidth: 340,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  successTitle: {
    fontSize: 15
  },
  successSubtitle: {
    fontSize: 12,
    opacity: 0.5
  },
  closeCartButton: {

  }
})
