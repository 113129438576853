import React from 'react'
import { createUseStyles } from 'react-jss'

const IconDownload = () => {
    const classes = useStyles()

    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes.downloadIcon} >
            <rect width="40" height="40" fill="currentColor"/>
            <path d="M12.5 21.8999C12.6326 21.8999 12.7598 21.9526 12.8536 22.0463C12.9473 22.1401 13 22.2673 13 22.3999V24.8999C13 25.1651 13.1054 25.4195 13.2929 25.607C13.4804 25.7945 13.7348 25.8999 14 25.8999H26C26.2652 25.8999 26.5196 25.7945 26.7071 25.607C26.8946 25.4195 27 25.1651 27 24.8999V22.3999C27 22.2673 27.0527 22.1401 27.1464 22.0463C27.2402 21.9526 27.3674 21.8999 27.5 21.8999C27.6326 21.8999 27.7598 21.9526 27.8536 22.0463C27.9473 22.1401 28 22.2673 28 22.3999V24.8999C28 25.4303 27.7893 25.939 27.4142 26.3141C27.0391 26.6892 26.5304 26.8999 26 26.8999H14C13.4696 26.8999 12.9609 26.6892 12.5858 26.3141C12.2107 25.939 12 25.4303 12 24.8999V22.3999C12 22.2673 12.0527 22.1401 12.1464 22.0463C12.2402 21.9526 12.3674 21.8999 12.5 21.8999Z" fill="white"/>
            <path d="M19.6466 23.854C19.6931 23.9006 19.7483 23.9375 19.809 23.9627C19.8697 23.9879 19.9349 24.0009 20.0006 24.0009C20.0664 24.0009 20.1315 23.9879 20.1923 23.9627C20.253 23.9375 20.3082 23.9006 20.3546 23.854L23.3546 20.854C23.4485 20.7601 23.5013 20.6328 23.5013 20.5C23.5013 20.3672 23.4485 20.2399 23.3546 20.146C23.2607 20.0521 23.1334 19.9994 23.0006 19.9994C22.8679 19.9994 22.7405 20.0521 22.6466 20.146L20.5006 22.293V13.5C20.5006 13.3674 20.448 13.2402 20.3542 13.1464C20.2604 13.0527 20.1332 13 20.0006 13C19.868 13 19.7408 13.0527 19.6471 13.1464C19.5533 13.2402 19.5006 13.3674 19.5006 13.5V22.293L17.3546 20.146C17.2607 20.0521 17.1334 19.9994 17.0006 19.9994C16.8679 19.9994 16.7405 20.0521 16.6466 20.146C16.5527 20.2399 16.5 20.3672 16.5 20.5C16.5 20.6328 16.5527 20.7601 16.6466 20.854L19.6466 23.854Z" fill="white"/>
        </svg>
    )
}
  
const useStyles = createUseStyles({
    downloadIcon: {
        position: 'absolute',
        right: 0,
        top: 0
    }
})

export default IconDownload

  