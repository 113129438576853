import { primaryInput } from "detect-it";
import { sortBy } from "lodash";
import filter from "lodash/filter";
import get from "lodash/get";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedItem, openDetailsPanel } from "../../state/slices/drawer";
import { isCurrentBreakpointMobile } from "../../state/slices/layout";
import LinkList from "../LinkList";
import { useGlobalContext } from "../SiteContext";

export default function DocumentByProducts() {
  const { products, counts } = useGlobalContext();
  const selectedItem = useSelector(getSelectedItem("resources"));
  const isMobile = useSelector(isCurrentBreakpointMobile);
  const dispatch = useDispatch();
  const productItems = useMemo(() => {
    return filter(
      sortBy(products, (x) => x.title).map((product) => ({
        count: get(counts.documentsByProduct, [product.id, "totalCount"]),
        text: product.title,
        subtitle: product.category?.title,
        product: product,
      })),
      (x) => x.count > 0
    );
  }, [products]); /* eslint-disable-line */

  const onSelectSecondLevelItem = useCallback(
    (value) => {
      if (selectedItem !== value) {
        dispatch(openDetailsPanel({ slug: "resources", item: value }));
      }
    },
    [dispatch, selectedItem]
  );

  return (
    <LinkList
      items={productItems}
      onMouseEnter={
        primaryInput === "touch" || isMobile ? null : onSelectSecondLevelItem
      }
      selectedItem={selectedItem}
      onClick={
        primaryInput === "touch" || isMobile ? onSelectSecondLevelItem : null
      }
      size="medium"
    />
  );
}
