import React, { useEffect, useRef, useState } from "react";

import gsap from "gsap";
import find from "lodash/find";
import last from "lodash/last";
import map from "lodash/map";
import { createUseStyles } from "react-jss";
import UseInternalLinkUrl from "../../hooks/useInternalLinkUrl";
import theme from "../../style/theme";
import ImageCurtain from "../Animation/ImageCurtain";
import Button from "../Button";
import { getObjectPosition } from "../ResponsiveImage";
import transform from "../ResponsiveImage/transform";
import { Caption } from "../Typography";
import Panel from "./Panel";

export default function ProjectsPanel({ project, open }) {
  const [currentImage, setCurrentImage] = useState();
  const [currentProject, setCurrentProject] = useState(project);
  const classes = useStyles();
  const detailsPanelOpenAndReady = open && !!currentImage;
  const url = UseInternalLinkUrl(currentProject?.project);

  const localsRef = useRef({ open, animatedIn: false });
  const imageContainerRef = useRef();
  const imageCurtainRef = useRef();
  const detailsRef = useRef();

  useEffect(() => {
    if (!open) {
      localsRef.current.animatedIn = false;
    }
  }, [open]);

  const animateIn = () => {
    if (imageCurtainRef.current && detailsRef.current) {
      const tl = gsap.timeline();
      tl.set(detailsRef.current.children, { opacity: 0 });
      tl.to(
        detailsRef.current.children,
        {
          opacity: 1,
          duration: 0.8,
          ease: "sine.inOut",
          stagger: 0.1,
          onComplete: () => {
            localsRef.current.animatedIn = true;
          },
        },
        !localsRef.current.animatedIn ? 0.6 : 0.1
      );

      const timeline = imageCurtainRef.current.getTimeline();
      if (!localsRef.current.animatedIn) {
        gsap.set(imageContainerRef.current, { opacity: 1 });
        timeline.seek(0);
        timeline.resume();
      } else {
        // timeline.seek(1)
        tl.to(
          imageContainerRef.current,
          { opacity: 1, duration: 0.5, ease: "sine.inOut" },
          0
        );
      }

      localsRef.current.animateInTimeline = tl;
      return () => {
        tl.kill();
      };
    }
  };

  useEffect(() => {
    if (detailsRef.current) {
      // Kill the animating in timeline as we have moved to a different project
      if (localsRef.current.animateInTimeline) {
        localsRef.current.animateInTimeline.kill();
      }
      const tl = gsap.timeline();
      tl.to(imageContainerRef.current, {
        opacity: 0,
        duration: 0.15,
        ease: "sine.inOut",
      });
      tl.to(
        detailsRef.current.children,
        {
          opacity: 0,
          duration: 0.15,
          ease: "sine.inOut",
          onComplete: () => {
            setCurrentProject(project);
          },
        },
        0
      );
      return () => {
        tl.kill();
      };
    } else {
      setCurrentProject(project);
    }
  }, [project]);

  useEffect(() => {
    const image = currentProject?.project?.image;
    if (image) {
      let newProjectLoaded = false;
      const imageSources = transform(image, 630 / 810);
      const { url } =
        find(
          imageSources.sizes,
          ({ height }) => height >= window.innerHeight
        ) || last(imageSources.sizes);
      const img = new window.Image();
      img.onload = function () {
        if (!newProjectLoaded) {
          setCurrentImage({
            src: url,
            alt: image.alt,
            objectPosition: getObjectPosition(image.hotspot, image.crop),
          });
        }
      };
      img.src = url;
      return () => {
        newProjectLoaded = true;
      };
    }
  }, [currentProject]);

  useEffect(() => {
    if (currentImage) {
      return animateIn();
    }
  }, [currentImage]);

  return (
    <Panel
      title="Project Details"
      open={detailsPanelOpenAndReady}
      showHeader={false}
      fill
      index={3}
      showCloseButton
      fillCloseButton
    >
      {currentImage && (
        <div className={classes.container}>
          <div ref={imageContainerRef} className={classes.imageContainer}>
            <img
              src={currentImage.src}
              alt={currentImage.alt}
              className={classes.image}
              style={{ objectPosition: currentImage.objectPosition }}
            />
            <ImageCurtain
              animatedWhenInView
              animationType="topDown"
              delay={0.3}
              duration={0.8}
              ref={imageCurtainRef}
            />
          </div>
          <div className={classes.product} ref={detailsRef}>
            <span className={classes.title}>
              {currentProject?.project?.title}
            </span>
            <div>
              {currentProject?.project?.locationDetails && (
                <div className={classes.locationDetails}>
                  <Caption className={classes.caption}>Location</Caption>
                  <span>{currentProject?.project?.locationDetails}</span>
                </div>
              )}
              <div className={classes.productDetails}>
                <div>
                  <Caption className={classes.caption}>Products</Caption>
                  {map(currentProject?.project?.products, ({ title, id }) => (
                    <div key={id}>{title}</div>
                  ))}
                </div>
                <div>
                  <Caption className={classes.caption}>Applications</Caption>
                  {map(
                    currentProject?.project?.products,
                    ({ application, id }) => (
                      <div key={id}>{application[0]}</div>
                    )
                  )}
                </div>
              </div>
              <Button to={url}>View Project</Button>
            </div>
          </div>
        </div>
      )}
    </Panel>
  );
}

const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    fontSize: 12,
  },
  imageContainer: {
    width: "100%",
    position: "relative",
    flexGrow: 1,
    flexShrink: 1,
  },
  image: {
    objectFit: "cover",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: "100%",
    width: "100%",
  },
  product: {
    minHeight: 280,
    flexGrow: 0,
    flexShrink: 0,
    padding: theme.gutter.sm,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      padding: theme.gutter.md,
    },
  },
  title: {
    fontSize: 15,
    display: "block",
    marginBottom: theme.spacing(5),
  },
  productDetails: {
    display: "flex",
    marginBottom: theme.spacing(5),
    "& > *": {
      width: "50%",
    },
  },
  locationDetails: {
    marginBottom: theme.spacing(5),
  },
  caption: {
    opacity: 0.5,
    display: "block",
  },
});
