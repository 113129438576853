import React, { useRef, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { isCurrentBreakpointMobile } from '../../state/slices/layout'
import theme from '../../style/theme'
import ImageCurtain from '../Animation/ImageCurtain'
import ResponsiveImage from '../ResponsiveImage'

const ImageTile = ({ width, image }) => {
  const classes = useImageTileStyles({ width })
  return (
    <div className={classes.imageContainer}>
      {image.asset && (
        <ResponsiveImage image={image} aspect={image.asset.metadata.dimensions.aspectRatio}>
          <ImageCurtain animationType='topDown' animatedWhenInView />
        </ResponsiveImage>
      )}
    </div>
  )
}

const useImageTileStyles = createUseStyles({
  imageContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: ({ width = 3 }) => `calc((((100% + ${theme.gutter.md * 2}px) - ${theme.gutter.md * 8}px) / 7) * ${width} + (${theme.gutter.md}px * ${width - 1}))`
    }
  }
})

export default function ImageGrid ({ slice }) {
  const { images } = slice
  const classes = useStyles()
  const ref = useRef()
  const isMobile = useSelector(isCurrentBreakpointMobile)

  useEffect(() => {
    if (ref.current) {
      let pckry
      const initPackery = async () => {
        const Packery = (await import('packery')).default
        pckry = new Packery(ref.current, {
          gutter: isMobile ? theme.gutter.sm : theme.gutter.md,
          percentPosition: true
        })
      }
      initPackery()
      return () => {
        if (pckry) pckry.destroy()
      }
    }
  }, [isMobile])
  return (
    <section className={classes.grid} ref={ref}>
      {images && images.map(({ width, image }, i) => (
        <ImageTile key={i} width={width} image={image} />
      ))}
    </section>
  )
}

const useStyles = createUseStyles({
  grid: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: [0, theme.gutter.sm, theme.spacing(10)],
    [theme.breakpoints.up('md')]: {
      margin: [0, theme.gutter.md, theme.spacing(10)]
    }
  }
})
