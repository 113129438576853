import get from "lodash/get";
import mapValues from "lodash/mapValues";
import pickBy from "lodash/pickBy";
// import imageResolver from './imageResolver'
import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";
import theme from "../../style/theme";
import forEach from "lodash/forEach";
// import theme from '../../style/theme'

export const SANITY_REF_PATTERN = /^image-([a-f\d]+)-(\d+x\d+)-(\w+)$/;

// export const DEFAULT_IMAGE_CONFIG = __GATSBY_PLUGIN_SANITY_IMAGE__DEFAULT_IMAGE_CONFIG__ || {
//   auto: 'format',
//   fit: 'max',
//   quality: 75
// }

export const client = sanityClient({
  dataset: process.env.GATSBY_SANITY_DATASET,
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  useCdn: process.env.GATSBY_SANITY_USE_CDN,
  apiVersion: process.env.GATSBY_SANITY_API_VERSION,
});

export const builder = imageUrlBuilder(client);

function transformPalette(palette) {
  const schemes = pickBy(palette, { _type: "sanity.imagePaletteSwatch" });
  return mapValues(schemes, (scheme) =>
    pickBy(scheme, (value, key) => key !== "_type")
  );
}

// TODO: Creat the different aspects for mobile if needed
const getAspect = (
  asset,
  vector,
  width,
  targetDesktopAspect,
  targetMobileAspect
) => {
  const sourceAspect = get(asset, ["metadata", "dimensions", "aspectRatio"]);
  const resolvedAspect =
    width <= theme.breakpoints.values.md
      ? targetMobileAspect || targetDesktopAspect
      : targetDesktopAspect;
  const needsCrop =
    !vector && resolvedAspect && resolvedAspect !== sourceAspect;
  return {
    width,
    needsCrop: needsCrop,
    aspect: (needsCrop && resolvedAspect) || sourceAspect,
  };
};

const WIDTHS = [320, 420, 640, 960, 1280, 1600, 1920, 2240, 2560, 3200, 7680];

export default function transform(image, targetAspect, targetMobileAspect) {
  if (!image || !image.asset) {
    return;
  }

  const { asset } = image;

  const imageMeta = parseImageRef(asset._id || asset._ref);

  const vector = imageMeta.format === "svg";

  const hasAlpha = get(asset, ["metadata", "hasAlpha"]);
  const opaque = get(asset, ["metadata", "isOpaque"]);
  const width =
    get(asset, ["metadata", "dimensions", "width"]) ||
    imageMeta.dimensions.width;
  const height =
    get(asset, ["metadata", "dimensions", "height"]) ||
    imageMeta.dimensions.height;
  const sourceAspect =
    get(asset, ["metadata", "dimensions", "aspectRatio"]) ||
    imageMeta.dimensions.width / imageMeta.dimensions.height;
  const palette = transformPalette(get(image, ["metadata", "palette"]));
  const sizes = [];
  const result = {
    sourceAspect,
    hasAlpha,
    opaque,
    vector,
    palette,
    sizes,
  };

  let widths = WIDTHS;
  // If the image is smaller then the smallest width, then just use the image width
  if (WIDTHS[0] > imageMeta.dimensions.width) {
    widths = [imageMeta.dimensions.width];
  }
  const aspects = widths.map((width) => ({
    ...getAspect(
      asset,
      vector,
      width,
      targetAspect || sourceAspect,
      targetMobileAspect
    ),
  }));

  if (image.alt) {
    result.alt = image.alt;
  }
  if (image.caption) {
    result.title = image.caption;
  }
  if (image.attribution) {
    result.credit = image.attribution;
  }
  if (image.hotspot) {
    const { _type: type, ...hotspot } = image.hotspot;
    result.hotspot = {
      type,
      ...hotspot,
    };
  }
  if (image.crop) {
    const { _type: type, ...crop } = image.crop;
    result.crop = {
      type,
      ...crop,
    };
  }

  if (vector) {
    sizes.push({
      width,
      height,
      url: builder.image(image).url(),
    });
  } else {
    // const webpEnabled = imageMeta.format !== 'gif'
    // let w = 0
    const maxWidth = width;
    // let maxHeight = height
    let resizeBuilder = builder.image(image);

    forEach(aspects, ({ needsCrop, aspect, width: w }) => {
      if (w <= maxWidth) {
        if (needsCrop) {
          if (image.hotspot) {
            resizeBuilder = resizeBuilder
              .fit("crop")
              .crop("focalpoint")
              .focalPoint(image.hotspot.x, image.hotspot.y);
          } else {
            resizeBuilder = resizeBuilder.fit("min");
          }
        }
        let sizeBuilder = resizeBuilder.width(w);
        if (needsCrop) {
          sizeBuilder = sizeBuilder.height(Math.round(w / aspect));
        }
        sizeBuilder = sizeBuilder.auto("format");
        const size = {
          width: w,
          height: Math.round(w / aspect),
          url: sizeBuilder.url(),
        };

        // We use auto format so the images are set to webp automatically
        // if (webpEnabled) {
        //   size.webpUrl = sizeBuilder.format('webp').url()
        // }
        sizes.push(size);
      }
    });
    const originalAspect = targetAspect || sourceAspect;
    const originalSizeBuilder = resizeBuilder
      .width(imageMeta.dimensions.width)
      .height(Math.round(imageMeta.dimensions.width / originalAspect))
      .auto("format");
    sizes.push({
      width: imageMeta.dimensions.width,
      height: imageMeta.dimensions.width / originalAspect,
      url: originalSizeBuilder.url(),
    });
  }

  return result;
}

export const parseImageRef = (id) => {
  try {
    const [, assetId, dimensions, format] = SANITY_REF_PATTERN.exec(id);
    const [width, height] = dimensions.split("x").map((v) => parseInt(v, 10));

    return {
      assetId,
      dimensions: { width, height },
      format,
    };
  } catch {
    throw new Error(`Could not parse image ID "${id}"`);
  }
};
