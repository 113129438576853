import React, { useRef, useEffect } from "react";
import { createUseStyles } from "react-jss";
import theme from "../../../style/theme";
import ImageCurtain from "../../Animation/ImageCurtain";
import ResponsiveImage from "../../ResponsiveImage";
import Slider from "../../Slider";

const ImageAspects = {
  2: 420 / 660,
  3: 1,
  4: 870 / 660,
};

const Image = ({ image, size, index }) => {
  const classes = useImageStyles({ size });
  // we delay the second item as this is visible, but the other are not visible in the carouse
  const delay = index === 1 ? 0.1 : 0;
  return (
    <ResponsiveImage
      image={image}
      className={classes.image}
      aspect={ImageAspects[size]}
    >
      {index < 2 && (
        <ImageCurtain
          animationType="topDown"
          animatedWhenInView
          delay={delay}
        />
      )}
    </ResponsiveImage>
  );
};

export default function ProjectImageCarousel({
  images,
  index = 0,
  onIndexChanged,
  onClick,
}) {
  const classes = useStyles();
  const sliderRef = useRef();

  useEffect(() => {
    const flkty = sliderRef.current.getFlickity();
    flkty.on("change", onIndexChanged);
    flkty.on("staticClick", onClick);
    return () => {
      flkty.off("change", onIndexChanged);
      flkty.off("staticClick", onClick);
    };
  }, [onIndexChanged, onClick]);

  useEffect(() => {
    const flkty = sliderRef.current.getFlickity();
    if (flkty.selectedIndex !== index) {
      flkty.select(index, false, false);
    }
  }, [index]);

  return (
    <div className={classes.container}>
      <Slider ref={sliderRef}>
        {images &&
          images.map(({ image, size }, i) => (
            <Image key={i} image={image} size={size} index={i} />
          ))}
      </Slider>
    </div>
  );
}

const useStyles = createUseStyles({
  container: {
    overflow: "hidden",
    margin: [0, -theme.gutter.sm],
    padding: [0, theme.gutter.sm],
    [theme.breakpoints.up("md")]: {
      margin: [0, -theme.gutter.md],
      padding: [0, theme.gutter.md],
    },
  },
});

const useImageStyles = createUseStyles({
  image: {
    flexShrink: 0,
    marginRight: theme.gutter.sm,
    height: "calc((100vw / 375) * 300)",
    width: ({ size }) => `${(parseInt(size) / 4) * 100}%`,
    cursor: "pointer",
    [theme.breakpoints.up("md")]: {
      marginRight: theme.gutter.md,
      width: ({ size = 3 }) => theme.span(size, "md"),
      height: "calc((100vw / 1440) * 600)",
    },
  },
});
