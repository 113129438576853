import React, { useMemo, useCallback, useRef } from "react";
import { createUseStyles } from "react-jss";
import { resolveInternalLinkUrl } from "../../helpers/resolveLink";
import theme from "../../style/theme";
import ResponsiveImage from "../ResponsiveImage";
import SliceHeader from "../SliceHeader";
import Link from "../Link";
import Section from "../Section";
import ImageCurtain from "../Animation/ImageCurtain";
import { HorizontalLine } from "../Lines";
import { Caption } from "../Typography";
import { useStaticQuery, graphql } from "gatsby";
import get from "lodash/get";
import useStandardInAnimation from "../Animation/useStandardInAnimation";
import { formatDate } from "../../helpers/format";

const ArticleTile = ({ article }) => {
  const { title, summary, post_date: postDate, image, category } = article;
  const to = useMemo(() => resolveInternalLinkUrl(article), [article]);
  const classes = useStyles();
  const containerRef = useRef();

  const [inViewRef] = useStandardInAnimation(
    useCallback(() => {
      return containerRef.current.children;
    }, [])
  );

  return (
    <Link to={to} className={classes.article} ref={inViewRef}>
      <Section
        grid
        noBottomMargin
        tag="article"
        className={classes.grid}
        ref={containerRef}
      >
        <ResponsiveImage
          image={image}
          aspect={380 / 210}
          className={classes.image}
        >
          <ImageCurtain animationType="topDown" animatedWhenInView />
        </ResponsiveImage>
        <div className={classes.content}>
          <span className={classes.title}>{title}</span>
          <p className={classes.summary}>{summary}</p>
          {category && (
            <Caption className={classes.category}>{category.title}</Caption>
          )}
          {postDate && (
            <Caption className={classes.date}>{formatDate(postDate)}</Caption>
          )}
        </div>
      </Section>
      <HorizontalLine className={classes.line} position="bottom" />
    </Link>
  );
};

export default function RecentArticles({ slice, id }) {
  const classes = useStyles();
  const { title, copy, cta, ctaLabel } = slice;

  const data = useStaticQuery(graphql`
    query MyQuery {
      allSanityPage(
        sort: { post_date: DESC }
        filter: { parentPage: { slug: { current: { eq: "resources" } } } }
        limit: 3
      ) {
        nodes {
          ...PageReference
        }
      }
    }
  `);

  const articles = get(data, ["allSanityPage", "nodes"]);

  return (
    <section className={classes.section} id={id}>
      <SliceHeader title={title} copy={copy} cta={cta} ctaLabel={ctaLabel} />
      {articles &&
        articles.map((article) => (
          <ArticleTile key={article.title} article={article} />
        ))}
    </section>
  );
}

const useStyles = createUseStyles({
  section: {
    marginBottom: theme.spacing(20),
  },
  article: {
    display: "block",
    position: "relative",
    marginBottom: theme.gutter.sm,
    paddingBottom: theme.gutter.sm,
    textDecoration: "none",
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.gutter.md,
      paddingBottom: theme.gutter.md,
    },
    "& $category, & $date, & $line": {
      transition: "opacity 0.15s ease-in-out",
    },
    "&:hover $category, &:hover $date, &:hover $line": {
      opacity: 1,
    },
  },
  grid: {},
  image: {
    alignSelf: "start",
    gridColumn: "1 / span 4",
    opacity: 0,
    [theme.breakpoints.up("md")]: {
      gridColumn: "1 / span 2",
    },
  },
  content: {
    opacity: 0,
    gridColumn: "1 / span 4",
    display: "grid",
    gap: theme.gutter.sm,
    gridTemplateColumns: "repeat(4, 1fr)",
    [theme.breakpoints.up("md")]: {
      gridColumn: "3 / span 5",
      gridTemplateColumns: "repeat(5, 1fr)",
      gridTemplateRows: "auto min-content",
      gap: theme.gutter.md,
    },
  },
  title: {
    fontSize: 18,
    margin: 0,
    gridColumn: "1 / span 4",
    lineHeight: 1.4,
    [theme.breakpoints.up("md")]: {
      fontSize: 20,
      gridColumn: "1 / span 2",
    },
  },
  summary: {
    margin: [0, 0, theme.spacing(3)],
    gridColumn: "1 / span 4",
    fontSize: 12,
    [theme.breakpoints.up("md")]: {
      maxWidth: 400,
      fontSize: 15,
      margin: 0,
      gridColumn: "3 / span 2",
    },
  },
  date: {
    margin: 0,
    opacity: 0.5,
    gridColumn: "3 / span 2",
    gridRow: 3,
    [theme.breakpoints.up("md")]: {
      gridRow: 2,
      gridColumn: 2,
    },
  },
  category: {
    margin: 0,
    opacity: 0.5,
    gridColumn: "1 / span 2",
    gridRow: 3,
    [theme.breakpoints.up("md")]: {
      gridRow: 2,
      gridColumn: 1,
    },
  },
  line: {
    bottom: 0,
    opacity: 0.1,
  },
});
