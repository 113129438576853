import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import cn from 'classnames'

export default function SliceTitle ({ className, ...props }) {
  const classes = useStyles()
  return (
    <h2 className={cn(classes.title, className)} {...props} /> /* eslint-disable-line */
  )
}

const useStyles = createUseStyles({
  title: {
    gridColumn: '1 / span 4',
    lineHeight: 1,
    marginBottom: theme.spacing(10),
    [theme.breakpoints.up('md')]: {
      gridColumn: '1 / span 7',
      marginBottom: theme.spacing(30)
    }
  }
})
