import React, { useCallback } from "react";
import Section from "../Section";
import RichText from "../RichText";
import ResponsiveImage from "../ResponsiveImage";
import UseInternalLinkUrl from "../../hooks/useInternalLinkUrl";
import Lnk from "../Link";
import { HorizontalLine } from "../Lines";
import { createUseStyles } from "react-jss";
import theme from "../../style/theme";
import DetailsPanel, { Cell } from "../DetailsPanel";
import cx from "classnames";

function FeedbackBigImage({ slice }) {
  const classes = useStyles();
  const { feedbacks } = slice;

  const renderFeedbacks = useCallback(
    (listFeedbacks) =>
      listFeedbacks.map(
        (
          { company, date, role, imgPosition, _rawText, image, project, title },
          idx
        ) => {
          return (
            <Section
              key={`${date}-${idx}`}
              grid
              noBottomMargin
              className={classes.section}
            >
              <div className={cx(classes.firstContainer, classes[imgPosition])}>
                <ResponsiveImage image={image} className={classes.image} />
              </div>
              <div
                className={cx(
                  classes.secondContainer,
                  classes[`${imgPosition}Text`]
                )}
              >
                <div>
                  {Boolean(title?.length) && (
                    <span className={classes.quoteTitle}>{title}</span>
                  )}
                  <RichText blocks={_rawText.text} />
                </div>

                <div className={classes.detailsContainer}>
                  <DetailsPanel className={classes.details}>
                    <Cell label={"Company"}>{company}</Cell>
                    <Cell label={"Role"}>{role}</Cell>
                    <Cell label={"Project"}>
                      {Boolean(project) && (
                        <Lnk to={UseInternalLinkUrl(project)}>
                          {project.title}
                        </Lnk>
                      )}
                    </Cell>
                    <Cell label={"Date"}>{date}</Cell>
                  </DetailsPanel>
                </div>
              </div>
              <HorizontalLine
                position="bottom"
                extend
                animationOrigin="0%"
                className={classes.horizontalLine}
              />
            </Section>
          );
        }
      ),
    [classes]
  );
  return Boolean(feedbacks?.length) && renderFeedbacks(feedbacks);
}

const useStyles = createUseStyles({
  section: {
    position: "relative",
    [theme.breakpoints.up("md")]: {
      paddingBottom: "64px",
      paddingTop: "64px",
    },
  },
  image: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "720px",
    },
  },
  firstContainer: {
    gridColumn: "1 / span 4",
    marginBottom: theme.spacing(10),
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      gridColumn: "span 4",
      marginBottom: 0,
      paddingTop: 0,
    },
  },
  left: {
    [theme.breakpoints.up("md")]: {
      gridColumn: "span 4",
    },
  },
  right: {
    [theme.breakpoints.up("md")]: {
      gridColumn: "4 / span 4",
      "& > div": {
        marginLeft: "auto",
      },
    },
  },
  secondContainer: {
    gridColumn: "1 / span 4",
    display: "flex",
    flexDirection: "column",
    gap: "77px",
    [theme.breakpoints.up("md")]: {
      justifyContent: "space-between",
      gap: "16px",
    },
  },
  leftText: {
    [theme.breakpoints.up("md")]: {
      gridRow: 1,
      gridColumn: "5 / span 3",
    },
  },
  rightText: {
    [theme.breakpoints.up("md")]: {
      gridColumn: "span 3",
      gridRow: 1,
    },
  },
  detailsContainer: {
    overflow: "hidden",
    margin: [0, -theme.gutter.sm],
    [theme.breakpoints.up("md")]: {
      margin: [0, theme.gutter.md, 0, 0],
    },
  },
  details: {
    margin: [1, -1],
    [theme.breakpoints.up("md")]: {
      margin: [1, 0],
      width: "calc(100% - 2px)",
    },
  },
  horizontalLine: {
    visibility: "hidden",
    [theme.breakpoints.up("md")]: {
      visibility: "visible",
    },
  },
  quoteTitle: {
    display: "flex",
    alignItems: "center",
    fontSize: 20,
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(6),
    },
  },
});

export default FeedbackBigImage;
