import React, { useCallback, useMemo, useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import Section from "../../Section";
import { useDispatch, useSelector } from "react-redux";
import theme from "../../../style/theme";
import Button from "../../Button";
import {
  fetchAbout,
  getItemsSelector,
  isLoadedSelector,
} from "../../../state/slices/data/lists";
import range from "lodash/range";
import map from "lodash/map";
import RichText from "../../RichText";
import isEmpty from "lodash/isEmpty";
import Tile from "./Tile";
import { HorizontalLine } from "../../Lines";

const LIMIT_NUMBER = 12;

export default function TileGrid({ emptyCopy, type }) {
  const classes = useStyles();
  const items = useSelector(getItemsSelector(type));
  const [limit, setLimit] = useState(LIMIT_NUMBER);
  const loaded = useSelector(isLoadedSelector(type));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaded) {
      dispatch(fetchAbout());
    }
  }, [dispatch]); /* eslint-disable-line */

  const onLoadMoreClick = useCallback(() => {
    setLimit((value) => value + LIMIT_NUMBER);
  }, []);

  const pagedItems = useMemo(() => {
    if (!loaded) {
      return map(range(LIMIT_NUMBER), (_, i) => ({ id: i, placeholder: true }));
    }
    return items ? items.slice(0, limit) : [];
  }, [limit, items, loaded]);

  const totalItems = items ? items.length : 0;
  const hasMoreItems = pagedItems.length < totalItems;

  return (
    <Section grid className={classes.section} noBottomMargin>
      {loaded && isEmpty(pagedItems) && (
        <RichText className={classes.emptyResults} blocks={emptyCopy.text} />
      )}
      {pagedItems.map((project, index) => (
        <Tile
          key={project.id}
          item={project}
          placeholder={project.placeholder}
          index={index}
        />
      ))}
      {hasMoreItems && (
        <>
          <div>
            <HorizontalLine extend position="bottom" />
          </div>
          <Button
            onClick={onLoadMoreClick}
            className={classes.loadMoreButton}
            showArrow={false}
          >
            Load More
          </Button>
        </>
      )}
    </Section>
  );
}

const useStyles = createUseStyles({
  section: {
    position: "relative",
    padding: [theme.gutter.sm, 0],
    marginBottom: theme.spacing(20),
    [theme.breakpoints.up("md")]: {
      padding: [theme.gutter.md, 0],
      marginBottom: theme.spacing(25),
    },
  },
  emptyResults: {
    gridColumn: "1 / span 4",
    [theme.breakpoints.up("md")]: {
      gridColumn: "1 / span 7",
    },
  },
  loadMoreButton: {
    gridColumn: "1 / span 4",
    justifyContent: "center",
    padding: theme.gutter.sm,
    marginTop: -theme.gutter.sm,
    [theme.breakpoints.up("md")]: {
      gridColumn: "1 / span 7",
      padding: theme.gutter.md,
      marginTop: -theme.gutter.md,
    },
  },
});
