import { route } from '../../slices/route'
import { openPopup, closePopup, togglePopup, setOpenState, isChatPopupOpen } from '../../slices/ui/chat'
import inDom from 'dom-helpers/canUseDOM'

function initChatlio () {
  window._chatlio = window._chatlio||[]; // eslint-disable-line
  !function(){ var t=document.getElementById("chatlio-widget-embed");if(t&&window.ChatlioReact&&_chatlio.init)return void _chatlio.init(t,ChatlioReact);for(var e=function(t){return function(){_chatlio.push([t].concat(arguments)) }},i=["configure","identify","track","show","hide","isShown","isOnline", "page", "open", "showOrHide"],a=0;a<i.length;a++)_chatlio[i[a]]||(_chatlio[i[a]]=e(i[a]));var n=document.createElement("script"),c=document.getElementsByTagName("script")[0];n.id="chatlio-widget-embed",n.src="https://w.chatlio.com/w.chatlio-widget.js",n.async=!0,n.setAttribute("data-embed-version","2.3"); // eslint-disable-line
      n.setAttribute('data-widget-id','4b70aa79-4ad9-4c55-6579-dfefbcff649d'); // eslint-disable-line
      c.parentNode.insertBefore(n,c); // eslint-disable-line
  }(); // eslint-disable-line
}

const chat = (store) => {
  if (inDom) {
    if (!window._chatlio) {
      initChatlio()
      document.addEventListener('chatlio.expanded', (event) => {
        store.dispatch(setOpenState(true))
      })
      document.addEventListener('chatlio.collapsed', (event) => {
        store.dispatch(setOpenState(false))
      })
    }
  }
  return (next) => (action) => {
    const result = next(action)
    if (window._chatlio) {
      const _chatlio = window._chatlio
      switch (action.type) {
        case openPopup.toString():
          _chatlio.show({ expanded: true })
          break
        case closePopup.toString():
          _chatlio.show({ expanded: false })
          break
        case togglePopup.toString(): {
          const open = isChatPopupOpen(store.getState())
          _chatlio.show({ expanded: open })
          break
        }
        case route.toString(): {
          store.dispatch(closePopup())
          break
        }
        default:
      }
    }
    return result
  }
}

export default chat
