/* eslint-env browser */
import omitBy from 'lodash/omitBy'

export const fetchRequest = async (url, method = 'get', payload, headers = {}, fetchOptions = {}) => {
  const headerContentType = payload && !(payload instanceof FormData) ? 'application/json' : undefined

  const allHeaders = omitBy({
    'Content-Type': headerContentType,
    ...(headers || {})
  }, x => !x)

  const response = await fetch(url, {
    method,
    headers: allHeaders,
    body: payload ? payload instanceof FormData ? payload : JSON.stringify(payload) : null,
    ...(fetchOptions || {})
  })

  if (!response.ok) {
    const msg = `${response.status}: ${response.statusText}`
    throw Error(msg)
  }

  const contentType = response.headers.get('content-type') || ''
  if (contentType.indexOf('application/json') !== -1) {
    return response.json()
  } else if (contentType.indexOf('application/octet-stream') !== -1) {
    return response.arrayBuffer()
  } else {
    return response.text()
  }
}
