import React, { useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import LogoImage from './logo.inline.svg'
import { useGlobalContext } from '../SiteContext'

import map from 'lodash/map'
import Link from '../Link'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import { getY, useScrollListener } from '../useSmoothScrollbar'
import gsap from 'gsap'
import MenuButton from './MenuButton'
import CartButton from './CartButton'
import { useLocation } from '@reach/router'
import { isMenuOpen } from '../../state/slices/layout'
import { isPreloading } from '../../state/slices/preload'
import { isDrawerOpen } from '../../state/slices/drawer'
import MenuItem from './MenuItem'

export const getCurrentHeaderHeight = () => {
  return getY() === 0 && window.innerWidth >= theme.breakpoints.values.md ? theme.header.height.large : theme.header.height.small
}

export default function Header () {
  const classes = useStyles()
  const { menu } = useGlobalContext()
  const mobileMenuOpen = useSelector(isMenuOpen)
  const headerRef = useRef()
  const listRef = useRef()
  const lineRef = useRef()
  const localsRef = useRef({ shrink: false, topOfHome: true, animatedIn: false })
  const preloading = useSelector(isPreloading)
  const drawerOpen = useSelector(isDrawerOpen)
  const logoRef = useRef()

  const location = useLocation()
  const isHome = location.pathname === '/'

  const animateHeader = (y, home, menuOpen, drawerOpen) => {
    if (logoRef.current) {
      const shrink = y > 0 || menuOpen
      const topOfHome = y === 0 && home && !menuOpen && !drawerOpen
      if (localsRef.current.shrink !== shrink) {
        gsap.to(headerRef.current, { y: shrink ? -28 : 0, duration: 0.5, ease: 'power2.out' })
        gsap.to(headerRef.current.children[0], { y: shrink ? 14 : 0, duration: 0.5, ease: 'power2.out' })
        // gsap.to(logoRef.current.children[0], { width: shrink ? 89 : 105, height: shrink ? 22 : 26, duration: 0.5, ease: 'power2.out' })
        gsap.to(logoRef.current.children[0], { x: shrink ? '-6%' : 0, scale: shrink ? 0.82 : 1, duration: 0.5, ease: 'power2.out' })
        localsRef.current.shrink = shrink
      }
      if (localsRef.current.topOfHome !== topOfHome) {
        gsap.to(lineRef.current, { opacity: topOfHome ? 0 : 1, duration: 0.5, ease: 'power2.out' })
        localsRef.current.topOfHome = topOfHome
      }
    }
  }

  useScrollListener(useCallback(({ y }) => {
    if (headerRef.current && y !== undefined) {
      animateHeader(y, isHome, mobileMenuOpen, drawerOpen)
    }
  }, [isHome, mobileMenuOpen, drawerOpen]))

  useEffect(() => {
    animateHeader(getY(), isHome, mobileMenuOpen, drawerOpen)
  }, [isHome, mobileMenuOpen, drawerOpen])

  useEffect(() => {
    if (!preloading && !localsRef.current.animatedIn) {
      gsap.to(listRef.current.children, { opacity: 1, duration: 1.5, stagger: 0.1, ease: 'sine.out', delay: 1.2 })
      localsRef.current.animatedIn = true
    }
  }, [preloading])

  return (
    <header className={classes.header} ref={headerRef}>
      <nav className={classes.nav}>
        <ul className={classes.list} ref={listRef}>
          <li><Link className={classes.logoLink} to='/' aria-label='Home' ref={logoRef}><LogoImage /></Link></li>
          {map(menu, ({ link }, i) => (
            <MenuItem key={i} link={link} />
          ))}
          <li className={classes.menuItem}><CartButton className={classes.cartLink} classNames={{ cartCount: classes.cartCount }} /></li>
          <li className={classes.mobileMenu}><MenuButton /></li>
        </ul>
      </nav>
      <div className={classes.line} ref={lineRef} />
    </header>
  )
}

const useStyles = createUseStyles({
  header: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    display: 'block',
    zIndex: theme.zIndex.header,
    backgroundColor: theme.colors.background
  },
  line: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.colors.border,
    height: 1,
    opacity: 0
  },
  nav: {
    padding: [12, theme.gutter.sm],
    [theme.breakpoints.up('md')]: {
      padding: [12, theme.gutter.md]
    }
  },
  list: {
    listStyle: 'none',
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
    margin: [0, -theme.gutter.sm / 2],
    [theme.breakpoints.up('md')]: {
      margin: [0, -theme.gutter.md / 2]
    },
    '& li': {
      opacity: 0,
      width: 'calc(100% / 7)',
      margin: [0, theme.gutter.sm / 2],
      [theme.breakpoints.up('md')]: {
        margin: [0, theme.gutter.md / 2]
      }
    }
  },
  menuItem: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  mobileMenu: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  logoLink: {
    textDecoration: 'none',
    color: 'currentColor',
    minHeight: 50,
    display: 'inline-flex',
    alignItems: 'center'
  },
  logo: {
    width: 105,
    height: 26
  },
  link: {
    cursor: 'pointer',
    color: 'currentColor',
    padding: 0,
    border: 'none',
    background: 'transparent',
    borderRadius: 0,
    textTransform: 'uppercase',
    fontSize: 10,
    lineHeight: 1.2,
    fontFamily: theme.fonts.body,
    letterSpacing: 0.5,
    textDecoration: 'none',
    minHeight: 42,
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    '&:after': {
      position: 'absolute',
      content: '""',
      top: '50%',
      left: 0,
      right: 0,
      transform: 'translate(-110%, 1.2em)',
      transformOrigin: '0% 50%',
      backgroundColor: 'currentColor',
      display: 'block',
      height: 1,
      transition: 'transform 0.15s ease-in-out'
    },
    '&:hover:after, &.active:after': {
      transform: 'translate(0%, 1.2em)'
    }
  },
  cartLink: {
    composes: ['$link'],
    '&:after': {
      right: 22
    }
  },
  cartCount: {
    [theme.breakpoints.up('md')]: {
      width: 20,
      height: 20
    }
  }
})
