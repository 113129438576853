import cn from "classnames";
import compact from "lodash/compact";
import findLast from "lodash/findLast";
import first from "lodash/first";
import React, { useCallback, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import useWindowResize from "../../hooks/useWindowResize";
import {
  getPageAnchorLinks,
  hasPageAnchorLinks,
} from "../../state/slices/data/page";
import theme from "../../style/theme";
import DropDown, { DropDownItem } from "../Dropdown";
import CaretIcon from "../Slices/Listing/caret.inline.svg";
import {
  getY,
  scrollToElement,
  useScrollListener,
} from "../useSmoothScrollbar";
import ToolbarItem from "./ToolbarItem";

export const sanitizeAnchorId = (anchorId) => {
  return anchorId ? anchorId.replace(/\s/g, "-") : anchorId;
};

const AnchorLink = ({ anchorId, title, onClick }) => {
  const sanitizedAnchorId = sanitizeAnchorId(anchorId);
  const handleClick = useCallback(
    (e) => {
      const element = document.querySelector(`#${sanitizedAnchorId}`);
      if (element) {
        scrollToElement(element);
      }
      if (onClick) onClick();
      e.preventDefault();
    },
    [sanitizedAnchorId, onClick]
  );
  return (
    <DropDownItem
      tag="a"
      href={`#${sanitizedAnchorId}`}
      onClick={handleClick}
      title={title}
    />
  );
};

const AnchorLinks = ({ className, title = "" }) => {
  const classes = useStyles();
  const anchorLinks = useSelector(getPageAnchorLinks);
  const hasLinks = useSelector(hasPageAnchorLinks);
  const [open, setOpen] = useState();
  const localsRef = useRef({ elements: [], currentAnchorId: null });
  const [currentTitle, setCurrentTitle] = useState("-");

  const containerRef = useOnClickOutside(
    useCallback(() => {
      setOpen(false);
    }, [])
  );

  const onToggleDropDown = useCallback(() => {
    setOpen((value) => !value);
  }, []);

  const onAnchorClick = useCallback(() => {
    setOpen(false);
  }, []);

  const calculateOffsets = useCallback(() => {
    localsRef.current.elements = compact(
      anchorLinks.map(({ anchorId, title }) => {
        const sanitizedAnchorId = sanitizeAnchorId(anchorId);
        const element = document.querySelector(`#${sanitizedAnchorId}`);
        if (element) {
          return {
            anchorId: sanitizedAnchorId,
            title,
            offset: element.offsetTop - window.innerHeight / 2,
          };
        }
        return null;
      })
    );
  }, [anchorLinks]);

  const updateSelectedAnchor = useCallback(({ y }) => {
    const element =
      findLast(localsRef.current.elements, (element) => element.offset <= y) ||
      first(localsRef.current.elements);
    if (element && localsRef.current.currentAnchorId !== element.anchorId) {
      localsRef.current.currentAnchorId = element.anchorId;
      setCurrentTitle(element.title);
    }
  }, []);

  useWindowResize(
    useCallback(() => {
      calculateOffsets();
      updateSelectedAnchor({ y: getY() });
    }, [calculateOffsets, updateSelectedAnchor]),
    true
  );

  useScrollListener(updateSelectedAnchor);

  if (!hasLinks) return null;
  return (
    <ToolbarItem
      ref={containerRef}
      button
      className={classes.container}
      containerClassName={classes.toolbarItem}
      caption="Jump To"
      title={currentTitle}
      onClick={onToggleDropDown}
      icon={<CaretIcon className={cn(classes.caret, { open })} />}
    >
      <DropDown
        key={title}
        className={classes.dropdown}
        open={open}
        position="top"
      >
        {anchorLinks.map(({ anchorId, title }) => (
          <AnchorLink
            key={anchorId}
            anchorId={anchorId}
            title={title}
            onClick={onAnchorClick}
          />
        ))}
      </DropDown>
    </ToolbarItem>
  );
};

const useStyles = createUseStyles({
  toolbarItem: {
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
      flexGrow: 0,
    },
  },
  container: {
    width: "100%",
    textAlign: "left",
    [theme.breakpoints.up("md")]: {
      minWidth: 400,
    },
  },
  dropdown: {
    position: "absolute",
    top: 0,
    left: -1,
    right: -1,
  },
  caret: {
    marginLeft: [theme.spacing(5), "!important"],
    transition: "transform 0.15s ease-in-out",
    "&.open": {
      transform: "rotate(180deg)",
    },
  },
});

export default AnchorLinks;
