import React, { useCallback } from 'react'
import { useGlobalContext } from '../SiteContext'
import Count from '../Count'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { closeCart, getCartItemsCount, isCartOpen, openCart } from '../../state/slices/ui/cart'

export default function CartButton ({ className, classNames = {} }) {
  const classes = useStyles()
  const cartOpen = useSelector(isCartOpen)
  const { cart: { link_title: cartLinkTitle } } = useGlobalContext()
  const dispatch = useDispatch()
  const onClick = useCallback(() => {
    dispatch(cartOpen ? closeCart() : openCart())
  }, [dispatch, cartOpen])
  const count = useSelector(getCartItemsCount)
  return (
    <button className={className} onClick={onClick}>
      <span>{cartLinkTitle}</span>
      <Count className={cn(classes.count, classNames.cartCount)} count={count} />
    </button>
  )
}

const useStyles = createUseStyles({
  count: {
    marginLeft: theme.spacing(1)
  }
})
