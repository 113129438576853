import React, { forwardRef, useCallback, useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import cn from 'classnames'
import gsap from 'gsap'
import useComposeRefs from '../../helpers/composeRefs'
import CloseIcon from './close.inline.svg'
import Count from '../Count'
import Link from '../Link'
import BackIcon from './back.inline.svg'
import { useDispatch, useSelector } from 'react-redux'
import { isCurrentBreakpointMobile } from '../../state/slices/layout'
import CustomEase from 'gsap/CustomEase'
import { closeDrawer } from '../../state/slices/drawer'

gsap.registerPlugin(CustomEase)

const Panel = forwardRef(({
  index, title, titleCount, titleLink,
  children, open, showCloseButton, fillCloseButton,
  showHeader = true, fill = false,
  breadcrumbLabel, breadcrumbOnClick,
  flex
}, ref) => {
  const classes = useStyles()
  const containerRef = useRef()
  const isMobile = useSelector(isCurrentBreakpointMobile)
  const locals = useRef({ isMobile, open })
  const dispatch = useDispatch()

  const opened = useRef(false)

  useEffect(() => {
    locals.current.isMobile = isMobile
  }, [isMobile])

  const onCloseDrawer = useCallback(() => {
    dispatch(closeDrawer())
  }, [dispatch])

  const timelineRef = useRef()

  useEffect(() => {
    locals.current.open = open
    if (containerRef.current) {
      const tl = gsap.timeline()
      tl.set(containerRef.current, { display: flex ? 'flex' : 'block' })
      opened.current = opened.current || open
      if (open) {
        tl.set(containerRef.current, { opacity: 1, pointerEvents: 'all', x: isMobile ? '110%' : index === 1 ? '-200%' : '-110%' })
        tl.to(
          containerRef.current,
          {
            x: '0%',
            duration: index === 1 && !isMobile ? 1.3 : 1,
            ease: CustomEase.create('drawer', '.52, .175, .45, 1')
          }
        )
      } else {
        tl.set(containerRef.current, { pointerEvents: 'none' })
        tl.to(containerRef.current, {
          opacity: 0,
          duration: 0.5,
          ease: 'sine.inOut',
          onComplete: () => {
            gsap.set(containerRef.current, { x: '-110%' })
          }
        })
      }
      timelineRef.current = tl
      return () => {
        // Lets kill it every time so that we do not get any problems
        tl.kill()
        // if (!open) { tl.kill() }
      }
    }
  }, [open, index, flex, isMobile])

  const composedRefs = useComposeRefs(containerRef, ref)

  if (!open && !opened.current) return null

  return (
    <div className={cn(classes.panel, `panel${index}`, { padding: !fill, flex })} ref={composedRefs}>
      {showHeader && (
        <header className={classes.header}>
          <div
            onClick={breadcrumbOnClick}
            role='button'
            tabIndex={-1}
            onKeyDown={null}
            className={cn(classes.breadcrumb, { show: breadcrumbLabel })}
          >
            <BackIcon className={classes.backIcon} /><span>{breadcrumbLabel}</span>
          </div>
          <div className={classes.title}>
            {titleLink ? <Link to={titleLink} className={classes.titleLink}>{title}</Link> : title}
            {titleCount && <Count className={classes.count} pad count={titleCount} />}
          </div>
        </header>
      )}
      <button
        className={cn(classes.closeButton, { show: showCloseButton, fill: fillCloseButton })}
        onClick={onCloseDrawer}
        aria-label='Close Panels'
      >
        <CloseIcon />
      </button>
      {children}
    </div>
  )
})

export default Panel

const useStyles = createUseStyles({
  panel: {
    position: 'relative',
    display: 'none',
    backgroundColor: theme.colors.background,
    overflow: 'auto',
    transform: 'translate(110%, 0)',
    height: '100vh',
    [theme.breakpoints.up('md')]: {
      transform: 'translate(-110%, 0)'
    },
    '&.padding': {
      padding: [theme.spacing(4), theme.gutter.sm, theme.gutter.sm],
      [theme.breakpoints.up('md')]: {
        padding: [theme.spacing(6), theme.gutter.md, theme.gutter.md]
      }
    },
    '&.flex': {
      display: 'flex',
      flexDirection: 'column'
    },
    ...theme.scrollbar
  },
  header: {},
  breadcrumb: {
    marginBottom: theme.spacing(6),
    minHeight: 42,
    fontSize: 12,
    alignItems: 'center',
    textTransform: 'uppercase',
    lineHeight: 1.4,
    visibility: 'hidden',
    display: 'flex',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      '&.show': {
        visibility: 'visible'
      }
    }
  },
  title: {
    fontSize: 20,
    lineHeight: 1.4,
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(10)
    }
  },
  titleLink: {
    textDecoration: 'none',
    transition: 'opacity 0.15s ease-in-out',
    '&:hover': {
      opacity: 0.5
    }
  },
  count: {
    marginLeft: theme.spacing(1)
  },
  closeButton: {
    position: 'absolute',
    top: 24,
    right: theme.spacing(3),
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '50%',
    padding: 0,
    margin: 0,
    height: 26,
    width: 26,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    opacity: 0,
    transition: 'opacity 0.5s ease-in-out',
    pointerEvents: 'none',
    zIndex: 1,
    [theme.breakpoints.up('md')]: {
      top: 24,
      right: 24,
      display: 'flex'
    },
    '&:hover, &.fill': {
      backgroundColor: '#F2F2F2'
    },
    '&.show': {
      pointerEvents: 'all',
      opacity: 1
    }
  },
  backIcon: {
    width: 24,
    height: 24,
    marginRight: theme.spacing(1)
  }
})
