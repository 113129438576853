import React, { useEffect, useRef } from 'react'
import RichText from '../RichText'
import Section from '../Section'
import animationData from '../Animation/lottie/curve.json'
import useLottieAnimation from '../../hooks/useLottieAnimation'
import { createUseStyles } from 'react-jss'
import { useInView } from 'react-intersection-observer'
import { Caption } from '../Typography'
import theme from '../../style/theme'
import gsap from 'gsap'
import Button from '../Button'
import { useGlobalContext } from '../SiteContext'

const aspectRatio = animationData.w / animationData.h

export default function ChartSlice ({ slice, id }) {
  const { requestEmail, requestPhone } = useGlobalContext()
  const classes = useStyles()
  const { title, _rawCopy } = slice
  const labelsRef = useRef()
  const [chartRef, timeline] = useLottieAnimation(animationData)
  const [inViewRef, inView] = useInView({ threshold: 0.8, triggerOnce: true })
  useEffect(() => {
    if (timeline) {
      if (inView) {
        timeline.resume()
        gsap.to(labelsRef.current.children, { opacity: 1, duration: 1, delay: 0.55, ease: 'sine.inOut', stagger: 0.10 })
      } else {
        timeline.pause()
      }
    }
  }, [inView, timeline])

  return (
    <Section fullWidth id={id}>
      <Section grid tag='div'>
        <h2 className={classes.title}>{title}</h2>
        <div className={classes.copy}>
          {_rawCopy && <RichText blocks={_rawCopy.text} />}
          <Button to={`tel:${requestPhone}`}>Call <span className={classes.actionLabel}>{requestPhone}</span></Button>
          <Button to={`mailto:${requestEmail}`}>Email <span className={classes.actionLabel}>{requestEmail}</span></Button>
        </div>
      </Section>
      <Section className={classes.chartContainer} ref={inViewRef} fullWidth>
        <div className={classes.labels} ref={labelsRef}>
          <Caption className={classes.chartTitle}>DECORLUX®<br />NRC 0.60 {'->'} 0.75</Caption>
          <Caption className={classes.chartLabel1}>A_01</Caption>
          <Caption className={classes.chartLabel2}>
            <span>D_02</span>
            <span className={classes.soundProfileLabel}>NRC<br />0.60</span>
          </Caption>
          <Caption className={classes.chartLabel3}>
            <span>S_03</span>
            <span className={classes.soundProfileLabel}>NRC<br />0.75</span>
          </Caption>
          <Caption className={classes.chartLabel4}>R_04</Caption>
        </div>
        <div className={classes.chart} ref={chartRef} />
      </Section>
    </Section>
  )
}

const useStyles = createUseStyles({
  chartContainer: {
    position: 'relative',
    display: 'block',
    paddingTop: `${100 / aspectRatio}%`
  },
  title: {
    gridColumn: '1 / span 4',
    maxWidth: 540,
    [theme.breakpoints.up('md')]: {
      gridColumn: '1 / span 4'
    }
  },
  copy: {
    gridColumn: '1 / span 4',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    [theme.breakpoints.up('md')]: {
      maxWidth: 300,
      gridColumn: 'span 3'
    },
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(3)
    }
  },
  actionLabel: {
    opacity: 0.5,
    marginLeft: theme.spacing(1)
  },
  chart: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    '& svg > g > g > path': {
      fill: theme.colors.text
    },
    '& svg > g > g:nth-child(6) > g > path, & svg > g > g:nth-child(7) > g > path, & svg > g > g:nth-child(8) > g > path, & svg > g > g:nth-child(9) > g > path': {
      fill: theme.colors.text
    }
  },
  labels: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: '8%',
    '& > *': {
      opacity: 0
    }
  },
  chartTitle: {
    position: 'absolute',
    display: 'none',
    left: theme.gutter.sm,
    [theme.breakpoints.up('md')]: {
      display: 'block',
      left: theme.gutter.md
    }
  },
  label: {
    position: 'absolute',
    display: 'flex'
  },
  soundProfileLabel: {
    display: 'none',
    marginLeft: theme.spacing(3),
    opacity: 0.5,
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  chartLabel1: {
    composes: ['$label'],
    left: '17%'
  },
  chartLabel2: {
    composes: ['$label'],
    left: '31%'
  },
  chartLabel3: {
    composes: ['$label'],
    left: '59%'
  },
  chartLabel4: {
    composes: ['$label'],
    left: '87%'
  }
})
