import React, { useRef, useEffect, useCallback } from "react";
import ProjectsPanel from "./ProjectsPanel";
import ProductsPanel from "./ProductsPanel";
import ResourcesPanel from "./ResourcesPanel";
import TopMenuPanel from "./TopMenuPanel";
import { createUseStyles } from "react-jss";
import theme from "../../style/theme";
import Section from "../Section";
import { useDispatch, useSelector } from "react-redux";
import { closeDrawer, isDrawerOpen } from "../../state/slices/drawer";
import gsap from "gsap";
import AboutPanel from "./AboutPanel";

export default function Drawer() {
  const classes = useStyles();
  const open = useSelector(isDrawerOpen);
  const overlayRef = useRef();
  const containerRef = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    const timeline = gsap.timeline();
    if (overlayRef.current) {
      timeline.set(containerRef.current, {
        pointerEvents: open ? "all" : "none",
      });
      timeline.to(overlayRef.current, {
        opacity: open ? 0.6 : 0,
        duration: open ? 1 : 1.5,
        ease: "expo.out",
      });
    }
    return () => {
      timeline.kill();
    };
  }, [open]);

  const onCloseDrawer = useCallback(() => {
    dispatch(closeDrawer());
  }, [dispatch]);

  return (
    <Section
      grid
      className={classes.container}
      noBottomMargin
      ref={containerRef}
      fullWidth
    >
      <div
        className={classes.overlay}
        ref={overlayRef}
        onClick={onCloseDrawer}
        onKeyDown={null}
        role="button"
        aria-label="Close Dialog"
        tabIndex={-1}
      />
      <TopMenuPanel open={open} />
      <ProjectsPanel />
      <ProductsPanel />
      <ResourcesPanel />
      <AboutPanel />
    </Section>
  );
}

const useStyles = createUseStyles({
  container: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.drawer,
    pointerEvents: "none",
    overflow: "hidden",
    padding: 0,
    [theme.breakpoints.up("md")]: {
      padding: [0, theme.gutter.md],
    },
    "& > .panel1": {
      gridColumn: "1 / span 4",
      gridRow: 1,
      [theme.breakpoints.up("md")]: {
        borderRight: [1, "solid", theme.colors.border],
        marginLeft: -theme.gutter.md,
        gridColumn: "2 / span 2",
        zIndex: 3,
      },
    },
    "& > .panel2": {
      gridColumn: "1 / span 4",
      gridRow: 1,
      [theme.breakpoints.up("md")]: {
        borderRight: [1, "solid", theme.colors.border],
        marginLeft: -theme.gutter.md,
        gridColumn: "4 / span 2",
        zIndex: 2,
      },
    },
    "& > .panel3": {
      gridColumn: "1 / span 4",
      gridRow: 1,
      marginRight: theme.gutter.sm,
      [theme.breakpoints.up("md")]: {
        borderRight: [1, "solid", theme.colors.border],
        marginLeft: -theme.gutter.md,
        marginRight: -theme.gutter.md - 1, // hide the border on the right, but show it for the animations
        gridColumn: "6 / span 2",
        zIndex: 1,
      },
    },
  },
  overlay: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    backgroundColor: theme.colors.black,
    opacity: 0,
  },
});
