import { primaryInput } from "detect-it";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resolveInternalLinkUrl } from "../../helpers/resolveLink";
import {
  closeCategoryPanel,
  closeDetailsPanel,
  closePanel,
  getSelectedCategory,
  getSelectedItem,
  isCategoryPanelOpen,
  isDetailsPanelOpen,
  isPanelOpen,
  openCategoryPanel,
  openDetailsPanel,
} from "../../state/slices/drawer";
import { isCurrentBreakpointMobile } from "../../state/slices/layout";
import { openFireGroupCalculatorDrawer } from "../../state/slices/ui/fireGroupCalculatorDrawer";
import { FIRE_GROUP_RATING_CALCULATOR_SLUG } from "../FireGroupCalculatorDrawer";
import LinkList from "../LinkList";
import { useGlobalContext } from "../SiteContext";
import ArticlesList from "./ArticlesList";
import { useCategoryList } from "./ConnectedPanels";
import DocumentsByProducts from "./DocumentsByProducts";
import DocumentsList from "./DocumentsList";
import ImageDetailsPanel from "./ImageDetailsPanel";
import Panel from "./Panel";

const SLUG = "resources";

export default function ResourcesPanel() {
  const open = useSelector(isPanelOpen(SLUG));
  const category = useSelector(getSelectedCategory(SLUG));
  const item = useSelector(getSelectedItem(SLUG));
  const categoryPanelOpen = useSelector(isCategoryPanelOpen(SLUG));
  const detailsPanelOpen = useSelector(isDetailsPanelOpen(SLUG));
  const globals = useGlobalContext();

  const { counts, resourceLink, pageCategories, resourcesNav } = globals;

  const articleCounts = useMemo(
    () => ({
      byCategory: counts.articlesByCategory,
      total: counts.articles,
    }),
    [counts.articlesByCategory, counts.articles]
  );
  const url = resolveInternalLinkUrl(resourceLink.reference);
  const isMobile = useSelector(isCurrentBreakpointMobile);
  const dispatch = useDispatch();
  const onClose = useCallback(() => {
    dispatch(closePanel({ slug: SLUG }));
  }, [dispatch]);

  const onCloseCategoryPanel = useCallback(() => {
    dispatch(closeCategoryPanel({ slug: SLUG }));
  }, [dispatch]);

  const onCloseDetailsPanel = useCallback(() => {
    dispatch(closeDetailsPanel({ slug: SLUG }));
  }, [dispatch]);

  const firstLevelItems = useMemo(() => {
    const items = [
      {
        to: url,
        count: counts.articles,
        text: "Articles",
        title: "All Articles",
        slug: "articles",
      },
      {
        count: counts.documents,
        text: "Product Resources",
        title: "All Product Resources",
        slug: "product-resources",
        to: "/product-resources",
      },
    ];

    if (counts.documentsByCategory.fire?.totalCount) {
      items.push({
        count: counts.documentsByCategory.fire?.totalCount + 1, // Add 1 resource for the fire group calculator
        text: "Fire Resources",
        title: "All Fire Resources",
        slug: "fire-resources",
        to: "/fire-resources",
      });
    }
    if (counts.documentsByCategory.acoustic?.totalCount) {
      items.push({
        count: counts.documentsByCategory.acoustic?.totalCount,
        text: "Acoustic Resources",
        title: "All Acoustic Resources",
        slug: "acoustic-resources",
        to: "/acoustic-resources",
      });
    }
    if (counts.documentsByCategory.green?.totalCount) {
      items.push({
        count: counts.documentsByCategory.green?.totalCount,
        text: "ESG Resources",
        title: "All ESG Resources",
        slug: "esg-resources",
        to: "/esg-resources",
      });
    }
    if (counts.documentsByCategory.brochures?.totalCount) {
      items.push({
        count: counts.documentsByCategory.brochures?.totalCount,
        text: "Brochures",
        title: "All Brochures",
        slug: "brochures",
        to: "/brochures",
      });
    }
    if (counts.documentsByCategory.careAndMaintenance?.totalCount) {
      items.push({
        count: counts.documentsByCategory.careAndMaintenance?.totalCount,
        text: "Care & Maintenance Resources",
        title: "All Care & Maintenance Resources",
        slug: "care-and-maintenance",
        to: "/care-and-maintenance",
      });
    }
    if (resourcesNav?.resourcesMenu?.length) {
      const { resourcesMenu } = resourcesNav;
      resourcesMenu.forEach((item) => {
        const { reference, text, showPanel = false } = item;
        const url = resolveInternalLinkUrl(reference);
        const newItem = {
          count: null,
          text: text || reference.title,
          title: reference.title,
          to: url,
          arrow: true,
          showPanel: showPanel,
          id: reference.id,
        };
        if (item.order) {
          items.splice(item.order - 1, 0, newItem);
          return;
        }
        items.push(newItem);
      });
    }
    return items;
  }, [
    url,
    counts.articles,
    counts.documents,
    counts.documentsByCategory,
    resourcesNav,
  ]);

  const onSelectFirstLevelItem = useCallback(
    (item) => {
      if (item !== category) {
        dispatch(openCategoryPanel({ slug: SLUG, category: item }));
      }
    },
    [dispatch, category]
  );

  const onSelectSecondLevelItem = useCallback(
    (value) => {
      if (item !== value) {
        dispatch(openDetailsPanel({ slug: SLUG, item: value }));
      }
    },
    [dispatch, item]
  );

  const openFireRatingCalculator = useCallback(() => {
    dispatch(closePanel({ slug: SLUG }));
    dispatch(openFireGroupCalculatorDrawer());
  }, [dispatch]);

  const articleCategoryItems = useCategoryList(
    pageCategories,
    articleCounts,
    url
  );
  const articlePageLink =
    category?.slug === "articles" && item
      ? `${url}?category=${item.category?.slug.current}`
      : null;

  const thirdPanelTitle =
    category?.slug === "articles"
      ? `All ${item?.category?.title}`
      : `${item?.product?.title}`;
  return (
    <>
      <Panel
        title="See All"
        titleCount={counts.resources}
        titleLink={url}
        open={open}
        breadcrumbLabel="Menu"
        breadcrumbOnClick={onClose}
        showCloseButton={open && !categoryPanelOpen}
        index={1}
      >
        <LinkList
          items={firstLevelItems}
          onMouseEnter={
            primaryInput === "touch" || isMobile ? null : onSelectFirstLevelItem
          }
          selectedItem={category}
          size="medium"
          animateIn={open}
          delay={0.75}
        />
      </Panel>

      <Panel
        title={category?.title}
        titleCount={category?.count}
        titleLink={category?.to}
        open={
          Boolean(category?.showPanel === false) ? false : categoryPanelOpen
        }
        showCloseButton={categoryPanelOpen && !detailsPanelOpen}
        onClose={onClose}
        breadcrumbLabel="Resources"
        breadcrumbOnClick={onCloseCategoryPanel}
        index={2}
      >
        {category?.slug === "articles" && (
          <LinkList
            items={articleCategoryItems}
            onMouseEnter={
              primaryInput === "touch" || isMobile
                ? null
                : onSelectSecondLevelItem
            }
            selectedItem={item}
            size="medium"
          />
        )}
        {category?.slug === "product-resources" && <DocumentsByProducts />}
        {category?.slug === "fire-resources" &&
          counts.documentsByCategory.fire && (
            <>
              <LinkList
                hideDivider
                items={[
                  {
                    text: "Group Fire Rating Calculator",
                    slug: FIRE_GROUP_RATING_CALCULATOR_SLUG,
                  },
                ]}
                onClick={openFireRatingCalculator}
                size="medium"
              />
              <DocumentsList category="fire" />
            </>
          )}
        {category?.slug === "acoustic-resources" &&
          counts.documentsByCategory.acoustic && (
            <DocumentsList category="acoustic" />
          )}
        {category?.slug === "esg-resources" &&
          counts.documentsByCategory.green && (
            <DocumentsList category="green" />
          )}
        {category?.slug === "brochures" &&
          counts.documentsByCategory.brochures && (
            <DocumentsList category="brochures" />
          )}
        {category?.slug === "care-and-maintenance" &&
          counts.documentsByCategory.careAndMaintenance && (
            <DocumentsList category="care-and-maintenance" />
          )}
        {category?.id &&
          resourcesNav?.resourcesMenu?.length &&
          resourcesNav.resourcesMenu.map(({ reference }) =>
            category.id === reference.id ? (
              <ImageDetailsPanel
                page={reference}
                open={detailsPanelOpen}
                key={reference.id}
              />
            ) : null
          )}
      </Panel>
      <Panel
        title={thirdPanelTitle}
        titleCount={item?.count}
        titleLink={articlePageLink}
        open={detailsPanelOpen}
        showCloseButton
        breadcrumbLabel="Documents By Product"
        breadcrumbOnClick={onCloseDetailsPanel}
        index={3}
        flex
      >
        {category?.slug === "articles" && item && (
          <ArticlesList category={item.category} />
        )}
        {category?.slug === "product-resources" && item && (
          <DocumentsList product={item.product} />
        )}
      </Panel>
    </>
  );
}
