
import { createSelector, createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { route } from '../route'
import { openPanel } from '../drawer'
import { getProducts } from '../data/products'
import map from 'lodash/map'
import { closeMenu } from '../layout'
import { keys } from 'lodash'

export const submitCartRequest = createAsyncThunk(
  'cart/submit',
  async (payload, thunkAPI) => {
    const cartItems = getCartProducts(thunkAPI.getState())
    const promise = new Promise((resolve, reject) => {
      setTimeout(() => {
        console.log('Carts items submitted', cartItems)
        resolve(true)
      }, 2000)
    })
    return promise
  }
)

const _resetCart = (state) => {
  state.items = {}
  state.loading = false
  state.submitted = false
  state.error = null
  state.success = false
  state.showForm = false
}

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: {},
    email: '',
    loading: false,
    submitted: false,
    error: null,
    open: false,
    success: false,
    showForm: false
  },
  reducers: {
    openCart: (state) => {
      state.open = true
    },
    closeCart: (state) => {
      state.open = false
      if (state.success) {
        _resetCart(state)
      }
    },
    addItem: (state, { payload: { id } }) => {
      // We only have one item in the cart for each product, this is just a request for a sample
      state.items[id] = 1
      state.open = true
      state.success = false
      state.showForm = false
    },
    removeItem: (state, { payload: { id, quantity } }) => {
      delete state.items[id]
      state.success = false
      state.showForm = false
    },
    clearItems: (state) => {
      state.items = {}
      state.success = false
      state.showForm = false
    },
    loadCartFromStorage: (state, { payload }) => {
      state.items = payload
    },
    resetCart: (state) => {
      _resetCart(state)
    },
    setCartSuccess: (state) => {
      state.success = true
      state.showForm = false
    },
    showCartForm: (state) => {
      state.showForm = true
    },
    hideCartForm: (state) => {
      state.showForm = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(route, (state, action) => {
      state.open = false
    })
    builder.addCase(openPanel, (state, action) => {
      state.open = false
    })
    builder.addCase(closeMenu, (state, action) => {
      state.open = false
    })
    builder.addCase(submitCartRequest.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(submitCartRequest.fulfilled, (state, { payload }) => {
      state.loading = false
      state.submitted = true
      state.error = null
    })
    builder.addCase(submitCartRequest.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  }
})

export const {
  openCart,
  closeCart,
  addItem,
  removeItem,
  clearItems,
  loadCartFromStorage,
  setCartSuccess,
  resetCart,
  showCartForm,
  hideCartForm
} = cartSlice.actions

export const isCartOpen = state => state.ui.cart.open
export const isCartLoading = state => state.ui.cart.loading
export const getCartError = state => state.ui.cart.error
export const hasCartBeenSubmitted = state => state.ui.cart.submitted
export const getCartItems = state => state.ui.cart.items
export const isCartSuccess = state => state.ui.cart.success
export const isCartFormShown = state => state.ui.cart.showForm

export const getCartProducts = createSelector(
  getProducts,
  getCartItems,
  (products, items) => {
    return map(items, (quantity, id) => {
      return {
        id,
        quantity: quantity,
        product: products.find(x => x.id === id)
      }
    })
  }
)

export const getCartItemsCount = createSelector(
  getCartItems,
  (items) => {
    return keys(items).length
  }
)

export default cartSlice.reducer
