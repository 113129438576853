import React, {
  useCallback,
  useRef,
  useEffect
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import gsap from 'gsap'
import CustomEase from 'gsap/CustomEase'
import {
  closeQuoteDrawer,
  isQuoteDrawerOpen,
  isQuoteFormSuccess
} from '../../state/slices/ui/quoteForm'
import theme from '../../style/theme'
import Button from '../Button'
import SalesforceForm from './SalesforceForm'
import CloseIcon from '../Drawer/close.inline.svg'
import { useGlobalContext } from '../SiteContext'


export default function RequestAQuote () {
  const classes = useStyles()
  const containerRef = useRef()
  const drawerRef = useRef()
  const dispatch = useDispatch()
  const open = useSelector(isQuoteDrawerOpen)
  const overlayRef = useRef()
  const formContentRef = useRef()
  const submitButtonRef = useRef()

  const success = useSelector(isQuoteFormSuccess)

  const { quoteForm } = useGlobalContext()
  const {
    quoteFormTitle,
    quoteProcessCopy,
    quoteSubmissionCopy,
    quoteSubmissionSuccessTitle,
    quoteSubmissionSuccessSubtitle
  } = quoteForm

  useEffect(() => {
    if (containerRef.current) {
      gsap.set(containerRef.current, { pointerEvents: open ? 'all' : 'none' })
      gsap.to(overlayRef.current, { opacity: open ? 0.6 : 0, duration: open ? 1 : 1.5, ease: 'expo.out' })
      gsap.to(drawerRef.current, { x: open ? '0%' : '100%', duration: 1, ease: CustomEase.create('drawer', '.32,0,.15,1') })
    }
  }, [open])

  useEffect(() => {
    if (formContentRef.current) {
      gsap.to(formContentRef.current, {
        autoAlpha: open ? 1 : 0,
        duration: open ? 1 : 0.5,
        delay: open ? 0.5 : 0,
        ease: 'expo.out'
      })
    }
  }, [open])

  const onClose = useCallback(() => {
    dispatch(closeQuoteDrawer())
  }, [dispatch])

  const onContinueBrowsing = useCallback(() => {
    dispatch(closeQuoteDrawer())
  }, [dispatch])

  const onRequestQuote = useCallback(() => {
    if (submitButtonRef.current) {
      submitButtonRef.current.click()
    }
  }, [])

  return (
    <section ref={containerRef} className={classes.container}>
      <div className={classes.overlay} ref={overlayRef} onClick={onClose} role='button' aria-label='Close Form' tabIndex={-1} onKeyDown={null} />
      <div className={classes.quoteForm} ref={drawerRef}>
        <div className={classes.quoteFormContainer}>
          <header className={cn(classes.header)}>
            <div className={classes.headerTop}>
              <h2 className={classes.title}>{quoteFormTitle}</h2>
              <button onClick={onClose} className={classes.closeButton} aria-label='Close Form'><CloseIcon /></button>
            </div>
          </header>
          {success
            ? (
              <div className={classes.successMessage}>
                <p className={classes.successTitle}>{quoteSubmissionSuccessTitle}</p>
                <p className={classes.successSubtitle}>
                  {quoteSubmissionSuccessSubtitle}
                </p>
                <Button className={classes.closeFormButton} onClick={onContinueBrowsing}>
                  Continue Browsing
                </Button>
              </div>
              )
            : (
              <>
                <div className={classes.content}>
                  {quoteProcessCopy && <p className={classes.quoteProcessCopy}>{quoteProcessCopy}</p>}
                  <div className={cn(classes.innerContent, classes.formContent)} ref={formContentRef}>
                    <SalesforceForm ref={submitButtonRef} />
                  </div>
                </div>
                <footer className={classes.footer}>
                  <p className={classes.quoteSubmissionCopy}>{quoteSubmissionCopy || ''}</p>
                  <Button className={classes.submitButton} onClick={onRequestQuote}>
                    Request A Quote
                  </Button>
                </footer>
              </>
              )}
        </div>
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  container: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: theme.zIndex.drawer,
    pointerEvents: 'none',
    overflow: 'hidden'
  },
  overlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    backgroundColor: theme.colors.black,
    opacity: 0
  },
  quoteForm: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
    width: '100%',
    backgroundColor: theme.colors.background,
    transform: 'translate(100%, 0)',
    [theme.breakpoints.up('md')]: {
      height: 'auto',
      bottom: 0,
      width: 480
    }
  },
  quoteFormContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  header: {
    flexGrow: 0,
    flexShrink: 0,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: [theme.spacing(5), theme.gutter.sm, 0],
    [theme.breakpoints.up('md')]: {
      margin: [theme.gutter.md, theme.gutter.md, 0]
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: 1,
      backgroundColor: theme.colors.border,
      opacity: 0.3
    }
  },
  headerTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(5)
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 1,
    overflow: 'auto',
    position: 'relative',
    '&.empty:after': {
      display: 'none'
    },
    padding: [0, theme.gutter.sm],
    [theme.breakpoints.up('md')]: {
      padding: [0, theme.gutter.md]
    }
  },
  quoteProcessCopy: {
    padding: [theme.spacing(8), 0],
    margin: 0,
    fontSize: 15,
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: 1,
      backgroundColor: theme.colors.border,
      opacity: 0.3
    }
  },
  quoteSubmissionCopy: {
    composes: ['$copy'],
    margin: [theme.spacing(3), 'auto', 0],
    fontSize: 10,
    maxWidth: 260
  },
  formContent: {
    opacity: 0,
    flexGrow: 2
  },
  footer: {
    flexGrow: 0,
    flexShrink: 0,
    padding: [0, theme.gutter.sm, theme.gutter.sm],
    [theme.breakpoints.up('md')]: {
      padding: [0, theme.gutter.md, theme.gutter.md]
    }
  },
  title: {
    fontSize: 20,
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
    fontFamily: theme.fonts.body
  },
  count: {
    marginLeft: theme.spacing(1)
  },
  copy: {
    whiteSpace: 'pre-wrap',
    fontSize: 10,
    textAlign: 'center',
    marginBottom: theme.spacing(3)
  },
  submitButton: {
    width: '100%',
    padding: [16, 32]
  },
  closeButton: {
    padding: 0,
    margin: 0,
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: 0,
    cursor: 'pointer'
  },
  successMessage: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    width: '100%',
    maxWidth: 340,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  successTitle: {
    fontSize: 15,
    margin: [0, 0, 20]
  },
  successSubtitle: {
    fontSize: 12,
    opacity: 0.5,
    margin: [0, 0, 20]
  }
})
