import {
  combineReducers,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import compact from "lodash/compact";
import flatten from "lodash/flatten";
import get from "lodash/get";
import map from "lodash/map";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import { createCachedSelector } from "re-reselect";
import { fetchRequest } from "../../fetchRequest";

export const fetchProducts = createAsyncThunk(
  "data/products/get",
  async (thunkAPI) => {
    const url = "/page-data/data/products/page-data.json";
    return fetchRequest(url);
  }
);

export const fetchArticles = createAsyncThunk(
  "data/articles/get",
  async (thunkAPI) => {
    const url = "/page-data/data/articles/page-data.json";
    return fetchRequest(url);
  }
);

export const fetchProjects = createAsyncThunk(
  "data/projects/get",
  async (thunkAPI) => {
    const url = "/page-data/data/projects/page-data.json";
    return fetchRequest(url);
  }
);

export const fetchDocuments = createAsyncThunk(
  "data/documents/get",
  async (thunkAPI) => {
    const url = "/page-data/data/documents/page-data.json";
    return fetchRequest(url);
  }
);

export const fetchAbout = createAsyncThunk(
  "data/about/get",
  async (thunkAPI) => {
    const url = "/page-data/data/about/page-data.json";
    return fetchRequest(url);
  }
);

export const fetches = {
  articles: fetchArticles,
  products: fetchProducts,
  projects: fetchProjects,
};

const createOptions = (items, prop) => {
  return map(
    compact(uniq(flatten(map(items, (item) => get(item, prop))))),
    (x) => ({ text: x, value: x })
  );
};

export const createListSlice = (
  type,
  fetchAction,
  getItems,
  getOptions,
  defaultOptions = {}
) => {
  const slice = createSlice({
    name: type,
    initialState: {
      loading: false,
      items: [],
      loaded: false,
      options: defaultOptions,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(fetchAction.pending, (state, action) => {
        state.loading = true;
      });
      builder.addCase(fetchAction.fulfilled, (state, { payload }) => {
        const items = getItems(payload);
        state.loading = false;
        state.loaded = true;
        state.items = items;
        state.error = null;

        state.options = getOptions(items);
      });
      builder.addCase(fetchAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    },
  });

  return slice;
};

const createTypeSelector = (prop) => {
  return createCachedSelector(
    (type) => type,
    (type) =>
      createSelector(
        (state) => state.data.lists,
        (lists) => get(lists[type], prop)
      )
  )((id) => id);
};

export const getItemsSelector = createTypeSelector("items");
export const isLoadedSelector = createTypeSelector("loaded");
export const isLoadingSelector = createTypeSelector("loading");
export const getOptionsSelector = createTypeSelector("options");

export default combineReducers({
  articles: createListSlice(
    "articles",
    fetchArticles,
    (payload) => get(payload, ["result", "data", "allSanityPage", "nodes"]),
    (items) => {
      const filterEmptyCategory = items.filter((x) => x.category);
      const category = uniqBy(
        filterEmptyCategory.map((x) => ({
          text: x.category.title,
          value: x.category.slug.current,
        })),
        (x) => x.value
      );
      return {
        category: {
          items: category,
          title: "All Articles",
          emptyText: "All Articles",
        },
      };
    },
    { category: { items: [] } }
  ).reducer,
  projects: createListSlice(
    "projects",
    fetchProjects,
    (payload) => get(payload, ["result", "data", "allSanityProject", "nodes"]),
    (items) => {
      const categories = uniqBy(
        items.map((x) => ({
          text: x.category.title,
          value: x.category.slug.current,
        })),
        (x) => x.value
      );
      const products = flatten(map(items, (item) => item.products));

      const options = {
        category: {
          items: categories,
          title: "All Projects",
          emptyText: "All Projects",
        },
        "products.application": {
          items: createOptions(products, "application"),
          title: "Application",
        },
        "products.id": {
          items: uniqBy(
            products.map(({ title, id }) => ({ text: title, value: id })),
            (item) => item.value
          ),
          title: "Product",
        },
        location: {
          items: createOptions(items, "location"),
          title: "Location",
        },
      };
      return options;
    },
    {
      category: { items: [], title: "All Projects", emptyText: "All Projects" },
      "products.application": { items: [], title: "Application" },
      "products.id": { items: [], title: "Product" },
      location: { items: [], title: "Location" },
    }
  ).reducer,
  products: createListSlice(
    "products",
    fetchProducts,
    (payload) => get(payload, ["result", "data", "allSanityProduct", "nodes"]),
    (items) => {
      const categories = uniqBy(
        items.map((x) => ({
          text: x.category.title,
          value: x.category.slug.current,
        })),
        (x) => x.value
      );
      const applications = createOptions(items, "application");
      const suitableFor = createOptions(items, "suitableFor");
      const finishes = createOptions(items, "finish");
      const groupFireRatings = createOptions(items, "groupFireRatings");
      const substrates = uniqBy(
        flatten(map(items, (item) => item.fireSubstrateFilters)).map((x) => {
          return {
            text: x.title,
            value: x.slug.current,
          };
        }),
        (x) => x.value
      );

      return {
        category: { items: categories },
        application: { items: applications },
        suitableFor: { items: suitableFor },
        finish: { items: finishes },
        fireSubstrate: { title: "substrate", items: substrates },
        groupFireRatings: {
          title: "Group Fire Rating",
          items: groupFireRatings,
        },
      };
    },
    {
      category: { items: [] },
      application: { items: [] },
      suitableFor: { items: [] },
      finish: { items: [] },
      fireSubstrate: { title: "substrate", items: [] },
      groupFireRatings: { title: "Group Fire Rating", items: [] },
    }
  ).reducer,
  documents: createListSlice(
    "documents",
    fetchDocuments,
    (payload) =>
      get(payload, ["result", "data", "allSanityDocumentResource", "nodes"]),
    (items) => null
  ).reducer,
  about: createListSlice(
    "about",
    fetchAbout,
    (payload) => get(payload, ["result", "data", "allSanityPage", "nodes"]),
    (items) => null
  ).reducer,
});
