import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useImperativeHandle,
} from "react";

import { createUseStyles } from "react-jss";

import { useSelector } from "react-redux";
import { useInView } from "react-intersection-observer";
import composeRefs from "../helpers/composeRefs";
import isEmpty from "lodash/isEmpty";
import { CustomEase } from "gsap/CustomEase";
import useWindowResize from "../hooks/useWindowResize";
import useDebouncedCallback from "../hooks/useDebouncedCallback";
import forEach from "lodash/forEach";
import toArray from "lodash/toArray";
import sortBy from "lodash/sortBy";
import first from "lodash/first";
import last from "lodash/last";
import { isPreloading } from "../state/slices/preload";
import { gsap } from "gsap";
import { SplitText } from "../helpers/splitText";
gsap.registerPlugin(CustomEase);

function useSplitText() {
  const splitTextRef = useRef({});
  const locals = splitTextRef.current;

  const constructSplitText = useCallback(
    (el) => {
      locals.split = new SplitText(el, { type: "lines,chars" });
      if (isEmpty(locals.split.chars)) return;

      gsap.set(locals.split.lines, {
        overflow: "hidden",
        padding: "0 0 0.12em 0",
        marginBottom: "-0.12em",
        display: "block",
        whiteSpace: "nowrap",
      });
      if (!locals.animatedIn) {
        gsap.set(locals.split.chars, { yPercent: 110, opacity: 1 });
        gsap.set(el, { visibility: "visible" });
      }

      locals.el = el;
    },
    [locals]
  );

  const destructSplitText = useCallback(() => {
    if (locals.split) {
      locals.split.revert();
      delete locals.targets;
      delete locals.split;
    }
  }, [locals]);

  useWindowResize(
    useDebouncedCallback(
      () => {
        // if (!fontsReady) return
        if (locals.split) {
          destructSplitText();
        }
        if (locals.el) {
          constructSplitText(locals.el);
        }
      },
      150,
      [destructSplitText, destructSplitText]
    ),
    false
  );

  return [splitTextRef, constructSplitText, destructSplitText];
}

const createTimeline = (splitTextRef) => {
  const locals = splitTextRef.current;
  locals.animatedIn = true;
  // Find the largest line width and use that as the center point, this is so
  // all the lines start at the same location
  let textWidth = 0;
  forEach(locals.split.lines, (line, i) => {
    const chars = toArray(line.children);
    const lineWidth =
      last(chars).getBoundingClientRect().right -
      first(chars).getBoundingClientRect().left;
    if (lineWidth > textWidth) {
      textWidth = lineWidth;
    }
  });

  const timeline = gsap.timeline();

  forEach(locals.split.lines, (line, i) => {
    let chars = toArray(line.children);
    const offset = line.getBoundingClientRect().left;
    chars = sortBy(chars, (char) => {
      const center =
        char.getBoundingClientRect().x + char.getBoundingClientRect().width / 2;
      return Math.abs(center - offset - textWidth / 2);
    });
    timeline.to(
      chars,
      {
        ease: "power4.out",
        yPercent: 0,
        stagger: 0.04,
        duration: 1.5,
        opacity: 1,
        delay: i * 0.25,
      },
      0
    );
  });

  return timeline;
};

const WaveTextAnimation = forwardRef(
  (
    {
      children,
      srOnlyContent,
      screenReaderClone = true,
      animateWhenInView = true,
    },
    ref
  ) => {
    const [inViewRef, inView] = useInView({ triggerOnce: true });
    const containerRef = useRef();
    const preloading = useSelector(isPreloading);

    const classes = useStyles();
    const child = React.Children.only(children);
    const content = child.props.children;
    const [splitTextRef, constructSplitText, destructSplitText] =
      useSplitText();

    useImperativeHandle(
      ref,
      () => ({
        createTimeline: () => {
          if (!splitTextRef.current.split) {
            // construct the split text here
            constructSplitText(containerRef.current);
          }
          return createTimeline(splitTextRef);
        },
      }),
      [constructSplitText, splitTextRef]
    );

    useEffect(() => {
      if (animateWhenInView && inView && !preloading) {
        constructSplitText(containerRef.current);
        createTimeline(splitTextRef);
      }
    }, [
      animateWhenInView,
      inView,
      preloading,
      splitTextRef,
      constructSplitText,
    ]);

    useEffect(() => {
      return () => {
        destructSplitText();
      };
    }, [destructSplitText]);

    return React.cloneElement(
      child,
      {},
      <>
        {screenReaderClone && (
          <div className="srOnly">{srOnlyContent || content}</div>
        )}
        <div
          className={classes.splitWords}
          ref={composeRefs(inViewRef, containerRef)}
          aria-hidden="true"
        >
          {content}
        </div>
      </>
    );
  }
);

const useStyles = createUseStyles({
  splitWords: {
    visibility: "hidden",
    "& a": {
      textDecoration: "none",
    },
  },
  srOnly: {
    composes: ["srOnly"],
  },
});

export default WaveTextAnimation;
