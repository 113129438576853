import { useEffect, useState } from 'react'

let _hydrated = false

const useOnHydration = (ref, createTimeline, dependencies) => {
  const [hydrated, setHydrated] = useState(_hydrated)
  useEffect(() => {
    if (!_hydrated) {
      _hydrated = true
      setHydrated(_hydrated)
    }
  }, [])

  return hydrated
}

export default useOnHydration
