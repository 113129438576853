import { createSlice } from "@reduxjs/toolkit";
import { route } from "./route";
import { closeMenu } from "./layout";
import forEach from "lodash/forEach";
import find from "lodash/find";
import get from "lodash/get";

export const slugs = ["projects", "products", "resources", "about"];

const defaultState = (slug) => ({
  slug: slug,
  open: false,
  categoryPanelOpen: false,
  selectedCategory: null,
  detailsPanelOpen: false,
  selectedProject: null,
});

const closeOtherPanels = (state, currentSlug) => {
  forEach(state, (item, key) => {
    if (key !== currentSlug) {
      item.open = false;
      item.categoryPanelOpen = false;
      item.detailsPanelOpen = false;
    }
  });
};

const drawerSlice = createSlice({
  name: "drawer",
  initialState: {
    projects: defaultState("projects"),
    products: defaultState("products"),
    resources: defaultState("resources"),
    about: defaultState("about"),
  },
  reducers: {
    closeDrawer: (state) => {
      closeOtherPanels(state);
    },
    openPanel: (state, { payload: { slug } }) => {
      if (!state[slug].open) {
        closeOtherPanels(state, slug);
        state[slug].open = true;
        state[slug].selectedCategory = null;
        state[slug].selectedItem = null;
      }
    },
    closePanel: (state, { payload: { slug } }) => {
      state[slug].open = false;
      state[slug].categoryPanelOpen = false;
      state[slug].detailsPanelOpen = false;
    },
    openCategoryPanel: (state, { payload: { slug, category } }) => {
      state[slug].categoryPanelOpen = true;
      state[slug].selectedCategory = category;
      state[slug].detailsPanelOpen = false;
    },
    closeCategoryPanel: (state, { payload: { slug } }) => {
      state[slug].categoryPanelOpen = false;
      state[slug].detailsPanelOpen = false;
    },
    openDetailsPanel: (state, { payload: { slug, item } }) => {
      state[slug].detailsPanelOpen = true;
      state[slug].selectedItem = item;
    },
    closeDetailsPanel: (state, { payload: { slug } }) => {
      state[slug].detailsPanelOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(route.toString(), (state, action) => {
      closeOtherPanels(state);
    });
    builder.addCase(closeMenu.toString(), (state, action) => {
      closeOtherPanels(state);
    });
    builder.addCase("cart/openCart", (state, action) => {
      closeOtherPanels(state);
    });
  },
});

export const {
  openPanel,
  closePanel,
  openCategoryPanel,
  closeCategoryPanel,
  openDetailsPanel,
  closeDetailsPanel,
  closeDrawer,
} = drawerSlice.actions;

export const isDrawerOpen = (state) => !!find(state.drawer, (x) => x.open);
export const isPanelOpen = (slug) => (state) =>
  get(state.drawer, [slug, "open"], false);
export const isCategoryPanelOpen = (slug) => (state) =>
  state.drawer[slug].categoryPanelOpen;
export const isDetailsPanelOpen = (slug) => (state) =>
  state.drawer[slug].detailsPanelOpen;
export const getSelectedCategory = (slug) => (state) =>
  state.drawer[slug].selectedCategory;
export const getSelectedItem = (slug) => (state) =>
  state.drawer[slug].selectedItem;

export default drawerSlice.reducer;
