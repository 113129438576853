import React from 'react'
import cn from 'classnames'
import { Caption } from './Typography'
import { createUseStyles } from 'react-jss'

export default function Field ({ className, label, children, fontSize = 12, classNames = {} }) {
  const classes = useStyles({ fontSize })
  return (
    <div className={cn(className, classes.field)}>
      <Caption tag='label' className={cn(classes.label, classNames.label)}>{label}</Caption>
      <span className={cn(classes.text, classNames.text)}>{children}</span>
    </div>
  )
}

const useStyles = createUseStyles({
  text: {
    display: 'block',
    fontSize: ({ fontSize }) => fontSize
  },
  label: {
    opacity: 0.5,
    display: 'block'
  }
})
