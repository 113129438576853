import React, { forwardRef, useCallback, useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import gsap from 'gsap'
import useImperativeTimelineHandle from '../../hooks/useImperativeTimelineHandle'
import { useInView } from 'react-intersection-observer'
import useComposeRefs from '../../helpers/composeRefs'
import cn from 'classnames'

export default forwardRef(({ animatedWhenInView, animationType = 'showcase', delay = 0, threshold = 0.2, duration = 1.2 }, ref) => {
  const classes = useStyles()
  const curtainRef = useRef()
  const [inViewRef, inView] = useInView({ threshold, triggerOnce: true })

  const createTimeline = useCallback(() => {
    const tl = gsap.timeline()
    switch (animationType) {
      case 'showcase': {
        const left = curtainRef.current.children[0]
        const right = curtainRef.current.children[1]
        tl.to(left, { x: '-100%', duration, ease: 'sine.inOut', delay })
        tl.to(right, { x: '100%', duration, ease: 'sine.inOut', delay }, 0)
        break
      }
      case 'topDown': {
        const left = curtainRef.current.children[0]
        tl.to(left, { y: '100%', duration: duration, ease: 'power4.inOut', delay })
        break
      }
      default:
    }
    return tl
  }, [animationType, delay, duration])

  useEffect(() => {
    if (inView && animatedWhenInView) {
      createTimeline()
    }
  }, [inView, animatedWhenInView, createTimeline])

  useImperativeTimelineHandle(ref, createTimeline, [])

  return (
    <div className={classes.curtain} ref={useComposeRefs(curtainRef, inViewRef)}>
      <div className={cn(classes.leftCurtain, animationType)} />
      <div className={cn(classes.rightCurtain, animationType)} />
    </div>
  )
})

const useStyles = createUseStyles({
  curtain: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    pointerEvents: 'none',
    display: 'flex',
    overflow: 'hidden'
  },
  leftCurtain: {
    backgroundColor: theme.colors.background,
    width: '50%',
    '&.topDown': {
      width: '100%'
    }
  },
  rightCurtain: {
    backgroundColor: theme.colors.background,
    width: '50%',
    '&.topDown': {
      display: 'none'
    }
  }
})
