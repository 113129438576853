import gsap from "gsap";
import React, { useCallback, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import { getPage, hasPageAnchorLinks } from "../../state/slices/data/page";
import { hasToolbar } from "../../state/slices/layout";
import { addItem } from "../../state/slices/ui/cart";
import { openQuoteDrawer } from "../../state/slices/ui/quoteForm";
import theme from "../../style/theme";
import AnchorLinksToolbarItem from "./AnchorLinksToolbarItem";
import ChatToolbarItem from "./ChatToobarItem";
import ToolbarItem from "./ToolbarItem";

export default function Toolbar() {
  const classes = useStyles();
  const containerRef = useRef();
  const toolbarRef = useRef();
  const dispatch = useDispatch();
  const page = useSelector(getPage);
  const enabled = useSelector(hasToolbar);
  const hasLinks = useSelector(hasPageAnchorLinks);

  const onAddSample = useCallback(() => {
    dispatch(addItem({ id: page.id }));
  }, [dispatch, page]);

  const onRequestAQuote = useCallback(() => {
    dispatch(openQuoteDrawer());
  }, [dispatch]);

  useEffect(() => {
    if (toolbarRef.current) {
      gsap.to(toolbarRef.current, {
        y: enabled ? "0%" : "100%",
        duration: 0.25,
        ease: "expo.out",
      });
    }
  }, [enabled]);

  if (!enabled) return null;

  const { title, _type } = page;

  return ReactDOM.createPortal(
    <section className={classes.toolbarContainer} ref={containerRef}>
      <div className={classes.toolbar} ref={toolbarRef}>
        <ToolbarItem
          title={title}
          caption={_type}
          className={classes.titleItem}
          containerClassName={classes.hideOnMobile}
        />
        {hasLinks && <AnchorLinksToolbarItem title={title} />}
        <ToolbarItem grow containerClassName={classes.hideOnMobile} />
        <ChatToolbarItem />
        <ToolbarItem title="Request a quote" button onClick={onRequestAQuote} />
        {_type === "product" && (
          <ToolbarItem
            title="Add to samples"
            button
            onClick={onAddSample}
            containerClassName={classes.hideOnMobile}
          />
        )}
      </div>
    </section>,
    document.getElementById("___gatsby")
  );
}

const useStyles = createUseStyles({
  toolbarContainer: {
    position: "fixed",
    pointerEvents: "none",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.toolbar,
    overflow: "hidden",
    display: "block",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
    },
  },
  toolbar: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    height: theme.spacing(10),
    pointerEvents: "all",
    backgroundColor: theme.colors.background,
    borderTop: [1, "solid", theme.colors.border],
    transform: "translate(0, 100%)",
    justifyContent: "flex-end",
  },
  titleItem: {
    minWidth: 220,
  },
  hideOnMobile: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
});
