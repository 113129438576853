import { createSlice } from '@reduxjs/toolkit'

const routeSlice = createSlice({
  name: 'route',
  initialState: {
    location: null,
    prevLocation: null
  },
  reducers: {
    route: (state, { payload: { location, prevLocation } }) => {
      state.location = location
      state.prevLocation = prevLocation
    }
  }
})

export const {
  route
} = routeSlice.actions

export default routeSlice.reducer
