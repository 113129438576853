import React, { Suspense } from 'react'
const ProductModel = React.lazy(() => import('./ProductModel' /* webpackChunkName: "product-model" */))

export default function Index (props) {
  const isSSR = typeof window === 'undefined'
  if (isSSR) return null
  return (
    <Suspense fallback={null}>
      <ProductModel {...props} />
    </Suspense>
  )
}
