import React, { useMemo, forwardRef, useContext } from "react";
import { createUseStyles } from "react-jss";
import cn from "classnames";
import BlockContent from "@sanity/block-content-to-react";
import merge from "lodash/merge";
import Link from "./Link";
// import UseInternalLinkUrl from "../hooks/useInternalLinkUrl";
import get from "lodash/get";
import ResponsiveImage from "./ResponsiveImage";
import { Caption } from "./Typography";
import theme from "../style/theme";
import { HorizontalLine } from "./Lines";
import transform from "./ResponsiveImage/transform";
import ImageCurtain from "./Animation/ImageCurtain";
import VimeoPlayer from "./VimeoPlayer";

const ContainerContext = React.createContext();

const LinkListItem = ({ item, children }) => {
  const { link, title, subtitle, image } = item;
  const classes = useStyles();
  return (
    <li className={classes.linkListItem}>
      <Link
        link={get(link, [0])}
        className={classes.linkListLink}
        showText={false}
      >
        {children}
        {image && (
          <ResponsiveImage
            image={image}
            aspect={1}
            showPreview={false}
            className={classes.linkListImage}
          />
        )}
        <div className={classes.linkListTitle}>
          {title}
          <Caption>{subtitle}</Caption>
        </div>
        <HorizontalLine
          position="bottom"
          className={classes.linkListItemLine}
          animationOrigin="0%"
        />
      </Link>
    </li>
  );
};

const marks = {
  link: ({ mark, children }) => {
    const className = mark.href?.startsWith("tel:")
      ? "click-to-call"
      : mark.href?.startsWith("mailto:")
      ? "click-to-email"
      : null;
    return (
      <Link
        to={mark.href}
        target={mark.open_in_new_window ? "_blank" : undefined}
        className={className}
      >
        {children}
      </Link>
    );
  },
  internal_link: ({ mark, children }) => {
    let url;
    // url = UseInternalLinkUrl(mark?.reference); /* eslint-disable-line */
    if (!Boolean(url)) {
      const parentType =
        mark?.reference?.parentPage?.slug?.current ?? mark?.reference?._type;
      const slug = mark?.reference?.slug?.current;
      const file = mark?.reference?.file?.asset?.url;

      if (slug === "home") {
        url = "/";
      } else if (parentType && slug) {
        url = `/${parentType}/${slug}`;
      } else if (slug) {
        url = `/${slug}`;
      } else if (file) {
        url = file;
      }
    }

    return <Link to={url}>{children}</Link>;
  },
  label: ({ mark, children }) => (
    <span className="altHeadings">{children}</span>
  ),
};

const serializers = {
  marks,
  types: {
    richTextLinkList: ({ node: { items } }) => {
      const classes = useStyles(); /* eslint-disable-line */
      return (
        <ul className={classes.list}>
          {!!items?.length &&
            items.map((item, i) =>
              i === 0 ? (
                <LinkListItem key={item.title} item={item}>
                  <HorizontalLine
                    position="top"
                    className={classes.linkListLine}
                    animationOrigin="0%"
                  />
                </LinkListItem>
              ) : (
                <LinkListItem key={item.title} item={item} />
              )
            )}
        </ul>
      );
    },
    richTextImage: ({ node }) => {
      const { span, logo, image, alt, caption } = node;

      const imageNode = {
        ...image,
        alt,
        caption,
      };

      const { offsetColumn } =
        useContext(ContainerContext); /* eslint-disable-line */
      const classes = UseImageStyles({
        span,
        offsetColumn,
      }); /* eslint-disable-line */
      if (!image?.asset) return null;
      if (logo) {
        const imageSource = transform(image);
        const { url, width, height } = get(imageSource, ["sizes", 0], {});
        return url ? (
          <img
            className={classes.logoImage}
            src={imageSource.sizes[0].url}
            alt={alt}
            width={width}
            height={height}
          />
        ) : null;
      }

      return (
        <ResponsiveImage
          showCaption
          image={imageNode}
          className={classes.image}
        >
          <ImageCurtain animatedWhenInView animationType="topDown" />
        </ResponsiveImage>
      );
    },
    richTextVimeoVideo: ({ node }) => {
      const { vimeoData } = node;
      const { offsetColumn } =
        useContext(ContainerContext); /* eslint-disable-line */
      const span = "5";
      const classes = UseImageStyles({
        span,
        offsetColumn,
      }); /* eslint-disable-line */

      return (
        <div className={classes.image}>
          <VimeoPlayer vimeoData={vimeoData} />
        </div>
      );
    },
  },
};

const UseImageStyles = createUseStyles({
  image: {
    margin: [theme.spacing(10), "auto"],
    [theme.breakpoints.up("md")]: {
      margin: [theme.section.marginBottom.md, "auto"],
      width: ({ span = "4" }) => theme.span(parseInt(span), "md"),
      marginLeft: ({ offsetColumn }) =>
        !offsetColumn
          ? "auto"
          : theme.span(parseInt(-(7 - offsetColumn)), "md", theme.gutter.md),
    },
  },
  logoImage: {
    margin: [theme.spacing(5), 0, 0],
    "&:not(:last-child)": {
      margin: [theme.spacing(5), 0, theme.spacing(10)],
    },
  },
});

const RichText = forwardRef(
  (
    {
      className,
      blocks = [],
      tag = "div",
      serializers: extraSerializers,
      children,
      offsetColumn = 0,
    },
    ref
  ) => {
    const classes = useStyles();
    const Component = tag;
    const allSerializers = useMemo(() => {
      if (extraSerializers) {
        return merge({}, serializers, extraSerializers);
      }
      return serializers;
    }, [extraSerializers]);
    const containerContextValue = useMemo(
      () => ({ offsetColumn: parseInt(offsetColumn) }),
      [offsetColumn]
    );

    return (
      <Component className={cn(className, classes.container)} ref={ref}>
        <ContainerContext.Provider value={containerContextValue}>
          <BlockContent blocks={blocks} serializers={allSerializers} />
          {children}
        </ContainerContext.Provider>
      </Component>
    );
  }
);

const useStyles = createUseStyles(
  {
    container: {
      "& > div > p:last-child, & > p:last-child": {
        marginBottom: 0,
      },
    },
    list: {
      padding: 0,
      margin: 0,
      listStyle: "none",
      position: "relative",
    },
    linkListLine: {
      opacity: 0.3,
    },
    linkListLink: {
      display: "flex",
      textDecoration: "none",
      padding: [theme.spacing(3), 0],
      position: "relative",
      "&:hover $linkListItemLine": {
        opacity: 1,
      },
    },
    linkListItemLine: {
      transition: "opacity 0.15s ease-in-out",
      opacity: 0.3,
    },
    linkListTitle: {
      display: "flex",
      flexDirection: "column",
    },
    linkListImage: {
      height: 48,
      width: 48,
      marginRight: theme.spacing(4),
    },
  },
  { name: "RichText" }
);

export default RichText;
