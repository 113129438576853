import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import theme from '../style/theme'
import { Caption } from './Typography'
import { HorizontalLine, VerticalLine } from './Lines'

export const Cell = ({ className, label, children, fullWidth, noBorder, noBottomPadding }) => {
  const classes = useStyles()
  return (
    <div className={cn(className, classes.cell, { fullWidth, noBorder, noBottomPadding })}>
      <span className={classes.cellContent}>
        {label && <Caption className={cn(className, classes.label)}>{label}</Caption>}
        {children}
      </span>
      {!noBorder && <HorizontalLine position='bottom' />}
      {!fullWidth && <VerticalLine position='right' animationOrigin='0%' />}
    </div>
  )
}

export default function DetailsPanel ({ children, className }) {
  const classes = useStyles()

  return (
    <div className={cn(className, classes.details)}>
      {children}
      <HorizontalLine position='top' />
      <HorizontalLine position='bottom' />
      <VerticalLine position='left' animationOrigin='0%' />
      <VerticalLine position='right' animationOrigin='0%' />
    </div>
  )
}

const useStyles = createUseStyles({
  details: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    position: 'relative'
  },
  cell: {
    display: 'block',
    padding: theme.gutter.sm,
    fontSize: 12,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      padding: theme.gutter.md
    },
    '&.fullWidth': {
      gridColumn: 'span 2'
    },
    '&.noBottomPadding': {
      paddingBottom: 0
    }
  },
  label: {
    opacity: 0.5,
    display: 'block',
    marginBottom: theme.spacing(1)
  },
  cellContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  }
})
