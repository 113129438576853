import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import { HorizontalLine } from '../Lines'
import RichText from '../RichText'
import Section from '../Section'
import SliceTitle from './SliceTitle'
import cn from 'classnames'
import transform from '../ResponsiveImage/transform'
import isEmpty from 'lodash/isEmpty'

export default function KeyFeatures ({ slice, id, page }) {
  const classes = useStyles()
  const { title, features, _rawCopy } = slice

  return (
    <Section grid className={classes.section} id={id}>
      <SliceTitle className={cn(classes.title, page._type)}>
        {title}
      </SliceTitle>
      <div className={cn(classes.featureGrid, page._type)}>
        {_rawCopy &&
          <div className={cn(classes.copy, { withoutFeatures: isEmpty(features) })}>
            <RichText blocks={_rawCopy.text} />
            {!isEmpty(features) && <HorizontalLine position='bottom' className={classes.line} />}
          </div>}
        {features && features.map(({ title, _rawText, icon }, i) => {
          const showLine = features.length > 2 && i < features.length - 2
          return (
            <div key={i} className={cn(classes.keyFeature, { showLine })}>
              {title && (
                <span className={classes.keyFeatureTitle}>
                  {icon && <img src={transform(icon).sizes[0].url} alt={icon.alt} className={classes.icon} />}
                  {title}
                </span>
              )}
              {_rawText && <RichText blocks={_rawText.text} />}
              {showLine && <HorizontalLine position='bottom' className={classes.line} />}
            </div>
          )
        })}
      </div>
      <HorizontalLine extend position='bottom' />
    </Section>
  )
}

const useStyles = createUseStyles({
  section: {
    position: 'relative'
  },
  title: {
    '&.page': {
      [theme.breakpoints.up('md')]: {
        gridColumn: '1 / span 3',
        gridRow: 1
      }
    }
  },
  featureGrid: {
    marginBottom: theme.section.marginBottom.sm,
    gridColumn: '1 / span 4',
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(18),
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gridColumn: '4 / span 4',
      gap: `${theme.spacing(15)}px ${theme.gutter.md}px`
    },
    '&.page': {
      [theme.breakpoints.up('md')]: {
        gridRow: 1,
        gap: `0px ${theme.gutter.md}px`
      }
    }
  },
  line: {
    opacity: 0.3
  },
  copy: {
    gridColumn: 'span 4',
    marginBottom: theme.spacing(5),
    paddingBottom: theme.spacing(15),
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(10),
      width: `calc(50% - ${theme.gutter.md / 2}px)`
    },
    '&.withoutFeatures': {
      marginBottom: 0,
      paddingBottom: 0
    }
  },
  keyFeature: {
    gridColumn: 'span 2',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      paddingRight: theme.gutter.md
    },
    '&:not(:last-child)': {
      marginBottom: theme.spacing(5)
    },
    '&.showLine': {
      paddingBottom: theme.spacing(15),
      marginBottom: theme.spacing(5)
    }
  },
  keyFeatureTitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(6)
    }
  },
  icon: {
    height: '2em',
    width: 'auto',
    marginRight: theme.spacing(2)
  }
})
