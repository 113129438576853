import React from "react";
import { createUseStyles } from "react-jss";
import theme from "../../style/theme";
import ImageCurtain from "../Animation/ImageCurtain";
import { HorizontalLine, VerticalLine } from "../Lines";
import ResponsiveImage from "../ResponsiveImage";
import Section from "../Section";
import { Caption } from "../Typography";
import cn from "classnames";

export default function PageHero({ slice }) {
  const classes = useStyles();
  const { title, captions, image } = slice;
  if (image) {
    return (
      <section className={cn(classes.section, { noImage: !!image })}>
        <Section grid noBottomMargin>
          <div className={classes.captions}>
            {captions &&
              captions.map((caption) => (
                <Caption key={caption}>{caption}</Caption>
              ))}
          </div>
          <h1 className={classes.title}>{title}</h1>
          <div className={classes.verticalLine}>
            <VerticalLine animationOrigin="0%" position="right" />
          </div>
          <HorizontalLine extend position="bottom" />
        </Section>
        <ResponsiveImage image={image} aspect={1440 / 700}>
          <ImageCurtain animatedWhenInView animationType="topDown" />
        </ResponsiveImage>
      </section>
    );
  }
  return (
    <Section grid>
      <h1 className={classes.titleNoImage}>{title}</h1>
      <div className={classes.captionsNoImage}>
        {captions &&
          captions.map((caption) => <Caption key={caption}>{caption}</Caption>)}
      </div>
      <div className={classes.horizontalLine}>
        <HorizontalLine extend />
      </div>
    </Section>
  );
}

const useStyles = createUseStyles({
  section: {
    position: "relative",
    overflow: "hidden",
    marginBottom: theme.section.marginBottom.sm,
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.section.marginBottom.md,
    },
  },
  captions: {
    gridRow: 1,
    gridColumn: "1 / span 4",
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(10),
    opacity: 0.5,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      display: "grid",
      gridColumn: "4 / span 4",
      gridTemplateColumns: "repeat(4, 1fr)",
      columnGap: theme.gutter.md,
      marginLeft: 0,
    },
  },
  title: {
    gridRow: 2,
    marginTop: theme.spacing(36),
    marginLeft: theme.spacing(10),
    gridColumn: "1 / span 4",
    [theme.breakpoints.up("md")]: {
      gridColumn: "4 / span 3",
      marginTop: theme.spacing(26),
      marginLeft: 0,
    },
    [theme.breakpoints.up("lg")]: {
      gridColumn: "4 / span 2",
    },
  },
  verticalLine: {
    position: "relative",
    gridRow: "1 / span 2",
    gridColumn: "1",
    marginLeft: theme.spacing(6),
    width: 1,
    [theme.breakpoints.up("md")]: {
      marginLeft: 0,
      gridColumn: "3",
      width: "100%",
    },
  },
  titleNoImage: {
    gridRow: 2,
    marginTop: theme.spacing(36),
    marginBottom: 0,
    gridColumn: "1 / span 4",
    [theme.breakpoints.up("md")]: {
      fontSize: "calc(35px + (65 - 10) * ((100vw - 320px) / (1920 - 320)))",
      maxWidth: 800,
      gridRow: 1,
      gridColumn: "1 / span 4",
      marginTop: theme.spacing(46),
    },
  },
  captionsNoImage: {
    gridRow: 1,
    gridColumn: "1 / span 4",
    marginTop: theme.spacing(5),
    opacity: 0.5,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      display: "grid",
      gridColumn: "5 / span 3",
      gridTemplateColumns: "repeat(3, 1fr)",
      columnGap: theme.gutter.md,
      alignItems: "end",
    },
  },
  horizontalLine: {
    position: "relative",
    height: 1,
    gridRow: 3,
    gridColumn: "1 / span 4",
    [theme.breakpoints.up("md")]: {
      gridRow: 2,
      gridColumn: "1 / span 7",
    },
  },
});
