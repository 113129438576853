import { filter, flatten, map } from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import range from "lodash/range";
import { sortByOrder } from "../../helpers/sortByOrder";
import { resolvePageUrl } from "../../hooks/useInternalLinkUrl";
import { fetchProducts } from "../../state/slices/data/lists";
import {
  getProducts,
  isLoadingProducts,
  isProductsLoaded,
} from "../../state/slices/data/products";
import { getSelectedItem, openDetailsPanel } from "../../state/slices/drawer";
import LinkList from "../LinkList";
import { useGlobalContext } from "../SiteContext";
import ProductProjectItem from "./ProductProjectItem";

export default function ProductsList({ fireSubstrate }) {
  const { productsLink } = useGlobalContext();
  const loading = useSelector(isLoadingProducts);
  const loaded = useSelector(isProductsLoaded);
  const products = useSelector(getProducts);
  const selectedProduct = useSelector(getSelectedItem("products"));
  const dispatch = useDispatch();

  const onMouseEnterProject = useCallback(
    (item) => {
      dispatch(openDetailsPanel({ slug: "products", item }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!loaded) {
      dispatch(fetchProducts());
    }
  }, [dispatch]); /* eslint-disable-line */
  const items = useMemo(() => {
    if (products && fireSubstrate) {
      let filteredList = filter(products, (x) =>
        flatten(map(x.fireSubstrateFilters, (item) => item.title)).includes(
          fireSubstrate.title
        )
      );

      switch (fireSubstrate.slug.current) {
        case "decorzen":
          filteredList = sortByOrder(
            filteredList,
            ["Zen", "Contours of Country", "Style"],
            "title"
          );
          break;
        case "decorslat":
          filteredList = sortByOrder(
            filteredList,
            ["Slat", "Max", "Tone", "Ali", "AliSorb"],
            "title"
          );
          break;
        case "decormetl":
          filteredList = sortByOrder(
            filteredList,
            ["Metl", "Mesh", "Arktura Vapor®", "Olympia"],
            "title"
          );
          break;
        default:
          break;
      }

      return filteredList.map((product) => ({
        to: resolvePageUrl(productsLink.reference, product),
        text: (
          <ProductProjectItem
            title={product.title}
            field1Label="Category"
            field1Value={product?.category?.title}
            field2Label="Sound Profile"
            field2Value={product?.soundProfile}
          />
        ),
        product: product,
        arrow: true,
      }));
    }
    return [];
  }, [fireSubstrate, products]); /* eslint-disable-line */

  const placeholders = useMemo(() => {
    const items = range(2);
    return items.map(() => ({
      text: (
        <ProductProjectItem
          placeholder
          field1Label="Category"
          field2Label="Product"
        />
      ),
      arrow: true,
    }));
  }, []);

  if (!loaded || loading) {
    return <LinkList items={placeholders} />;
  }

  return (
    <LinkList
      key={fireSubstrate?.slug?.current}
      items={items}
      onMouseEnter={onMouseEnterProject}
      selectedItem={selectedProduct}
    />
  );
}
