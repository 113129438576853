import { oneLineTrim } from 'common-tags'

export const unquotedBodyFace = 'NHaasGroteskTXPro'
export const bodyFace = `"${unquotedBodyFace}"`
export const body = `${bodyFace}, "Lucida Sans", Tahoma, sans-serif`
export const bodyFontWeight = 400

export const unquotedHeadingFace = 'NHaasGroteskDSPro'
export const headingFace = `"${unquotedHeadingFace}"`
export const heading = `${headingFace}, "Lucida Sans", Tahoma, sans-serif`
export const headingFontWeight = 400

export const unquotedCaptionFace = 'ABCMonumentGroteskMono'
export const captionFace = `"${unquotedCaptionFace}"`
export const caption = `${captionFace}, "Lucida Sans", Tahoma, sans-serif`
export const captionFontWeight = 400

export const faces = [{
  fontFamily: bodyFace,
  src: oneLineTrim`
    url('/fonts/NHaasGroteskTXPro-55Rg.woff2') format('woff2'),
    url('/fonts/NHaasGroteskTXPro-55Rg.woff') format('woff')`,
  fontWeight: 'normal',
  fontStyle: 'normal'
}, {
  fontFamily: headingFace,
  src: oneLineTrim`
     url('/fonts/NHaasGroteskDSPro-55Rg.woff2') format('woff2'),
     url('/fonts/NHaasGroteskDSPro-55Rg.woff') format('woff')`,
  fontWeight: 'normal',
  fontStyle: 'normal'
}, {
  fontFamily: captionFace,
  src: oneLineTrim`
     url('/fonts/ABCMonumentGroteskMono-Regular.woff2') format('woff2'),
     url('/fonts/ABCMonumentGroteskMono-Regular.woff') format('woff')`,
  fontWeight: 'normal',
  fontStyle: 'normal'
}]
