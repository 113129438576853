import React, { useCallback, useState } from "react";
import Section from "../Section";
import RichText from "../RichText";
import ResponsiveImage from "../ResponsiveImage";
import UseInternalLinkUrl from "../../hooks/useInternalLinkUrl";
import Lnk from "../Link";
import { createUseStyles } from "react-jss";
import theme from "../../style/theme";
import { HorizontalLine, VerticalLine } from "../Lines";
import { Cell } from "../DetailsPanel";
import Button from "../Button";
import { concat } from "lodash";
import transform from "../ResponsiveImage/transform";

const LIMIT = 3;

function Feedback({ slice }) {
  const classes = useStyles();
  const { feedbacks } = slice;
  const [showMore, setShowMore] = useState(Boolean(feedbacks.length > LIMIT));
  const [list, setList] = useState(
    feedbacks.length ? feedbacks.slice(0, LIMIT) : []
  );

  const [index, setIndex] = useState(LIMIT);

  const loadMore = useCallback(() => {
    const newIndex = index + LIMIT;
    const newShowMore = newIndex < feedbacks.length - 1;
    const newList = concat(list, feedbacks.slice(index, newIndex));
    setIndex(newIndex);
    setList(newList);
    setShowMore(newShowMore);
  }, [feedbacks, list, index]);

  const renderFeedbacks = useCallback(
    (listFeedbacks) =>
      listFeedbacks.map(
        (
          {
            company,
            date,
            role,
            _rawText,
            image,
            project,
            products,
            position = "top",
            showImage,
            title,
            labelBtn = "View project",
            icon,
          },
          idx
        ) => {
          return (
            <div key={`${Math.random(idx)}-${idx}`}>
              <Section noBottomMargin grid className={classes.section}>
                <div className={classes.quote}>
                  {icon && position === "top" && (
                    <span className={classes.keyFeatureTitle}>
                      <img
                        src={transform(icon).sizes[0].url}
                        alt={icon.alt}
                        className={classes.icon}
                      />
                    </span>
                  )}
                  {Boolean(title?.length) && (
                    <span className={classes.quoteTitle}>{title}</span>
                  )}
                  <RichText blocks={_rawText.text} />
                  {icon && position === "bottom" && (
                    <span className={classes.keyFeatureTitle}>
                      <img
                        src={transform(icon).sizes[0].url}
                        alt={icon.alt}
                        className={classes.icon}
                      />
                    </span>
                  )}
                </div>
                <div className={classes.verticalLine}>
                  <VerticalLine position="right" />
                </div>
                <div className={classes.detailsContainer}>
                  <Cell fullWidth label={"Role"}>
                    {role}
                  </Cell>
                  <Cell fullWidth label={"Company"}>
                    {company}
                  </Cell>
                  {Boolean(project) && (
                    <Cell fullWidth label={"Project"}>
                      <Lnk to={UseInternalLinkUrl(project)}>
                        {project.title}
                      </Lnk>
                    </Cell>
                  )}
                  {Boolean(products?.length) && (
                    <Cell fullWidth label={"Products"}>
                      <div className={classes.productLinks}>
                        {Boolean(products?.length) &&
                          products.map((product, i) => {
                            const urlProduct = UseInternalLinkUrl(product);
                            const { title } = product;
                            return (
                              <Lnk to={urlProduct} key={`${product.id}`}>
                                {title}
                                {i !== products.length - 1 && ","}
                              </Lnk>
                            );
                          })}
                      </div>
                    </Cell>
                  )}
                  <Cell fullWidth label={"Date"}>
                    {date}
                  </Cell>
                </div>
                <div className={classes.secondVerticalLine}>
                  <VerticalLine position="left" />
                </div>
                {Boolean(showImage) && (
                  <div className={classes.imageContainer}>
                    <ResponsiveImage image={image} />
                    <div>
                      {Boolean(project) && (
                        <Button to={UseInternalLinkUrl(project)}>
                          {labelBtn}
                        </Button>
                      )}
                    </div>
                  </div>
                )}
                <HorizontalLine position="bottom" extend animationOrigin="0%" />
              </Section>
            </div>
          );
        }
      ),
    [classes]
  );
  return (
    <div>
      {Boolean(list?.length) && renderFeedbacks(list)}
      {showMore && (
        <Section grid noBottomMargin className={classes.sectionLoadMore}>
          <Button
            onClick={loadMore}
            className={classes.loadMoreButton}
            showArrow={false}
          >
            Load More
          </Button>
        </Section>
      )}
    </div>
  );
}

const useStyles = createUseStyles({
  section: {
    position: "relative",
    marginTop: theme.spacing(10),
    paddingBottom: theme.spacing(16),
    [theme.breakpoints.up("md")]: {
      paddingBottom: 0,
      marginTop: 0,
    },
  },
  sectionLoadMore: {
    position: "relative",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  quote: {
    gridColumn: "1 / span 4",
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up("md")]: {
      gridColumn: " 3 / span 3",
      gridRow: 1,
      marginTop: theme.spacing(13),
      width: theme.span(2.5, "md"),
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  quoteTitle: {
    display: "flex",
    alignItems: "center",
    fontSize: 20,
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(6),
    },
  },
  detailsContainer: {
    gridRow: 2,
    gridColumn: "1 / span 2",

    [theme.breakpoints.up("md")]: {
      gridColumn: "1 / span 2",
      gridRow: 1,
      marginTop: theme.spacing(13),
      marginBottom: theme.spacing(8),
      paddingRight: "25%",
    },
  },
  productLinks: {
    display: "flex",
    flexWrap: "wrap",
    gap: "3px",
  },
  imageContainer: {
    gridColumn: "3 / span 2",
    gridRow: 2,
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    [theme.breakpoints.up("md")]: {
      gridRow: 1,
      gridColumn: "6 / span 2",
      marginLeft: theme.spacing(5),
      marginTop: theme.spacing(5),
    },
  },
  verticalLine: {
    position: "relative",
    visibility: "hidden",
    [theme.breakpoints.up("md")]: {
      visibility: "visible",
      marginLeft: 0,
      gridColumn: "2",
      gridRow: "1 / span 2",
      width: "100%",
    },
  },
  secondVerticalLine: {
    position: "relative",
    visibility: "hidden",
    [theme.breakpoints.up("md")]: {
      visibility: "visible",
      marginLeft: 0,
      gridColumn: 6,
      gridRow: "1 / span 2",
      width: "100%",
    },
  },
  loadMoreButton: {
    gridColumn: "1 / span 4",
    justifyContent: "center",
    padding: theme.gutter.sm,
    [theme.breakpoints.up("md")]: {
      gridColumn: "1 / span 7",
      padding: theme.gutter.md,
    },
  },
  icon: {
    height: "2em",
    width: "auto",
    marginRight: theme.spacing(2),
  },
  keyFeatureTitle: {
    display: "flex",
    alignItems: "center",
    fontSize: 20,
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(5),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(6),
      marginTop: theme.spacing(6),
    },
  },
});

export default Feedback;
