import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import theme from '../style/theme'

const Section = forwardRef(({ className, tag = 'section', fullWidth, noBottomMargin, children, grid, ...props }, ref) => {
  const classes = useStyles()
  const Tag = tag
  return (
    <Tag
      ref={ref}
      className={cn(className, classes.section, {
        gutter: !fullWidth,
        bottomMargin: !noBottomMargin,
        grid
      })}
      {...props}
    >
      {children}
    </Tag>
  )
})

export default Section

const useStyles = createUseStyles({
  section: {
    '&.gutter': {
      marginLeft: theme.gutter.sm,
      marginRight: theme.gutter.sm,
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.gutter.md,
        marginRight: theme.gutter.md
      }
    },
    '&.bottomMargin': {
      marginBottom: theme.section.marginBottom.sm,
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.section.marginBottom.md
      }
    },
    '&.grid': {
      ...theme.gridStyle
    }
  }
}, { name: 'Layout' })
