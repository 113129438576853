import React, { useMemo, forwardRef, useEffect, useState } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import isArray from 'lodash/isArray'
import { resolveLink } from '../helpers/resolveLink'
import { getFileNameLink } from '../helpers/getFileNameLink'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import TransitionLink from 'gatsby-plugin-transition-link'
import { ENTER_TRANSITION_DELAY, EXIT_TRANSITION_DURATION } from '../templates/PageComponent'

const Lnk = forwardRef(({ link, download, to, children, showText = true, useTransitionLink = true, ...rest }, ref) => {
  const [dataDownload, setDataDownload] = useState();
  const { url, text } = useMemo(() => {
    if (to) return { url: to }
    if (link) {
      return resolveLink(isArray(link) ? link[0] : link)
    }
    return {}
  }, [link, to])

  useEffect(() => {
    if(download && to) {
      (async() => {
        const data = await fetch(to);

        const fileName = getFileNameLink(to);
  
        const blob = await data.blob();
    
        const url = URL.createObjectURL(new Blob([blob]));

        setDataDownload({
          url,
          fileName
        })
      })()
    }

  }, [download, to]);

  if (dataDownload?.url && (dataDownload.url.indexOf('http') >= 0)) {
    return <a href={dataDownload.url} download={dataDownload.fileName} {...rest} ref={ref}>{showText && text}{children}</a>
  }
  if (url && (url.indexOf('http') >= 0 || url.indexOf('tel:') >= 0 || url.indexOf('mailto:') >= 0)) {
    return <a href={url} target='_blank' rel='noreferrer' {...rest} ref={ref}>{showText && text}{children}</a>
  }
  if (!url) {
    return <span {...rest} ref={ref}>{showText && text}{children}</span>
  }

  // The transition link does not support the e.preventDefault() in the onClick event.
  // I had to add a useTransitionLink to force it not to use the
  const ResolvedLink = useTransitionLink ? TransitionLink : GatsbyLink
  const linkProps = useTransitionLink
    ? {
        exit: { length: EXIT_TRANSITION_DURATION },
        entry: { delay: ENTER_TRANSITION_DELAY }
      }
    : {}

  return (
    <ResolvedLink
      to={url}
      {...linkProps}
      {...rest}
      ref={ref}
    >
      {showText && text}{children}
    </ResolvedLink>
  )
})

export const AnimatedLink = forwardRef(({ className, ...props }, ref) => {
  const classes = useStyles()
  return <Lnk className={cn(className, classes.link)} {...props} ref={ref} />
})

const useStyles = createUseStyles({
  link: {
    display: 'inline-flex',
    position: 'relative',
    '&:after': {
      position: 'absolute',
      content: '""',
      bottom: 0,
      left: 0,
      right: 0,
      transform: 'scaleX(0)',
      transformOrigin: '0 0',
      backgroundColor: 'currentColor',
      display: 'block',
      height: 1,
      transition: 'transform 0.15s ease-in-out'
    },
    '&:hover:after, &.active:after': {
      transform: 'scaleX(1)'
    }
  }
})

export default Lnk
