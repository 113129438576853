import get from "lodash/get";
import React, { useMemo } from "react";
import ErrorBoundary from "./ErrorBoundary";
import { usePageContext } from "./SiteContext";
import AboutHero from "./Slices/AboutHero";
import ArticleHero from "./Slices/ArticleHero";
import ChartSlice from "./Slices/ChartSlice";
import Contact from "./Slices/Contact";
import Feedback from "./Slices/Feedback";
import FeedbackBigImage from "./Slices/FeedbackBigImage";
import HomeHero from "./Slices/HomeHero";
import ImageGrid from "./Slices/ImageGrid";
import ImageSlider from "./Slices/ImageSlider";
import IntegrationsList from "./Slices/IntegrationsList";
import KeyFeatures from "./Slices/KeyFeatures";
import Listing from "./Slices/Listing";
import ListingAbout from "./Slices/ListingAbout";
import NewsLetterFormSlice from "./Slices/NewsLetterFormSlice";
import OurApproach from "./Slices/OurApproach";
import PageHero from "./Slices/PageHero";
import ProductDocumentsList from "./Slices/ProductDocumentsList";
import ProductGrid from "./Slices/ProductGrid";
import ProductHero from "./Slices/ProductHero";
import ProductListing from "./Slices/ProductListing";
import ProductProfiles from "./Slices/ProductProfiles";
import ProductVariances from "./Slices/ProductVariances";
import ProjectHero from "./Slices/ProjectHero";
import ProjectShowcase from "./Slices/ProjectShowcase";
import RecentArticles from "./Slices/RecentArticles";
import RelatedPages from "./Slices/RelatedPages";
import ResourcesDocumentsList from "./Slices/ResourcesDocumentsList";
import RichTextSlice from "./Slices/RichTextSlice";
import TeamMembers from "./Slices/TeamMembers";
import TypeFormSlice from "./Slices/TypeFormSlice";
import VimeoPlayerSlice from "./Slices/VimeoPlayerSlice";
import { sanitizeAnchorId } from "./Toolbar/AnchorLinksToolbarItem";

const sliceComponentSelector = {
  home_hero: HomeHero,
  project_showcase: ProjectShowcase,
  recent_articles: RecentArticles,
  product_grid: ProductGrid,
  product_listing: ProductListing,
  product_hero: ProductHero,
  image_slider: ImageSlider,
  keyFeatures: KeyFeatures,
  product_profiles: ProductProfiles,
  product_variances: ProductVariances,
  product_documents_list: ProductDocumentsList,
  resources_documents_list: ResourcesDocumentsList,
  rich_text_slice: RichTextSlice,
  integrations_list: IntegrationsList,
  related_pages: RelatedPages,
  project_hero: ProjectHero,
  image_grid: ImageGrid,
  project_listing: Listing,
  article_listing: Listing,
  about_listing: ListingAbout,
  article_hero: ArticleHero,
  page_hero: PageHero,
  vimeo_player_slice: VimeoPlayerSlice,
  contact_slice: Contact,
  team_members: TeamMembers,
  chart_slice: ChartSlice,
  our_approach: OurApproach,
  newsletterForm: NewsLetterFormSlice,
  typeform_slice: TypeFormSlice,
  about_hero: AboutHero,
  feedbackSlice: Feedback,
  feedbackBigImage: FeedbackBigImage,
};

export default function Slices({ className }) {
  const page = usePageContext();
  const slices = get(page, ["slicesList", "slices"]);
  const sliceComponents = useMemo(() => {
    if (!slices) return null;
    return slices.map((slice, index) => {
      const Component = sliceComponentSelector[slice._type];
      if (!Component) return null;
      const id = sanitizeAnchorId(get(slice, ["anchorLink", "anchorId"]));
      return (
        <ErrorBoundary key={`slice-${index}`}>
          <Component slice={slice} page={page} id={id} />
        </ErrorBoundary>
      );
    });
  }, [page, slices]);

  return sliceComponents;
}
