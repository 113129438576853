import React, { useRef, useEffect, forwardRef, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useInView } from 'react-intersection-observer'
import useComposeRefs from '../helpers/composeRefs'
import theme from '../style/theme'
import useImperativeTimelineHandle from '../hooks/useImperativeTimelineHandle'
import gsap from 'gsap'
import cn from 'classnames'

const Line = forwardRef(({ className, animatedWhenInView = true, animationOrigin = '50%', vertical = false, style }, ref) => {
  const classes = useStyles({ animationOrigin })
  const lineRef = useRef()
  const [inViewRef, inView] = useInView({ threshold: 0, triggerOnce: true })

  const createTimeline = useCallback(() => {
    const timeline = gsap.timeline()
    const scale = vertical ? 'scaleY' : 'scaleX'
    timeline.to(lineRef.current, { [scale]: '1', duration: 1.2, ease: 'sine.inOut', delay: 0.15 })
    return timeline
  }, [vertical])
  useImperativeTimelineHandle(ref, createTimeline, [])
  useEffect(() => {
    if (animatedWhenInView && inView) {
      createTimeline()
    }
  }, [inView, animatedWhenInView, createTimeline])

  return (
    <div className={cn(classes.line, className)} ref={useComposeRefs(lineRef, inViewRef)} style={style} />
  )
})

export const HorizontalLine = forwardRef(({ className, animatedWhenInView = true, animationOrigin = '50%', position = 'top', extend }, ref) => {
  const classes = useStyles({ animationOrigin })
  return (
    <Line className={cn(classes.horizontal, { extend }, className)} ref={ref} style={{ [position]: 0 }} />
  )
})

export const VerticalLine = forwardRef(({ className, animatedWhenInView = true, animationOrigin = '50%', position = 'right', extend }, ref) => {
  const classes = useStyles({ animationOrigin })
  return (
    <Line className={cn(classes.vertical, { extend }, className)} ref={ref} vertical style={{ [position]: 0 }} />
  )
})

const useStyles = createUseStyles({
  line: {
    backgroundColor: theme.colors.border
  },
  horizontal: {
    position: 'absolute',
    left: 0,
    right: 0,
    height: 1,
    transform: 'scaleX(0)',
    transformOrigin: ({ animationOrigin }) => `${animationOrigin} 50%`,
    '&.extend': {
      left: -theme.gutter.sm,
      right: -theme.gutter.sm,
      [theme.breakpoints.up('md')]: {
        left: -theme.gutter.md,
        right: -theme.gutter.md
      }
    }
  },
  vertical: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: 1,
    transform: 'scaley(0)',
    transformOrigin: ({ animationOrigin }) => `50% ${animationOrigin}`,
    '&.extend': {
      top: -theme.gutter.sm,
      bottom: -theme.gutter.sm,
      [theme.breakpoints.up('md')]: {
        top: -theme.gutter.md,
        bottom: -theme.gutter.md
      }
    }
  }
})
