import cn from "classnames";
import React from "react";
import { createUseStyles } from "react-jss";
import { formatDate } from "../../helpers/format";
import theme from "../../style/theme";
import ImageCurtain from "../Animation/ImageCurtain";
import DetailsPanel, { Cell } from "../DetailsPanel";
import { HorizontalLine } from "../Lines";
import ResponsiveImage from "../ResponsiveImage";
import RichText from "../RichText";
import Section from "../Section";
import ShareLinks from "../ShareLinks";
import WaveTextAnimation from "../WaveTextAnimation";

export default function ArticleHero({ slice, page }) {
  const { image, title, _rawCopy, showMeta } = slice;
  const { category, post_date: postDate, author } = page;
  const classes = useStyles();

  return (
    <Section fullWidth>
      {image && (
        <ResponsiveImage
          image={image}
          aspect={1440 / 700}
          className={classes.image}
        >
          <ImageCurtain animationType="topDown" animatedWhenInView />
          <HorizontalLine position="bottom" />
        </ResponsiveImage>
      )}
      <Section grid tag="div">
        <div className={cn(classes.content, { topMargin: !image })}>
          <WaveTextAnimation>
            <h1 className={classes.title}>{title}</h1>
          </WaveTextAnimation>
          {showMeta && (
            <DetailsPanel className={classes.detailsPanel}>
              <Cell label="Category">{category?.title}</Cell>
              <Cell label="Published">
                {postDate ? formatDate(postDate) : ""}
              </Cell>
              <Cell label="Written By">{author}</Cell>
              <Cell label="Share">
                <ShareLinks
                  className={classes.shareLinks}
                  text={title ? title : ""}
                />
              </Cell>
            </DetailsPanel>
          )}
        </div>
        {_rawCopy && (
          <RichText grid blocks={_rawCopy.text} className={classes.richText} />
        )}
      </Section>
    </Section>
  );
}

const useStyles = createUseStyles({
  content: {
    gridColumn: "1 / span 4",
    [theme.breakpoints.up("md")]: {
      gridColumn: "3 / span 3",
    },
    "&.topMargin": {
      marginTop: theme.spacing(10),
    },
  },
  title: {
    marginBottom: theme.spacing(10),
  },
  image: {
    marginBottom: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.section.marginBottom.md,
    },
  },
  detailsPanel: {
    marginBottom: theme.spacing(10),
  },
  shareLinks: {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      "& > *": {
        margin: 0,
      },
      "& > *:not(:last-child)": {
        marginBottom: theme.spacing(3),
      },
    },
  },
  richText: {
    gridColumn: "span 4",
    [theme.breakpoints.up("md")]: {
      gridColumn: "span 7",
      "& > div > p, & > div > ul,& > div > ol, & > div > h2, & > div > h3, & > div > h4, & > div > h5, & > div > h6, & > div > blockquote":
        {
          width: theme.span(3, "md"),
          marginLeft: "auto",
          marginRight: "auto",
        },
    },
  },
});
