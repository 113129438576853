export const sortByOrder = (items, sortOrder, fieldName) =>
  items.sort((a, b) => {
    const indexA = sortOrder.indexOf(a[`${fieldName}`]);
    const indexB = sortOrder.indexOf(b[`${fieldName}`]);

    if (indexA === -1) return 1; // If not found in sortOrder, move to the end.
    if (indexB === -1) return -1; // If not found in sortOrder, move to the end.

    return indexA - indexB;
  });
