import React, { useCallback, useRef, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";
import { getFilteredItemsSelector } from "../../../state/slices/ui/listings";
import theme from "../../../style/theme";
import ResponsiveImage from "../../ResponsiveImage";
import Section from "../../Section";
import Filters from "./Filters";
import { getY, scrollTo, useScrollListener } from "../../useSmoothScrollbar";
import { isCurrentBreakpointMobile } from "../../../state/slices/layout";
import { VerticalLine } from "../../Lines";
import { Caption } from "../../Typography";
import UseInternalLinkUrl from "../../../hooks/useInternalLinkUrl";
import Link from "../../Link";
import isEmpty from "lodash/isEmpty";
import RichText from "../../RichText";
import { isProductsLoaded } from "../../../state/slices/data/products";
import { useLocation } from "@reach/router";
import { resolveGroupFireRatingLabel } from "../../../helpers/resolveGroupFireRatingLabel";

const ProductTile = ({ product }) => {
  const classes = useStyles();
  const link = UseInternalLinkUrl(product);
  const { title, category, soundProfile, groupFireRatings } = product;
  return (
    <Link to={link} className={classes.tile}>
      <ResponsiveImage
        image={product.image}
        aspect={1}
        className={classes.image}
        fadeIn
        fadeInDuration={0.5}
        showPreview={false}
      />
      <div className={classes.content}>
        <div>
          <div className={classes.tileTitle}>{title}</div>
          <Caption className={classes.tileCategory}>{category?.title}</Caption>
        </div>
        <div className={classes.metaData}>
          {soundProfile && <Caption>{soundProfile}</Caption>}
          {soundProfile && groupFireRatings && <Caption> | </Caption>}
          {groupFireRatings && (
            <Caption>{resolveGroupFireRatingLabel(groupFireRatings)}</Caption>
          )}
        </div>
      </div>
    </Link>
  );
};

export default function ProductListing({ slice, id }) {
  const { title, _rawEmptyResultsCopy } = slice;
  const classes = useStyles();
  const products = useSelector(getFilteredItemsSelector("products"));
  const loaded = useSelector(isProductsLoaded);
  const filtersRef = useRef();
  const containerRef = useRef();
  const lineRef = useRef();
  const { pathname } = useLocation();

  const localsRef = useRef({ pathname });
  const [filteredProducts, setFilteredProducts] = useState(products);

  const isMobile = useSelector(isCurrentBreakpointMobile);

  const onResize = (entries) => {
    localsRef.current.offsetTop = containerRef.current.offsetTop;
    localsRef.current.offsetBottom =
      containerRef.current.getBoundingClientRect().height -
      window.innerHeight +
      72;
  };

  useEffect(() => {
    const container = containerRef.current;
    const resizeObserver = new ResizeObserver(onResize);
    resizeObserver.observe(container);
    onResize();
    return () => {
      resizeObserver.unobserve(container);
    };
  }, []);

  const setFilterPosition = useCallback(
    ({ y }) => {
      if (filtersRef.current) {
        if (isMobile) {
          filtersRef.current.style.transform = "translate3d(0, 0px, 0)";
        } else {
          const { offsetTop } = localsRef.current;
          const translateY = Math.min(
            Math.max(0, y - offsetTop),
            localsRef.current.offsetBottom || 0
          );
          filtersRef.current.style.transform = `translate3d(0, ${translateY}px, 0)`;
        }
      }
    },
    [isMobile]
  );

  useEffect(() => {
    // We only change the filtered products when the path is the same as the original path
    if (localsRef.current.pathname === pathname) {
      setFilteredProducts(products);
      scrollTo(0, 0);
    }
  }, [products, pathname]);

  useScrollListener(setFilterPosition);
  useEffect(() => {
    setFilterPosition({ y: getY() });
  }, [setFilterPosition, products]);

  useEffect(() => {
    if (lineRef.current) {
      lineRef.current.getTimeline();
    }
  }, []);

  return (
    <Section
      grid
      ref={containerRef}
      className={classes.section}
      noBottomMargin
      id={id}
    >
      <div className={classes.filters} ref={filtersRef}>
        <h1 className={classes.title}>{title}</h1>
        <div>
          <Filters />
        </div>
        <VerticalLine
          animationOrigin="0%"
          className={classes.verticalLine}
          animatedWhenInView={false}
          ref={lineRef}
        />
      </div>
      <div className={classes.listContainer}>
        <div className={classes.list}>
          {filteredProducts &&
            filteredProducts.map((product) => (
              <ProductTile key={product.id} product={product} />
            ))}
          {loaded && isEmpty(filteredProducts) && (
            <RichText
              className={classes.emptyResults}
              blocks={_rawEmptyResultsCopy.text}
            />
          )}
        </div>
      </div>
    </Section>
  );
}

const useStyles = createUseStyles({
  section: {
    paddingBottom: theme.spacing(6),
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      minHeight: `calc(100vh - ${theme.header.height.large}px)`,
    },
  },
  filters: {
    gridColumn: "1 / span 4",
    padding: ["30vh", 0, theme.spacing(5)],
    [theme.breakpoints.up("md")]: {
      gridColumn: "1 / span 3",
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      top: 0,
      height: "calc(100vh - 46px)",
      width: "100%",
      marginTop: -26,
      padding: [theme.spacing(10), theme.gutter.md, theme.spacing(5), 0],
    },
  },
  title: {
    fontSize: 45,
    [theme.breakpoints.up("md")]: {
      fontSize: 65,
    },
  },
  verticalLine: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  listContainer: {
    gridColumn: "1 / span 4",
    [theme.breakpoints.up("md")]: {
      gridColumn: "4 / span 4",
    },
  },
  list: {
    [theme.breakpoints.up("md")]: {
      display: "grid",
      gridTemplateColumns: "repeat(4, 1fr)",
      columnGap: theme.gutter.md,
    },
  },
  tile: {
    position: "relative",
    display: "flex",
    padding: [theme.spacing(4), 0],
    textDecoration: "none",
    [theme.breakpoints.up("md")]: {
      gridColumn: "span 2",
    },
    "&:after": {
      transition: "opacity 0.1s ease-in-out",
      content: '""',
      display: "block",
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: theme.colors.border,
      opacity: 0.3,
      height: 1,
    },
    "&:hover:after": {
      opacity: 1,
    },
  },
  image: {
    width: "50%",
    flexGrow: 0,
    flexShrink: 0,
    [theme.breakpoints.up("md")]: {
      width: `calc(50% - ${theme.gutter.md / 2}px)`,
      gridColumn: "span 2",
    },
  },
  content: {
    marginLeft: theme.spacing(3),
    flexGrow: 1,
    flexShrink: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  tileTitle: {
    fontSize: 20,
  },
  tileCategory: {
    fontSize: 10,
    display: "block",
    opacity: 0.7,
  },
  metaData: {
    opacity: 0.5,
    display: "flex",
    alignItems: "center",
  },
  emptyResults: {
    gridColumn: "1 / span 4",
    width: "100%",
    fontSize: 20,
    paddingTop: theme.spacing(5),
  },
});
