import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import { Caption } from '../Typography'
import cn from 'classnames'
import { BUTTON_TRANSITION } from '../Button'

const ToolbarItem = forwardRef(({ className, containerClassName, grow, title, caption, button = false, invert, icon, children, ...props }, ref) => {
  const classes = useStyles()
  const Component = button ? 'button' : 'div'
  return (
    <div className={cn(classes.container, { grow }, containerClassName)} ref={ref}>
      {children}
      <Component className={cn(className, classes.toolbarItem, { button, invert })} {...props}>
        {caption && <Caption className={classes.caption}>{caption}</Caption>}
        <div className={classes.itemTitle}>
          {title && <span>{title}</span>}
          {icon}
        </div>
      </Component>
    </div>
  )
})

export default ToolbarItem

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    height: '100%',
    borderRight: [1, 'solid', theme.colors.border],
    '&:last-child': {
      borderRight: 'none'
    },
    '&.grow': {
      flexGrow: 1,
      flexShrink: 1
    }
  },
  toolbarItem: {
    position: 'relative',
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.colors.background,
    border: 'none',
    margin: 0,
    height: '100%',
    fontFamily: theme.fonts.body,
    fontSize: 12,
    textTransform: 'none',
    padding: [0, theme.gutter.sm],
    [theme.breakpoints.up('md')]: {
      padding: [0, theme.gutter.md]
    },
    '&.invert': {
      backgroundColor: theme.colors.text,
      color: theme.colors.background
    },
    '&.button': {
      cursor: 'pointer',
      transition: BUTTON_TRANSITION,
      '&:hover': {
        backgroundColor: theme.colors.text,
        color: theme.colors.background
      },
      '&.invert:hover': {
        backgroundColor: theme.colors.background,
        color: theme.colors.text
      }
    }
  },
  caption: {
    opacity: 0.5
  },
  itemTitle: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: theme.spacing(2)
    }
  }
})
