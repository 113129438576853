import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { closePanel, isPanelOpen } from "../../state/slices/drawer";
import { useGlobalContext } from "../SiteContext";
import Panel from "./Panel";
import { resolveInternalLinkUrl } from "../../helpers/resolveLink";
import AboutLists from "./AboutLists";

const SLUG = "about";

const sortByUpdatedAt = (pages) => {
  return pages.sort((a, b) => new Date(b._updatedAt) - new Date(a._updatedAt));
};

const AboutPanel = () => {
  const globals = useGlobalContext();
  const open = useSelector(isPanelOpen(SLUG));

  const { aboutLink, aboutList } = globals;

  const dispatch = useDispatch();
  const onClose = useCallback(() => {
    dispatch(closePanel({ slug: SLUG }));
  }, [dispatch]);

  const url = resolveInternalLinkUrl(aboutLink.reference);

  return (
    <Panel
      title={"See All"}
      titleCount={aboutList.length}
      titleLink={url}
      open={open}
      showCloseButton={open}
      breadcrumbLabel="Menu"
      breadcrumbOnClick={onClose}
      className="firstPanel"
      index={1}
    >
      {Boolean(aboutList?.length) && (
        <AboutLists
          aboutLink={aboutLink}
          aboutLists={sortByUpdatedAt(aboutList)}
        />
      )}
    </Panel>
  );
};

export default AboutPanel;
