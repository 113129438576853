import React, { useEffect, useRef } from "react";
import { primaryInput } from "detect-it";
import Hls from "hls.js";
import { createUseStyles } from "react-jss";
import theme from "../style/theme";

const HlsPlayer = ({ source, onReady, resolution, isLooping }) => {
  const classes = useStyles({ resolution: resolution ? resolution : "16/7" });
  const videoRef = useRef(null);
  const hlsRef = useRef(null);
  const localsRef = useRef({ loadedMetadata: false });
  // const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    const video = videoRef.current;

    if (source && video) {
      if (Hls.isSupported()) {
        hlsRef.current = new Hls({
          capLevelToPlayerSize: true,
          startLevel: primaryInput === "touch" ? undefined : 5,
        });

        hlsRef.current.loadSource(source);
        hlsRef.current.attachMedia(video);
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = source;
      }

      return () => {
        if (hlsRef.current) {
          hlsRef.current.detachMedia();
        }
      };
    }
  }, [source]);

  useEffect(() => {
    const video = videoRef.current;
    const handleMetadataLoaded = () => {
      localsRef.current.loadedMetadata = true;
      if (onReady) {
        onReady(video, hlsRef.current);
      }
    };

    if (video) {
      if (hlsRef.current) {
        hlsRef.current.on(Hls.Events.MANIFEST_PARSED, handleMetadataLoaded);
      } else {
        video.addEventListener("loadedmetadata", handleMetadataLoaded);
      }

      if (localsRef.current.loadedMetadata) {
        handleMetadataLoaded();
      }

      return () => {
        if (hlsRef.current) {
          hlsRef.current.off(Hls.Events.MANIFEST_PARSED, handleMetadataLoaded);
        } else {
          video.removeEventListener("loadedmetadata", handleMetadataLoaded);
        }
      };
    }
  }, [onReady]);

  useEffect(() => {
    const video = videoRef.current;

    const options = {
      root: null,
      rootMargin: "100px 0px -100px 0px",
      threshold: 0.35,
      triggerOnce: true,
    };

    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        // setIsIntersecting(true);
        if (video.paused) {
          video.play();
        }
      } else {
        // setIsIntersecting(false);
        if (!video.paused) {
          video.pause();
        }
      }
    }, options);

    if (video) {
      observer.observe(video);
      return () => {
        observer.unobserve(video);
      };
    }
  }, []);

  const videoStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "contain",
  };

  return (
    <div className={classes.sliceWrapper}>
      <div className={classes.videoWrap}>
        <video
          ref={videoRef}
          preload="metadatas"
          controls
          muted
          style={videoStyle}
          loop={isLooping}
        />
      </div>
    </div>
  );
};

export const HlsPlayerMemo = React.memo(HlsPlayer);

const useStyles = createUseStyles({
  sliceWrapper: {
    ...theme.gridStyle,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  videoWrap: {
    position: "relative",
    overflow: "hidden",
    gridColumn: "1 / -1",
    width: "100%",
    aspectRatio: ({ resolution }) => resolution,
  },
});
