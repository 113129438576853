import React from "react";
import { createUseStyles } from "react-jss";
import theme from "../../../style/theme";
import { Caption } from "../../Typography";
import TileGrid from "./TileGrid";
import Toolbar from "./Toolbar";

export default function ListingAbout({ slice }) {
  const { title, captions, _rawEmptyResultsCopy } = slice;
  const type = "about";
  const classes = useStyles();

  return (
    <>
      <section className={classes.section}>
        <header className={classes.header}>
          <h1 className={classes.title}>{title}</h1>
          {captions &&
            captions.map((c, i) => (
              <Caption className={classes.caption} key={i}>
                {c}
              </Caption>
            ))}
        </header>
        <Toolbar type={type} />
      </section>
      <TileGrid emptyCopy={_rawEmptyResultsCopy} type={type} />
    </>
  );
}

const useStyles = createUseStyles({
  section: {
    zIndex: 1,
    position: "relative",
  },
  header: {
    padding: [theme.spacing(46), theme.gutter.sm, theme.gutter.sm],
    zIndex: 1,
    position: "relative",
    backgroundColor: theme.colors.background,
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: theme.gutter.sm,
    [theme.breakpoints.up("md")]: {
      padding: [theme.spacing(46), theme.gutter.md, theme.gutter.md],
      gridTemplateColumns: "repeat(7, 1fr)",
      gap: theme.gutter.md,
    },
  },
  title: {
    lineHeight: 1,
    gridColumn: "1 / span 5",
    maxWidth: 500,
    margin: 0,
  },
  caption: {
    alignSelf: "end",
    whiteSpace: "pre-wrap",
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
});
