import React from "react";
import LogoImage from "../Footer/logo-footer.inline.svg";
import { createUseStyles } from "react-jss";
import theme from "../../style/theme";
import NewsletterForm from "../Footer/NewsletterForm";
import Section from "../Section";

const NewsLetterFormSlice = ({ slice, page }) => {
  const {
    newsletterPlaceholder,
    newsletterSuccessMessage,
    newsletterTitle,
    cols = "1 / span 2",
  } = slice;

  const classes = useStyles({ cols });

  return (
    <Section grid className={classes.section}>
      <div className={classes.logoNewsletterContainer}>
        <LogoImage className={classes.logo} />
        <NewsletterForm
          title={newsletterTitle}
          placeholder={newsletterPlaceholder}
          successMessage={newsletterSuccessMessage}
        />
      </div>
    </Section>
  );
};
const useStyles = createUseStyles({
  section: {
    position: "relative",
  },
  logoNewsletterContainer: {
    gridColumn: `1 / span 4`,
    gridRow: 1,
    [theme.breakpoints.up("md")]: {
      gridColumn: ({ cols }) => cols,
      paddingRight: theme.gutter.md,
    },
  },
  logo: {
    width: 64,
    height: 64,
    marginBottom: theme.spacing(10),
  },
  titleLine: {
    position: "relative",
    gridColumn: "1 / span 4",
    gridRow: 2,
    [theme.breakpoints.up("md")]: {
      gridColumn: "1 / span 7",
    },
  },
});
export default NewsLetterFormSlice;
