import { fetchProducts } from '../../slices/data/lists'
import { rehydrated } from '../../slices/layout'
import {
  addItem,
  removeItem,
  clearItems,
  loadCartFromStorage,
  getCartItems
} from '../../slices/ui/cart'

const KEY = 'decor-systems-cart-v1'

const cart = (store) => (next) => (action) => {
  const result = next(action)
  if (action.type === rehydrated.toString()) {
    store.dispatch(fetchProducts())
    const cartData = window.sessionStorage.getItem(KEY)
    if (cartData) {
      const payload = JSON.parse(cartData)
      store.dispatch(loadCartFromStorage(payload))
    }
  }

  if (action.type === addItem.toString() ||
  action.type === removeItem.toString() ||
  action.type === clearItems.toString()) {
    const items = getCartItems(store.getState())
    window.sessionStorage.setItem(KEY, JSON.stringify(items))
  }

  return result
}

export default cart
