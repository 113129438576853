import React from "react";
import { createUseStyles } from "react-jss";
import { Caption } from "../../Typography";
import ResponsiveImage from "../../ResponsiveImage";
import cn from "classnames";
import theme from "../../../style/theme";
import { HorizontalLine, VerticalLine } from "../../Lines";
import UseInternalLinkUrl from "../../../hooks/useInternalLinkUrl";
import Link from "../../Link";
import Button from "../../Button";
import Placeholder from "../../Placeholder";
import first from "lodash/first";
import { toSentenceCase, formatDate } from "../../../helpers/format";

export default function Tile({
  item = {},
  placeholder,
  index,
  showBottomLine,
}) {
  const {
    _type,
    title,
    image,
    category,
    products,
    summary,
    post_date: postDate,
  } = item;
  const product = first(products);
  const classes = useStyles();
  const url = UseInternalLinkUrl(item);

  return (
    <>
      <Link
        to={url}
        className={cn(classes.tile, _type, {
          large: index % 6 === 0 || (index - 5) % 6 === 0,
          noPadding: (index - 2) % 3 === 0,
        })}
      >
        <div className={classes.imageContainer}>
          {image && (
            <ResponsiveImage
              key={index}
              image={image}
              className={classes.image}
              showPreview={false}
              fadeIn
              fadeInDelay={index * 0.1}
            />
          )}
          {placeholder && <Placeholder fill />}
        </div>
        <div className={classes.tileContent}>
          <div className={classes.titleContainer}>
            <div className={classes.titleFieldset}>
              <Caption className={classes.titleCaption}>
                {_type === "page" ? "Article" : toSentenceCase(_type)}
              </Caption>
              <span className={classes.title}>
                {title}
                {placeholder && <Placeholder />}
              </span>
            </div>
            <div className={classes.productFieldset}>
              <Caption className={classes.titleCaption}>Product</Caption>
              <span className={classes.title}>{product?.title}</span>
            </div>
            <div className={classes.arrowFieldset}>
              <Button onClick={null} />
            </div>
          </div>
          <div className={classes.summaryContainer}>
            {summary && <p className={classes.summary}>{summary}</p>}
          </div>
          <div className={classes.meta}>
            <Caption className={classes.caption}>{category?.title}</Caption>
            {product?.title && (
              <Caption className={classes.caption}>{product?.title}</Caption>
            )}
            {product?.soundProfile && (
              <Caption className={classes.caption}>
                {product?.soundProfile}
              </Caption>
            )}
            {postDate && (
              <Caption className={classes.dateCaption}>
                {formatDate(postDate)}
              </Caption>
            )}
            {placeholder && <Placeholder />}
            {placeholder && <Placeholder />}
            {placeholder && <Placeholder />}
          </div>
        </div>
        {(index - 2) % 3 !== 0 && (
          <div className={classes.verticalLine}>
            <VerticalLine position="right" animationOrigin="0%" />
          </div>
        )}
        <HorizontalLine
          extend
          className={classes.mobileLine}
          position="bottom"
        />
      </Link>
      {(showBottomLine || (index - 2) % 3 === 0) && (
        <div className={classes.bottomLine}>
          <HorizontalLine extend />
        </div>
      )}
    </>
  );
}

const useStyles = createUseStyles({
  tile: {
    position: "relative",
    display: "flex",
    flexDirection: "column-reverse",
    gridColumn: "span 4",
    textDecoration: "none",
    padding: [0, 0, theme.gutter.sm, 0],
    "&.page": {
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
      gridColumn: "span 2",
      padding: [0, theme.gutter.md, 0, 0],
    },
    "&.large": {
      gridColumn: "span 4",
      [theme.breakpoints.up("md")]: {
        gridColumn: "span 3",
      },
    },
    "&.noPadding": {
      [theme.breakpoints.up("md")]: {
        paddingRight: 0,
      },
    },
  },
  mobileLine: {
    display: "block",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  tileContent: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  imageContainer: {
    width: "100%",
    height: "64vw",
    marginBottom: 0,
    position: "relative",
    "$tile.page &": {
      marginBottom: theme.gutter.sm,
    },
    [theme.breakpoints.up("md")]: {
      height: "26vw",
      marginBottom: theme.gutter.md,
    },
  },
  image: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  bottomLine: {
    position: "relative",
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
      gridColumn: "span 7",
    },
  },
  verticalLine: {
    position: "absolute",
    right: 0,
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
      top: -theme.gutter.md,
      bottom: -theme.gutter.md,
    },
  },
  caption: {
    opacity: 0.5,
  },
  titleCaption: {
    composes: ["$caption"],
    "$tile.page &": {
      display: "none",
    },
  },
  dateCaption: {
    composes: ["$caption"],
    [theme.breakpoints.down("md")]: {
      gridColumn: "3 / span 2",
    },
  },
  title: {
    lineHeight: 1.4,
    fontSize: 12,
    "$tile.page &": {
      fontSize: 18,
      [theme.breakpoints.up("md")]: {
        fontSize: 20,
      },
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 20,
    },
  },
  meta: {
    display: "none",
    justifyContent: "space-between",
    "$tile.page &": {
      [theme.breakpoints.down("md")]: {
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        gap: theme.gutter.sm,
      },
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      maxWidth: theme.span(2, "md"),
    },
  },
  loadMoreButton: {
    gridColumn: "1 / span 4",
    justifyContent: "center",
    padding: theme.gutter.sm,
    [theme.breakpoints.up("md")]: {
      gridColumn: "1 / span 7",
      padding: theme.gutter.md,
    },
  },
  titleContainer: {
    display: "grid",
    gap: theme.gutter.sm,
    gridTemplateColumns: "repeat(4, 1fr)",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  titleFieldset: {
    display: "flex",
    flexDirection: "column",
    gridColumn: "span 2",
    "$tile.page &": {
      gridColumn: "span 4",
    },
  },
  productFieldset: {
    display: "flex",
    flexDirection: "column",
    "$tile.page &": {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  arrowFieldset: {
    display: "block",
    justifySelf: "end",
    alignSelf: "end",
    "& button": {
      padding: [4, 8],
    },
    "$tile.page &": {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  summaryContainer: {
    flexGrow: 1,
  },
  summary: {
    lineHeight: 1.4,
    height: "4.2em",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    fontSize: 12,
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
    [theme.breakpoints.up("md")]: {
      maxWidth: theme.span(2, "md"),
    },
  },
});
