import { createSelector, createSlice } from '@reduxjs/toolkit'
import map from 'lodash/map'
import find from 'lodash/find'
import { faces } from '../../style/fonts'

const preloadSlice = createSlice({
  name: 'preload',
  initialState: {
    pageLoading: false,
    slicesLoading: {},
    fontsLoading: map(faces, ({ fontFamily, fontWeight }) => ({
      fontFamily: fontFamily.replace(/"/g, ''),
      fontWeight,
      loading: true
    }))
  },
  reducers: {
    pagePreloaded: (state, action) => {
      state.pageLoading = false
    },
    slicePreloaded: (state, action) => {
      state.slicesLoading[action.payload.slice.id] = false
    },
    fontPreloaded: (state, { payload }) => {
      const font = state.fontsLoading.find(x => x.fontFamily === payload.fontFamily && x.fontWeight === payload.fontWeight)
      font.loading = false
    }
  }
})

export const {
  pagePreloaded,
  slicePreloaded,
  fontPreloaded
} = preloadSlice.actions

export const getFontsLoading = state => state.preload.fontsLoading

export const isPreloading = createSelector(
  state => state.preload.pageLoading,
  state => state.preload.slicesLoading,
  getFontsLoading,
  (pageLoading, slicesLoading, fontsLoading) => {
    return pageLoading || !!find(slicesLoading, x => x) || !!find(fontsLoading, x => x.loading)
  }
)

export default preloadSlice.reducer
