import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import { HorizontalLine } from '../Lines'
import Section from '../Section'
import SliceHeader from '../SliceHeader'
import Tile from './Listing/Tile'

export default function RelatedPages ({ slice, id }) {
  const { title, cta, ctaLabel, pages } = slice
  const classes = useStyles()
  return (
    <Section className={classes.section} grid fullWidth id={id}>
      <SliceHeader
        title={title}
        cta={cta}
        ctaLabel={ctaLabel}
        className={classes.header}
        ctaAlignment='end'
        classNames={{
          title: classes.headerTitle
        }}
      />
      <div className={classes.titleLine}>
        <HorizontalLine position='bottom' />
      </div>
      <Section tag='div' grid className={classes.container} noBottomMargin>
        {pages && pages.map((page, i) => (
          <Tile key={i} item={page} index={i} showBottomLine={(pages.length === 2 && i === 1) || (pages.length === 1 && i === 0)} />
        ))}
      </Section>
    </Section>
  )
}

const useStyles = createUseStyles({
  section: {
    position: 'relative'
  },
  header: {
    gridColumn: '1 / span 4',
    marginBottom: theme.gutter.sm,
    gridRow: 1,
    [theme.breakpoints.up('md')]: {
      gridColumn: '1 / span 7',
      marginBottom: theme.gutter.md
    }
  },
  headerTitle: {
    maxWidth: 540
  },
  titleLine: {
    position: 'relative',
    gridColumn: '1 / span 4',
    gridRow: 2,
    [theme.breakpoints.up('md')]: {
      gridColumn: '1 / span 7'
    }
  },
  container: {
    gridColumn: '1 / span 4',
    gridRow: 3,
    [theme.breakpoints.up('md')]: {
      gridColumn: '1 / span 7'
    }
  }
})
