import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import { HorizontalLine } from '../Lines'
import RichText from '../RichText'
import Section from '../Section'
import cn from 'classnames'

export default function RichTextSlice ({ slice, page, id }) {
  const classes = useStyles()
  const { _rawText, title } = slice

  return (
    <Section grid className={classes.section} id={id}>
      {title && <h2 className={classes.title}>{title}</h2>}
      <div className={cn(classes.text, page._type, (page._type === 'product') && 'row2')}>
        {_rawText && <RichText blocks={_rawText.text} offsetColumn={page._type === 'page' || page._type === 'project' ? '4' : '5'} />}
      </div>
      <HorizontalLine extend position='bottom' />
    </Section>
  )
}

const useStyles = createUseStyles({
  section: {
    position: 'relative'
  },
  title: {
    gridColumn: '1 / span 4',
    lineHeight: 1,
    marginBottom: theme.spacing(10),
    [theme.breakpoints.up('md')]: {
      gridColumn: '1 / span 3',
      marginBottom: theme.spacing(30)
    }
  },
  text: {
    gridColumn: '1 / span 4',
    marginBottom: theme.section.marginBottom.sm,
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.section.marginBottom.md,
      gridColumn: '5 / span 2',
      '&.row2': {
        gridRow: 2
      }
    },
    '&.project': {
      gridColumn: '1 / span 4',
      [theme.breakpoints.up('md')]: {
        gridColumn: '4 / span 2'
      }
    },
    '&.page': {
      gridColumn: '1 / span 4',
      [theme.breakpoints.up('md')]: {
        gridColumn: '4 / span 2'
      }
    }
  }
})
