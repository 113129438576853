
import { createSlice } from '@reduxjs/toolkit'

const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    open: false
  },
  reducers: {
    togglePopup: (state) => {
      state.open = !state.open
    },
    openPopup: (state) => {
      state.open = true
    },
    closePopup: (state) => {
      state.open = false
    },
    setOpenState: (state, { payload }) => {
      state.open = payload
    }
  }
})

export const {
  togglePopup,
  openPopup,
  closePopup,
  setOpenState
} = chatSlice.actions

export const isChatPopupOpen = state => state.ui.chat.open

export default chatSlice.reducer
