import inDOM from 'dom-helpers/canUseDOM'
import { current, values } from '../../style/breakpoints'
import { breakpointChanged, getCurrentBreakpoint, getPreviousBreakpoint, rehydrated } from '../slices/layout'
import FontFaceObserver from 'fontfaceobserver'
import { fontPreloaded, getFontsLoading } from '../slices/preload'
import forEach from 'lodash/forEach'
import { closePanel, isPanelOpen, slugs } from '../slices/drawer'

const layout = (store) => {
  const preloadFonts = (store) => {
    const fonts = getFontsLoading(store.getState())
    forEach(fonts, ({ fontFamily, fontWeight }) => {
      const loader = new FontFaceObserver(fontFamily, { fontWeight })
      const onLoaded = () => {
        store.dispatch(fontPreloaded({ fontFamily, fontWeight }))
      }
      loader.load().then(onLoaded).catch(onLoaded)
    })
  }

  const check = () => {
    const bp = current()
    const state = store.getState()
    const prev = getCurrentBreakpoint(state)
    if (bp !== prev) {
      store.dispatch(breakpointChanged(bp))
    }
  }
  if (inDOM) {
    window.addEventListener('resize', check)
  }

  return next => (action) => {
    const ret = next(action)
    if (action.type === rehydrated.toString()) {
      preloadFonts(store)
      check()
    }

    if (action.type === breakpointChanged.toString()) {
      const breakpoint = action.payload
      const previousBreakpoint = getPreviousBreakpoint(store.getState())
      if (previousBreakpoint) {
        const fromMobileToDesktop = values[previousBreakpoint] < values.md && values[breakpoint] >= values.md
        const fromDesktopToMobile = values[previousBreakpoint] > values.md && values[breakpoint] <= values.md
        if (fromMobileToDesktop || fromDesktopToMobile) {
          forEach(slugs, slug => {
            if (isPanelOpen(slug)(store.getState())) {
              store.dispatch(closePanel({ slug }))
            }
          })
        }
      }
    }

    return ret
  }
}

export default layout
