import React, { useRef, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import gsap from 'gsap'
import { usePageContext } from '../../SiteContext'
import theme from '../../../style/theme'
import get from 'lodash/get'

const toRadians = (degrees) => {
  return degrees * (Math.PI / 180)
}

const trigOpposite = (degrees, hyp) => {
  return Math.sin(toRadians(degrees)) * hyp
}

const trigAdjacent = (degrees, hyp) => {
  return Math.cos(toRadians(degrees)) * hyp
}

const Point = ({ foreground, background, index, selected, angle, outerRadius, padding, radius = 10 }) => {
  const classes = useStyles()
  const ref = useRef()
  const textRef = useRef()
  const degrees = angle * index
  const x = trigOpposite(degrees, outerRadius)
  const y = -trigAdjacent(degrees, outerRadius)
  useEffect(() => {
    if (ref.current) {
      gsap.to(ref.current, { scale: selected ? 2.5 : 1, transformOrigin: '50% 50%', fill: selected ? foreground : background })
      gsap.to(textRef.current, { opacity: selected ? 1 : 0, color: background })
    }
  }, [selected, foreground, background])
  // console.log('i', index, degrees, toRadians(degrees), x, y)
  return (
    <>
      <circle ref={ref} cx={x + padding} cy={y + padding} r={radius} fill='none' stroke='currentColor' className={cn(classes.point, { selected })} />
      <text ref={textRef} x={x + padding - 8} y={y + padding + 10} className={classes.text}>{index + 1}</text>
    </>
  )
}

export default function Graph ({ className, steps, selectedIndex = 0 }) {
  const classes = useStyles()
  const anglePerStep = 360 / steps.length
  const progressRef = useRef()
  const padding = 40
  const radius = 360
  const viewBounds = radius + padding
  const page = usePageContext()
  const { foreground, background } = theme.colors.pageTheme[get(page, ['color', 'title'], page.title ? 'White' : 'Brown')]
  const circumference = 2 * Math.PI * radius

  useEffect(() => {
    if (progressRef.current) {
      const offset = circumference - (circumference * (selectedIndex / steps.length))
      gsap.set(progressRef.current, { rotation: -90, transformOrigin: 'center' })
      gsap.to(progressRef.current, { strokeDashoffset: offset, ease: 'expo.out', duration: 1.8 })
    }
  }, [circumference, selectedIndex, steps.length])

  return (
    <svg className={className} viewBox={`-${viewBounds} -${viewBounds} ${viewBounds * 2} ${viewBounds * 2}`} xmlns='http://www.w3.org/2000/svg'>
      <circle cx={0} cy={0} r={radius} fill='none' stroke='currentColor' className={classes.outline} strokeWidth={4} />
      <circle
        ref={progressRef}
        cx={0}
        cy={0}
        r={radius}
        fill='none'
        stroke='currentColor'
        className={classes.progress}
        strokeWidth={4}
        strokeDasharray={circumference}
        strokeDashoffset={circumference}
      />
      {steps.map((_, index) => (
        <Point
          index={index}
          selected={selectedIndex === index}
          angle={anglePerStep}
          key={index}
          padding={0}
          outerRadius={radius}
          foreground={foreground}
          background={background}
          radius={15}
        />
      ))}
    </svg>
  )
}

const useStyles = createUseStyles({
  outline: {
    opacity: 0.1
  },
  progress: {},
  point: {
    // fill: 'none'
    // '&.selected': {
    //   fill: 'currentColor',
    //   transform: 'scale(1)'
    // }
  },
  text: {
    opacity: 0,
    font: `bold 28px ${theme.fonts.body}`,
    pointerEvents: 'none'
  }
})

/* <svg width="379" height="372" viewBox="0 0 379 372" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M190.777 10.7087C254.265 10.7087 310.099 43.4738 342.294 93.0168" stroke="#CFCFB0"/>
<path opacity="0.1" d="M370.842 191.276C370.842 290.724 290.223 371.343 190.774 371.343C91.3259 371.343 10.707 290.724 10.707 191.276C10.707 91.8276 91.3259 11.2087 190.774 11.2087C254.085 11.2087 309.766 43.8822 341.872 93.2893C360.197 121.489 370.842 155.138 370.842 191.276Z" stroke="#CFCFB0"/>
<circle cx="341.356" cy="93.0168" r="21.0556" fill="#CFCFB0"/>
<path d="M337.118 98.5H344.618V97.165H338.888L338.918 97.045C339.338 95.935 340.793 95.125 341.903 94.45C343.508 93.535 344.813 92.845 344.813 90.865C344.813 88.975 343.403 87.58 341.213 87.58C340.043 87.58 339.023 87.94 338.363 88.645C337.718 89.305 337.373 90.16 337.373 91.36H338.858C338.858 90.46 339.038 89.875 339.458 89.44C339.848 89.005 340.373 88.78 341.093 88.78C342.458 88.78 343.313 89.635 343.313 90.895C343.313 92.125 342.533 92.665 341.198 93.43C340.178 94.045 339.068 94.66 338.258 95.56C337.613 96.295 337.178 97.21 337.118 98.5Z" fill="#3A3A1F"/>
<circle cx="369.43" cy="190" r="9.07071" fill="#3A3A1F" stroke="#CFCFB0"/>
<circle cx="346.461" cy="284.431" r="9.07071" fill="#3A3A1F" stroke="#CFCFB0"/>
<circle cx="235.438" cy="362.273" r="9.07071" fill="#3A3A1F" stroke="#CFCFB0"/>
<circle cx="112.934" cy="353.34" r="9.07071" fill="#3A3A1F" stroke="#CFCFB0"/>
<circle cx="40.1957" cy="294.64" r="9.07071" fill="#3A3A1F" stroke="#CFCFB0"/>
<circle cx="9.57071" cy="170.859" r="9.07071" fill="#3A3A1F" stroke="#CFCFB0"/>
<circle cx="70.8246" cy="61.1145" r="9.07071" fill="#3A3A1F" stroke="#CFCFB0"/>
</svg> */
