import React from "react";
import { createUseStyles } from "react-jss";
import theme from "../../style/theme";
import { HorizontalLine } from "../Lines";
import Section from "../Section";
import SliceTitle from "./SliceTitle";
import Link from "../Link";
import DocumentIcon from "./document_icon.inline.svg";
import { Caption } from "../Typography";
import { formatDate } from "../../helpers/format";
import DownloadIcon from "../Drawer/download.inline.svg";
import get from "lodash/get";
import { formatBytes } from "../../helpers/formatBytes";

const DocumentItem = ({ document }) => {
  const classes = useStyles();
  const { title, fileInfo, date } = document;
  const asset = get(document, ["file", "asset"]);
  const { size, mimeType, url } = asset || {};

  if (!asset) {
    console.warn(`The asset is missing from the document ${title}`);
  }

  return (
    <li className={classes.item}>
      <Link to={url} className={classes.link} target="_blank">
        <DocumentIcon className={classes.icon} />
        <div className={classes.documentTitle}>
          {title}
          {asset && (
            <Caption className={classes.fileInfo}>
              {formatDate(date)} | {formatBytes(size)} {fileInfo || mimeType}
            </Caption>
          )}
        </div>
        <DownloadIcon className={classes.downloadIcon} />
        <HorizontalLine
          position="bottom"
          className={classes.linkLink}
          animationOrigin="0%"
        />
      </Link>
    </li>
  );
};

export default function ProductDocumentsList({ slice, page, id }) {
  const classes = useStyles();
  const { documents } = page;
  const { title } = slice;
  return (
    <Section grid className={classes.section} id={id}>
      <SliceTitle className={classes.title}>{title}</SliceTitle>
      <div className={classes.content}>
        <ul className={classes.list}>
          {documents &&
            documents.map((document, i) => (
              <DocumentItem key={i} document={document} />
            ))}
        </ul>
      </div>
      <HorizontalLine extend position="bottom" />
    </Section>
  );
}

const useStyles = createUseStyles({
  section: {
    position: "relative",
  },
  title: {
    gridColumn: "1 / span 4",
    [theme.breakpoints.up("md")]: {
      gridColumn: "1 / span 7",
    },
  },
  content: {
    marginBottom: theme.section.marginBottom.sm,
    gridColumn: "1 / span 4",
    [theme.breakpoints.up("md")]: {
      gridColumn: "5 / span 2",
      gridRow: 2,
      marginBottom: theme.section.marginBottom.md,
    },
  },
  list: {
    margin: 0,
    padding: 0,
    listStyle: "none",
  },
  item: {
    "&:last-child > $link": {
      borderBottomWidth: 0,
      marginBottom: 0,
    },
  },
  link: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    position: "relative",
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(3),
    "&:hover $linkLink": {
      opacity: 1,
    },
  },
  linkLink: {
    transition: "opacity 0.15s ease-in-out",
    opacity: 0.3,
  },
  fileInfo: {
    display: "block",
  },
  documentTitle: {
    flexGrow: 1,
    flexShrink: 1,
  },
  icon: {
    flexGrow: 0,
    flexShrink: 0,
    marginRight: theme.spacing(4),
    "& > path": {
      fill: theme.colors.background,
    },
  },
  downloadIcon: {
    flexGrow: 0,
    flexShrink: 0,
    width: 14,
    height: 16,
  },
});
