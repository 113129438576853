import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../style/theme'

let ErrorSlice

if (process.env.NODE_ENV === 'production') {
  ErrorSlice = () => null
}

if (process.env.NODE_ENV !== 'production') {
  ErrorSlice = ({ message, details }) => {
    const classes = useStyles()
    return (
      <div className={classes.errorSlice}>
        <h2 className={classes.title}>Error rendering slice</h2>
        {details
          ? (
            <details className={classes.details}>
              <summary><code>{message}</code></summary>
              <pre>{details}</pre>
            </details>
          )
          : <code>{message}</code>}
      </div>
    )
  }

  const useStyles = createUseStyles({
    errorSlice: {
      border: [1, 'solid', theme.colors.border],
      backgroundColor: '#f0f',
      color: theme.colors.white,
      padding: 40
    },
    details: {
      cursor: 'pointer'
    },
    title: {
      animation: '0.6s linear infinite $blink'
    },
    '@keyframes blink': {
      '0%': {
        color: theme.colors.black
      },
      '50%': {
        color: theme.colors.black
      },
      '50.01%': {
        color: 'inherit'
      },
      '100%': {
        color: 'inherit'
      }
    }
  }, { name: 'ErrorSlice' })
}

class ErrorBoundary extends React.Component {
  state = { errorMessage: null, errorDetail: null }

  static getDerivedStateFromError (error) {
    return { errorMessage: error.message }
  }

  componentDidCatch (error, errorInfo) {
    console.error('Error rendering slice', error, errorInfo)
    this.setState({
      errorMessage: error.message,
      errorDetail: errorInfo.componentStack
    })
  }

  render () {
    const { children } = this.props
    const { errorMessage, errorDetail } = this.state

    if (!errorMessage) {
      return children
    }

    return <ErrorSlice message={errorMessage} details={errorDetail} />
  }
}

export default ErrorBoundary
