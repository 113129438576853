import React, { useMemo, forwardRef, useCallback, useRef, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import Link from './Link'
import theme from '../style/theme'
import isArray from 'lodash/isArray'
import { resolveLink } from '../helpers/resolveLink'
import { primaryInput } from 'detect-it'
import get from 'lodash/get'
import gsap from 'gsap'
import useComposeRefs from '../helpers/composeRefs'

export const Arrow = ({ className }) => {
  return (
    <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 8'>
      <polyline vectorEffect='non-scaling-stroke' points='12.8,0.6 16.1,4 12.8,7.3' stroke='currentColor' fill='transparent' />
      <line vectorEffect='non-scaling-stroke' x1='0' y1='4' x2='16.1' y2='4' stroke='currentColor' />
    </svg>
  )
}

const Button = forwardRef(({ className, tag, disabled, iconClassName, to, link, onClick, children, backArrow, showArrow = true, ...rest }, ref) => {
  const classes = useStyles()
  const buttonRef = useRef()
  const cloneRef = useRef()
  const cloneContentRef = useRef()
  const localsRef = useRef({})

  const resolvedLink = useMemo(() => {
    if (to) return { url: to }
    if (link) {
      return resolveLink(isArray(link) ? link[0] : link)
    }
  }, [link, to])

  const onMouseEnter = useCallback(() => {
    if (localsRef.current.timeline) localsRef.current.timeline.kill()
    const icons = buttonRef.current.querySelectorAll(`.${classes.icon}`)

    const timeline = gsap.timeline()
    timeline.fromTo(cloneRef.current, { y: '110%' }, { y: '0%', duration: 0.6, ease: 'expo.out', force3D: false })
    timeline.fromTo(cloneContentRef.current, { y: '-110%' }, { y: '0%', duration: 0.6, ease: 'expo.out', force3D: false }, 0)
    localsRef.current.timeline = timeline

    const arrowTimeline = gsap.timeline()
    arrowTimeline.fromTo(icons, { x: '0%' }, { x: backArrow ? '-100%' : '100%', duration: 0.25, ease: 'sine.out', force3D: false }, 0)
    arrowTimeline.fromTo(icons, { x: backArrow ? '100%' : '-100%' }, { x: '0%', duration: 0.25, ease: 'sine.out', force3D: false }, 0.25)
  }, [classes, backArrow])

  const onMouseLeave = useCallback(() => {
    if (localsRef.current.timeline) localsRef.current.timeline.kill()
    const timeline = gsap.timeline()
    timeline.to(cloneRef.current, { y: '-110%', duration: 0.35, ease: 'expo.out', force3D: false })
    timeline.to(cloneContentRef.current, { y: '110%', duration: 0.35, ease: 'expo.out', force3D: false }, 0)
    localsRef.current.timeline = timeline
  }, [])

  useEffect(() => {
    if (disabled) onMouseLeave()
  }, [onMouseLeave, disabled])

  const Tag = tag || (resolveLink ? Link : 'button')

  const props = {
    onClick,
    className: cn(className, classes.button),
    ref: useComposeRefs(ref, buttonRef),
    onMouseLeave: primaryInput === 'touch' ? null : onMouseLeave,
    onMouseEnter: primaryInput === 'touch' ? null : onMouseEnter,
    disabled,
    ...rest
  }

  if (resolveLink) {
    props.to = get(resolvedLink, ['url'])
  }

  const text = get(resolvedLink, ['text'])

  return (
    <Tag {...props}>
      {showArrow && backArrow && (
        <span className={cn(classes.iconContainer, iconClassName, { backArrow })}>
          <Arrow className={cn(classes.icon, { backArrow })} />
        </span>
      )}
      {children || text}
      {showArrow && !backArrow && (
        <span className={cn(classes.iconContainer, iconClassName)}>
          <Arrow className={cn(classes.icon)} />
        </span>
      )}
      <div ref={cloneRef} className={classes.clone} aria-hidden='true'>
        <span ref={cloneContentRef} className={classes.cloneContent}>
          {showArrow && backArrow && (
            <span className={cn(classes.iconContainer, iconClassName, { backArrow })}>
              <Arrow className={cn(classes.icon, { backArrow })} />
            </span>
          )}
          {children || text}
          {showArrow && !backArrow && (
            <span className={cn(classes.iconContainer, iconClassName)}>
              <Arrow className={classes.icon} />
            </span>
          )}
        </span>
      </div>
    </Tag>
  )
})

export default Button

export const BUTTON_TRANSITION = 'color 0.15s ease-in-out, background-color 0.15s ease-in-out'

const useStyles = createUseStyles({
  button: {
    padding: [theme.spacing(1.5), theme.spacing(3)],
    margin: 0,
    display: 'inline-flex',
    color: 'currentColor',
    border: [1, 'solid', theme.colors.text],
    borderRadius: 80,
    textTransform: 'uppercase',
    fontSize: 10,
    lineHeight: 1.6,
    letterSpacing: 0.5,
    background: 'transparent',
    textDecoration: 'none',
    alignItems: 'center',
    cursor: 'pointer',
    transition: BUTTON_TRANSITION,
    overflow: 'hidden',
    position: 'relative',
    justifyContent: 'center',
    ...(primaryInput !== 'touch'
      ? {}
      : {
          '&:hover, &:active': {
            color: theme.colors.background,
            backgroundColor: theme.colors.text
          }
        }
    ),
    '&:disabled': {
      opacity: 0.5,
      color: 'currentColor',
      background: 'transparent',
      cursor: 'inherit'
    }
  },
  clone: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.colors.text,
    color: theme.colors.background,
    overflow: 'hidden',
    transform: 'translateY(110%)',
    '$button.selected &': {
      transform: 'translateY(0%)'
    }
  },
  cloneContent: {
    padding: [theme.spacing(1.5), theme.spacing(3)],
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'translateY(-110%)',
    '$button.selected &': {
      transform: 'translateY(0%)'
    }
  },
  icon: {
    display: 'block',
    width: 18,
    '&.backArrow': {
      transform: 'rotate(180deg)'
    }
  },
  iconContainer: {
    marginLeft: theme.spacing(1),
    marginTop: 1,
    display: 'block',
    overflow: 'hidden',
    '&.backArrow': {
      marginRight: theme.spacing(1),
      marginLeft: 0
    }
  }
})
