
function replaceAllExceptFirst(str, search, replace) {
    return str
      .split(search)
      .reduce((prev, curr, i) => prev + (i === 1 ? search : replace) + curr);
  }

function replaceLast(str, search, replace) {
    const lastIndex = str.lastIndexOf(search);
    return str.substring(0, lastIndex) + replace + str.substring(lastIndex + 1); 
};

// Concatenates array of group fire ratings into a comma seperated list where the last two values in the array are seperate by 'or'
export const resolveGroupFireRatingLabel = (groupFireRatings) => {
    if(!groupFireRatings || groupFireRatings.length === 0) return '-'
    if(groupFireRatings.length === 1) return groupFireRatings[0]
    let ratingLabel = groupFireRatings.join(',')
    ratingLabel = replaceAllExceptFirst(ratingLabel, 'Group', '')
    return replaceLast(ratingLabel, ',', ' or ')
}