import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import { HorizontalLine } from '../Lines'
import RichText from '../RichText'
import Section from '../Section'
import SliceHeader from '../SliceHeader'

export default function Contact ({ slice }) {
  const classes = useStyles()
  const { title, _rawCopy, cta, ctaLabel, locations } = slice
  return (
    <section className={classes.section}>
      <SliceHeader title={title} rawCopy={_rawCopy} cta={cta} ctaLabel={ctaLabel} />
      <Section tag='div' grid>
        {locations && locations.map(({ locationState, locations }) => {
          return (
            <div key={locationState} className={classes.locations}>
              <HorizontalLine position='top' className={classes.line} animationOrigin='0%' />
              <h4 className={classes.locationTitle}>{locationState}</h4>
              <div className={classes.contacts}>
                {locations.map(({ _rawText }, i) => (
                  <div key={i} className={classes.locationDetails}>
                    {i > 0 && <HorizontalLine position='top' className={classes.line} animationOrigin='0%' />}
                    <RichText blocks={_rawText} className={classes.locationText} />
                  </div>
                ))}
              </div>
            </div>
          )
        })}
      </Section>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    marginBottom: -theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      marginBottom: -theme.spacing(20)
    }
  },
  locations: {
    marginBottom: theme.spacing(5),
    position: 'relative',
    gridColumn: 'span 4',
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(20),
      gridColumn: 'span 2'
    }
  },
  line: {
    opacity: 0.3
  },
  locationTitle: {
    fontSize: 15,
    fontFamily: theme.fonts.body,
    fontWeight: 'normal',
    textTransform: 'none',
    padding: [theme.spacing(5), 0],
    margin: 0
    // borderTop: [1, 'solid', theme.colors.border]
  },
  contacts: {
    position: 'relative'
  },
  locationDetails: {
    position: 'relative',
    '&:not(:first-child)': {
      marginTop: theme.spacing(5),
      paddingTop: theme.spacing(5)
    }
  },
  locationText: {
    opacity: 0.7,
    '& p, & a, & ul, & ol': {
      fontFamily: theme.fonts.body,
      textTransform: 'uppercase',
      fontSize: 12,
      lineHeight: 1.6,
      textDecoration: 'none',
      '&:not(:last-child)': {
        marginBottom: theme.spacing(5)
      }
    },
    '& a:hover': {
      textDecoration: 'underline'
    }
  }
})
