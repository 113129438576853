/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import selectText from '../../helpers/selectText'
import theme from '../../style/theme'
import { HorizontalLine } from '../Lines'
import ResponsiveImage from '../ResponsiveImage'
import Section from '../Section'
import SliderWithPagination from '../SliderWithPagination'
import SliceTitle from './SliceTitle'
import DownloadIcon from '../Icons/DownloadIcon'
import Link from '../Link'

const Profile = ({ profile, fixAspect }) => {
  const classes = useStyles()
  const { title, image, profileDetails } = profile
  const onElementClick = useCallback((e) => {
    selectText(e.target)
  }, [])
  const aspect = fixAspect ? 400 / 260 : 1
  return (
    <div className={classes.profile}>
      <div className={classes.imageContainer}>
        <Link download to={image ? image.asset.url : null}>
          <DownloadIcon />
          <ResponsiveImage className={classes.image} classNames={{ image: classes.picture }} image={image} aspect={aspect} showPreview={false} />
        </Link>
      </div>
      <div className={classes.meta}>
        <span className={classes.profileTitle} onClick={onElementClick}>{title}</span>
        {profileDetails && <span className={classes.profileDetails} onClick={onElementClick}>{profileDetails}</span>}
      </div>
    </div>
  )
}

export default function ProductProfiles ({ slice, id }) {
  const { title, profiles, fixAspect } = slice
  const classes = useStyles()
  return (
    <Section className={classes.section} id={id}>
      <SliceTitle>{title}</SliceTitle>
      <SliderWithPagination className={classes.slider} extend paginationTitle='Items' paginationLocation='top'>
        {profiles && profiles.map((profile, i) => (
          <Profile key={i} profile={profile} fixAspect={fixAspect} />
        ))}
      </SliderWithPagination>
      <HorizontalLine position='bottom' extend />
    </Section>
  )
}

const useStyles = createUseStyles({
  section: {
    position: 'relative'
  },
  slider: {
    marginTop: theme.spacing(6),
    marginBottom: theme.section.marginBottom.sm,
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.section.marginBottom.md
    }
  },
  profile: {
    width: '80%',
    marginRight: theme.gutter.sm,
    [theme.breakpoints.up('md')]: {
      marginRight: theme.gutter.md,
      width: '28%'
    }
  },
  meta: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  profileTitle: {
    fontSize: 12,
    display: 'block',
    width: '50%',
    marginRight: theme.spacing(2)
  },
  profileDetails: {
    fontSize: 10,
    whiteSpace: 'pre-wrap',
    lineHeight: 1,
    opacity: 0.5,
    textTransform: 'uppercase',
    width: '50%'
  },
  imageContainer: {
    marginBottom: theme.spacing(3),
    border: [1, 'solid', theme.colors.border],
    position: 'relative',
    paddingTop: `${100 / (380 / 250)}%`
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  picture: {
    objectFit: 'contain',
    '&:before': {
      content: '""'
    }
  }
})
