import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import { HorizontalLine, VerticalLine } from '../Lines'
import Section from '../Section'
import { useGlobalContext } from '../SiteContext'
import { Caption } from '../Typography'
import LogoImage from './logo-footer.inline.svg'
import Link from '../Link'
import ResponsiveImage from '../ResponsiveImage'
import { resolveLink } from '../../helpers/resolveLink'
import NewsletterForm from './NewsletterForm'
import { useSelector } from 'react-redux'
import { hasToolbar } from '../../state/slices/layout'
import cn from 'classnames'

const useCategoryLinks = (parentPageLink, categories) => {
  const resolvedPageLink = useMemo(() => resolveLink(parentPageLink), [parentPageLink])

  const links = useMemo(() => {
    if (!categories) return null
    return categories.map(cat => ({
      text: cat.title,
      url: resolvedPageLink.url + `?category=${cat.slug.current}`
    }))
  }, [resolvedPageLink, categories])

  return [resolvedPageLink, links]
}

const Links = ({ className, title, titleLink, links, target, rel }) => {
  const classes = useStyles()
  if (!links) return null
  return (
    <nav className={className}>
      <Caption className={classes.caption}>
        {titleLink ? <Link className={classes.link} link={titleLink} target={target} rel={rel} /> : title}
      </Caption>
      <ul className={classes.linkList}>
        {links.map((link, i) => <li key={i}><Link className={classes.link} link={link} target={target} rel={rel} /></li>)}
      </ul>
    </nav>
  )
}

export default function Footer ({ color }) {
  const classes = useStyles()
  const { footer, productsLink, projectsLink, resourceLink } = useGlobalContext()
  const {
    acknowledges,
    contactEmail,
    contactPhone,
    copyright,
    resourceLinks,
    productCategories,
    projectCategories,
    newsletterPlaceholder,
    newsletterTitle,
    newsletterSuccessMessage,
    socialLinks,
    partnersWhite,
    partnersBrown,
    partnersGreen,
    partnersBlue
  } = footer
  const partners = {
    white: partnersWhite,
    brown: partnersBrown,
    green: partnersGreen,
    blue: partnersBlue
  }

  const toolbarVisible = useSelector(hasToolbar)

  const [resolvedProductLink, productCategoryLinks] = useCategoryLinks(productsLink, productCategories)
  const [resolvedProjectLink, projectCategoryLinks] = useCategoryLinks(projectsLink, projectCategories)

  const resolvedResourceLink = useMemo(() => resolveLink(resourceLink), [resourceLink])
  const resolvedResourceLinks = useMemo(() => resourceLinks.map(l => resolveLink(l)), [resourceLinks])

  return (
    <footer className={classes.footer}>
      <HorizontalLine position='top' />
      <Section grid noBottomMargin className={cn(classes.section, { extraToolbarPadding: toolbarVisible })}>
        <div className={classes.firstLine}>
          <VerticalLine animationOrigin={0} position='right' />
        </div>
        <div className={classes.secondLine}>
          <VerticalLine animationOrigin={0} position='right' />
        </div>
        <div className={classes.firstMobileLine}>
          <HorizontalLine position='top' />
        </div>
        <div className={classes.secondMobileLine}>
          <HorizontalLine position='top' />
        </div>

        <div className={classes.logoNewsletterContainer}>
          <LogoImage className={classes.logo} />
          <NewsletterForm title={newsletterTitle} placeholder={newsletterPlaceholder} successMessage={newsletterSuccessMessage} />
        </div>

        <p className={classes.acknowledgeContainer}><span data-nosnippet>{acknowledges}</span></p>

        <Links className={classes.socialLinksContainer} links={socialLinks} title='Social' target='_blank' rel='noreferrer' />

        <div className={classes.copyrightContainer}>
          <div>
            <div className={classes.partnerLogos}>
              {partners[color].map(({ logo, link }, index) => link ? (
                <Link key={index} to={link} className={classes.partnerLogo}>
                  <ResponsiveImage image={logo} aspect={1} showPreview={false} />
                </Link>
              ):
              (
                <ResponsiveImage key={index} className={classes.partnerLogo} image={logo} aspect={1} showPreview={false} />
              )
              )}
            </div>
            <span>{copyright}</span>
          </div>
        </div>

        <Links className={classes.productsContainer} links={productCategoryLinks} titleLink={resolvedProductLink} />

        <Links className={classes.projectsContainer} links={projectCategoryLinks} titleLink={resolvedProjectLink} />

        <div className={classes.contactsContainer}>
          <Caption className={classes.caption}>Contact</Caption>
          <a href={`tel:${contactPhone}`} className={cn(classes.link, 'click-to-call')} target='_blank' rel='noreferrer'>{contactPhone}</a>
          <a href={`mailto:${contactEmail}`} className={cn(classes.link, 'click-to-email')} target='_blank' rel='noreferrer'>{contactEmail}</a>
        </div>

        <Links className={classes.resourcesContainer} links={resolvedResourceLinks} titleLink={resolvedResourceLink} />
      </Section>
    </footer>
  )
}

const useStyles = createUseStyles({
  footer: {
    position: 'relative',
    fontSize: 12
  },
  section: {
    padding: [theme.spacing(10), 0],
    rowGap: `${theme.gutter.md * 2}px !important`,
    [theme.breakpoints.up('md')]: {
      rowGap: `${theme.gutter.md}px !important`,
      padding: [theme.gutter.md, 0]
    },
    '&.extraToolbarPadding': {
      [theme.breakpoints.up('md')]: {
        padding: [theme.gutter.md, 0, theme.spacing(15)]
      }
    }
  },
  logoNewsletterContainer: {
    gridColumn: '1 / span 4',
    gridRow: 1,
    [theme.breakpoints.up('md')]: {
      gridColumn: '1 / span 2',
      paddingRight: theme.gutter.md
    }
  },
  acknowledgeContainer: {
    fontSize: 12,
    margin: 0,
    gridColumn: '1 / span 4',
    gridRow: 7,
    marginBottom: theme.spacing(12),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      marginTop: theme.spacing(56),
      marginBottom: 0,
      gridColumn: '1 / span 2',
      gridRow: 2,
      paddingRight: theme.gutter.md * 2
    }
  },
  socialLinksContainer: {
    gridColumn: '1 / span 2',
    gridRow: 5,
    [theme.breakpoints.up('md')]: {
      gridColumn: '3 / span 2',
      gridRow: 1
    }
  },
  copyrightContainer: {
    gridColumn: '3 / span 2',
    gridRow: 5,
    [theme.breakpoints.up('md')]: {
      gridColumn: '3 / span 2',
      gridRow: 2,
      alignSelf: 'end'
    }
  },
  productsContainer: {
    gridColumn: '3 / span 2',
    gridRow: 2,
    textAlign: 'right',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      gridColumn: '5 / span 2',
      gridRow: 1
    }
  },
  projectsContainer: {
    gridColumn: '1 / span 2',
    gridRow: 2,
    [theme.breakpoints.up('md')]: {
      gridColumn: '7',
      gridRow: 1,
      textAlign: 'right'
    }
  },
  contactsContainer: {
    gridColumn: '1 / span 2',
    gridRow: 3,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      gridColumn: '5 / span 2',
      gridRow: 2,
      alignSelf: 'end'
    }
  },
  resourcesContainer: {
    gridColumn: '3 / span 2',
    gridRow: 3,
    textAlign: 'right',
    [theme.breakpoints.up('md')]: {
      gridColumn: '7',
      gridRow: 2,
      alignSelf: 'end'
    }
  },
  logo: {
    width: 64,
    height: 64,
    marginBottom: theme.spacing(10)
  },
  link: {
    textDecoration: 'none',
    lineHeight: 1.8,
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  caption: {
    opacity: 0.5,
    marginBottom: theme.spacing(3),
    display: 'block'
  },
  partnerLogos: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '20em',
    margin: [0, theme.spacing(-1), theme.spacing(3)],
    [theme.breakpoints.up('md')]: {
      margin: [0, theme.spacing(-1), theme.spacing(6)]
    }
  },
  partnerLogo: {
    maxWidth: 48,
    width: '100%',
    margin: [theme.spacing(1), theme.spacing(1)],
    '& img': {
      objectFit: 'contain'
    },
    '& > div': {
      maxHeight: '100%'
    }
  },
  linkList: {
    padding: 0,
    margin: 0,
    listStyleType: 'none'
  },
  verticalLineContainer: {
    position: 'relative',
    display: 'none',
    pointerEvents: 'none',
    [theme.breakpoints.up('md')]: {
      gridRow: '1 / span 2',
      display: 'block',
      margin: [-theme.gutter.md, 0]
    }
  },
  firstLine: {
    composes: ['$verticalLineContainer'],
    [theme.breakpoints.up('md')]: {
      gridColumn: 2
    }
  },
  secondLine: {
    composes: ['$verticalLineContainer'],
    [theme.breakpoints.up('md')]: {
      gridColumn: 4
    }
  },
  horizontalLineContainer: {
    position: 'relative',
    gridColumn: '1 / span 4',
    display: 'block',
    margin: [0, -theme.gutter.sm],
    pointerEvents: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  firstMobileLine: {
    composes: ['$horizontalLineContainer'],
    gridRow: 4
  },
  secondMobileLine: {
    composes: ['$horizontalLineContainer'],
    gridRow: 6
  }
})
