import React, { useMemo, forwardRef, useEffect, useCallback, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import transform from './transform'
import get from 'lodash/get'
import theme from '../../style/theme'
import cn from 'classnames'
import gsap from 'gsap'

export const getObjectPosition = (hotspot, crop) => {
  if (!hotspot) return '50% 50%'
  if (!crop) return `${hotspot.x * 100}% ${hotspot.y * 100}%`
  return `${((hotspot.x - crop.left) / (1 - (crop.left + crop.right))) * 100}% ${((hotspot.y - crop.top) / (1 - (crop.top + crop.bottom))) * 100}%`
}

const Source = ({ media, sizes, lazy }) => {
  const hasWebp = !!get(sizes, [0, 'webpUrl'])
  const srcName = lazy ? 'data-srcset' : 'srcSet'
  const srcset = key => sizes.map(item => (`${item[key]} ${item.width}w`)).join()
  return (
    <>
      {hasWebp && <source media={media} {...{ [srcName]: srcset('webpUrl') }} type='image/webp' />}
      <source media={media} {...{ [srcName]: srcset('url') }} />
    </>
  )
}

const Picture = forwardRef(({ pictureClassName, className, alt, sizes, mobileSizes, onLoad, loading = 'lazy' }, ref) => {
  const lazy = loading === 'lazy'
  return (
    <picture className={pictureClassName}>
      {mobileSizes && sizes && (
        <>
          <Source sizes={mobileSizes} media={`(max-width: ${theme.breakpoints.values.md - 1}px)`} lazy={lazy} />
          <Source sizes={sizes} media={`(min-width: ${theme.breakpoints.values.md}px)`} lazy={lazy} />
        </>
      )}
      {!mobileSizes && sizes && <Source sizes={sizes} lazy={lazy} />}
      <img
        ref={ref}
        data-sizes='auto'
        alt={alt}
        className={cn((sizes && lazy && 'lazyload') || (!lazy && 'lazyloaded'), className)}
        onLoad={onLoad}
      />
    </picture>
  )
})

const Preview = forwardRef(({ className, alt, src }, ref) => {
  return (
    <img ref={ref} alt={alt} src={src} className={className} />
  )
})

const ResponsiveImage = forwardRef(({
  className, classNames = {}, aspect, mobileAspect,
  children, image, onLoad, loading = 'lazy',
  showCaption, showPreview = true, style,
  fadeIn = false, fadeInDelay = 0, fadeInDuration = 1.5
}, ref) => {
  const imageData = useMemo(() => transform(image, aspect), [image, aspect])
  const mobileImageData = useMemo(() => mobileAspect ? transform(image, mobileAspect) : null, [image, mobileAspect])
  const classes = useStyles({ aspect: aspect || imageData.sourceAspect, mobileAspect: mobileAspect || aspect || imageData.sourceAspect, hotspot: get(image, ['hotspot']), crop: get(image, ['crop']) })

  const pictureRef = useRef()

  const preview = get(image, ['asset', 'metadata', 'lqip'])
  const caption = get(image, ['caption'])

  const handleLoad = useCallback(() => {
    if (onLoad) onLoad()
    if (fadeIn) {
      gsap.to(pictureRef.current, { opacity: 1, duration: fadeInDuration, ease: 'sine.out', delay: fadeInDelay })
    }
  }, [onLoad, fadeIn, fadeInDelay, fadeInDuration])

  useEffect(() => {
    if (pictureRef.current && pictureRef.current.complete) {
      handleLoad()
    }
  }, [handleLoad])

  const imageClassName = cn(classes.image, classNames.image)

  // const sizes = useResolvedSizes(data, mobileData)
  const alt = get(image, ['alt'])

  return (
    <div className={cn(className, classes.imageContainer, {
      [classes.overflowHidden]: showCaption && caption
    })} ref={ref} style={style}>
      {showPreview && preview && <Preview src={preview} alt={alt} className={imageClassName} />}
      <Picture
        pictureClassName={cn(classes.picture)}
        className={cn(imageClassName, { fadeIn })}
        alt={alt}
        sizes={imageData?.sizes}
        mobileSizes={mobileImageData?.sizes}
        onLoad={handleLoad}
        ref={pictureRef}
        loading={loading}
      />
      {children}
      {showCaption && caption && <span className={classes.caption}>{caption}</span>}
    </div>
  )
})

export default ResponsiveImage

const useStyles = createUseStyles({
  imageContainer: {
    position: 'relative',
    width: '100%',
    display: 'block',
    '& picture::before': {
      display: 'block',
      content: ({ aspect }) => aspect ? '""' : undefined,
      paddingTop: ({ aspect, mobileAspect }) => mobileAspect ? `${100 / mobileAspect}%` : aspect ? `${100 / aspect}%` : undefined
    },
    [theme.breakpoints.up('md')]: {
      '& picture::before': {
        paddingTop: ({ aspect }) => aspect ? `${100 / aspect}%` : undefined
      }
    },
    '&.overflowHidden': {
      overflow: 'hidden'
    }
  },
  picture: {
  },
  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: ({ hotspot, crop }) => getObjectPosition(hotspot, crop),
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '&.fadeIn': {
      opacity: 0
    }
  },
  caption: {
    position: 'absolute',
    right: 0,
    top: `calc(100% + ${theme.spacing(2)}px)`,
    fontSize: 10,
    fontFamily: theme.fonts.caption
  }
})
