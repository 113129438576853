import React from "react";
import { createUseStyles } from "react-jss";
import cn from "classnames";
import { useLocation } from "@reach/router";
import get from "lodash/get";
import theme from "../style/theme";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

export default function ShareLinks({ className, text }) {
  const classes = useStyles();
  const href = get(useLocation(), ["href"]);
  // const encodeText = Boolean(text?.length) ? encodeURI(text) : "";

  // const facebookShareLink = `https://www.facebook.com/sharer/sharer.php/?u=${href}`;
  // const twitterShareLink = `https://twitter.com/intent/tweet/?url=${href}/?text=${encodeText}`;
  // const linkedInShareLink = `https://www.linkedin.com/sharing/share-offsite/?url=${href}/?title=${encodeText}`;
  // const emailLink = `mailto:info@example.com?&subject=${encodeText}&cc=&bcc=&body=${href}`;
  // console.log(emailLink);
  return (
    <div className={cn(className, classes.links)}>
      {/* <a
        href={facebookShareLink}
        className={classes.link}
        target="_blank"
        rel="noreferrer"
      >
        Facebook
      </a> */}
      <FacebookShareButton
        url={href}
        className={classes.link}
        hashtag="#decorsystems"
        quote={text}
      >
        Facebook
      </FacebookShareButton>
      <LinkedinShareButton
        url={href}
        className={classes.link}
        title={text}
        source={"decorsystems"}
      >
        LinkedIn
      </LinkedinShareButton>
      {/* <a
        href={linkedInShareLink}
        className={classes.link}
        target="_blank"
        rel="noreferrer"
      >
        LinkedIn
      </a> */}
      <TwitterShareButton
        url={href}
        className={classes.link}
        title={text}
        hashtag="#decorsystems"
      >
        Twitter
      </TwitterShareButton>
      {/* <a
        href={twitterShareLink}
        className={classes.link}
        target="_blank"
        rel="noreferrer"
      >
        Twitter
      </a> */}
      <EmailShareButton url={href} className={classes.link} subject={text}>
        Email
      </EmailShareButton>
      {/* <a
        href={emailLink}
        className={classes.link}
        target="_blank"
        rel="noreferrer"
      >
        Email
      </a> */}
    </div>
  );
}

const useStyles = createUseStyles({
  links: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: 12,
  },
  link: {
    width: "25%",
    textDecoration: "none",
    textTransform: "capitalize",
    "&:hover": {
      textDecoration: "underline",
    },
    "&:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
  },
});
