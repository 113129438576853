import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import reducer from './slices'
import middlewares from './middlewares'

export var store

const creatStore = (preloadedState) => {
  store = configureStore({
    reducer,
    preloadedState,
    middleware: [
      ...middlewares,
      ...getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false
      })
    ]
  })
  return store
}

export default creatStore
