import React from "react";
import cn from "classnames";
import ResponsiveImage from "../ResponsiveImage";
import Section from "../Section";
import SliceHeader from "../SliceHeader";
import Field from "../Field";
import ImageCurtain from "../Animation/ImageCurtain";
import { createUseStyles } from "react-jss";
import theme from "../../style/theme";
import { HorizontalLine } from "../Lines";
import Link from "../Link";
import UseInternalLinkUrl from "../../hooks/useInternalLinkUrl";
import { resolveGroupFireRatingLabel } from "../../helpers/resolveGroupFireRatingLabel";

const ProductTile = ({ product, large, delay }) => {
  const { image, title, soundProfile, groupFireRatings, category } = product;
  const classes = useStyles();
  const link = UseInternalLinkUrl(product);

  return (
    <Link className={cn(classes.product, { large })} to={link}>
      <ResponsiveImage
        image={image}
        aspect={large ? 584 / 472 : 380 / 472}
        className={classes.image}
        mobileAspect={344 / 255}
      >
        <ImageCurtain
          animationType="topDown"
          animatedWhenInView
          delay={delay}
        />
      </ResponsiveImage>
      <span className={classes.title}>{title}</span>
      <div className={classes.fieldContainer}>
        <Field label="Category" fontSize={10}>
          {category?.title}
        </Field>
        <Field className={classes.fireGroup} label="Fire Rating" fontSize={10}>
          {resolveGroupFireRatingLabel(groupFireRatings)}
        </Field>
        <Field label="Sound Profile" fontSize={10}>
          {soundProfile}
        </Field>
      </div>
    </Link>
  );
};

export default function ProductGrid({ slice, id }) {
  const { title, copy, cta, ctaLabel, products } = slice;
  const classes = useStyles();
  return (
    <section className={classes.section} id={id}>
      <SliceHeader title={title} copy={copy} cta={cta} ctaLabel={ctaLabel} />
      <Section grid tag="div" noBottomMargin>
        {products &&
          products.map((product, i) => (
            <ProductTile
              key={product.title}
              product={product}
              large={i % 3 === 0}
              delay={(i % 3) * 0.15}
            />
          ))}
      </Section>
      <HorizontalLine position="bottom" />
    </section>
  );
}

const useStyles = createUseStyles({
  section: {
    position: "relative",
    paddingBottom: theme.section.marginBottom.sm,
    marginBottom: theme.section.marginBottom.sm,
    [theme.breakpoints.up("md")]: {
      paddingBottom: theme.section.marginBottom.md,
      marginBottom: theme.section.marginBottom.md,
    },
    overflow: "hidden",
  },
  product: {
    overflow: "hidden",
    textDecoration: "none",
    gridColumn: "1 / span 4",
    paddingBottom: 0,
    [theme.breakpoints.up("md")]: {
      paddingBottom: theme.gutter.md,
      gridColumn: "span 2",
      "&.large": {
        gridColumn: "span 3",
      },
    },
    "& label": {
      transition: "opacity 0.15s ease-in-out",
    },
    "&:hover label": {
      opacity: 1,
    },
  },
  image: {
    marginBottom: theme.spacing(3),
  },
  title: {
    display: "block",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(10),
    },
  },
  fieldContainer: {
    textTransform: "uppercase",
    margin: 0,
    display: "grid",
    gridGap: theme.gutter.sm,
    gridTemplateColumns: "1fr 1fr",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      margin: [0, theme.spacing(-3)],
      "& > *": {
        margin: [0, theme.spacing(3)],
      },
    },
    [theme.breakpoints.up("lg")]: {
      margin: [0, theme.spacing(-6)],
      "& > *": {
        margin: [0, theme.spacing(6)],
      },
    },
  },
  fireGroup: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
});
