import { resolveInternalLinkUrl } from "../helpers/resolveLink";
import { useGlobalContext } from "../components/SiteContext";
import get from "lodash/get";

export const resolvePageUrl = (parentReference, page) => {
  return `${resolveInternalLinkUrl(parentReference)}${resolveInternalLinkUrl(
    page
  )}`;
};

const UseInternalLinkUrl = (page) => {
  const { projectsLink, productsLink } = useGlobalContext();
  if (!page) return "";
  const ifParentExist = !Boolean(page?.parentPage?.slug?.current);
  let url = "";
  switch (page._type) {
    case "project":
      url = resolveInternalLinkUrl(page);
      if (ifParentExist) {
        url = `${resolveInternalLinkUrl(projectsLink.reference)}${url}`;
      }
      break;
    case "product":
      url = resolveInternalLinkUrl(page);
      if (ifParentExist) {
        url = `${resolveInternalLinkUrl(productsLink.reference)}${url}`;
      }
      break;
    case "documentResource": {
      url = get(page, ["file", "asset", "url"]);
      break;
    }
    case "page": {
      url = resolveInternalLinkUrl(page);
      break;
    }
    default:
  }
  return url;
};

export default UseInternalLinkUrl;
