export function getText(e) {
  const type = e.nodeType;
  let result = "";

  if (
    type === Node.ELEMENT_NODE ||
    type === Node.DOCUMENT_NODE ||
    type === Node.DOCUMENT_FRAGMENT_NODE
  ) {
    if (typeof e.textContent === "string") {
      return e.textContent;
    } else {
      for (let child = e.firstChild; child; child = child.nextSibling) {
        result += getText(child);
      }
    }
  } else if (type === Node.TEXT_NODE || type === Node.CDATA_SECTION_NODE) {
    return e.nodeValue;
  }

  return result;
}
export const emojiExp =
  /[\u263a-\u263b\u2665\u270b-\u270c\u274c\u2764\ufe0f\u{1f004}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}]/gu;
