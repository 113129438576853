import { flatten, map } from "lodash";
import React, { useCallback } from "react";
import { createUseStyles } from "react-jss";
import { useDispatch } from "react-redux";
import { resolveGroupFireRatingLabel } from "../../../helpers/resolveGroupFireRatingLabel";
import { addItem } from "../../../state/slices/ui/cart";
import { openQuoteDrawer } from "../../../state/slices/ui/quoteForm";
import theme from "../../../style/theme";
import DetailsPanel, { Cell } from "../../DetailsPanel";

export default function ProductDetailsPanel({ product, className }) {
  const classes = useStyles();
  const {
    title,
    groupFireRatings,
    pricingGuide,
    leadTime,
    category,
    fireSubstrateFilters,
    standardPanelSize,
  } = product;
  const substrate = flatten(
    map(fireSubstrateFilters, (item) => item.title)
  ).filter((substrate) => substrate !== "Accessories");

  const dispatch = useDispatch();
  const addToSamples = useCallback(() => {
    dispatch(addItem({ id: product.id }));
  }, [dispatch, product]);

  const requestAQuote = useCallback(() => {
    dispatch(openQuoteDrawer());
  }, [dispatch]);

  return (
    <DetailsPanel className={className}>
      <Cell label="Product" className={classes.titleCell} fullWidth>
        {title}
      </Cell>
      {category?.title && <Cell label="Category">{category.title}</Cell>}
      <Cell label="Fire Rating">
        {groupFireRatings && resolveGroupFireRatingLabel(groupFireRatings)}
      </Cell>
      <Cell label="Approx Pricing Guide">{pricingGuide || "-"}</Cell>
      <Cell label="Lead Time">{leadTime || "-"}</Cell>
      <Cell label="Substrate">
        {substrate && substrate.length > 0 ? substrate.join(", ") : "-"}
      </Cell>
      <Cell label="Standard Panel Size">{standardPanelSize || "-"}</Cell>
      <Cell className={classes.buttonCell}>
        <button className={classes.invertedButton} onClick={requestAQuote}>
          Request a quote
        </button>
      </Cell>
      <Cell className={classes.buttonCell}>
        <button className={classes.button} onClick={addToSamples}>
          Add to samples
        </button>
      </Cell>
    </DetailsPanel>
  );
}

const useStyles = createUseStyles({
  titleCell: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  buttonCell: {
    padding: 0,
    display: "flex",
  },
  shareLinks: {
    fontSize: 12,
  },
  button: {
    display: "block",
    padding: theme.gutter.sm,
    border: "none",
    width: "100%",
    fontFamily: theme.fonts.body,
    fontSize: 12,
    textTransform: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
    transition: "background-color 0.25s ease-in-out, color 0.25s ease-in-out",
    height: "100%",
    textDecoration: "none",
    textAlign: "center",
    "&:hover": {
      backgroundColor: theme.colors.text,
      color: theme.colors.background,
    },
  },
  invertedButton: {
    composes: ["$button"],
    backgroundColor: theme.colors.text,
    color: theme.colors.background,
    "&:hover": {
      backgroundColor: theme.colors.background,
      color: theme.colors.text,
    },
  },
});
