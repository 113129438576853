import React, { useCallback, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../../style/theme'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { getOptionsSelector } from '../../../state/slices/data/lists'
import { getFiltersSelector, actions } from '../../../state/slices/ui/listings'
import FilterSpacer from './FilterSpacer'
import FilterSelect from './FilterSelect'
import map from 'lodash/map'

export default function Toolbar ({ className, type }) {
  const classes = useStyles()
  const filterOptions = useSelector(getOptionsSelector(type))
  const filters = useSelector(getFiltersSelector(type))
  const dispatch = useDispatch()

  const onApplyFilter = useCallback(({ key, value }) => {
    dispatch(actions[type].applyFilter({ key, value }))
  }, [dispatch, type])

  const selects = useMemo(() => map(filterOptions, ({ title, emptyText }, key) => ({
    filter: key, title, emptyText
  })), [filterOptions])

  return (
    <>
      <div className={classes.line} />
      <section className={cn(className, classes.toolbar)}>
        {selects.map(({ title, filter }, i) => (
          <React.Fragment key={i}>
            {i > 0 && <FilterSpacer />}
            <FilterSelect
              title={title}
              filter={filter}
              filterValue={filters[filter]}
              options={filterOptions[filter].items}
              onApplyFilter={onApplyFilter}
            />
          </React.Fragment>
        ))}
      </section>
      <div className={classes.line} />
    </>
  )
}

const useStyles = createUseStyles({
  line: {
    height: 1,
    width: '100%',
    backgroundColor: theme.colors.border,
    zIndex: 1,
    position: 'relative'
  },
  toolbar: {
    position: 'relative',
    display: 'flex',
    height: theme.spacing(10),
    overflow: 'auto',
    gridColumn: '1 / span 4',
    [theme.breakpoints.up('md')]: {
      gridColumn: '1 / span 7',
      border: 'none',
      overflow: 'visible',
      display: 'grid',
      gridTemplateColumns: 'repeat(7, 1fr)',
      gap: theme.gutter.md,
      margin: [0, theme.gutter.md]
    }
  }
})
