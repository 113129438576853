import React, { useCallback, useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../style/theme'
import Count from './Count'
import Link from './Link'
import map from 'lodash/map'
import last from 'lodash/last'
import cn from 'classnames'
import { primaryInput } from 'detect-it'
import isNumber from 'lodash/isNumber'
import Button from './Button'
import { Caption } from './Typography'
// import { HorizontalLine } from './Lines'
import gsap from 'gsap'

const Item = ({ item, selectedItem, selectedItemIndex, onMouseEnter, onClick, inset, size, hover, children, ...rest }) => {
  const classes = useStyles()
  const { to, count, text, arrow, subtitle, target, icon } = item
  const mouseEnterTimeoutRef = useRef()
  const Icon = icon

  const handleMouseEnter = useCallback(() => {
    if (onMouseEnter) {
      mouseEnterTimeoutRef.current = setTimeout(() => onMouseEnter(item), 250)
      return () => {
        clearTimeout(mouseEnterTimeoutRef.current)
      }
    }
  }, [item, onMouseEnter])

  const handleMouseLeave = useCallback(() => {
    if (mouseEnterTimeoutRef.current) {
      clearTimeout(mouseEnterTimeoutRef.current)
    }
  }, [])

  const handleClick = useCallback((e) => {
    if (onClick) onClick(item, e)
    if (mouseEnterTimeoutRef.current) {
      clearTimeout(mouseEnterTimeoutRef.current)
    }
  }, [onClick, item])

  return (
    <li className={classes.item}>
      <Link
        className={cn(classes.link, { selected: !selectedItem || selectedItemIndex === -1 || selectedItem === item, inset, hover }, size)}
        to={to}
        onMouseEnter={primaryInput === 'touch' ? null : handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        target={target}
        {...rest}
      >
        <span className={classes.text}>
          {text}
          {subtitle && <Caption className={classes.subtitle}>{subtitle}</Caption>}
        </span>
        {count && isNumber(count) && <Count pad count={count} className={cn(classes.count, { selected: selectedItem === item })} />}
        {arrow && <Button className={cn(classes.arrowButton, { selected: selectedItem === item })} iconClassName={classes.iconClassName} />}
        {icon && <Icon className={cn(classes.icon, { selected: selectedItem === item })} />}
      </Link>
      {children}
    </li>
  )
}

export default function LinkList ({
  className, items, onMouseEnter, onClick,
  inset = false, selectedItem, hover, size = 'small',
  animateIn = true, delay = 0, hideDivider = false
}) {
  const classes = useStyles()
  const selectedItemIndex = items.indexOf(selectedItem)
  const listRef = useRef()

  useEffect(() => {
    if (listRef.current && animateIn) {
      const timeline = gsap.timeline()
      const lines = listRef.current.querySelectorAll(`.${classes.line}`)
      const links = listRef.current.querySelectorAll(`.${classes.link}`)
      timeline.set(lines, { scaleX: 0, transformOrigin: '0 0' }, 0)
      timeline.to(lines, { scaleX: 1, duration: 0.5, ease: 'sine.inOut', stagger: 0.1 }, delay)
      const linkTimelines = map(links, (link, i) => {
        const linkTl = gsap.timeline()
        linkTl.set(link.children, { opacity: 0 }, 0)
        linkTl.to(link.children, { opacity: 1, duration: 0.5, ease: 'sine.inOut', stagger: 0.25, delay: (i * 0.1) + delay })
        return linkTl
      })
      timeline.add(linkTimelines, 0)
      return () => {
        timeline.kill()
      }
    }
  }, [delay, animateIn]) /* eslint-disable-line react-hooks/exhaustive-deps */

  return (
    <nav className={cn(classes.nav, className)}>
      <ul className={classes.list} ref={listRef}>
        {map(items, (item, i) => (
          <Item
            key={item.text + i + ''}
            item={item}
            onMouseEnter={onMouseEnter}
            onClick={onClick}
            selectedItem={selectedItem}
            selectedItemIndex={selectedItemIndex}
            inset={inset}
            hover={hover}
            size={size}
          >
            <div className={cn(classes.line, { fade: selectedItemIndex !== -1 && selectedItemIndex !== i && selectedItemIndex !== i - 1 })} />
          </Item>
        ))}
        {!hideDivider && <div className={cn(classes.line, 'bottom', { fade: selectedItemIndex !== -1 && selectedItem !== last(items) })} />}
      </ul>
    </nav>
  )
}

const useStyles = createUseStyles({
  nav: {},
  list: {
    display: 'block',
    listStyle: 'none',
    overflow: 'hidden',
    padding: 0,
    margin: 0,
    position: 'relative'
  },
  line: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 1,
    backgroundColor: theme.colors.border,
    transition: 'opacity 0.15s ease-in-out',
    '&.fade': {
      opacity: 0.4
    },
    '&.bottom': {
      top: 'inherit',
      bottom: 0
    }
  },
  item: {
    display: 'block',
    // borderTop: [1, 'solid', theme.colors.border],
    // borderBottom: [1, 'solid', theme.colors.border],
    marginBottom: -1,
    transition: 'opacity 0.15s ease-in-out',
    position: 'relative'
  },
  link: {
    opacity: 0.4,
    padding: [theme.spacing(2), 0],
    textDecoration: 'none',
    border: 'none',
    background: 'transparent',
    fontFamily: theme.fonts.body,
    fontSize: 15,
    letterSpacing: '0.03em',
    textTransform: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    textAlign: 'left',
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      fontSize: 12
    },
    '&.inset': {
      padding: [theme.spacing(2), theme.gutter.sm]
    },
    '&.medium': {
      fontSize: 15
    },
    '&.large': {
      fontSize: 20,
      padding: [theme.spacing(3), 0]
    },
    '&.large.inset': {
      padding: [theme.spacing(3), theme.gutter.sm]
    },
    '&.hover:hover $count, &.hover:hover $arrowButton': {
      backgroundColor: theme.colors.text,
      color: theme.colors.background
    },
    '&.selected, &:hover': {
      opacity: 1
    }
  },
  text: {
    flexGrow: 1,
    flexShrink: 1
  },
  subtitle: {
    display: 'block',
    opacity: 0.5
  },
  count: {
    transition: 'background-color 0.15s ease-in-out, color 0.15s ease-in-out',
    flexGrow: 0,
    flexShrink: 0,
    '&.selected': {
      backgroundColor: theme.colors.text,
      color: theme.colors.background
    }
  },
  arrowButton: {
    flexGrow: 0,
    flexShrink: 0,
    padding: [3, 6],
    alignSelf: 'flex-start',
    '&.selected': {
      backgroundColor: theme.colors.text,
      color: theme.colors.background
    },
    '& svg': {
      margin: 0
    },
    '& > div > span': {
      padding: [3, 6]
    }
  },
  iconClassName: {
    marginLeft: 0
  },
  icon: {
    flexGrow: 0,
    flexShrink: 0,
    padding: [3, 6],
    alignSelf: 'flex-start'
  }
})
