import React, { useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Panel from './Panel'
import {
  closePanel,
  getSelectedItem,
  getSelectedCategory,
  isCategoryPanelOpen,
  isDetailsPanelOpen
} from '../../state/slices/drawer'
import { useGlobalContext } from '../SiteContext'
import ProjectsList from './ProjectsList'
import ConnectedPanels from './ConnectedPanels'
import ProjectDetailsPanel from './ProjectDetailsPanel'

const SLUG = 'projects'

export default function ProjectsPanel () {
  const category = useSelector(getSelectedCategory(SLUG))
  const project = useSelector(getSelectedItem(SLUG))
  const categoryPanelOpen = useSelector(isCategoryPanelOpen(SLUG))
  const detailsPanelOpen = useSelector(isDetailsPanelOpen(SLUG))
  const globals = useGlobalContext()

  const { projectCategories, counts, projectsLink } = globals

  const dispatch = useDispatch()
  const onClose = useCallback(() => {
    dispatch(closePanel({ slug: SLUG }))
  }, [dispatch])

  const projectCounts = useMemo(() => ({
    byCategory: counts.projectsByCategory,
    total: counts.projects
  }), [counts])

  return (
    <ConnectedPanels
      showCloseButton={!categoryPanelOpen}
      title='See All'
      slug={SLUG}
      categories={projectCategories}
      link={projectsLink}
      counts={projectCounts}
    >
      <Panel
        title={`All ${category?.category.title}`}
        titleCount={category?.count}
        titleLink={category?.to}
        open={categoryPanelOpen}
        showCloseButton={categoryPanelOpen && !detailsPanelOpen}
        onClose={onClose}
        index={2}
      >
        <ProjectsList category={category?.category} />
      </Panel>
      <ProjectDetailsPanel project={project} open={detailsPanelOpen} />
    </ConnectedPanels>
  )
}
