import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import { HorizontalLine } from '../Lines'
import ResponsiveImage from '../ResponsiveImage'
import Section from '../Section'
import range from 'lodash/range'
import { Caption } from '../Typography'
import cn from 'classnames'

export default function TeamMembers ({ slice, id }) {
  const classes = useStyles()
  const { title, image, teamMembers } = slice

  return (
    <Section className={classes.section} id={id}>
      <h2 className={classes.title}>{title}</h2>
      <ResponsiveImage image={image} aspect={1440 / 700} className={classes.image} />
      <Section grid fullWidth noBottomMargin className={classes.membersContainerRow}>
        {teamMembers.map(({ name, position }, j) => (
          <div key={j} className={classes.memberContainer}>
            <div>{name}</div>
            <Caption className={classes.position}>{position}</Caption>
          </div>
        ))}
        {range(Math.ceil(teamMembers.length / 7)).map((_, key) => (
          <div className={classes.line} key={key} style={{ gridRow: (key * 2) + 2 }}>
            <HorizontalLine position='bottom' />
          </div>
        ))}
        {range(Math.ceil(teamMembers.length / 2)).map((_, key) => (
          <div className={cn(classes.line, 'mobile')} key={key} style={{ gridRow: (key * 2) + 2 }}>
            <HorizontalLine position='bottom' />
          </div>
        ))}
      </Section>
      <HorizontalLine position='bottom' extend />
    </Section>
  )
}

const useStyles = createUseStyles({
  section: {
    position: 'relative',
    paddingBottom: theme.section.marginBottom.sm,
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.section.marginBottom.md
    }
  },
  title: {
    marginBottom: theme.spacing(10)
  },
  membersContainerRow: {
    fontSize: 15,
    position: 'relative',
    margin: [theme.spacing(3), 0],
    gridGap: [theme.spacing(3), '!important'],
    [theme.breakpoints.up('md')]: {
      margin: [theme.spacing(7), 0],
      gridGap: [theme.spacing(7), '!important']
    }
  },
  memberContainer: {
    gridColumn: 'span 2',
    [theme.breakpoints.up('md')]: {
      gridColumn: 'span 1'
    }
  },
  image: {
    marginBottom: theme.spacing(10),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(7)
    }
  },
  line: {
    opacity: 0.3,
    position: 'relative',
    gridColumn: '1 / span 4',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      gridColumn: '1 / span 7'
    },
    '&.mobile': {
      display: 'block',
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    }
  },
  position: {
    opacity: 0.5
  }
})
