import cn from "classnames";
import { sortBy } from "lodash";
import get from "lodash/get";
import React, { useCallback, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../helpers/format";
import { formatBytes } from "../../helpers/formatBytes";
import {
  fetchDocuments,
  getItemsSelector,
  isLoadedSelector,
} from "../../state/slices/data/lists";
import theme from "../../style/theme";
import DownloadIcon from "../Drawer/download.inline.svg";
import { HorizontalLine } from "../Lines";
import Link from "../Link";
import Section from "../Section";
import { useGlobalContext } from "../SiteContext";
import { Caption } from "../Typography";
import CaretIcon from "./Listing/caret.inline.svg";
import SliceTitle from "./SliceTitle";
import DocumentIcon from "./document_icon.inline.svg";

const DocumentItem = ({ document }) => {
  const classes = useStyles();
  const { title, fileInfo, date } = document;
  const asset = get(document, ["file", "asset"]);
  const { size, mimeType, url } = asset || {};

  if (!asset) {
    console.warn(`The asset is missing from the document ${title}`);
  }

  return (
    <li className={classes.item}>
      <Link to={url} className={classes.link} target="_blank">
        <DocumentIcon className={classes.icon} />
        <div className={classes.documentTitle}>
          {title}
          {asset && (
            <Caption className={classes.fileInfo}>
              {formatDate(date)} | {formatBytes(size)} {fileInfo || mimeType}
            </Caption>
          )}
        </div>
        <DownloadIcon className={classes.downloadIcon} />
        <HorizontalLine
          position="bottom"
          className={classes.linkLink}
          animationOrigin="0%"
        />
      </Link>
    </li>
  );
};

const CustomSelect = ({ options, onChange }) => {
  const classes = useStylesSelect();
  const [selectedOption, setSelectedOption] = useState("All documents");

  const [open, setOpen] = useState();
  const handleChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
    onChange(value);
  };
  const onToggleDropdown = useCallback(() => {
    setOpen((value) => !value);
  }, []);
  const onItemClicked = useCallback(
    (e, value) => {
      onChange(value);
      setOpen(false);
    },
    [onChange]
  );
  return (
    <div className={classes.item}>
      <div className={classes.selectWrapper}>
        <select
          value={selectedOption || ""}
          onChange={handleChange}
          className={classes.select}
          placeholder="hello world"
        >
          <option value="">All documents</option>
          {options &&
            options.map(({ title }) => (
              <option key={title} value={title}>
                {title}
              </option>
            ))}
        </select>
        <CaretIcon className={classes.selectCaret} />
      </div>
      <div className={cn(classes.dropdown, { open })}>
        <button
          className={classes.dropdownItem}
          onClick={(e) => onItemClicked(e, "")}
        >
          All documents
        </button>
        {options &&
          options.map(({ title }) => (
            <button
              className={classes.dropdownItem}
              key={title}
              onClick={(e) => onItemClicked(e, title)}
            >
              {title}
            </button>
          ))}
      </div>
      <button
        className={cn(classes.button, { open })}
        onClick={onToggleDropdown}
      >
        <span>{selectedOption}</span>
        <CaretIcon className={cn(classes.itemIcon, { open })} />
      </button>
    </div>
  );
};

const Toolbar = ({ items = [], onChange }) => {
  const classes = useStylesSelect();
  return (
    items.length && (
      <>
        <div className={classes.line} />
        <section className={classes.toolbar}>
          <CustomSelect options={items} onChange={onChange} />
        </section>
        <div className={classes.line} />
        <div className={classes.line} />
      </>
    )
  );
};

export default function ResourcesDocumentsList({ slice, page, id }) {
  const { products } = useGlobalContext();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState(null);
  const [listDocuments, setListDocuments] = useState([]);
  const { title, category } = slice;
  const isProduct = Boolean(category === "product");
  const loaded = useSelector(isLoadedSelector("documents"));
  const allDocuments = useSelector(getItemsSelector("documents"));

  const onChange = (value) => {
    setSelectedValue(value);
  };

  useEffect(() => {
    if (slice.category === "product") {
      const allProductDocuments = allDocuments.filter(
        (it) => it.product?.title
      );
      const currentDocuments = allProductDocuments.filter((x) =>
        selectedValue ? x.product?.title === selectedValue : x
      );
      setListDocuments(currentDocuments);
      return;
    }

    setListDocuments(
      allDocuments.filter((x) => x.category.includes(slice.category))
    );
  }, [allDocuments, slice, selectedValue]);

  useEffect(() => {
    if (!loaded) {
      dispatch(fetchDocuments());
    }
  }, [dispatch]); /* eslint-disable-line */

  return (
    <>
      {isProduct && (
        <Toolbar items={sortBy(products, (x) => x.title)} onChange={onChange} />
      )}
      <Section grid className={classes.section} id={id}>
        {title && <SliceTitle className={classes.title}>{title}</SliceTitle>}
        <div className={classes.content}>
          <ul className={classes.list}>
            {listDocuments &&
              listDocuments.map((document, i) => (
                <DocumentItem key={i} document={document} />
              ))}
          </ul>
        </div>
        <HorizontalLine extend position="bottom" />
      </Section>
    </>
  );
}

const useStyles = createUseStyles({
  section: {
    position: "relative",
    marginTop: theme.section.marginBottom.sm,
    [theme.breakpoints.up("md")]: {
      marginTop: theme.section.marginBottom.md,
    },
  },
  title: {
    gridColumn: "1 / span 4",
    [theme.breakpoints.up("md")]: {
      gridColumn: "1 / span 7",
      marginBottom: "75px",
    },
  },
  content: {
    marginBottom: theme.section.marginBottom.sm,
    gridColumn: "1 / span 4",
    [theme.breakpoints.up("md")]: {
      gridColumn: "5 / span 2",
      gridRow: 2,
      marginBottom: theme.section.marginBottom.md,
    },
  },
  list: {
    margin: 0,
    padding: 0,
    listStyle: "none",
  },
  item: {
    "&:last-child > $link": {
      borderBottomWidth: 0,
      marginBottom: 0,
    },
  },
  link: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    position: "relative",
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(3),
    "&:hover $linkLink": {
      opacity: 1,
    },
  },
  linkLink: {
    transition: "opacity 0.15s ease-in-out",
    opacity: 0.3,
  },
  fileInfo: {
    display: "block",
  },
  documentTitle: {
    flexGrow: 1,
    flexShrink: 1,
  },
  icon: {
    flexGrow: 0,
    flexShrink: 0,
    marginRight: theme.spacing(4),
    "& > path": {
      fill: theme.colors.background,
    },
  },
  downloadIcon: {
    flexGrow: 0,
    flexShrink: 0,
    width: 14,
    height: 16,
  },
});

const useStylesSelect = createUseStyles({
  line: {
    height: 1,
    width: "100%",
    backgroundColor: theme.colors.border,
    zIndex: 1,
    position: "relative",
  },
  toolbar: {
    position: "relative",
    display: "flex",
    height: theme.spacing(10),
    overflow: "hidden",
    gridColumn: "1 / span 4",
    [theme.breakpoints.up("md")]: {
      gridColumn: "1 / span 7",
      border: "none",
      overflow: "visible",
      display: "grid",
      gridTemplateColumns: "repeat(7, 1fr)",
      gap: theme.gutter.md,
      margin: [0, theme.gutter.md],
    },
  },
  spacer: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      marginLeft: -theme.gutter.md,
      display: "block",
    },
  },
  item: {
    position: "relative",
    height: "100%",
    borderRight: [1, "solid", theme.colors.border],
    [theme.breakpoints.up("md")]: {
      border: "none",
      gridColumn: "span 1",
      width: "100%",
    },
  },
  button: {
    position: "absolute",
    margin: 0,
    padding: 0,
    borderRadius: 0,
    cursor: "pointer",
    fontFamily: theme.fonts.caption,
    fontSize: 10,
    textTransform: "uppercase",
    alignItems: "center",
    bottom: 0,
    top: 0,
    border: "none",
    borderRight: [1, "solid", "transparent"],
    borderLeft: [1, "solid", "transparent"],
    backgroundColor: theme.colors.background,
    display: "none",
    transition: "border-color 0.15s ease-in-out",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      margin: [0, 0, 0, -theme.gutter.md],
      padding: [0, theme.gutter.md],
      width: `calc(100% + ${theme.gutter.md}px)`,
    },
    "&:hover, &.open": {
      borderRight: [1, "solid", theme.colors.border],
      borderLeft: [1, "solid", theme.colors.border],
    },
  },
  itemIcon: {
    marginLeft: theme.spacing(2),
  },
  dropdown: {
    position: "absolute",
    top: "100%",
    left: 0,
    right: 0,
    transform: "translate(0, -100%)",
    backgroundColor: theme.colors.background,
    margin: [-1, 0, 0, -theme.gutter.sm],
    padding: theme.gutter.sm,
    border: [1, "solid", theme.colors.border],
    transition: "transform 0.15s ease-in-out",
    display: "none",
    "&.open": {
      transform: "translate(0, 0)",
      zIndex: 1,
      display: "block",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
      padding: [theme.gutter.sm, theme.gutter.md],
      margin: [-1, 0, 0, -theme.gutter.md],
    },
  },
  dropdownItem: {
    width: "100%",
    backgroundColor: "transparent",
    color: "currentColor",
    border: "none",
    textAlign: "left",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  selectWrapper: {
    height: "100%",
    minWidth: 150,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  selectCaret: {
    position: "absolute",
    right: theme.gutter.sm,
    top: "50%",
    transform: "translate(-50%, 0)",
  },
  select: {
    height: "100%",
    outline: "none",
    border: "none",
    margin: [0, theme.gutter.sm + 15, 0, theme.gutter.sm],
    padding: 0,
    background: "transparent",
    color: "currentColor",
    width: "100%",
    "-webkit-appearance": "none",
  },
});
