import { useRef, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import useWindowResize from '../../../hooks/useWindowResize'
import { isCurrentBreakpointMobile } from '../../../state/slices/layout'
import theme from '../../../style/theme'
import { getY, useScrollListener } from '../../useSmoothScrollbar'

const useStickyElement = (containerRef, elementRef, offsetBottom = 0) => {
  const localsRef = useRef({})
  const isMobile = useSelector(isCurrentBreakpointMobile)
  useWindowResize(useCallback(() => {
    if (containerRef.current) {
      localsRef.current.offsetTop = containerRef.current.offsetTop
      const { height: containerHeight } = containerRef.current.getBoundingClientRect()
      const { height: elementHeight } = elementRef.current.getBoundingClientRect()
      localsRef.current.maxTransform = containerHeight - elementHeight - offsetBottom
    }
  }, [containerRef, elementRef, offsetBottom]), true)

  const setFilterPosition = useCallback(({ y }) => {
    if (elementRef.current) {
      if (isMobile) {
        elementRef.current.style.transform = 'translate3d(0, 0px, 0)'
      } else {
        const { offsetTop } = localsRef.current
        const translateY = y - offsetTop + theme.header.height.large
        const boundY = Math.max(0, Math.min(translateY, localsRef.current.maxTransform))
        elementRef.current.style.transform = `translate3d(0, ${boundY}px, 0)`
      }
    }
  }, [isMobile, elementRef])

  useScrollListener(setFilterPosition)
  useEffect(() => { setFilterPosition({ y: getY() }) }, [setFilterPosition])
}

export default useStickyElement
