import React, { useEffect, useRef, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector, useDispatch } from 'react-redux'
import cn from 'classnames'
import useBodyScrollLock from '../hooks/useBodyScrollLock'
import { useGlobalContext } from './SiteContext'
import theme from '../style/theme'
import { isMenuOpen } from '../state/slices/layout'
import gsap from 'gsap'
import Link from './Link'
import { resolveInternalLinkUrl } from '../helpers/resolveLink'
import map from 'lodash/map'
import Count from './Count'
import { openPanel, slugs } from '../state/slices/drawer'
import { openCart } from '../state/slices/ui/cart'
import toArray from 'lodash/toArray'
import CustomEase from 'gsap/CustomEase'

const MenuItem = ({ link }) => {
  const classes = useStyles()
  const { counts } = useGlobalContext()
  const count = counts[link.reference.slug.current]

  const dispatch = useDispatch()
  const onClick = useCallback((e) => {
    if (slugs.includes(link.reference.slug.current)) {
      dispatch(openPanel({ slug: link.reference.slug.current }))
      e.preventDefault()
    }
    return false
  }, [dispatch, link])

  return (
    <li className={classes.menuItem}>
      <Link
        className={classes.link}
        to={resolveInternalLinkUrl(link.reference)}
        onClick={onClick}
        useTransitionLink={!slugs.includes(link.reference.slug.current)}
      >
        <span>{link.text}</span>
        {count && <Count className={classes.count} pad count={count} />}
      </Link>
    </li>
  )
}

export default function MobileMenu () {
  const classes = useStyles()
  const open = useSelector(isMenuOpen)
  const ref = useBodyScrollLock(open)
  const containerRef = useRef()
  const { menu, cart: { link_title: cartLinkTitle } } = useGlobalContext()
  const dispatch = useDispatch()
  const menuItemsRef = useRef()
  const titleRef = useRef()

  useEffect(() => {
    if (containerRef.current) {
      const tl = gsap.timeline()
      if (open) {
        tl.to(containerRef.current, {
          x: '0%',
          duration: 1,
          ease: CustomEase.create('drawer', '.32,0,.15,1')
        })
        tl.fromTo([titleRef.current, ...toArray(menuItemsRef.current.children)], { opacity: 0 }, { opacity: 1, duration: 1, stagger: 0.1 }, 0.15)
      } else {
        tl.to(containerRef.current, { x: '100%', duration: 0.5, ease: 'expo.out' })
        tl.to([titleRef.current, ...toArray(menuItemsRef.current.children)], { opacity: 0, duration: 0.5, ease: 'expo.out' }, 0)
      }
      return () => {
        tl.kill()
      }
    }
  }, [open])

  const onOpenCart = useCallback(() => {
    dispatch(openCart())
  }, [dispatch])

  return (
    <div className={cn(classes.container, { open })} ref={containerRef}>
      <div className={classes.inner} ref={ref}>
        <header className={classes.header}>
          <div className={classes.breadcrumb}>{'< Breadcrumb'}</div>
          <div className={classes.title} ref={titleRef}>Menu</div>
        </header>
        <nav>
          <ul className={classes.menuItems} ref={menuItemsRef}>
            {map(menu, ({ link }, i) => (
              <MenuItem key={i} link={link} />
            ))}
            <li className={classes.menuItem}>
              <button className={classes.link} onClick={onOpenCart}>
                <span>{cartLinkTitle}</span>
                <Count className={classes.count}>02</Count>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    color: 'currentColor',
    backgroundColor: theme.colors.background,
    zIndex: theme.zIndex.menu,
    pointerEvents: 'none',
    transform: 'translate(100%, 0)',
    '&.open': {
      pointerEvents: 'all'
    }
  },
  inner: {
    paddingTop: 42,
    position: 'absolute',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0
  },
  header: {
    padding: [theme.spacing(6), theme.gutter.sm]
  },
  breadcrumb: {
    marginBottom: theme.spacing(6),
    fontSize: 12,
    alignItems: 'center',
    textTransform: 'uppercase',
    lineHeight: 1.4,
    visibility: 'hidden',
    '&.show': {
      visibility: 'visible'
    }
  },
  title: {
    fontSize: 20,
    lineHeight: 1.4
  },
  menuItems: {
    display: 'block',
    listStyle: 'none',
    overflow: 'hidden',
    padding: 0,
    margin: 0,
    paddingBottom: 1
  },
  menuItem: {
    display: 'block',
    borderTop: [1, 'solid', theme.colors.border],
    borderBottom: [1, 'solid', theme.colors.border],
    marginBottom: -1
  },
  link: {
    padding: theme.gutter.sm,
    textDecoration: 'none',
    border: 'none',
    background: 'transparent',
    fontFamily: theme.fonts.body,
    fontSize: 15,
    letterSpacing: '0.03em',
    textTransform: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    textAlign: 'left',
    cursor: 'pointer'
  },
  count: {
    fontSize: 9
  }
}, { name: 'MobileMenu' })
