import React, { useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import Panel from "./Panel";
import {
  closePanel,
  getSelectedItem,
  getSelectedCategory,
  isCategoryPanelOpen,
  isDetailsPanelOpen,
} from "../../state/slices/drawer";
import { useGlobalContext } from "../SiteContext";
import ConnectedPanels, { CATEGORISE_BY_SUBSTRATE } from "./ConnectedPanels";
import ProductsList from "./ProductsList";
import ProductDetailsPanel from "./ProductDetailsPanel";
import { createUseStyles } from "react-jss";

const SLUG = "products";

export default function ProductsPanel() {
  const {
    category: fireSubstrate,
    count: fireSubstrateProductount,
    to: productPanelLink,
  } = useSelector(getSelectedCategory(SLUG)) || {
    category: undefined,
    to: undefined,
    title: undefined,
  }; // should return a fireSubstrate category
  const product = useSelector(getSelectedItem(SLUG));
  const categoryPanelOpen = useSelector(isCategoryPanelOpen(SLUG));
  const detailsPanelOpen = useSelector(isDetailsPanelOpen(SLUG));
  const globals = useGlobalContext();
  const classes = useStyles();
  const { counts, productsLink, productsFilters } = globals;
  const dispatch = useDispatch();
  const onClose = useCallback(() => {
    dispatch(closePanel({ slug: SLUG }));
  }, [dispatch]);

  const productCounts = useMemo(
    () => ({
      byCategory: counts.productsBySubstrate,
      total: counts.products,
    }),
    [counts]
  );

  return (
    <ConnectedPanels
      showCloseButton={!categoryPanelOpen}
      title="See All"
      slug={SLUG}
      categories={productsFilters}
      categoriseBy={CATEGORISE_BY_SUBSTRATE}
      link={productsLink}
      counts={productCounts}
    >
      <Panel
        title={`All ${fireSubstrate?.title}`}
        titleCount={fireSubstrateProductount}
        titleLink={productPanelLink}
        open={categoryPanelOpen}
        showCloseButton={categoryPanelOpen && !detailsPanelOpen}
        onClose={onClose}
        index={2}
      >
        <ProductsList fireSubstrate={fireSubstrate} />
      </Panel>
      {detailsPanelOpen && (
        <Panel
          title="Product Details"
          open={detailsPanelOpen}
          showCloseButton
          showHeader={false}
          fill
          className={classes.detailsPanel}
          index={3}
        >
          <ProductDetailsPanel product={product?.product} />
        </Panel>
      )}
    </ConnectedPanels>
  );
}

const useStyles = createUseStyles({
  detailsPanel: {
    overflow: "hidden",
  },
});
