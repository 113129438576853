import { useEffect, useState, useRef } from 'react'
import get from 'lodash/get'
import each from 'lodash/each'
import isEmpty from 'lodash/isEmpty'
import gsap from 'gsap'
import { useInView } from 'react-intersection-observer'
import useComposeRefs from '../helpers/composeRefs'

function makeLottieTween (animationItem) {
  const tweener = { value: animationItem.currentFrame }
  const duration = animationItem.getDuration()
  const toValue = animationItem.totalFrames - 1
  let tween = gsap.fromTo(
    tweener,
    { value: 0 },
    {
      value: toValue,
      duration,
      ease: 'none',
      paused: animationItem.isPaused,
      onUpdate () {
        animationItem.goToAndStop(tweener.value, true)
      }
    }
  )
  const markers = get(animationItem, ['animationData', 'markers'])
  if (!isEmpty(markers)) {
    const timeline = gsap.timeline({
      paused: animationItem.isPaused
    }).add(tween, 0)
    tween.resume()
    each(get(animationItem, ['animationData', 'markers']), ({ tm: frame, cm: label }) => {
      timeline.addLabel(label, duration * (frame / toValue))
    })
    tween = timeline
  }
  tween.lottieAnimationItem = animationItem
  return tween
}

export default function useLottieAnimation (animationData) {
  const [inViewRef, inView] = useInView({ threshold: 0, triggerOnce: true })
  const containerRef = useRef()
  const [tween, setTween] = useState()
  const targetRef = useComposeRefs(containerRef, inViewRef)
  useEffect(() => {
    const createLottieAnimation = async () => {
      const lottie = (await import('lottie-web')).default
      const animationItem = lottie.loadAnimation({
        container: containerRef.current,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData
      })
      const t = makeLottieTween(animationItem)
      setTween(t)
      return () => {
        t.current.kill()
        t.current.lottieAnimationItem.destroy()
      }
    }
    if (inView) {
      createLottieAnimation()
    }
  }, [animationData, inView]) /* eslint-disable-line react-hooks/exhaustive-deps */

  return [targetRef, tween]
}
