import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import { HorizontalLine } from '../Lines'
import ResponsiveImage from '../ResponsiveImage'
import RichText from '../RichText'
import Section from '../Section'
import { Caption } from '../Typography'
import SliceTitle from './SliceTitle'

const Integration = ({ integration }) => {
  const { title, subtitle, image, _rawCopy } = integration
  const classes = useStyles()
  return (
    <Section tag='div' grid fullWidth className={classes.integration} noBottomMargin>
      <div className={classes.imageContainer}>
        <ResponsiveImage image={image} aspect={380 / 210} />
      </div>
      <div className={classes.title}>{title}</div>
      <Caption className={classes.caption}>{subtitle}</Caption>
      {_rawCopy && <RichText blocks={_rawCopy.text} className={classes.richText} />}
      <HorizontalLine extend position='bottom' className={classes.integrationLine} />
    </Section>
  )
}

export default function IntegrationsList ({ slice, id }) {
  const classes = useStyles()
  const { title, integrations } = slice

  return (
    <Section grid className={classes.section} id={id}>
      <SliceTitle>{title}</SliceTitle>
      <div className={classes.integrations}>
        {integrations && integrations.map(integration => (
          <Integration key={integration.title} integration={integration} />
        ))}
      </div>
      <HorizontalLine extend position='bottom' />
    </Section>
  )
}

const useStyles = createUseStyles({
  section: {
    position: 'relative'
  },
  integrations: {
    marginBottom: theme.section.marginBottom.sm,
    gridColumn: '1 / span 4',
    [theme.breakpoints.up('md')]: {
      gridColumn: '1 / span 7',
      marginBottom: theme.section.marginBottom.md
    }
  },
  integration: {
    position: 'relative',
    paddingBottom: theme.gutter.sm,
    marginBottom: theme.gutter.sm,
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.gutter.md,
      marginBottom: theme.gutter.md
    },
    '&:last-child': {
      marginBottom: 0
    }
  },
  integrationLine: {
    opacity: 0.3
  },
  imageContainer: {
    gridColumn: '1 / span 4',
    [theme.breakpoints.up('md')]: {
      gridColumn: '1 / span 2',
      gridRow: '1 / span 2'
    }
  },
  title: {
    gridColumn: '1 / span 4',
    [theme.breakpoints.up('md')]: {
      gridColumn: '3 / span 2',
      gridRow: '1'
    }
  },
  richText: {
    gridColumn: '1 / span 4',
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      gridColumn: '5 / span 2',
      gridRow: '1 / span 2',
      marginBottom: 0
    }
  },
  caption: {
    opacity: 0.5,
    gridColumn: '1 / span 4',
    gridRow: '4',
    [theme.breakpoints.up('md')]: {
      gridColumn: '3 / span 2',
      gridRow: '2',
      alignSelf: 'end'
    }
  }
})
