import React from "react";
import { createUseStyles } from "react-jss";
import theme from "../../style/theme";
import Section from "../Section";
import { VerticalLine, HorizontalLine } from "../Lines";

function AboutHero({ slice }) {
  const classes = useStyles();
  const { title } = slice;
  return (
    <Section grid className={classes.section} noBottomMargin>
      <h1 className={classes.title}>{title}</h1>
      <div className={classes.verticalLine}>
        <VerticalLine position="right" />
      </div>
      <div className={classes.horizontalLine}>
        <HorizontalLine extend position={"bottom"} />
      </div>
    </Section>
  );
}

const useStyles = createUseStyles({
  title: {
    gridRow: 2,
    marginTop: theme.spacing(46),
    gridColumn: "1 / span 3",
    [theme.breakpoints.up("md")]: {
      gridColumn: "1 / span 4",
      marginTop: theme.spacing(36),
      marginLeft: 0,
    },
    [theme.breakpoints.up("lg")]: {
      gridColumn: "1 / span 3",
    },
  },
  verticalLine: {
    position: "relative",
    gridRow: "1 / span 3",
    gridColumn: "4",
    marginLeft: theme.spacing(9),
    width: 1,
    [theme.breakpoints.up("md")]: {
      marginLeft: 0,
      gridColumn: "5",
      gridRow: "1 / span 3",
      width: "100%",
    },
  },
  horizontalLine: {
    position: "relative",
    height: 1,
    gridRow: 3,
    gridColumn: "1 / span 4",
    [theme.breakpoints.up("md")]: {
      gridRow: 3,
      gridColumn: "1 / span 7",
    },
  },
});

export default AboutHero;
