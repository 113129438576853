import React, { useMemo, useCallback, useState } from "react";
import LinkList from "../LinkList";
import { resolvePageUrl } from "../../hooks/useInternalLinkUrl";
import ProductProjectItem from "./ProductProjectItem";
import { map } from "lodash";

const AboutLists = ({ aboutLists, aboutLink }) => {
  const [selectedPage, setSelectedPage] = useState(null);
  const onMouseEnterAbout = useCallback((item) => {
    setSelectedPage(item);
  }, []);
  const items = useMemo(() => {
    return map(aboutLists, (aboutItem) => ({
      to: resolvePageUrl(aboutLink.reference, aboutItem),
      page: aboutItem,
      text: <ProductProjectItem title={aboutItem.title} />,
      arrow: true,
    }));
  }, [aboutLists, aboutLink.reference]);

  return (
    <LinkList
      items={items}
      hover
      onMouseEnter={onMouseEnterAbout}
      selectedItem={selectedPage}
    />
  );
};

export default AboutLists;
