import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import theme from '../style/theme'

export default function Placeholder ({ className, fill }) {
  const classes = useStyles()
  return (
    <div className={cn(className, classes.placeholder, { fill })} dangerouslySetInnerHTML={{ __html: '&nbsp' }} />
  )
}

const useStyles = createUseStyles({
  placeholder: {
    width: '100%',
    backgroundColor: theme.colors.text,
    opacity: 0.1,
    borderRadius: '20px',
    '&.fill': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }
  }
})
