
import { createSlice } from '@reduxjs/toolkit'

const fireGroupCalculatorSlice = createSlice({
  name: 'fireGroupCalculatorDrawer',
  initialState: {
    open: false,
  },
  reducers: {
    openFireGroupCalculatorDrawer: (state) => {
      state.open = true
    },
    closeFireGroupCalculatorDrawer: (state) => {
      state.open = false
    },
  }
})

export const {
  openFireGroupCalculatorDrawer,
  closeFireGroupCalculatorDrawer,
} = fireGroupCalculatorSlice.actions

export const isFireGroupCalculatorDrawerOpen = state => state.ui.fireGroupCalculatorDrawer.open

export default fireGroupCalculatorSlice.reducer
