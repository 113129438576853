exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-data-js": () => import("./../../../src/templates/aboutData.js" /* webpackChunkName: "component---src-templates-about-data-js" */),
  "component---src-templates-articles-data-js": () => import("./../../../src/templates/articlesData.js" /* webpackChunkName: "component---src-templates-articles-data-js" */),
  "component---src-templates-documents-data-js": () => import("./../../../src/templates/documentsData.js" /* webpackChunkName: "component---src-templates-documents-data-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-products-data-js": () => import("./../../../src/templates/productsData.js" /* webpackChunkName: "component---src-templates-products-data-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-projects-data-js": () => import("./../../../src/templates/projectsData.js" /* webpackChunkName: "component---src-templates-projects-data-js" */)
}

