import React from 'react'

import { Caption } from '../Typography'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import Placeholder from '../Placeholder'

export default function ProductProjectItem ({ title, field1Label, field1Value, field2Label, field2Value, placeholder }) {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <span className={classes.title}>
        {title}
        {placeholder && <Placeholder />}
      </span>
      <div className={classes.fieldset}>
        {field1Label && (
          <div className={classes.field1}>
            <Caption className={classes.caption}>{field1Label}</Caption>
            <span className={classes.fieldValue}>
              {field1Value}
              {placeholder && <Placeholder />}
            </span>
          </div>
        )}
        {field2Label && (
          <div className={classes.field2}>
            <Caption className={classes.caption}>{field2Label}</Caption>
            <span className={classes.fieldValue}>
              {field2Value}
              {placeholder && <Placeholder />}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    overflow: 'hidden',
    width: '100%'
  },
  title: {
    margin: [0, theme.spacing(2), theme.spacing(2), 0],
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 15
  },
  fieldset: {
    display: 'flex',
    margin: [0, -theme.spacing(6)],
    '& > *': {
      margin: [0, theme.spacing(6)]
    }
  },
  field1: {
    width: '40%'
  },
  field2: {
    width: '60%'
  },
  caption: {
    opacity: 0.5
  },
  fieldValue: {
    display: 'flex',
    fontSize: 12,
    flexDirection: 'column'
  }
})
