import { gsap } from "gsap";
import toArray from "lodash/toArray";
import React, { useEffect, useRef } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";
import { isPreloading } from "../../../state/slices/preload";
import theme from "../../../style/theme";
import Button from "../../Button";
import { HlsPlayerMemo } from "../../HlsPlayer";
import ResponsiveImage from "../../ResponsiveImage";
import Section from "../../Section";
import { Caption } from "../../Typography";
import WaveTextAnimation from "../../WaveTextAnimation";
import LineAnimations from "./LineAnimations";

export default function Hero({ slice, id }) {
  const {
    title,
    copy,
    captions,
    cta,
    image,
    imageCaptions,
    showVideo,
    vimeoData,
    resolution,
    isLooping = false,
    icon,
  } = slice;
  const classes = useStyles({ hiddenImage: showVideo });
  const imageRef = useRef();
  const contentRef = useRef();
  const linesRef = useRef();
  const textRef = useRef();
  const preloading = useSelector(isPreloading);

  useEffect(() => {
    if (!preloading) {
      const masterTimeline = gsap.timeline();
      const [, copy, captions, button] = toArray(contentRef.current.children);
      masterTimeline.add(linesRef.current.createTimeline());
      masterTimeline.add(textRef.current.createTimeline(), 1.2);
      masterTimeline.to(
        [copy, captions, button],
        { duration: 1.5, opacity: 1, y: 0, stagger: 0.2, ease: "expo.inOut" },
        1.5
      );
      masterTimeline.to(
        imageRef.current,
        { opacity: 1, ease: "expo.inOut", duration: 1 },
        2
      );
    }
  }, [preloading]);

  return (
    <section className={classes.section} id={id}>
      <Section
        grid
        className={classes.content}
        tag="div"
        noBottomMargin
        ref={contentRef}
      >
        <WaveTextAnimation
          ready={false}
          ref={textRef}
          animateWhenInView={false}
        >
          <h1 className={classes.title}>{title}</h1>
        </WaveTextAnimation>
        <p className={classes.copy}>{copy}</p>
        <div className={classes.captions}>
          {captions &&
            captions.map((caption) => (
              <Caption key={caption}>{caption}</Caption>
            ))}
          {Boolean(icon?.asset?.url) && (
            <img
              src={icon.asset.url}
              alt="Suply Nation Certified"
              className={classes.logo}
            />
          )}
        </div>
        <div className={classes.button}>
          <Button link={cta} />
        </div>
        <LineAnimations ref={linesRef} />
      </Section>
      {showVideo && (
        <HlsPlayerMemo
          source={vimeoData.hlsPlayerLink}
          resolution={resolution}
          isLooping={isLooping}
        />
      )}
      <ResponsiveImage
        className={classes.image}
        image={image}
        aspect={1920 / 1080}
        mobileAspect={375 / 460}
        loading="eager"
        ref={imageRef}
      >
        <Section
          className={classes.imageCaptions}
          grid
          tag="div"
          noBottomMargin
        >
          {imageCaptions &&
            imageCaptions.map((caption) => (
              <Caption className={classes.imageCaption} key={caption}>
                {caption}
              </Caption>
            ))}
        </Section>
      </ResponsiveImage>
      <div className={classes.line} />
    </section>
  );
}

const useStyles = createUseStyles({
  section: {
    marginBottom: theme.spacing(6),
  },
  content: {
    height: "calc(100vh - 250px)",
    paddingBottom: theme.spacing(5),
    gridTemplateRows: "auto min-content min-content",
    alignItems: "end",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      paddingBottom: theme.spacing(10),
      gridTemplateRows: "auto",
      alignItems: "start",
      height: "auto",
      paddingTop: "15vw",
    },
  },
  title: {
    gridColumn: "1 / span 4",
    fontSize: 42,
    lineHeight: 0.9,
    maxWidth: 600,
    marginBottom: theme.spacing(7),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(10),
      maxWidth: "100%",
      gridColumn: "1 / span 7",
      fontSize: "8.5vw",
    },
    [theme.breakpoints.up("lg")]: {},
  },
  copy: {
    textIndent: theme.styles.textIndent,
    maxWidth: 360,
    gridColumn: "1 / span 4",
    marginBottom: 0,
    opacity: 0,
    [theme.breakpoints.up("md")]: {
      gridColumn: "2 / span 2",
    },
  },
  captions: {
    display: "none",
    opacity: 0,
    [theme.breakpoints.up("md")]: {
      gridColumn: "5 / span 3",
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      gap: 24,
    },
  },
  button: {
    gridColumn: "1 / span 4",
    opacity: 0,
    [theme.breakpoints.up("md")]: {
      gridColumn: "2 / span 2",
    },
  },
  image: {
    opacity: 0,
    [theme.breakpoints.up("md")]: {
      display: ({ hiddenImage }) => (hiddenImage ? "none" : "block"),
    },
  },
  imageCaptions: {
    marginTop: theme.spacing(6),
    color: theme.colors.white,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(10),
    },
  },
  imageCaption: {
    gridColumn: "span 2",
    [theme.breakpoints.up("md")]: {
      gridColumn: "span 1",
      "&:first-child": {
        gridColumn: 5,
      },
    },
  },
  line: {
    width: "100%",
    height: 1,
    backgroundColor: theme.colors.border,
    opacity: 1,
  },
  logo: {
    maxWidth: "80px",
    marginTop: "-7px",
  },
});
