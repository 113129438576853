import React, { useCallback, useState, useRef } from "react";
import { createUseStyles } from "react-jss";
import { navigate } from "gatsby";
import theme from "../../../style/theme";
import { HorizontalLine, VerticalLine } from "../../Lines";
import Section from "../../Section";
import { AnimatedLink } from "../../Link";
import ProjectImageCarousel from "./ProjectImageCarousel";
import useStandardInAnimation from "../../Animation/useStandardInAnimation";
import useComposeRefs from "../../../helpers/composeRefs";
import toArray from "lodash/toArray";
import Field from "../../Field";
import UseInternalLinkUrl from "../../../hooks/useInternalLinkUrl";
import NextPreviousButtons from "../../NextPreviousButtons";

const ProjectItem = ({ project }) => {
  const classes = useStyles();
  const {
    project: { title, category, architect, product },
    images: { images },
  } = project;
  const [slideIndex, setSlideIndex] = useState(0);
  const contentRef = useRef();

  const onBackClick = useCallback(() => {
    setSlideIndex((i) => i - 1);
  }, []);
  const onNextClick = useCallback(() => {
    setSlideIndex((i) => i + 1);
  }, []);
  const onIndexChanged = useCallback((i) => {
    setSlideIndex(i);
  }, []);

  const url = UseInternalLinkUrl(project.project);

  const [inViewRef] = useStandardInAnimation(
    useCallback(() => {
      return [
        contentRef.current.children[0],
        ...toArray(contentRef.current.children[1].children),
      ];
    }, [])
  );

  const onClick = useCallback(() => {
    navigate(url);
  }, [url]);

  return (
    <Section
      tag="div"
      grid
      noBottomMargin
      fullWidth
      className={classes.project}
    >
      <HorizontalLine animationOrigin="28%" />
      <div
        className={classes.content}
        ref={useComposeRefs(inViewRef, contentRef)}
      >
        <Field className={classes.titleField} label="Project">
          <AnimatedLink to={url} className={classes.title}>
            {title}
          </AnimatedLink>
        </Field>
        <div className={classes.detailsContainer}>
          {category && (
            <Field label="Category" className={classes.onlyDesktop}>
              {category?.title}
            </Field>
          )}
          {product && (
            <Field label="Sound Profile" className={classes.onlyDesktop}>
              {product?.soundProfile}
            </Field>
          )}
          {product && <Field label="Product">{product?.title}</Field>}
          <Field label="Architect" className={classes.onlyDesktop}>
            {architect}
          </Field>
          <Field
            label={`Images ${slideIndex + 1}/${images.length}`}
            className={classes.onlyDesktop}
          >
            <NextPreviousButtons
              onBackClick={onBackClick}
              onNextClick={onNextClick}
              backDisabled={slideIndex <= 0}
              nextDisabled={slideIndex >= images.length - 1}
            />
          </Field>
        </div>
        <VerticalLine animationOrigin="0%" className={classes.verticalLine} />
      </div>
      <div className={classes.images}>
        <ProjectImageCarousel
          images={images}
          url={url}
          index={slideIndex}
          onIndexChanged={onIndexChanged}
          onClick={onClick}
        />
      </div>
    </Section>
  );
};

export default function ProjectList({ projects }) {
  const classes = useStyles();
  return (
    <div className={classes.projects}>
      {projects &&
        projects.map((project, i) => <ProjectItem key={i} project={project} />)}
      <HorizontalLine animationOrigin="28%" position="bottom" />
    </div>
  );
}

const useStyles = createUseStyles({
  projects: {
    position: "relative",
  },
  project: {
    position: "relative",
    padding: [0, theme.gutter.sm],
    [theme.breakpoints.up("md")]: {
      padding: [0, theme.gutter.md],
    },
  },
  content: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gap: theme.gutter.sm,
    position: "relative",
    padding: [theme.gutter.sm, 0, 0],
    gridColumn: "1 / span 4",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      gridColumn: "1 / span 2",
      padding: [theme.gutter.md, theme.gutter.md, theme.gutter.md, 0],
      flexDirection: "column",
    },
  },
  images: {
    padding: [0, 0, theme.gutter.sm],
    gridColumn: "1 / span 4",
    [theme.breakpoints.up("md")]: {
      gridColumn: "3 / span 5",
      padding: [theme.gutter.md, 0],
    },
  },
  verticalLine: {
    display: "none",
    zIndex: 1,
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  onlyDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  titleField: {
    gridColumn: "1 / span 2",
    flexGrow: 1,
    flexShrink: 1,
    opacity: 0,
  },
  title: {
    fontSize: 12,
    textDecoration: "none",
    [theme.breakpoints.up("md")]: {
      fontSize: 24,
    },
  },
  detailsContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: theme.gutter.sm,
    [theme.breakpoints.up("md")]: {
      gap: theme.gutter.md,
    },
    "& > *": {
      opacity: 0,
    },
  },
  paginationButton: {
    padding: [6, 8],
    marginTop: 8,
    "&:first-child": {
      marginRight: 8,
    },
    "& svg": {
      margin: 0,
    },
  },
});
