import React, { forwardRef } from "react";
import useFetch from "use-http";
import get from "lodash/get";
import { useForm } from "react-hook-form";
import { createUseStyles } from "react-jss";
import cn from "classnames";
import { HorizontalLine } from "../Lines";
import theme from "../../style/theme";
import { useDispatch } from "react-redux";
import { setCartSuccess } from "../../state/slices/ui/cart";

const FormField = ({ field, register, disabled, hasError }) => {
  const { name, label, type, required, pattern, options = null } = field;
  const classes = useStyles();
  return (
    <div
      className={cn(
        { hasError },
        type === "textarea" ? classes.textareaContainer : classes.inputContainer
      )}
    >
      <label className={classes.inputLabel} htmlFor={name}>
        {label}
      </label>
      {type === "select" || type === "checkbox" || type === "textarea" ? (
        <React.Fragment>
          {type === "select" && (
            <select
              className={classes.input}
              id={name}
              name={name}
              type={type}
              disabled={disabled}
              {...register(name, {
                required,
                pattern,
              })}
            >
              {options &&
                options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </select>
          )}
          {type === "checkbox" && (
            <input
              type={type}
              className={classes.inputCheckbox}
              id={name + "-quote"}
              name={name}
              disabled={disabled}
              {...register(name, {
                required,
                pattern,
              })}
            />
          )}
          {type === "textarea" && (
            <textarea
              className={classes.input}
              id={name + "-quote"}
              name={name}
              type={type}
              placeholder={""}
              disabled={disabled}
              {...register(name, {
                required,
                pattern,
              })}
              onInput={(e) => {
                e.target.style.height = "";
                e.target.style.height = e.target.scrollHeight + "px";
              }}
            />
          )}
        </React.Fragment>
      ) : (
        <input
          className={classes.input}
          id={name}
          name={name}
          type={type}
          disabled={disabled}
          {...register(name, {
            required,
            pattern,
          })}
        />
      )}

      <HorizontalLine
        className={cn(classes.bottomBorder, "bottomBorder")}
        position="bottom"
      />
    </div>
  );
};

const formFields = [
  {
    label: "First Name",
    name: "first_name",
    type: "text",
    required: true,
    pattern: null,
  },
  {
    label: "Last Name",
    name: "last_name",
    type: "text",
    required: true,
    pattern: null,
  },
  {
    label: "Email",
    name: "email",
    type: "email",
    required: true,
    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  },
  {
    label: "Company Name",
    name: "company",
    type: "text",
    required: true,
    pattern: null,
  },
  {
    label: "Mobile Number",
    name: "phone",
    type: "text",
    required: true,
    pattern: null,
  },
  {
    label: "Street Address",
    name: "street",
    type: "text",
    required: true,
    pattern: null,
  },
  {
    label: "Suburb",
    name: "city",
    type: "text",
    required: true,
    pattern: null,
  },
  {
    label: "State",
    name: "state",
    type: "select",
    options: [
      { value: "", label: "" },
      {
        value: "Australian Capital Territory",
        label: "Australian Capital Territory",
      },
      { value: "New South Wales", label: "New South Wales" },
      { value: "Northern Territory", label: "Northern Territory" },
      { value: "Queensland", label: "Queensland" },
      { value: "South Australia", label: "South Australia" },
      { value: "Tasmania", label: "Tasmania" },
      { value: "Victoria", label: "Victoria" },
      { value: "Western Australia", label: "Western Australia" },
    ],
    required: true,
    pattern: null,
  },
  {
    label: "Project Name",
    name: "00N28000003bP1f",
    type: "text",
    required: true,
    pattern: null,
  },
  {
    label: "Project Location",
    name: "00N0K00000Lf8yy",
    type: "select",
    options: [
      { value: "", label: "" },
      { value: "NSW", label: "NSW" },
      { value: "NT", label: "NT" },
      { value: "QLD", label: "QLD" },
      { value: "SA", label: "SA" },
      { value: "TAS", label: "TAS" },
      { value: "VIC", label: "VIC" },
      { value: "WA", label: "WA" },
    ],
    required: true,
    pattern: null,
  },
  {
    label: "Sample Details (Profile, Finish)",
    name: "More_Information__c",
    type: "textarea",
    required: false,
    pattern: null,
  },
  {
    label: "STAY UPDATED",
    name: "Active_Email_Subscriber__c",
    type: "checkbox",
    required: false,
    pattern: null,
  },
];

const SalesforceForm = forwardRef(
  ({ className, cartItems }, submitButtonRef) => {
    const classes = useStyles();
    const {
      post,
      response,
      loading,
      error: apiError,
    } = useFetch("/api/salesforce");
    const {
      handleSubmit,
      register,
      formState: { errors },
    } = useForm();
    const dispatch = useDispatch();

    // handle form submission
    const onSubmit = (data, e) => {
      e.preventDefault();
      const sampleNames = cartItems
        .map((item) => get(item, "product.title", null))
        .filter((item) => item)
        .join(", ");
      const formData = {
        ...data,
        oid: "00D28000000XFTy",
        lead_source: "Website",
        lead_status: "Initial Enquiry",
        "00N0K00000Lf9xc": sampleNames,
        country: "Australia",
        // debug: "1",
        // debugEmail: "vlad.s@halo-lab.team",
      };
      const postSaleforceData = async () => {
        await post(formData);
        if (response.ok) {
          dispatch(setCartSuccess());
        }
      };
      postSaleforceData();
    };

    const errorKeys = Object.keys(errors);
    const errorArray = errorKeys.map((key) => ({
      key,
      ...errors[key],
    }));
    const errorMessages = errorArray
      .map((error, index) => {
        const errorFieldName = errorKeys[index];
        const matchingField = formFields.find(
          (field) => field.name === errorFieldName
        );
        if (error.type !== "required") {
          return `Please check ${matchingField.label} is valid.`;
        } else {
          return null;
        }
      })
      .filter((e) => e);
    const errorMissingRequireds =
      errorArray.filter((e) => e.type === "required").length > 0;

    return (
      <div className={classes.formContainer}>
        <form
          className={cn(classes.form, className || null)}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={classes.formControls}>
            {formFields &&
              formFields.map((field) => (
                <FormField
                  key={field.name}
                  field={field}
                  register={register}
                  hasError={errorKeys.includes(field.name)}
                  disabled={loading}
                />
              ))}
          </div>
          <button
            className={classes.hiddenButton}
            ref={submitButtonRef}
            type="submit"
          >
            <span>OK</span>
          </button>
        </form>
        <div className={classes.errors}>
          {errorMessages &&
            errorMessages.map((error) => (
              <p key={error} className={classes.error}>
                {error}
              </p>
            ))}
          {errorMissingRequireds && (
            <p className={classes.error}>Please complete all fields</p>
          )}
          {apiError && (
            <p className={classes.error}>
              Sorry, something went wrong! Try again later
            </p>
          )}
        </div>
      </div>
    );
  }
);

const useStyles = createUseStyles({
  formContainer: {
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
      paddingBottom: theme.spacing(10),
    },
  },
  form: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(5),
    },
  },
  errors: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  error: {
    color: "#CF6C4F",
    fontFamily: theme.fonts.caption,
    fontSize: 10,
    lineHeight: 1,
    textTransform: "uppercase",
    textAlign: "center",
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(5),
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    height: 50,
    "&.hasError": {
      color: "#CF6C4F",
      "& > label": {
        opacity: 1,
      },
    },
    "&:focus-within .bottomBorder": {
      opacity: 1,
    },
  },
  inputLabel: {
    flex: 1,
    fontFamily: theme.fonts.body,
    fontSize: 10,
    lineHeight: 1,
    textTransform: "uppercase",
    opacity: 0.5,
  },
  input: {
    padding: [16, 0],
    flex: 2,
    fontFamily: theme.fonts.body,
    fontSize: 12,
    lineHeight: 1,
    border: "none",
    borderRadius: 0,
    backgroundColor: "transparent",
    outline: "none",
    color: "currentColor",
    "&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus &:-webkit-autofill, &:-webkit-autofill:hover &:-webkit-autofill:focus":
      {
        boxShadow: "0 0 0px 1000px transparent inset",
        transition: "background-color 5000s ease-in-out 0s",
        caretColor: "currentColor",
      },
  },
  textareaContainer: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    flexGrow: 2,
    padding: [20, 0, 0],
    "& label": {
      flex: 0,
    },
    "& textarea": {
      flex: "auto",
      maxWidth: "100%",
      resize: "none",
      fontSize: 12,
      "&::placeholder": {
        opacity: 0.3,
      },
    },
  },
  inputCheckbox: {
    cursor: "pointer",
    WebkitAppearance: "none",
    appearance: "none",
    backgroundColor: "#fff",
    margin: "0 4px 0 auto",
    font: "inherit",
    color: "#3A3A1F",
    outline: "1px solid #3A3A1F",
    width: "14px",
    height: "14px",
    display: "grid",
    placeContent: "center",
    transition: " background-color 0.2s, border-color 0.2s ease-in-out",
    "&:checked": {
      backgroundColor: "#3A3A1F",
      border: "1px solid #fff",
    },
  },
  bottomBorder: {
    opacity: 0.2,
    transition: "opacity 0.25s ease-in-out",
  },
  hiddenButton: {
    display: "none",
  },
});

export default SalesforceForm;
