import React, { useEffect, useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import filter from 'lodash/filter'

import LinkList from '../LinkList'
import { fetchProjects, getItemsSelector, isLoadingSelector, isLoadedSelector } from '../../state/slices/data/lists'
import { getSelectedItem, openDetailsPanel } from '../../state/slices/drawer'
import { useGlobalContext } from '../SiteContext'
import { resolvePageUrl } from '../../hooks/useInternalLinkUrl'
import ProductProjectItem from './ProductProjectItem'
import range from 'lodash/range'
import map from 'lodash/map'

export default function ProjectsList ({ category }) {
  const { projectsLink } = useGlobalContext()
  const loading = useSelector(isLoadingSelector('projects'))
  const loaded = useSelector(isLoadedSelector('projects'))
  const projects = useSelector(getItemsSelector('projects'))
  const selectedProject = useSelector(getSelectedItem('projects'))
  const dispatch = useDispatch()

  const onMouseEnterProject = useCallback((item) => {
    dispatch(openDetailsPanel({ slug: 'projects', item }))
  }, [dispatch])

  useEffect(() => {
    if (!loaded) {
      dispatch(fetchProjects())
    }
  }, [dispatch]) /* eslint-disable-line */

  const items = useMemo(() => {
    if (projects && category) {
      return filter(projects, x => x.category.slug.current === category.slug.current)
        .map(project => ({
          to: resolvePageUrl(projectsLink.reference, project),
          text: <ProductProjectItem
            title={project.title}
            field1Label='Products'
            field1Value={map(project.products, ({ title }) => <span key={title}>{title}</span>)}
            field2Label='Location'
            field2Value={project.location}
                />,
          project: project,
          arrow: true
        }))
    }
    return []
  }, [category, projects]) /* eslint-disable-line */

  const placeholders = useMemo(() => {
    const items = range(2)
    return items.map(() => ({
      text: <ProductProjectItem placeholder field1Label='Category' field2Label='Product' />,
      arrow: true
    }))
  }, [])

  if (!loaded || loading) {
    return <LinkList items={placeholders} />
  }

  return (
    <LinkList key={category?.slug?.current} items={items} onMouseEnter={onMouseEnterProject} selectedItem={selectedProject} />
  )
}
