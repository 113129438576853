import React, {
    useCallback,
    useRef,
    useEffect
  } from 'react'
  import { useDispatch, useSelector } from 'react-redux'
  import { createUseStyles } from 'react-jss'
  import cn from 'classnames'
  import gsap from 'gsap'
  import { useLocation } from '@reach/router'
  import CustomEase from 'gsap/CustomEase'
  import {
    closeFireGroupCalculatorDrawer,
    isFireGroupCalculatorDrawerOpen,
    openFireGroupCalculatorDrawer,
  } from '../state/slices/ui/fireGroupCalculatorDrawer'
  import theme from '../style/theme'
  import CloseIcon from './Drawer/close.inline.svg'
import TypeformEmbed from './TypeformEmbed'
import { useGlobalContext } from './SiteContext'

export const FIRE_GROUP_RATING_CALCULATOR_SLUG = 'group-fire-rating-calculator'
  
  export default function FireGroupCalculatorDrawer () {
    const classes = useStyles()
    const containerRef = useRef()
    const drawerRef = useRef()
    const drawerBodyRef = useRef()
    const overlayRef = useRef()
    const dispatch = useDispatch()
    const { search: urlQueryParams } = useLocation()
    const { groupFireCalculatorTypeformId } = useGlobalContext()
    const open = useSelector(isFireGroupCalculatorDrawerOpen)

    useEffect(() => {
      // If the fire calculator query param is present when the resource drawer is opened, then open the fire calculator category
      if(new URLSearchParams(urlQueryParams).get(FIRE_GROUP_RATING_CALCULATOR_SLUG) !== null){
        dispatch(openFireGroupCalculatorDrawer())
      }
    }, [dispatch, urlQueryParams])
  
    useEffect(() => {
      // If selected category is the fire-calculator, then add the fire-rating-calculator query param to the current page URL
      if(open){
        const params = new URLSearchParams({[FIRE_GROUP_RATING_CALCULATOR_SLUG]: '' });
        const relativePath = window.location.pathname + '?' + params.toString()
        window.history.pushState(null, '', relativePath)
      } else {
        const relativePath = window.location.pathname
        window.history.pushState(null, '', relativePath)
      }

      if (containerRef.current) {
        gsap.set(containerRef.current, { pointerEvents: open ? 'all' : 'none' })
        gsap.to(overlayRef.current, { opacity: open ? 0.6 : 0, duration: open ? 1 : 1.5, ease: 'expo.out', overwrite: true })
        gsap.to(drawerBodyRef.current, { opacity: open ? 1 : 0, duration: open ? 1 : 1.5, overwrite: true, delay: 0.5 })
        gsap.to(drawerRef.current, { x: open ? '0%' : '-100%', duration: 1, ease: CustomEase.create('drawer', '.32,0,.15,1') })
      }
    }, [open])
  
    const onClose = useCallback(() => {
      dispatch(closeFireGroupCalculatorDrawer())
    }, [dispatch])

    if(!groupFireCalculatorTypeformId) return null
  
    return (
      <section ref={containerRef} className={classes.container}>
        <div className={classes.overlay} ref={overlayRef} onClick={onClose} role='button' aria-label='Close Form' tabIndex={-1} onKeyDown={null} />
        <div className={classes.drawerWrapper} ref={drawerRef}>
            <div className={classes.drawerContainer}>
                <header className={cn(classes.header)}>
                    <div className={classes.headerTop}>
                      <h2 className={classes.title}>Group Fire Rating Calculator</h2>
                      <button onClick={onClose} className={classes.closeButton} aria-label='Close Form'><CloseIcon /></button>
                    </div>
                </header>
                <div className={classes.drawerBody} ref={drawerBodyRef}>
                  <TypeformEmbed id={groupFireCalculatorTypeformId} open={open} />
                </div>
            </div>
        </div>
      </section>
    )
  }
  
  const useStyles = createUseStyles({
    container: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      zIndex: theme.zIndex.drawer,
      pointerEvents: 'none',
      overflow: 'hidden'
    },
    overlay: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      backgroundColor: theme.colors.black,
      opacity: 0
    },
    drawerWrapper: {
      position: 'absolute',
      left: 0,
      top: 0,
      height: '100%',
      width: '100%',
      backgroundColor: theme.colors.background,
      transform: 'translate(-100%, 0)',
      [theme.breakpoints.up('md')]: {
        height: 'auto',
        bottom: 0,
        // padding: theme.gutter.md,
        width: 480
      }
    },
    drawerContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative'
    },
    header: {
      flexGrow: 0,
      flexShrink: 0,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      margin: [theme.spacing(5), theme.gutter.sm, 0],
      [theme.breakpoints.up('md')]: {
        margin: [theme.gutter.md, theme.gutter.md, 0]
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: 1,
        backgroundColor: theme.colors.border,
        opacity: 0.3
      }
    },
    headerTop: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'start',
      marginBottom: theme.spacing(5),
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(5)
      }
    },
    drawerBody: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      flexShrink: 1,
      overflow: 'auto',
      position: 'relative',
      '&.empty:after': {
        display: 'none'
      },
      padding: [theme.gutter.sm],
      paddingTop: 0,
      marginTop: -8,
      [theme.breakpoints.up('md')]: {
        padding: [theme.gutter.md],
        paddingTop: 0
      }
    },
    title: {
      fontSize: 20,
      display: 'flex',
      alignItems: 'center',
      textTransform: 'none',
      fontFamily: theme.fonts.body
    },
    closeButton: {
      marginLeft: 'auto',
      padding: 0,
      margin: 0,
      backgroundColor: 'transparent',
      border: 'none',
      borderRadius: 0,
      cursor: 'pointer'
    },
  })
  