import inDOM from 'dom-helpers/canUseDOM'
import { createSlice, createAction, createSelector } from '@reduxjs/toolkit'
import { current, keys, values } from '../../style/breakpoints'
import { route } from './route'
import { getPage } from './data/page'

export const rehydrated = createAction('rehydrated')

const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    breakpoint: current(),
    previousBreakpoint: current(),
    breakpointSet: inDOM,
    menuOpen: false
  },
  reducers: {
    breakpointChanged: (state, action) => {
      state.previousBreakpoint = state.breakpoint
      state.breakpoint = action.payload
      state.breakpointSet = true
      if (state.previousBreakpoint) {
        const fromMobileToDesktop = values[state.previousBreakpoint] < values.md && values[state.breakpoint] >= values.md
        const fromDesktopToMobile = values[state.previousBreakpoint] > values.md && values[state.breakpoint] <= values.md
        if (fromMobileToDesktop || fromDesktopToMobile) {
          state.menuOpen = false
        }
      }
    },
    openMenu: (state, action) => {
      state.menuOpen = true
    },
    closeMenu: (state, action) => {
      state.menuOpen = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(route.toString(), (state, action) => {
      state.menuOpen = false
    })
    builder.addCase('drawer/closeDrawer', (state, action) => {
      state.menuOpen = false
    })
  }
})

export const {
  breakpointChanged,
  openMenu,
  closeMenu
} = layoutSlice.actions

export const getPreviousBreakpoint = state => state.layout.previousBreakpoint
export const getCurrentBreakpoint = state => state.layout.breakpoint
export const isCurrentBreakpointAtLeast = (state, breakpoint) => (
  keys.indexOf(getCurrentBreakpoint(state)) >= keys.indexOf(breakpoint)
)
export const isBreakpointSet = state => state.layout.breakpointSet
export const isCurrentBreakpointMobile = state => !isCurrentBreakpointAtLeast(state, 'md')

export const isMenuOpen = state => state.layout.menuOpen

export const hasToolbar = createSelector(
  getPage,
  (page) => page && (page._type === 'product' || page._type === 'project' || page.showToolbar)
)

export default layoutSlice.reducer
