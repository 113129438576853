import React, { useMemo } from "react";
import get from "lodash/get";
import omitBy from "lodash/omitBy";
import defaults from "lodash/defaults";
import { useLocation } from "@reach/router";
import { useGlobalContext, usePageContext } from "./SiteContext";
import { GatsbySeo } from "gatsby-plugin-next-seo";

export default function Seo() {
  const page = usePageContext();
  const globals = useGlobalContext();
  const { pathname } = useLocation();

  const metadata = useMemo(() => {
    const defaultCanonicalUrl = `${process.env.GATSBY_CANONICAL_URL_BASE}${pathname}`;
    const pageTitle = get(page, ["title"]);
    const siteTitle = get(globals, ["title"]);
    let title = pageTitle || siteTitle;
    if (title !== siteTitle) {
      title = `${title} | ${siteTitle}`;
    }

    return defaults(
      {},
      omitBy(get(page, ["seo"], {}), (x) => !x),
      omitBy(get(globals, ["seo"], {}), (x) => !x),
      {
        // these are the defaults for fields that aren't set above
        meta_title: pageTitle,
        site_name: siteTitle,
        og_url: defaultCanonicalUrl,
        canonical_url: defaultCanonicalUrl,
        title,
      }
    );
  }, [page, globals, pathname]);

  const htmlAttributes = useMemo(() => ({ lang: "en" }), []);
  if (!globals) return null;

  return (
    <GatsbySeo
      htmlAttributes={htmlAttributes}
      title={metadata.meta_title}
      description={metadata.meta_description}
      canonical={
        metadata.canonical_url || `${process.env.GATSBY_CANONICAL_URL_BASE}`
      }
      openGraph={{
        url: `${metadata.og_url || process.env.GATSBY_CANONICAL_URL_BASE}`,
        title: metadata.meta_title,
        description: metadata.meta_description,
        type: "website",
        images: metadata?.meta_image?.asset?.url
          ? [
              {
                url: metadata.meta_image.asset.url,
                width: 800,
                height: 600,
                alt: "Og Image Alt",
              },
            ]
          : [],
        site_name: metadata.site_name || "Decor Systems",
        article: page?.author
          ? {
              authors: page.author,
              tags: metadata.meta_keywords,
            }
          : {},
      }}
      twitter={{
        handle: "@decorsystems",
        site: "@decorsystems",
        cardType: metadata.twitter_card_type || "summary_large_image",
      }}
    />
  );
}
