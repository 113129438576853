import React, { useCallback, useState } from "react";
import { createUseStyles } from "react-jss";
import Section from "../../Section";
import SliceTitle from "../SliceTitle";
import cn from "classnames";
import ResponsiveImage from "../../ResponsiveImage";
import theme from "../../../style/theme";
import { HorizontalLine } from "../../Lines";
import { Caption } from "../../Typography";
import Button from "../../Button";
import DownloadIcon from "../../Icons/DownloadIcon";
import Link from "../../Link";

const Tabs = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.tabs}>
      <div className={classes.tabsInner}>{children}</div>
    </div>
  );
};

const Tab = ({ index, children, onTabSelected, selected, width }) => {
  const classes = useStyles({ width });
  const onClick = useCallback(() => {
    if (onTabSelected) onTabSelected(index);
  }, [index, onTabSelected]);
  return (
    <Button
      onClick={onClick}
      className={cn(classes.tab, { selected })}
      showArrow={false}
    >
      {children}
    </Button>
  );
};

export default function ProductVariances({ slice, id }) {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const { title, tabs, showDownloadLink } = slice;
  const hasTabs = tabs && tabs.length > 1;
  const copy = tabs[tabIndex]?.copy;
  const variances = tabs[tabIndex]?.variances;
  return (
    <Section className={classes.section} grid id={id}>
      <SliceTitle>{title}</SliceTitle>
      {hasTabs && (
        <Tabs>
          {tabs.map(({ title }, i) => (
            <Tab
              key={i}
              index={i}
              onTabSelected={setTabIndex}
              selected={i === tabIndex}
              width={`${(1 / tabs.length) * 100}%`}
            >
              {title}
            </Tab>
          ))}
        </Tabs>
      )}
      {copy && <p className={classes.copy}>{copy}</p>}
      {variances &&
        variances.map(({ title, number, image, url }, i) => {
          const takeUrlForLink = url
            ? url
            : showDownloadLink && image
            ? image.asset.url
            : null;
          return (
            <div key={i} className={classes.variance}>
              <Link download={url ? false : true} to={takeUrlForLink}>
                <ResponsiveImage
                  image={image}
                  aspect={1}
                  className={classes.image}
                >
                  {(showDownloadLink || url) && (
                    <DownloadIcon className={classes.downloadIcon} />
                  )}
                </ResponsiveImage>
              </Link>
              <div className={classes.varianceContent}>
                {number && (
                  <Caption className={classes.varianceNumber}>{number}</Caption>
                )}
                <Caption className={classes.varianceTitle}>{title}</Caption>
              </div>
            </div>
          );
        })}
      <HorizontalLine position="bottom" extend />
    </Section>
  );
}

const useStyles = createUseStyles({
  section: {
    position: "relative",
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
      paddingBottom: theme.spacing(20),
    },
  },
  copy: {
    gridColumn: "1 / span 4",
    [theme.breakpoints.up("md")]: {
      gridColumn: "1 / span 7",
    },
  },
  tabs: {
    gridColumn: "1 / span 4",
    margin: [0, -theme.gutter.sm, 0],
    overflow: "hidden",
    height: 42,
    [theme.breakpoints.up("md")]: {
      height: "auto",
      margin: 0,
      gridColumn: "1 / span 7",
    },
  },
  tabsInner: {
    overflowX: "scroll",
    padding: [0, theme.gutter.sm, 118],
    whiteSpace: "nowrap",
    [theme.breakpoints.up("md")]: {
      whiteSpace: "normal",
      display: "flex",
      overflowX: "auto",
      padding: 0,
      flexWrap: "wrap",
      gridColumn: "1 / span 7",
      marginBottom: theme.spacing(3),
    },
  },
  tab: {
    textTransform: "none",
    backgroundColor: "transparent",
    border: [1, "solid", theme.colors.border],
    margin: [0, -1, 0, 0],
    cursor: "pointer",
    fontFamily: theme.fonts.body,
    fontSize: 12,
    padding: theme.spacing(2),
    transition: "color 0.15s ease-in-out, background-color 0.15s ease-in-out",
    whiteSpace: "nowrap",
    borderRadius: 0,
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4),
      fontSize: 15,
      minWidth: (props) => props?.width || "auto",
    },
  },
  variance: {
    gridColumn: "span 2",
    [theme.breakpoints.up("md")]: {
      gridColumn: "span 1",
    },
  },
  varianceContent: {
    display: "flex",
  },
  image: {
    border: [1, "solid", theme.colors.border],
    marginBottom: theme.spacing(2),
  },
  varianceTitle: {
    fontSize: 12,
  },
  varianceNumber: {
    fontSize: 12,
    display: "block",
    opacity: 0.5,
    marginRight: theme.spacing(3),
  },
});
