import get from "lodash/get";
import compact from "lodash/compact";

function resolveSlug(document) {
  const slug = get(
    document,
    ["slug", "current"],
    get(document, ["current"], document)
  );
  if (slug !== "home") {
    // home resolves to `/`
    return slug;
  }
}

export function resolveInternalLinkUrl({ parentPage, slug, type }) {
  const parts = [resolveSlug(parentPage), resolveSlug(slug)];

  return `/${compact(parts).join("/")}`;
}

export function resolveInternalLink(reference, text) {
  if (!reference) {
    return reference;
  }
  return {
    text: text || reference.title,
    url: resolveInternalLinkUrl(reference),
  };
}

export function resolveLink(link) {
  if (get(link, ["_type"]) === "internalLink") {
    return resolveInternalLink(link.reference, link.text);
  }
  return link;
}
