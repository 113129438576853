import React, { forwardRef } from "react";
import useFetch from "use-http";
import { useForm } from "react-hook-form";
import { createUseStyles } from "react-jss";
import cn from "classnames";
import { HorizontalLine } from "../Lines";
import theme from "../../style/theme";
import { useDispatch } from "react-redux";
import { setSubmitQuoteFormSuccess } from "../../state/slices/ui/quoteForm";
import { useGlobalContext } from "../SiteContext";

const FormField = ({ field, register, disabled, hasError }) => {
  const {
    name,
    label,
    type,
    required,
    pattern,
    placeholder,
    maxLength,
    options = null,
  } = field;
  const classes = useStyles();

  const renderInput = (type) => {
    switch (type) {
      case "textarea":
        return (
          <textarea
            className={classes.input}
            id={name + "-quote"}
            name={name}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            {...register(name, {
              required,
              pattern,
            })}
            onInput={(e) => {
              e.target.style.height = "";
              e.target.style.height = e.target.scrollHeight + "px";
            }}
          />
        );
      case "file":
        return (
          <input
            className={classes.input}
            id={name + "-quote"}
            name={name}
            type={type}
            multiple="multiple"
            disabled={disabled}
            maxLength={maxLength}
            {...register(name, {
              required,
              pattern,
            })}
          />
        );
      case "select":
        return (
          <select
            className={classes.input}
            id={name + "-quote"}
            name={name}
            type={type}
            disabled={disabled}
            {...register(name, {
              required,
              pattern,
            })}
          >
            {options &&
              options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
          </select>
        );
      case "checkbox":
        return (
          <input
            type={type}
            className={classes.inputCheckbox}
            id={name + "-quote"}
            name={name}
            disabled={disabled}
            {...register(name, {
              required,
              pattern,
            })}
          />
        );
      default:
        return (
          <input
            className={classes.input}
            id={name + "-quote"}
            name={name}
            type={type}
            disabled={disabled}
            {...register(name, {
              required,
              pattern,
            })}
          />
        );
    }
  };
  return (
    <div
      className={cn(
        { hasError },
        classes.formFieldContainer,
        type === "textarea" ? classes.textareaContainer : classes.inputContainer
      )}
    >
      <label
        className={`${classes.inputLabel} ${
          type === "checkbox" ? classes.labelCheckbox : ""
        }`}
        htmlFor={name + "-quote"}
      >
        {label}
      </label>
      {renderInput(type)}
      <HorizontalLine
        className={cn(classes.bottomBorder, "bottomBorder")}
        position="bottom"
      />
    </div>
  );
};

const resolveFormFields = ({ quoteAboutPlaceholder }) => {
  return [
    {
      label: "First Name*",
      name: "first_name",
      type: "text",
      required: true,
      pattern: null,
    },
    {
      label: "Last Name*",
      name: "last_name",
      type: "text",
      required: true,
      pattern: null,
    },
    {
      label: "Email*",
      name: "email",
      type: "email",
      required: true,
      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    },
    {
      label: "Company",
      name: "company",
      type: "text",
      required: false,
      pattern: null,
    },
    {
      label: "Phone*",
      name: "phone",
      type: "text",
      required: true,
      pattern: null,
    },
    {
      label: "Potential Project State",
      name: "00N0K00000Lf8yy",
      type: "select",
      options: [
        { value: "", label: "" },
        { value: "ACT", label: "ACT" },
        { value: "NSW", label: "NSW" },
        { value: "NT", label: "NT" },
        { value: "QLD", label: "QLD" },
        { value: "SA", label: "SA" },
        { value: "TAS", label: "TAS" },
        { value: "VIC", label: "VIC" },
        { value: "WA", label: "WA" },
      ],
      required: false,
      pattern: null,
    },
    {
      label: "Approximate Value",
      name: "00N28000003asfH",
      type: "text",
      required: false,
      pattern: null,
      maxLength: 20,
    },
    {
      label: "Project Name",
      name: "00N28000003bP1f",
      type: "text",
      required: false,
      pattern: null,
      maxLength: 200,
    },
    {
      label: "About the project",
      name: "00N9q000000OKIG",
      type: "textarea",
      required: false,
      pattern: null,
      placeholder: quoteAboutPlaceholder,
    },
    {
      label: "STAY UPDATED",
      name: "Active_Email_Subscriber__c",
      type: "checkbox",
      required: false,
      pattern: null,
    },
  ];
};

const SalesforceForm = forwardRef(({ className }, submitButtonRef) => {
  const classes = useStyles();
  const {
    post,
    response,
    loading,
    error: apiError,
  } = useFetch("/api/salesforce");
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const { quoteForm } = useGlobalContext();
  const formFields = resolveFormFields(quoteForm);

  // handle form submission
  const onSubmit = (data, e) => {
    e.preventDefault();
    const formData = {
      ...data,
      oid: "00D28000000XFTy",
      lead_source: "Request a Quote",
      lead_status: "Open",
      // debug: '1',
      // debugEmail: 'mike@nightjar.co'
    };
    const postSaleforceData = async () => {
      await post(formData);
      if (response.ok) {
        dispatch(setSubmitQuoteFormSuccess());
      }
    };
    postSaleforceData();
  };

  const errorKeys = Object.keys(errors);
  const errorArray = errorKeys.map((key) => ({
    key,
    ...errors[key],
  }));
  const errorMessages = errorArray
    .map((error, index) => {
      const errorFieldName = errorKeys[index];
      const matchingField = formFields.find(
        (field) => field.name === errorFieldName
      );
      if (error.type !== "required") {
        return `Please check ${matchingField.label} is valid.`;
      } else {
        return null;
      }
    })
    .filter((e) => e);
  const errorMissingRequireds =
    errorArray.filter((e) => e.type === "required").length > 0;

  return (
    <>
      <form
        className={cn(classes.form, className || null)}
        onSubmit={handleSubmit(onSubmit)}
      >
        {formFields &&
          formFields.map((field) => (
            <FormField
              key={field.name}
              field={field}
              register={register}
              hasError={errorKeys.includes(field.name)}
              disabled={loading}
            />
          ))}
        <button
          className={classes.hiddenButton}
          ref={submitButtonRef}
          type="submit"
        >
          <span>OK</span>
        </button>
      </form>
      <div className={classes.errors}>
        {errorMessages &&
          errorMessages.map((error) => (
            <p key={error} className={classes.error}>
              {error}
            </p>
          ))}
        {errorMissingRequireds && (
          <p className={classes.error}>Please complete all fields</p>
        )}
        {apiError && (
          <p className={classes.error}>
            Sorry, something went wrong! Try again later
          </p>
        )}
      </div>
    </>
  );
});

const useStyles = createUseStyles({
  form: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  errors: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  error: {
    color: "#CF6C4F",
    fontFamily: theme.fonts.caption,
    fontSize: 10,
    lineHeight: 1,
    textTransform: "uppercase",
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(5),
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
  formFieldContainer: {
    display: "flex",
    position: "relative",
    "&.hasError": {
      color: "#CF6C4F",
      "& > label": {
        opacity: 1,
      },
    },
    "&:focus-within .bottomBorder": {
      opacity: 1,
    },
  },
  inputContainer: {
    alignItems: "center",
    height: 50,
  },
  textareaContainer: {
    flexDirection: "column",
    // flexGrow: 2,
    padding: [20, 0, 0],
    "& label": {
      flex: 0,
    },
    "& textarea": {
      flex: "auto",
      maxWidth: "100%",
      resize: "none",
      fontSize: 12,
      "&::placeholder": {
        opacity: 0.3,
      },
    },
  },
  inputLabel: {
    flex: 1,
    fontFamily: theme.fonts.body,
    fontSize: 10,
    lineHeight: 1,
    textTransform: "uppercase",
    opacity: 0.5,
  },
  input: {
    padding: [16, 0],
    flex: 2,
    fontFamily: theme.fonts.body,
    fontSize: 12,
    lineHeight: 1,
    border: "none",
    borderRadius: 0,
    backgroundColor: "transparent",
    outline: "none",
    color: "currentColor",
    "&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus &:-webkit-autofill, &:-webkit-autofill:hover &:-webkit-autofill:focus":
      {
        boxShadow: "0 0 0px 1000px transparent inset",
        transition: "background-color 5000s ease-in-out 0s",
        caretColor: "currentColor",
      },
  },
  inputCheckbox: {
    cursor: "pointer",
    WebkitAppearance: "none",
    appearance: "none",
    backgroundColor: "#fff",
    margin: "0 4px 0 auto",
    font: "inherit",
    color: "#3A3A1F",
    outline: "1px solid #3A3A1F",
    width: "14px",
    height: "14px",
    display: "grid",
    placeContent: "center",
    transition: " background-color 0.2s, border-color 0.2s ease-in-out",
    "&:checked": {
      backgroundColor: "#3A3A1F",
      border: "1px solid #fff",
    },
  },
  labelCheckbox: {
    flex: "none",
    pointerEvents: "none",
  },
  bottomBorder: {
    opacity: 0.2,
    transition: "opacity 0.25s ease-in-out",
  },
  hiddenButton: {
    display: "none",
  },
});

export default SalesforceForm;
