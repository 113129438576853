import React from 'react'
import { createUseStyles } from 'react-jss'
import Button from './Button'

export default function NextPrevButtons ({ onBackClick, onNextClick, backDisabled, nextDisabled }) {
  const classes = useStyles()
  return (
    <div>
      <Button tag='button' backArrow onClick={onBackClick} className={classes.paginationButton} disabled={backDisabled} />
      <Button tag='button' onClick={onNextClick} className={classes.paginationButton} disabled={nextDisabled} />
    </div>
  )
}

const useStyles = createUseStyles({
  paginationButton: {
    padding: [4, 8],
    marginTop: 8,
    minWidth: 42,
    '&:first-child': {
      marginRight: 8
    },
    '& span': {
      margin: 0
    },
    '& > div > span': {
      padding: [4, 8]
    }
  }
})
