import gsap from "gsap";
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import Footer from "../components/Footer";
import GraphQLError from "../components/GraphQLError";
import MetaPixel from "../components/MetaPixel";
import Seo from "../components/Seo";
import { PageContextProvider } from "../components/SiteContext";
import Slices from "../components/Slices";
import Toolbar from "../components/Toolbar";
import { scrollTo } from "../components/useSmoothScrollbar";
import { setPage } from "../state/slices/data/page";
import "./fragments";

export const EXIT_TRANSITION_DURATION = 0.5;
export const ENTER_TRANSITION_DELAY = 0.5;
export const ENTER_TRANSITION_DURATION = 0.5;

const PageComponent = ({ pageContext, transitionStatus, data, errors }) => {
  const dispatch = useDispatch();
  const containerRef = useRef();

  useEffect(() => {
    dispatch(setPage(data.page));
  }, [dispatch, data]);

  useEffect(() => {
    switch (transitionStatus) {
      case "exiting":
        gsap.to(containerRef.current, {
          opacity: 0,
          duration: EXIT_TRANSITION_DURATION,
        });
        break;
      case "exited":
        gsap.set(containerRef.current, { opacity: 0 });
        break;
      case "entering":
        // At the start of the entering transition we need to reset the scroll position
        scrollTo(0, 0, 0);
        gsap.to(containerRef.current, {
          opacity: 1,
          duration: ENTER_TRANSITION_DELAY,
        });
        break;
      case "entered":
        gsap.set(containerRef.current, { opacity: 1 });
        break;
      default:
        break;
    }
  }, [transitionStatus]);

  if (errors) {
    return <GraphQLError errors={errors} />;
  }
  const { page } = data;

  return (
    <div ref={containerRef}>
      <PageContextProvider page={page}>
        <Seo />
        <MetaPixel />
        <Slices />
        <Toolbar />
      </PageContextProvider>
      <Footer color={pageContext?.color?.title?.toLowerCase()} />
    </div>
  );
};

export default PageComponent;
