import React, { useCallback, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import Section from '../../Section'
import { useSelector } from 'react-redux'
import theme from '../../../style/theme'
import Button from '../../Button'
import { isLoadedSelector } from '../../../state/slices/data/lists'
import range from 'lodash/range'
import map from 'lodash/map'
import RichText from '../../RichText'
import isEmpty from 'lodash/isEmpty'
import Tile from './Tile'
import { getFilteredItemsSelector } from '../../../state/slices/ui/listings'
import { HorizontalLine } from '../../Lines'

export default function TileGrid ({ emptyCopy, type }) {
  const items = useSelector(getFilteredItemsSelector(type))
  const classes = useStyles()
  const [limit, setLimit] = useState(12)
  const loaded = useSelector(isLoadedSelector(type))

  const onLoadMoreClick = useCallback(() => {
    setLimit(value => value + 12)
  }, [])

  const pagedItems = useMemo(() => {
    if (!loaded) {
      return map(range(12), (_, i) => ({ id: i, placeholder: true }))
    }
    return items ? items.slice(0, limit) : []
  }, [limit, items, loaded])

  const totalItems = items ? items.length : 0
  const hasMoreItems = pagedItems.length < totalItems

  return (
    <Section grid className={classes.section} noBottomMargin>
      {loaded && isEmpty(pagedItems) && <RichText className={classes.emptyResults} blocks={emptyCopy.text} />}
      {pagedItems.map((project, index) => (
        <Tile key={project.id} item={project} placeholder={project.placeholder} index={index} />
      ))}
      <div>
        <HorizontalLine extend position='bottom' />
      </div>
      {hasMoreItems && <Button onClick={onLoadMoreClick} className={classes.loadMoreButton} showArrow={false}>Load More</Button>}
    </Section>
  )
}

const useStyles = createUseStyles({
  section: {
    position: 'relative',
    padding: [theme.gutter.sm, 0],
    marginBottom: theme.spacing(20),
    [theme.breakpoints.up('md')]: {
      padding: [theme.gutter.md, 0],
      marginBottom: theme.spacing(25)
    }
  },
  emptyResults: {
    gridColumn: '1 / span 4',
    [theme.breakpoints.up('md')]: {
      gridColumn: '1 / span 7'
    }
  },
  loadMoreButton: {
    gridColumn: '1 / span 4',
    justifyContent: 'center',
    padding: theme.gutter.sm,
    marginTop: -theme.gutter.sm,
    [theme.breakpoints.up('md')]: {
      gridColumn: '1 / span 7',
      padding: theme.gutter.md,
      marginTop: -theme.gutter.md
    }
  }
})
