import { useRef, useEffect } from 'react'
import { primaryInput } from 'detect-it'
import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import remove from 'lodash/remove'
import Scrollbar from 'smooth-scrollbar'
// import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll'
import ScrollTrigger from 'gsap/ScrollTrigger'
import inDOM from 'dom-helpers/canUseDOM'

import forEach from 'lodash/forEach'

import { getStateStorage } from '../helpers/scroll'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

let scrollbar = null
const scrollState = {
  currentY: 0,
  previousY: -1
}

export const getY = () => {
  return scrollbar ? scrollbar.offset.y : (window.pageYOffset || document.documentElement.scrollTop)
}

export const scrollTo = (x, y, duration = 0) => {
  if (scrollbar) {
    scrollbar.scrollTo(x, y, 0)
  }
}

export const getElementScrollOffset = (element) => {
  if (scrollbar) {
    return element.offsetTop - 80
  } else {
    const y = getY()
    const { top } = element.getBoundingClientRect()
    return top + y - 80
  }
}

export const scrollToElement = (element) => {
  if (scrollbar) {
    const scrollTo = getElementScrollOffset(element)
    scrollbar.scrollTo(0, scrollTo, 500)
  } else {
    gsap.to(window, { duration: 0.5, scrollTo: { y: element, offsetY: 60 } })
  }
}

const scrollCallbacks = []
export const useScrollListener = (callback) => {
  useEffect(() => {
    const localCallback = { callback }
    scrollCallbacks.push(localCallback)
    return () => {
      remove(scrollCallbacks, localCallback)
    }
  }, [callback])
}

const readyCallbacks = []
export const useScrollReadyListener = (callback) => {
  useEffect(() => {
    if (scrollbar) {
      callback(scrollbar)
    } else {
      const localCallback = { callback }
      readyCallbacks.push(localCallback)
      return () => {
        remove(readyCallbacks, localCallback)
      }
    }
  }, [callback])
}

if (inDOM && primaryInput !== 'touch') {
  // This will setup the scroll trigger to use the resolved y from the smooth scrollbar
  ScrollTrigger.scrollerProxy(document.body, {
    scrollTop (value) {
      if (arguments.length) {
        scrollbar.scrollTo(0, value, 0)
      }
      return getY()
    }
  })
}

const useSmoothScrollbar = () => {
  const ref = useRef()

  useEffect(() => {
    if (ref.current) {
      if (primaryInput === 'touch') {
        const onScroll = () => {
          scrollState.currentY = window.pageYOffset
        }
        scrollState.currentY = window.pageYOffset
        window.addEventListener('scroll', onScroll)

        let animationId
        const loop = () => {
          if (scrollState.previousY !== scrollState.currentY) {
            scrollState.previousY = scrollState.currentY
            const offset = { x: 0, y: window.scrollY }
            forEach(scrollCallbacks, cb => cb.callback(offset))
          }
          animationId = window.requestAnimationFrame(loop)
        }
        animationId = window.requestAnimationFrame(loop)
        return () => {
          window.removeEventListener('scroll', onScroll)
          window.cancelAnimationFrame(animationId)
        }
      } else {
        // Scrollbar.use(OverscrollPlugin)
        scrollbar = Scrollbar.init(ref.current, { damping: 0.1, renderByPixels: true, alwaysShowTracks: false })
        // scrollbar.updatePluginOptions('overscroll', { effect: 'bounce' })

        scrollbar.addListener(({ offset }) => {
          // Updates the scroll history
          const storage = getStateStorage()
          storage.save(window.history.state || {}, null, [offset.x, offset.y])

          // Updates the scroll position for the scroll triggers
          ScrollTrigger.update()
          forEach(scrollCallbacks, cb => cb.callback(offset))
        })

        ScrollTrigger.refresh(true)

        forEach(readyCallbacks, cb => cb.callback(scrollbar))

        return () => {
          if (scrollbar) {
            scrollbar.destroy()
            scrollbar = null
          }
        }
      }
    }
  }, [])

  return { ref }
}

export default useSmoothScrollbar
