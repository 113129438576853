import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { removeItem } from '../../state/slices/ui/cart'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import ResponsiveImage from '../ResponsiveImage'
import { Caption } from '../Typography'

export default function CartItem ({ item }) {
  const { id, product = {} } = item
  const classes = useStyles()
  const dispatch = useDispatch()
  const onRemoveItem = useCallback(() => {
    dispatch(removeItem({ id }))
  }, [dispatch, id])

  const { title, category, image } = product

  return (
    <div className={classes.item}>
      {image && <ResponsiveImage className={classes.image} image={product.image} aspect={1} />}
      <div className={classes.content}>
        <div className={classes.titleContainer}>
          <span className={classes.title}>{title}</span>
          {category && <Caption className={classes.category}>{category?.title}</Caption>}
        </div>
        <button className={classes.removeButton} onClick={onRemoveItem}>Remove</button>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  item: {
    display: 'flex',
    padding: [theme.spacing(3), 0],
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: 1,
      backgroundColor: theme.colors.border,
      opacity: 0.3
    }
  },
  image: {
    width: 100,
    marginRight: theme.spacing(3)
  },
  titleContainer: {
    flexGrow: 1,
    flexShrink: 1
  },
  title: {
    display: 'block'
  },
  category: {
    display: 'block',
    opacity: 0.5
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  removeButton: {
    flexGrow: 0,
    flexShrink: 0,
    border: 'none',
    backgroundColor: 'transparent',
    outline: 'none',
    padding: 0,
    margin: 0,
    textDecoration: 'underline',
    fontFamily: theme.fonts.caption,
    fontSize: 10,
    textAlign: 'left',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none'
    }
  }
})
