import React, { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import filter from 'lodash/filter'

import LinkList from '../LinkList'
import { fetchArticles, getItemsSelector, isLoadingSelector, isLoadedSelector } from '../../state/slices/data/lists'
import ProductProjectItem from './ProductProjectItem'
import range from 'lodash/range'
import { resolveInternalLinkUrl } from '../../helpers/resolveLink'

export default function ArticlesLink ({ category }) {
  const loading = useSelector(isLoadingSelector('articles'))
  const loaded = useSelector(isLoadedSelector('articles'))
  const articles = useSelector(getItemsSelector('articles'))
  const dispatch = useDispatch()

  useEffect(() => {
    if (!loaded) {
      dispatch(fetchArticles())
    }
  }, []) /* eslint-disable-line */

  const items = useMemo(() => {
    if (articles && category) {
      return filter(articles, x => x.category?.slug.current === category.slug.current)
        .map(article => ({
          to: resolveInternalLinkUrl(article),
          text: <ProductProjectItem
            title={article.title}
            field1Label='Category'
            field1Value={article.category?.title}
                />,
          article: article,
          arrow: true
        }))
    }
    return []
  }, [category, articles])

  const placeholders = useMemo(() => {
    const items = range(2)
    return items.map(() => ({
      text: <ProductProjectItem placeholder field1Label='Category' />,
      arrow: true
    }))
  }, [])

  if (!loaded || loading) {
    return <LinkList items={placeholders} />
  }

  return (
    <LinkList items={items} hover />
  )
}
