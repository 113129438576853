import React, { useContext, useRef } from 'react'

export const PageContext = React.createContext()
export const GlobalContext = React.createContext()

export const usePageContext = () => {
  return useContext(PageContext)
}

export const useGlobalContext = () => {
  return useContext(GlobalContext)
}

export const PageContextProvider = ({ page, ...rest }) => {
  return (
    <PageContext.Provider value={page} {...rest} />
  )
}

export const GlobalsContextProvider = ({ globals, ...rest }) => {
  // Global is always the same, no need to update this once we get it the first time
  const globalsRef = useRef(globals)
  return (
    <GlobalContext.Provider value={globalsRef.current} {...rest} />
  )
}
