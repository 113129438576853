import { useRef, useImperativeHandle } from 'react'

const useImperativeTimelineHandle = (ref, createTimeline, dependencies) => {
  const timelineRef = useRef()
  useImperativeHandle(ref, () => ({
    getTimeline: () => {
      if (timelineRef.current == null) {
        timelineRef.current = createTimeline()
      }
      return timelineRef.current
    }
  }), dependencies) /* eslint-disable-line  */
  return timelineRef
}

export default useImperativeTimelineHandle
