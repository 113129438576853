import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../style/theme'
import cn from 'classnames'

function padNumber (num, size) {
  if (num === undefined) return ''
  const s = '000000000' + num
  return s.substr(s.length - size)
}

export default function Count ({ className, pad, count, children }) {
  const classes = useStyles()
  return (
    <span className={cn(className, classes.count)}>
      {pad ? padNumber(count, 2) : count}
      {children}
    </span>
  )
}

const useStyles = createUseStyles({
  count: {
    fontFamily: theme.fonts.caption,
    width: 20,
    height: 20,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    border: [1, 'solid', 'currentColor'],
    fontSize: 9,
    [theme.breakpoints.up('md')]: {
      width: 24,
      height: 24,
      fontSize: 10
    }
  }
})
