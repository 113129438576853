import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import ImageCurtain from '../Animation/ImageCurtain'
import ResponsiveImage from '../ResponsiveImage'
import Section from '../Section'
import SliderWithPagination from '../SliderWithPagination'
import map from 'lodash/map'

const Image = ({ image, size, index }) => {
  const classes = useImageStyles({ size })
  const delay = index === 1 ? 0.1 : 0
  return (
    <div className={classes.image}>
      <ResponsiveImage image={image} aspect={1188 / 678} mobileAspect={330 / 256}>
        {index < 2 && (
          <ImageCurtain animationType='topDown' animatedWhenInView delay={delay} threshold={0.03} />
        )}
      </ResponsiveImage>
    </div>
  )
}

export default function ImageSlider ({ slice, id }) {
  const { images } = slice
  const captions = useMemo(() => map(images, x => x.caption), [images])
  return (
    <Section id={id}>
      <SliderWithPagination extend paginationTitle='Image' captions={captions}>
        {images && images.map((image, i) => (
          <Image key={i} image={image} index={i} />
        ))}
      </SliderWithPagination>
    </Section>
  )
}

const useImageStyles = createUseStyles({
  image: {
    flexShrink: 0,
    marginRight: theme.gutter.sm,
    width: '90%',
    cursor: 'grab',
    [theme.breakpoints.up('md')]: {
      minHeight: 'auto',
      marginRight: theme.gutter.md,
      width: '90%'
    }
  }
})
