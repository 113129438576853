/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import useHlsPlayer from '../../hooks/useHlsPlayer'
import cn from 'classnames'
import PlayIcon from './play.inline.svg'
import theme from '../../style/theme'
import ImageCurtain from '../Animation/ImageCurtain'

export default function VimeoPlayer({ className, title, vimeoData }) {
  const classes = useStyles()
  const { hlsPlayerLink, thumbnails } = vimeoData
  const ref = useRef()
  const [displayOverlay, setDisplayOverlay] = useState(true)

  const play = () => {
    setDisplayOverlay(!displayOverlay)
    ref.current.play()
  }

  const handleOnPause = () => {
    setDisplayOverlay(true)
  }

  useHlsPlayer(ref, hlsPlayerLink)

  const renderThumbnails = (thumbnails) => {
    // Get thumbnails in order of largest to smallest
    const thumbnailsLargestToSmallest = [...thumbnails].reverse()
    return thumbnailsLargestToSmallest.map((thumbnail, index)=> {
      // Ensure image is sized responsively by setting the breakpoint of each thumbnail to the width of the next thumbnail in the list
      if(index < thumbnails.length - 1){
        return <source key={index} srcSet={thumbnail.link} media={`(min-width:${thumbnailsLargestToSmallest[index+1].width}px)`} />
      }

      // If last thumbnail in list, set to be the picture default (i.e. lowest quality thumbnail)
      return <img key={index} alt='video thumbnail' className={classes.thumbnail} src={thumbnail.link} />
    })
  }

  return (
    <div className={cn(className, classes.sliceWrapper)}>
    {title && <h2 className={classes.title}>{title}</h2>}
    <div className={cn(title ? classes.titledVideoPlayer : classes.fullWidthVideoPlayer, 'vimeo-player')}>
      <div className={classes.vimeoWrapper}>
        <video
          ref={ref}
          controls={!displayOverlay}
          playsInline
          onPause={handleOnPause}
        />
        {(!ref.current || ref.current.currentTime === 0) && displayOverlay && 
          <picture className={classes.thumbnail}>
            {renderThumbnails(thumbnails)}
            
          </picture>
        }
        <button className={cn(classes.playBtn, 'reset-button', !displayOverlay && classes.hide)} onClick={play} title='Play Video'>
            <PlayIcon className={classes.cursor} />
        </button>
      </div>
      <ImageCurtain animatedWhenInView animationType='topDown' />
    </div>
    </div>
  )
}

const useStyles = createUseStyles({
  sliceWrapper: {...theme.gridStyle},
  title: {
    gridColumn: '1 / span 4',
    lineHeight: 1,
    marginBottom: theme.spacing(10),
    [theme.breakpoints.up('md')]: {
      gridColumn: '1 / span 1',
      marginBottom: theme.spacing(30)
    }
  },
  fullWidthVideoPlayer: {
    position: 'relative',
    overflow: 'hidden',
    gridColumn: '1 / -1',
  },
  titledVideoPlayer: {
    position: 'relative',
    overflow: 'hidden',
    gridColumn: '1 / -1',
   [theme.breakpoints.up('md')]: {
    gridColumn: '3 / span 5',
    }
  },
  vimeoWrapper: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    paddingTop: '56.25%',
    gridColumn: '1 / -1',
    '& video': {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: '100%',
      height: '100%',
    }
  },
  thumbnail: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
  },
  playBtn: {
    cursor: 'pointer',
    border: 0,
    background: 'rgba(0,0,0,0.5)',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    '&:hover circle': {
      fill: 'rgba(255,255,255,0.3)'
    }
  },
  hide: {
    display: 'none'
  },
})
