import React, { useCallback, useRef } from "react";
import { createUseStyles } from "react-jss";
import theme from "../../style/theme";
import { HorizontalLine, VerticalLine } from "../Lines";
import ResponsiveImage from "../ResponsiveImage";
import RichText from "../RichText";
import Section from "../Section";
import { Caption } from "../Typography";
import DetailsPanel, { Cell } from "../DetailsPanel";
import UseInternalLinkUrl from "../../hooks/useInternalLinkUrl";
import Link from "../Link";
import { Arrow } from "../Button";
import ImageCurtain from "../Animation/ImageCurtain";
import first from "lodash/first";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import flatten from "lodash/flatten";
import uniq from "lodash/uniq";
import WaveTextAnimation from "../WaveTextAnimation";
import useStandardInAnimation from "../Animation/useStandardInAnimation";
import useComposeRefs from "../../helpers/composeRefs";

const ProductLink = ({ product }) => {
  const classes = useStyles();
  const url = UseInternalLinkUrl(product);
  return (
    <Link className={classes.productLink} to={url}>
      {product.title}
      <Arrow className={classes.arrow} />
    </Link>
  );
};

const MaybeExternalLink = ({ url, text }, index) => {
  // Returns an external link if url is present, otherwise return plain text
  const classes = useStyles();
  if (url) {
    return (
      <Link className={classes.externalLink} to={url} key={index}>
        {text}
        <svg
          className={classes.externalLinkIcon}
          height="8"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.25 5.95833V8.70833C8.25 8.95145 8.15342 9.18461 7.98151 9.35651C7.80961 9.52842 7.57645 9.625 7.33333 9.625H2.29167C2.04855 9.625 1.81539 9.52842 1.64349 9.35651C1.47158 9.18461 1.375 8.95145 1.375 8.70833V3.66667C1.375 3.42355 1.47158 3.19039 1.64349 3.01849C1.81539 2.84658 2.04855 2.75 2.29167 2.75H5.04167"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.875 1.375H9.625V4.125"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.58337 6.41667L9.62504 1.375"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Link>
    );
  }

  return text;
};

export default function ProjectHero({ slice, page }) {
  const { title, _rawCopy, image } = slice;
  const {
    products,
    category,
    locationDetails,
    architect,
    builder,
    installation,
    consultant,
    photography,
    architects,
    builders,
    installers,
    consultants,
    photographers,
    traditionalCustodians,
  } = page;
  const product = first(products);
  const classes = useStyles();
  const copyRef = useRef();

  const [inViewRef] = useStandardInAnimation(
    useCallback(() => copyRef.current?.children[0]?.children, [])
  );
  const composedCopyRefs = useComposeRefs(inViewRef, copyRef);

  return (
    <section className={classes.section}>
      <ResponsiveImage
        image={image}
        aspect={1440 / 700}
        mobileAspect={375 / 300}
        className={classes.image}
        fadeIn
      >
        <ImageCurtain animationType="topDown" animatedWhenInView />
      </ResponsiveImage>
      <div className={classes.imageLine}>
        <HorizontalLine animationOrigin="50%" />
      </div>
      <Section grid className={classes.content} noBottomMargin>
        <div className={classes.meta}>
          {product?.soundProfile && <Caption>{product?.soundProfile}</Caption>}
          {product?.title && <Caption>{product?.title}</Caption>}
          <Caption>{category?.title}</Caption>
        </div>
        <WaveTextAnimation>
          <h1 className={classes.title}>{title}</h1>
        </WaveTextAnimation>
        <div className={classes.titleLine}>
          <HorizontalLine extend animationOrigin="0%" />
        </div>
        <div className={classes.verticalLine}>
          <VerticalLine animationOrigin="0%" extend />
        </div>
        {_rawCopy && (
          <RichText
            className={classes.copy}
            blocks={_rawCopy.text}
            ref={composedCopyRefs}
          />
        )}
        <div className={classes.detailsContainer}>
          <DetailsPanel className={classes.detailsPanel}>
            <Cell label="Products">
              {products &&
                products.map((product) => (
                  <ProductLink key={product.title} product={product} />
                ))}
            </Cell>
            <Cell label="Application">
              {products &&
                uniq(
                  flatten(
                    filter(products, (p) => !isEmpty(p.application)).map(
                      (p) => p.application
                    )
                  )
                ).map((application) => (
                  <div key={application}>{application}</div>
                ))}
            </Cell>
            <Cell label="Location" fullWidth>
              {locationDetails}
            </Cell>
            <Cell label="Architects" noBorder noBottomPadding>
              {architects.length > 0
                ? architects.map((architect, index) =>
                    MaybeExternalLink(architect, index)
                  )
                : architect}
            </Cell>
            <Cell label="Builder" noBorder noBottomPadding>
              {builders.length > 0
                ? builders.map((builder, index) =>
                    MaybeExternalLink(builder, index)
                  )
                : builder}
            </Cell>
            <Cell label="Installation" noBorder noBottomPadding>
              {installers.length > 0
                ? installers.map((installer, index) =>
                    MaybeExternalLink(installer, index)
                  )
                : installation}
            </Cell>
            <Cell label="Consultant" noBorder noBottomPadding>
              {consultants.length > 0
                ? consultants.map((consultant, index) =>
                    MaybeExternalLink(consultant, index)
                  )
                : consultant}
            </Cell>
            <Cell label="Photography">
              {photographers.length > 0
                ? photographers.map((photographer, index) =>
                    MaybeExternalLink(photographer, index)
                  )
                : photography}
            </Cell>
            <Cell />
            {traditionalCustodians && (
              <Cell label="Traditional Custodians Of The Land" fullWidth>
                {traditionalCustodians}
              </Cell>
            )}
          </DetailsPanel>
        </div>
      </Section>
      <HorizontalLine animationOrigin="50%" position="bottom" />
    </section>
  );
}

const useStyles = createUseStyles({
  section: {
    position: "relative",
    marginBottom: theme.gutter.sm,
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.gutter.md,
    },
  },
  content: {
    padding: [theme.gutter.sm, 0],
    [theme.breakpoints.up("md")]: {
      padding: [theme.gutter.md, 0],
    },
  },
  imageLine: {
    position: "relative",
  },
  detailsContainer: {
    gridColumn: "1 / span 4",
    gridRow: 4,
    margin: [-theme.gutter.sm, -theme.gutter.sm, theme.gutter.sm],
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      overflow: "visible",
      gridRow: "1 / span 3",
      gridColumn: "1 / span 3",
      margin: 0,
      paddingRight: theme.gutter.md,
    },
    [theme.breakpoints.up("lg")]: {
      gridColumn: "1 / span 2",
      paddingRight: 0,
    },
  },
  detailsPanel: {
    margin: [0, -1],
    [theme.breakpoints.up("md")]: {
      margin: 0,
    },
  },
  title: {
    gridColumn: "1 / span 4",
    marginLeft: theme.spacing(12),
    marginBottom: 0,
    marginTop: theme.spacing(36),
    gridRow: 1,
    lineHeight: 1,
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(50),
      marginLeft: 0,
      gridColumn: "4 / span 3",
    },
  },
  titleLine: {
    position: "relative",
    gridRow: 2,
    gridColumn: "1 / span 4",
    [theme.breakpoints.up("md")]: {
      gridColumn: "4 / span 4",
    },
  },
  copy: {
    gridColumn: "1 / span 4",
    marginBottom: theme.spacing(10),
    gridRow: 5,
    "& > div > *": {
      opacity: 0,
    },
    [theme.breakpoints.up("md")]: {
      gridRow: 3,
      gridColumn: "4 / span 3",
      marginBottom: theme.spacing(40),
      "& p": {
        textIndent: theme.styles.textIndent,
      },
    },
    [theme.breakpoints.up("lg")]: {
      gridColumn: "4 / span 2",
      marginBottom: theme.spacing(40),
    },
  },
  verticalLine: {
    position: "relative",
    gridColumn: "1",
    gridRow: 1,
    marginLeft: theme.spacing(8),
    width: 1,
    [theme.breakpoints.up("md")]: {
      width: "100%",
      marginLeft: 0,
      gridColumn: "3",
      gridRow: "1 / span 4",
    },
  },
  meta: {
    display: "flex",
    justifyContent: "space-between",
    opacity: 0.5,
    gridRow: 1,
    gridColumn: "1 / span 4",
    marginLeft: theme.spacing(12),
    [theme.breakpoints.up("md")]: {
      marginLeft: 0,
      gridColumn: "4 / span 4",
      display: "grid",
      gridTemplateColumns: "repeat(4, 1fr)",
      gap: theme.gutter.md,
    },
  },
  productLink: {
    display: "flex",
    textDecoration: "none",
    alignItems: "center",
    justifyContent: "space-between",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  externalLink: {
    display: "flex",
    textDecoration: "none",
    alignItems: "baseline",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  externalLinkIcon: {
    transition: "margin 200ms ease",
    marginLeft: 2,
  },
  arrow: {
    width: 16,
    height: "auto",
    marginLeft: theme.spacing(1),
  },
});
