import React, { useCallback, useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import gsap from 'gsap'
import forEach from 'lodash/forEach'
import reverse from 'lodash/reverse'
import toArray from 'lodash/toArray'
import cn from 'classnames'
import theme from '../style/theme'
import { BUTTON_TRANSITION } from './Button'

export const DropDownItem = ({ className, tag = 'button', onClick, title, value, selected, ...props }) => {
  const classes = useStyles()
  const handleClick = useCallback((e) => {
    if (onClick) onClick(e, value)
  }, [onClick, value])
  const Component = tag
  return (
    <Component className={cn(className, classes.item, { selected })} onClick={handleClick} {...props}>{title}</Component>
  )
}

export default function DropDown ({ className, open, children, position }) {
  const classes = useStyles()
  const dropdownRef = useRef()

  const timelineRef = useRef()

  useEffect(() => {
    if (dropdownRef.current && dropdownRef.current.children.length > 0) {
      if (!timelineRef.current) {
        timelineRef.current = gsap.timeline()
        const elements = reverse(toArray(dropdownRef.current.children))
        const { height } = elements[0].getBoundingClientRect()
        forEach(elements, (element, i) => {
          timelineRef.current.to(element, { y: `${position === 'top' ? '-' : ''}${(height * (i + 1)) - (i)}px`, duration: 0.25, ease: 'expo.out', delay: i * 0.02 }, 0)
        })
      }

      if (open) {
        timelineRef.current.play()
      } else {
        timelineRef.current.reverse()
      }
    }
  }, [open, dropdownRef, position])

  useEffect(() => {
    return () => {
      timelineRef.current?.kill()
    }
  }, [])

  return (
    <div className={cn(classes.dropdown, className)} ref={dropdownRef}>
      {children}
    </div>
  )
}

const useStyles = createUseStyles({
  dropdown: {
    position: 'absolute',
    height: theme.spacing(10),
    top: 0,
    left: 0,
    right: 0
  },
  item: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.colors.background,
    border: [1, 'solid', theme.colors.border],
    height: theme.spacing(10),
    textAlign: 'left',
    display: 'flex',
    textDecoration: 'none',
    alignItems: 'center',
    fontSize: 13,
    padding: [0, theme.gutter.sm],
    transition: BUTTON_TRANSITION,
    outline: 'none',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: theme.colors.text,
      color: theme.colors.background
    },
    [theme.breakpoints.up('md')]: {
      padding: [0, theme.gutter.md]
    },
    '&.selected': {
      fontWeight: 'bold'
    }
  }
})
