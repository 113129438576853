import { primaryInput } from "detect-it";
import { graphql, useStaticQuery } from "gatsby";
import gsap from "gsap";
import get from "lodash/get";
import keyBy from "lodash/keyBy";
import sortBy from "lodash/sortBy";
import React, { useEffect, useMemo, useRef } from "react";
import { createUseStyles } from "react-jss";
import theme from "../style/theme";
import {
  ENTER_TRANSITION_DURATION,
  EXIT_TRANSITION_DURATION,
} from "../templates/PageComponent";
import Cart from "./Cart";
import Drawer from "./Drawer";
import FireGroupCalculatorDrawer from "./FireGroupCalculatorDrawer";
import Header from "./Header";
import MobileMenu from "./MobileMenu";
import QuoteForm from "./RequestAQuote";
import { GlobalsContextProvider } from "./SiteContext";
import useSmoothScrollbar from "./useSmoothScrollbar";

const usePageThemeColorTransition = (pageColor) => {
  const currentColor = useRef({ ...pageColor });
  useEffect(() => {
    const root = document.documentElement;
    gsap.to(currentColor.current, {
      foreground: pageColor.foreground,
      background: pageColor.background,
      border: pageColor.border,
      duration: EXIT_TRANSITION_DURATION + ENTER_TRANSITION_DURATION,
      ease: "sine.inOut",
      onUpdate: () => {
        root.style.setProperty("--foreground", currentColor.current.foreground);
        root.style.setProperty("--background", currentColor.current.background);
        root.style.setProperty("--border", currentColor.current.border);
      },
    });
  }, [pageColor]);
};

const Route = ({ children, location, pageContext }) => {
  const pageColor =
    theme.colors.pageTheme[
      get(
        pageContext,
        ["color", "title"],
        pageContext.title ? "White" : "Brown"
      )
    ];
  const classes = useStyles();
  const { ref: scrollRef } = useSmoothScrollbar();

  usePageThemeColorTransition(pageColor);

  return (
    <>
      <Header />
      <MobileMenu />
      <Drawer />
      <Cart />
      <QuoteForm />
      <FireGroupCalculatorDrawer />
      <main className={classes.main} ref={scrollRef}>
        <div className={classes.inner}>{children}</div>
      </main>
    </>
  );
};

export default function Layout({ children, location, pageContext }) {
  const globals = useStaticQuery(graphql`
    query GetSettings {
      sanitySettings {
        id
        seo {
          meta_title
          meta_description
          meta_keywords
          twitter_card_type
          canonical_url
          meta_image {
            ...ImageWithoutMeta
          }
        }
        title
        requestEmail
        requestPhone
        groupFireCalculatorTypeformId
        quoteForm {
          quoteFormTitle
          quoteSubmissionCopy
          quoteProcessCopy
          quoteAboutPlaceholder
          quoteSubmissionSuccessTitle
          quoteSubmissionSuccessSubtitle
        }
        cart {
          link_title
          emptyTitle
          emptyCopy
          emptyImage {
            ...ImageWithMeta
          }
          emptyCta {
            ...Link
          }
          emptyCtaLabel
          successCopy
          deliveryCopy
          emailValidationCopy
          emailPlaceholder
        }
        productsLink {
          ...Link
        }
        projectsLink {
          ...Link
        }
        resourceLink {
          ...Link
        }
        resourcesNav {
          resourcesMenu {
            text
            reference {
              ...PageReference
            }
            order
            showPanel
          }
        }

        aboutLink {
          ...Link
        }
        menu {
          link {
            text
            reference {
              ...PageReference
            }
          }
        }
        footer {
          acknowledges
          newsletterTitle
          newsletterPlaceholder
          newsletterSuccessMessage
          copyright
          socialLinks {
            url
            text
          }
          productCategories {
            title
            slug {
              current
            }
          }
          projectCategories {
            title
            slug {
              current
            }
          }
          resourceLinks {
            ...Link
          }
          contactPhone
          contactEmail
          partnersWhite {
            logo {
              ...ImageWithMeta
            }
            link
          }
          partnersBrown {
            logo {
              ...ImageWithMeta
            }
            link
          }
          partnersGreen {
            logo {
              ...ImageWithMeta
            }
            link
          }
          partnersBlue {
            logo {
              ...ImageWithMeta
            }
            link
          }
        }
      }
      allSanityProduct(filter: {}) {
        nodes {
          id
          _type
          title
          slug {
            current
          }
          category {
            title
            slug {
              current
            }
          }
        }
        totalCount
        group(field: { fireSubstrateFilters: { slug: { current: SELECT } } }) {
          totalCount
          fieldValue
        }
      }
      allSanityProject {
        totalCount
        group(field: { category: { slug: { current: SELECT } } }) {
          totalCount
          fieldValue
        }
      }
      allSanityPage(
        filter: { parentPage: { slug: { current: { eq: "resources" } } } }
      ) {
        totalCount
        group(field: { category: { slug: { current: SELECT } } }) {
          totalCount
          fieldValue
        }
      }
      allAboutPages: allSanityPage(
        filter: { parentPage: { slug: { current: { eq: "about" } } } }
      ) {
        nodes {
          id
          _type
          title
          slug {
            current
          }
          category {
            title
            slug {
              current
            }
          }
          _updatedAt
        }
      }
      allSanityDocumentResource {
        totalCount
        countByCategory: group(field: { category: SELECT }) {
          totalCount
          fieldValue
        }
        countByProduct: group(field: { product: { id: SELECT } }) {
          totalCount
          fieldValue
        }
      }
      allSanityProductCategory {
        nodes {
          title
          slug {
            current
          }
          order
        }
      }
      allSanityProjectCategory {
        nodes {
          title
          slug {
            current
          }
        }
      }
      allSanityPageCategory {
        nodes {
          title
          slug {
            current
          }
        }
      }
      allSanityProductFilters {
        nodes {
          title
          slug {
            current
          }
          order
        }
      }
    }
  `);

  const globalData = useMemo(
    () => ({
      ...globals.sanitySettings,
      counts: {
        products: globals.allSanityProduct.totalCount,
        productsBySubstrate: keyBy(
          globals.allSanityProduct.group,
          (x) => x.fieldValue
        ),
        projects: globals.allSanityProject.totalCount,
        projectsByCategory: keyBy(
          globals.allSanityProject.group,
          (x) => x.fieldValue
        ),
        resources:
          globals.allSanityPage.totalCount +
          globals.allSanityDocumentResource.totalCount,
        articles: globals.allSanityPage.totalCount,
        articlesByCategory: keyBy(
          globals.allSanityPage.group,
          (x) => x.fieldValue
        ),
        documents: globals.allSanityDocumentResource.totalCount,
        documentsByProduct: keyBy(
          globals.allSanityDocumentResource.countByProduct,
          (x) => x.fieldValue
        ),
        documentsByCategory: keyBy(
          globals.allSanityDocumentResource.countByCategory,
          (x) => x.fieldValue
        ),
      },
      products: globals.allSanityProduct.nodes,
      productCategories: sortBy(
        globals.allSanityProductCategory.nodes,
        (x) => x.order
      ),
      projectCategories: globals.allSanityProjectCategory.nodes,
      productsFilters: sortBy(
        globals.allSanityProductFilters.nodes,
        (x) => x.order
      ),
      pageCategories: globals.allSanityPageCategory.nodes,
      aboutList: globals.allAboutPages.nodes,
    }),
    [globals]
  );

  return (
    <GlobalsContextProvider globals={globalData}>
      <Route location={location} pageContext={pageContext}>
        {children}
      </Route>
    </GlobalsContextProvider>
  );
}

const useStyles = createUseStyles(
  {
    "@global": {
      ...theme.global,
    },
    main: {
      ...(primaryInput === "touch"
        ? {}
        : {
            overflowY: "auto",
            overflowX: "hidden",
            height: "100vh",
            width: "100%",
          }),
    },
    inner: {
      paddingTop: theme.header.height.large,
    },
  },
  { name: "Layout" }
);
