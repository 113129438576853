import React, { useCallback, useState } from "react";
import { createUseStyles } from "react-jss";
import cn from "classnames";
import theme from "../../style/theme";
import { Arrow } from "../Button";
import useFetch from "use-http";

export default function NewsletterForm({
  className,
  title,
  placeholder,
  successMessage,
}) {
  const classes = useStyles();
  const [data, setData] = useState({
    email: "",
    first_name: "",
    last_name: "",
  });
  const { post, response, loading, error } = useFetch("/api/salesforce");

  const setField = useCallback((field, value) => {
    setData((d) => ({
      ...d,
      [field]: value,
    }));
  }, []);

  const onEmailChange = useCallback(
    (e) => {
      setField("email", e.target.value);
    },
    [setField]
  );

  const onFirstNameChange = useCallback(
    (e) => {
      setField("first_name", e.target.value);
    },
    [setField]
  );

  const onLastNameChange = useCallback(
    (e) => {
      setField("last_name", e.target.value);
    },
    [setField]
  );

  const onSubmit = useCallback(
    (e) => {
      const postData = async () => {
        console.log("sending data to salesforce");
        await post({
          ...data,
          oid: "00D28000000XFTy",
          lead_source: "Newsletter",
          Active_Email_Subscriber__c: true,
        });
      };

      postData();
      e.preventDefault();
      return false;
    },
    [data, post]
  );

  const subscribed = response.ok;
  const { last_name: lastName, first_name: firstName, email } = data;

  return (
    <form
      className={cn(className, classes.form)}
      action="/api/newsletter"
      method="post"
      onSubmit={onSubmit}
    >
      <label htmlFor="email" className={classes.title}>
        {title}
      </label>
      {!subscribed && (
        <>
          <input
            className={classes.input}
            required
            name="first_name"
            id="first_name"
            maxLength={40}
            placeholder="First Name"
            value={firstName}
            onChange={onFirstNameChange}
          />
          <input
            className={classes.input}
            required
            name="last_name"
            id="last_name"
            maxLength={40}
            placeholder="Last Name"
            value={lastName}
            onChange={onLastNameChange}
          />
          <div className={classes.inputContainer}>
            <input
              className={classes.input}
              type="email"
              required
              name="email"
              id="email"
              maxLength={80}
              placeholder={placeholder}
              value={email}
              onChange={onEmailChange}
            />
            <button
              className={classes.button}
              type="submit"
              aria-label="Submit"
              disabled={loading || subscribed}
            >
              <Arrow className={classes.arrow} />
              <Arrow className={classes.arrow2} />
            </button>
          </div>
        </>
      )}
      {subscribed && <div>{successMessage}</div>}
      {error && (
        <div className={classes.error}>
          Oops, something has gone wrong, please try again later
        </div>
      )}
    </form>
  );
}

const useStyles = createUseStyles({
  form: {},
  title: {
    opacity: 0.5,
    display: "block",
    fontSize: 12,
    marginBottom: theme.spacing(1),
  },
  input: {
    appearance: "none",
    fontSize: 12,
    border: "none",
    borderTop: [1, "solid", theme.colors.border],
    borderBottom: [1, "solid", theme.colors.border],
    padding: [theme.spacing(2), theme.spacing(6), theme.spacing(2), 0],
    width: "100%",
    margin: [-1, 0, 0, 0],
    borderRadius: 0,
    backgroundColor: "transparent",
    outline: "none",
    boxShadow: "none",
  },
  inputContainer: {
    position: "relative",
  },
  button: {
    width: 18,
    height: theme.spacing(6),
    backgroundColor: "transparent",
    border: "none",
    padding: 0,
    margin: 0,
    cursor: "pointer",
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translate(0, -50%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    overflow: "hidden",
    "&:hover > $arrow": {
      transform: "translate(200%, 0)",
    },
    "&:hover > $arrow2": {
      transform: "translate(200%, 0)",
    },
  },
  arrow: {
    width: 18,
    transition: "transform 0.35s cubic-bezier(0.61, 1, 0.88, 1)",
    flexShrink: 0,
  },
  arrow2: {
    width: 18,
    transition: "transform 0.35s cubic-bezier(0.61, 1, 0.88, 1)",
    position: "absolute",
    right: "200%",
  },
  error: {
    color: "#AA0000",
    marginTop: theme.spacing(1),
  },
});
