const React = require("react");
const Layout = require("./src/components/Layout").default;
const gsap = require("gsap").default;
const CSSPlugin = require("gsap/CSSPlugin").default;
const ScrollToPlugin = require("gsap/ScrollToPlugin").default;
const initLazySizes = require("./src/initLazySizes");
const scrollHelper = require("./src/helpers/scroll");
const scrollTo = require("./src/components/useSmoothScrollbar").scrollTo;
const store = require("./src/state/createStore");
const { rehydrated } = require("./src/state/slices/layout");
const { route } = require("./src/state/slices/route");
// const { navigate } = require("gatsby");
gsap.registerPlugin(CSSPlugin, ScrollToPlugin);

initLazySizes.default();

const slugs = ["projects", "products", "resources"];

exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.action === "PUSH") {
    // scrollTo(0, 0, 0)
  } else {
    const { getStateStorage } = scrollHelper;
    const storage = getStateStorage();
    const storedState = storage.read(window.history.state || {}, null);
    if (storedState) {
      const [x, y] = storedState;
      setTimeout(() => scrollTo(x, y, 0), 0);
    } else {
      // this is handled by the transition
      // scrollTo(0, 0, 0)
    }
  }
  return true;
};

exports.onInitialClientRender = () => {
  const { dispatch } = store.store;
  dispatch(rehydrated());
};

exports.onRouteUpdate = ({ location, prevLocation }) => {
  const { dispatch } = store.store;
  if (
    location.key === "initial" &&
    !Boolean(slugs.includes(location.pathname.replaceAll("/", "")))
  ) {
    location.search = "";
  }
  dispatch(route({ location, prevLocation }));
};
