import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import filter from "lodash/filter";

import LinkList from "../LinkList";
import {
  fetchDocuments,
  getItemsSelector,
  isLoadedSelector,
  isLoadingSelector,
} from "../../state/slices/data/lists";
import ProductProjectItem from "./ProductProjectItem";
import range from "lodash/range";
import get from "lodash/get";
import DownloadImage from "./download.inline.svg";
import { createUseStyles } from "react-jss";
import { Caption } from "../Typography";
import { formatDate } from "../../helpers/format";
import theme from "../../style/theme";
import Button from "../Button";
import UseInternalLinkUrl from "../../hooks/useInternalLinkUrl";
import { formatBytes } from "../../helpers/formatBytes";

export const DocumentItem = ({ tag = "div", document, ...props }) => {
  const classes = useStyles();
  const { title, fileInfo, date } = document;
  if (!document?.file?.asset) return null;

  const {
    file: {
      asset: { size, mimeType },
    },
  } = document;
  const Tag = tag;
  return (
    <Tag className={classes.item} {...props}>
      <div className={classes.content}>
        {title}
        <Caption className={classes.fileInfo}>
          {formatDate(date)} | {fileInfo || mimeType} {formatBytes(size)}
        </Caption>
      </div>
      <DownloadImage className={classes.icon} />
    </Tag>
  );
};

export default function DocumentsList({ product, category }) {
  const classes = useStyles();
  const loading = useSelector(isLoadingSelector("documents"));
  const loaded = useSelector(isLoadedSelector("documents"));
  const documents = useSelector(getItemsSelector("documents"));
  const dispatch = useDispatch();
  const productLink = UseInternalLinkUrl(product);

  useEffect(() => {
    if (!loaded) {
      dispatch(fetchDocuments());
    }
  }, [dispatch]); /* eslint-disable-line */

  const items = useMemo(() => {
    // filters by product AND category
    if (documents && product && category) {
      return filter(
        documents,
        (document) =>
          document.product?.id === product?.id &&
          document.category?.includes(category) &&
          document.file?.asset
      ).map((doc) => ({
        to: get(doc, ["file", "asset", "url"]),
        text: <DocumentItem document={doc} />,
        target: "_blank",
        document: doc,
      }));
    }
    // filters by product OR category
    if (documents && product) {
      const filteredDocuments = filter(
        documents,
        (document) =>
          (document.product?.id === product?.id ||
            document.category?.includes(category)) &&
          document.file?.asset
      );
      return filteredDocuments.map((doc) => ({
        to: get(doc, ["file", "asset", "url"]),
        text: <DocumentItem document={doc} />,
        target: "_blank",
        document: doc,
      }));
    }

    // filters by product OR category
    if (documents && category) {
      const filteredDocuments = filter(
        documents,
        (document) =>
          document.category?.includes(category) && document.file?.asset
      );
      return filteredDocuments.map((doc) => ({
        to: get(doc, ["file", "asset", "url"]),
        text: <DocumentItem document={doc} />,
        target: "_blank",
        document: doc,
      }));
    }
    return [];
  }, [product, documents, category]);

  const placeholders = useMemo(() => {
    const items = range(2);
    return items.map(() => ({
      text: <ProductProjectItem placeholder />,
    }));
  }, []);

  if (!loaded || loading) {
    return <LinkList items={placeholders} />;
  }

  return (
    <>
      <LinkList items={items} hover className={classes.list} size="medium" />
      {product && (
        <Button to={productLink} className={classes.button}>
          View Product{" "}
          <span className={classes.buttonLabel}>{product.title}</span>
        </Button>
      )}
    </>
  );
}

const useStyles = createUseStyles({
  list: {
    marginBottom: theme.spacing(6),
    overflowY: "auto",
    marginRight: -theme.gutter.sm,
    paddingRight: theme.gutter.sm,
    flexGrow: 1,
    ...theme.scrollbar,
    [theme.breakpoints.up("md")]: {
      paddingRight: theme.gutter.md,
      marginRight: -theme.gutter.md,
    },
  },
  item: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 15,
  },
  content: {},
  icon: {
    flexGrow: 0,
    flexShrink: 0,
    width: 14,
    height: 16,
  },
  fileInfo: {
    display: "block",
    marginTop: theme.spacing(1),
    opacity: 0.5,
  },
  button: {
    alignSelf: "flex-start",
  },
  buttonLabel: {
    opacity: 0.5,
    display: "inline-block",
    marginLeft: "0.4em",
  },
});
