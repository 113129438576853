import React from "react";
import { createUseStyles } from "react-jss";
import theme from "../../../style/theme";
import { HorizontalLine, VerticalLine } from "../../Lines";
import RichText from "../../RichText";
import Section from "../../Section";
import Steps from "./Steps";

export default function OurApproach({ slice, id }) {
  const classes = useStyles();
  const { title, _rawCopy, stepsTitle, steps } = slice;
  return (
    <section id={id}>
      <Section tag="div" grid className={classes.section}>
        <h3 className={classes.title}>{title}</h3>
        <RichText blocks={_rawCopy.text} className={classes.copy} />
        <div className={classes.verticalLine}>
          <VerticalLine position="right" animationOrigin="0%" />
        </div>
        <HorizontalLine position="bottom" extend />
      </Section>
      <Steps title={stepsTitle} steps={steps} />
    </section>
  );
}

const useStyles = createUseStyles({
  section: {
    position: "relative",
    marginTop: -theme.section.marginBottom.sm,
    [theme.breakpoints.up("md")]: {
      marginTop: -theme.section.marginBottom.md,
    },
  },
  title: {
    gridColumn: "1 / span 4",
    margin: [theme.gutter.sm, 0, theme.spacing(30)],
    fontSize: 35,
    lineHeight: 1,
    [theme.breakpoints.up("md")]: {
      fontSize: "calc(35px + (65 - 35) * ((100vw - 320px) / (1920 - 320)))",
      maxWidth: 400,
      gridColumn: "4 / span 4",
      margin: [theme.gutter.md, 0, theme.spacing(40)],
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 65,
    },
  },
  copy: {
    "& p:first-child": {
      textIndent: theme.styles.textIndent,
    },
    gridColumn: "1 / span 4",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      maxWidth: 400,
      gridColumn: "4 / span 4",
    },
  },
  verticalLine: {
    position: "relative",
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
      gridColumn: 3,
      gridRow: "1 / span 3",
    },
  },
  horizontalLine: {
    position: "relative",
    gridColumn: "1 / span 4",
    [theme.breakpoints.up("md")]: {
      gridColumn: "1 / span 7",
    },
  },
});
