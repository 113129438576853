import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from '@reach/router'
import { resolveInternalLinkUrl } from '../../helpers/resolveLink'
import Link from '../Link'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import { getY } from '../useSmoothScrollbar'
import { isPanelOpen, openPanel, slugs } from '../../state/slices/drawer'
import cn from 'classnames'

export const getCurrentHeaderHeight = () => {
  return getY() === 0 && window.innerWidth >= theme.breakpoints.values.md ? theme.header.height.large : theme.header.height.small
}

const isLinkActive = (currentPath, linkPath) => {
  return currentPath.startsWith(linkPath)
}

const MenuItem = ({ link, className, containerClassName, isActiveLinkEnabled = true }) => {
  const { pathname } = useLocation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const slug = link.reference.slug.current
  const onClick = useCallback((e) => {
    if (slugs.includes(slug)) {
      dispatch(openPanel({ slug }))
      e.preventDefault()
    }
    return false
  }, [dispatch, slug])
  const isPanelOpenSelector = useMemo(() => isPanelOpen(slug), [slug])
  const internalLink = resolveInternalLinkUrl(link.reference)
  const selected = useSelector(isPanelOpenSelector) || (isActiveLinkEnabled && isLinkActive(pathname, internalLink))
  return (
    <li className={cn(containerClassName, classes.menuItem)}>
      <Link className={cn(className, classes.link, { selected })} to={internalLink} onClick={onClick} useTransitionLink={!slugs.includes(slug)}>{link.text}</Link>
    </li>
  )
}

export default MenuItem

const useStyles = createUseStyles({
  menuItem: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  link: {
    cursor: 'pointer',
    color: 'currentColor',
    padding: 0,
    border: 'none',
    background: 'transparent',
    borderRadius: 0,
    textTransform: 'uppercase',
    fontSize: 10,
    lineHeight: 1.2,
    fontFamily: theme.fonts.body,
    letterSpacing: 0.5,
    textDecoration: 'none',
    minHeight: 42,
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    '&:after': {
      position: 'absolute',
      content: '""',
      top: '50%',
      left: 0,
      right: 0,
      transform: 'translate(-110%, 1.2em)',
      transformOrigin: '0% 50%',
      backgroundColor: 'currentColor',
      display: 'block',
      height: 1,
      transition: 'transform 0.15s ease-in-out'
    },
    '&:hover:after, &.active:after, &.selected:after': {
      transform: 'translate(0%, 1.2em)'
    }
  }
})
