import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import theme from '../style/theme'

export const Caption = forwardRef(({ className, tag = 'span', ...rest }, ref) => {
  const classes = useStyles()
  const Tag = tag
  return <Tag className={cn(classes.caption, className)} {...rest} ref={ref} />
})

const useStyles = createUseStyles({
  caption: {
    fontFamily: theme.fonts.caption,
    fontSize: 10,
    lineHeight: 1.2,
    textTransform: 'uppercase',
    whiteSpace: 'pre-wrap'
  }
})
