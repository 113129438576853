import React, { useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import ToolbarItem from './ToolbarItem'
import ChatIcon from './chat.inline.svg'
import CloseIcon from '../Drawer/close.inline.svg'
import { isChatPopupOpen, togglePopup } from '../../state/slices/ui/chat'
import { useDispatch, useSelector } from 'react-redux'

export default function ChatToolbarItem ({ className }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const chatPopupOpen = useSelector(isChatPopupOpen)

  const onChatToggle = useCallback(() => {
    dispatch(togglePopup())
  }, [dispatch])

  return (
    <ToolbarItem
      title='Chat online'
      invert={!chatPopupOpen}
      button
      icon={chatPopupOpen ? <CloseIcon className={classes.closeIcon} /> : <ChatIcon className={classes.closeIcon} />}
      onClick={onChatToggle}
    />
  )
}

const useStyles = createUseStyles({
  closeIcon: {
    width: 14,
    height: 14
  }
})
