import React from "react";
import { createUseStyles } from "react-jss";
import cn from "classnames";
import theme from "../../../style/theme";
import { HorizontalLine, VerticalLine } from "../../Lines";
import ProductModel from "../../ProductModel";
import Section from "../../Section";
import { Caption } from "../../Typography";
import ProductDetailsPanel from "./ProductDetailsPanel";
import useOnHydration from "../../../hooks/useOnHydration";
import WaveTextAnimation from "../../WaveTextAnimation";
import ResponsiveImage from "../../ResponsiveImage";

const Meta = ({ className, page }) => {
  const { category, soundProfile } = page;
  const classes = useStyles();
  return (
    <div className={cn(className, classes.metaContainer)}>
      <div className={classes.meta}>
        <Caption>{soundProfile}</Caption>
        {category && <Caption>{category.title}</Caption>}
      </div>
    </div>
  );
};

export default function Hero({ slice, page }) {
  const classes = useStyles();
  const { title, copy } = slice;
  const { model, flatModel, modelAngleThreshold, modelLineOffsetFactor } = page;
  const hydrated = useOnHydration();

  return (
    <Section grid className={classes.section} id="product-hero">
      <div className={classes.verticalLine}>
        <VerticalLine animationOrigin="0%" />
      </div>
      <div className={classes.verticalLine2}>
        <VerticalLine animationOrigin="0%" />
      </div>
      <div className={classes.titleLine}>
        <HorizontalLine animationOrigin="40%" />
      </div>
      <div className={classes.modelLine}>
        <HorizontalLine animationOrigin="0%" />
      </div>
      <div className={classes.bottomLine}>
        <HorizontalLine animationOrigin="40%" />
      </div>

      <Meta className={classes.metaTop} page={page} />
      <Meta className={classes.metaBottom} page={page} />

      <div className={classes.detailsContainer}>
        <ProductDetailsPanel product={page} className={classes.details} />
      </div>

      <WaveTextAnimation>
        <h1 className={classes.title}>{title}</h1>
      </WaveTextAnimation>
      <div className={classes.modelContainer}>
        {!flatModel && model && model.asset && hydrated && (
          <ProductModel
            modelUrl={model.asset.url}
            thresholdAngle={modelAngleThreshold}
            lineOffset={modelLineOffsetFactor}
            zoomEnabled={false}
          />
        )}
        {flatModel && hydrated && (
          <ResponsiveImage
            image={flatModel}
            aspect={2326 / 1672}
            className={classes.flatModel}
            fadeIn
            fadeInDuration={0.5}
            showPreview={false}
          />
        )}
      </div>
      <div className={classes.copy}>{copy}</div>
    </Section>
  );
}

const useStyles = createUseStyles({
  section: {
    position: "relative",
  },
  lineContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    pointerEvents: "none",
  },
  rightContainer: {
    gridColumn: "1 / span 4",
    [theme.breakpoints.up("md")]: {
      gridColumn: "4 / span 4",
    },
  },
  metaContainer: {
    overflow: "hidden",
  },
  meta: {
    composes: ["$rightContainer"],
    display: "flex",
    opacity: 0.5,
    margin: [0, -theme.spacing(5)],
    overflow: "hidden",
    "& > *": {
      margin: [0, theme.spacing(5)],
      display: "block",
    },
    [theme.breakpoints.up("md")]: {
      display: "grid",
      gridTemplateColumns: "repeat(4, 1fr)",
      gap: theme.gutter.md,
      margin: 0,
      "& > *": {
        margin: 0,
      },
    },
  },
  metaTop: {
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(12),
    gridColumn: "1 /span 4",
    gridRow: 1,
    [theme.breakpoints.up("md")]: {
      marginLeft: 0,
      gridColumn: "4 /span 4",
    },
  },
  metaBottom: {
    gridRow: 5,
    gridColumn: "1 /span 4",
    [theme.breakpoints.up("md")]: {
      gridColumn: "4 /span 4",
    },
  },
  title: {
    composes: ["$rightContainer"],
    gridColumn: "1 / span 4",
    gridRow: "2",
    margin: [theme.spacing(60), 0, 0, theme.spacing(12)],
    lineHeight: 1,
    fontSize: 38,
    [theme.breakpoints.up("md")]: {
      fontSize: 65,
      margin: [theme.spacing(60), 0, 0, 0],
      gridColumn: "4 / span 4",
    },
  },
  modelContainer: {
    composes: ["$rightContainer"],
    height: "100vw",
    gridColumn: "1 /span 4",
    [theme.breakpoints.up("md")]: {
      gridColumn: "4 /span 4",
      height: "50vw",
      maxHeight: 900,
    },
  },
  flatModel: {
    top: "50%",
    transform: "translateY(-50%)",
  },
  copy: {
    gridColumn: "1 / span 4",
    margin: [theme.spacing(5), 0],
    [theme.breakpoints.up("md")]: {
      gridColumn: "4 / span 2",
      margin: [0, 0, theme.spacing(60), 0],
    },
    whiteSpace: "pre-line",
  },
  detailsContainer: {
    alignSelf: "start",
    gridColumn: "1 / span 4",
    gridRow: "3",
    overflow: "hidden",
    margin: [0, -theme.gutter.sm],
    [theme.breakpoints.up("md")]: {
      margin: [0, theme.gutter.md, 0, 0],
      gridColumn: "1 / span 3",
      gridRow: "4",
    },
    [theme.breakpoints.up("lg")]: {
      gridColumn: "1 / span 2",
    },
  },
  details: {
    margin: [1, -1],
    [theme.breakpoints.up("md")]: {
      margin: [1, 0],
      width: "calc(100% - 2px)",
    },
  },
  verticalLine: {
    position: "relative",
    gridColumn: "1",
    gridRow: "1 / span 2",
    marginLeft: theme.spacing(8),
    width: 1,
    marginBottom: -theme.gutter.sm,
    [theme.breakpoints.up("md")]: {
      width: "100%",
      marginLeft: 0,
      marginBottom: 0,
      gridColumn: "3",
      gridRow: "1 / span 3",
    },
  },
  verticalLine2: {
    position: "relative",
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
      gridColumn: "3",
      gridRow: "3 / span 6",
    },
  },
  titleLine: {
    position: "relative",
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
      gridColumn: "1 / span 7",
      gridRow: "3",
      left: -theme.gutter.md,
      width: `calc(100% + 2 * ${theme.gutter.md}px)`,
    },
  },
  modelLine: {
    position: "relative",
    gridColumn: "1 / span 4",
    gridRow: "6",
    left: -theme.gutter.sm,
    width: `calc(100% + 2 * ${theme.gutter.sm}px)`,
    [theme.breakpoints.up("md")]: {
      gridColumn: "4 / span 4",
      gridRow: "6",
      left: -theme.gutter.md,
      width: `calc(100% + 2 * ${theme.gutter.md}px)`,
    },
  },
  bottomLine: {
    position: "relative",
    gridColumn: "1 / span 4",
    gridRow: "8",
    left: -theme.gutter.sm,
    width: `calc(100% + 2 * ${theme.gutter.sm}px)`,
    [theme.breakpoints.up("md")]: {
      gridColumn: "1 / span 7",
      gridRow: "8",
      left: -theme.gutter.md,
      width: `calc(100% + 2 * ${theme.gutter.md}px)`,
    },
  },
});
