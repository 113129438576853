import listing from './listing'
import cart from './cart'
import chat from './chat'

const ui = [
  listing,
  cart,
  chat
]

export default ui
